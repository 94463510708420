import React, { useMemo } from 'react';
import SideFilter from '../../components-v2/SideFilter/SideFilter';
import InputField from '../../ui-v2/InputField/InputField';
import axiosErrorHandler from '../../utility/axiosErrorHandler';
import axios from 'axios';
import BoxField from '../../ui-v2/BoxField/BoxField';

const Filter = ({ loading, onChange, img_text = "", message = "", isImage, chat, num_loop, should_loop }) => {
  const onSubmit = async () => {

    try {
      if (isImage) {
        onChange({ loading: true });
        const data = (await axios.post('/open_ai_image', { text: img_text, number: 4 })).data;
        if (data.status !== "success") throw Error('Something went wrong!');
        return onChange(data);
      }
      let newChat = [...chat, { message }];
      onChange({ chat: newChat, loading: true });
      let isRobot = true
      if (should_loop){
        for(let i=0;i<num_loop;i++){
          const data = (await axios.post('/open_ai_chat', { chat:newChat})).data;
          if (data.status !== "success") throw Error('Something went wrong!');

          onChange({ chat: [...newChat, { message: data.result, isRobot: isRobot }] })
          newChat = [...newChat, { message: data.result, isRobot: isRobot }]
          isRobot = !isRobot
        }
      }else{
        const data = (await axios.post('/open_ai_chat', { chat:newChat})).data;
        if (data.status !== "success") throw Error('Something went wrong!');

        onChange({ chat: [...newChat, { message: data.result, isRobot: isRobot }] })
      }


    }
    catch (err) {
      axiosErrorHandler(err);
      onChange({ loading: false });
    }
  }

  const generate_movie = async () => {
    const script_arr = chat[chat.length - 1].message.split('.');
    for (let i = 0; i < script_arr.length; i = i + 2) {
      const text = (script_arr[i] + ' ' + (script_arr[i + 1] || '')).trim();
      if (text !== "") {
        const message = (await axios.post('/open_ai_image', { text, number: 1 })).data.other_images[0];

        const newChat = [...chat, { message, isRobot: true, isImg: true }];
        onChange({ chat: newChat })
      }
    }
  }

  return (<>

    <SideFilter>

      <div className='d-flex flex-column gap-3'>
        <BoxField label="Would you like the AI to talk to itself after your initial question?" checked={should_loop} onChange={() => onChange({ should_loop: !should_loop})} />

      </div>
      {should_loop&&
        <InputField
          type="number"
          value={num_loop}

          name='num_loop'
          label={"How many times would you like the AI to go back and forth?"}
          variant="outlined"
          placeholder='5'
          onChange={e => e.target.value <5? onChange({ "num_loop": e.target.value }):onChange({ "num_loop": 5 })}
        />
      }
      <InputField
        multiline
        value={isImage ? img_text : message}
        rows={5}
        name='text'
        label={isImage ? "Generate an image?" : "Ask a question?"}
        variant="outlined"
        placeholder="I want you to explain space and time and at the end of every subsequent answer you give me I want you to ask me a question"
        onChange={e => onChange({ [isImage ? "img_text" : "message"]: e.target.value })}
      />
      <button disabled={loading}
        onClick={onSubmit}
        className={`btn btn-primary ${loading ? 'progress-btn' : ''}`}

      >
        {isImage ? "Generate." : "Ask"}
      </button>
      <button
        onClick={e =>onChange({ chat: [] })}
      >
        Clear
      </button>
    </SideFilter>
  </>)
}

export default Filter;
