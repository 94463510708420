import React from 'react';
import MagnifierGlassIcon from "../../icons-v2/MagnifierGlassIcon";
import styles from './SearchField.module.scss';

const SearchField = ({ className = '', placeholder = 'Search', children, ...props }) => (
    <label className={`d-flex align-items-center position-relative ${className}`}>
        <MagnifierGlassIcon className={`cursor-text text-primary position-absolute ${styles.icon}`} />
        <input {...props} placeholder={placeholder} className={`w-100 ${styles.input} text-field`} />
        {children}
    </label>
)

export default SearchField;