import React from 'react';
// import React, { useEffect, useState } from "react";
import SideFilter from '../../components-v2/SideFilter/SideFilter';
import InputField from '../../ui-v2/InputField/InputField';
// import moment from "moment";
// import PickersUtilsProvider from '../../ui-v2/PickersUtilsProvider/PickersUtilsProvider';
// import DatePicker from '../../ui-v2/DatePicker/DatePicker';
import BoxField from '../../ui-v2/BoxField/BoxField';



const CoorelationFilter = ({ handleChange, fb_email, fb_pass, app, on_run, direction }) => {

    return (
        <SideFilter>
            <div className='d-flex flex-column gap-3'>
                <div>
                    <h5 className="m-0">Enter your facebook login you use for dating apps.</h5>
                    <p className="text-accent-4">We do not store your fb info</p>
                </div>
                <InputField
                    name='fb_email'
                    label="Email"
                    onChange={handleChange}
                    value={fb_email}
                />
                <InputField
                    name='fb_pass'
                    label="Password"
                    type='password'
                    onChange={handleChange}
                    value={fb_pass}
                />
                <div className='d-grid col-2 overflow-initial'>
                    <BoxField name="app"
                        type='radio'
                        onChange={handleChange}
                        value="bmbl"
                        label='Bumble'
                        checked={app === 'bmbl'}
                    />
                    <BoxField name="app"
                        type='radio'
                        onChange={handleChange}
                        value="tndr"
                        label='Tinder'
                        checked={app === 'tndr'}
                    />
                    {/*
                <BoxField name="app"
                    type='radio'
                    onChange={handleChange}
                    value="shpr"
                    label='Shapr'
                    checked={app === 'shpr'}
                />
                */}
                </div>
            </div>
            <div className='d-grid col-2 overflow-initial'>
                <BoxField name="direction"
                    type='radio'
                    onChange={handleChange}
                    value="left"
                    label='Swipe Left'
                    checked={direction === 'left'}
                />
                <BoxField name="direction"
                    type='radio'
                    onChange={handleChange}
                    value="right"
                    label='Swipe Right'
                    checked={direction === 'right'}
                />
            </div>
            <button onClick={on_run} className='btn-primary btn'>Run</button>
        </SideFilter>
    )
}

export default CoorelationFilter;
