import React from 'react';
import StockItem from './StockItem';
import TotalResults from '../../components-v2/AlgoHoldingCommonTools/TotalResults';

const StockTable = ({ ledger, total }) => {
    return (
        <>
            <TotalResults totalLength={total} />
            <div className='overflow-auto'>
                {ledger.length > 0 ? ledger.map(item => <StockItem {...item} key={item._id} />) : (
                    <td colSpan={20}>
                        <div className='text-center'>No Results...</div>
                    </td>
                )}
            </div>
        </>
    )
}

export default StockTable;
