import React, { useEffect, useState, memo } from 'react';
import Tag from '../Tag/Tag';

const defaultTags = ['#algo', '#tool', '#info', '#ai', '#screener', '#stocks', '#options', '#crypto', '#government']

const SelectTags = ({ required, errorText, onChange, values, defaultValues = [], className, Component = Tag, tags = defaultTags, isMultiple = true, ...props }) => {
    const [selectedTags, setSelectedTags] = useState([...defaultValues]);
    const onTagChange = e => {
        const { value, checked } = e.target;
        const newTags = isMultiple ? selectedTags.filter(tag => tag !== value) : [value];
        checked && newTags.push(value);
        setSelectedTags(newTags)
        onChange && onChange(e, isMultiple, newTags);
    }
    useEffect(() => {
        if (values) setSelectedTags(values);
    }, [values])

    return (
        <div className={className || 'd-flex flex-wrap gap-2 text-accent-4'}>
            <input type='text' className='d-none' required={required && selectedTags.length === 0} />
            {tags.map(tag => (
                <Component
                    label={tag}
                    type={isMultiple ? 'checkbox' : 'radio'}
                    key={tag}
                    value={tag}
                    title={tag}
                    onChange={onTagChange}
                    defaultChecked={values ? undefined : selectedTags.includes(tag)}
                    checked={values ? values.includes(tag) : undefined}
                    {...props}
                />
            ))}
            {errorText && <span className='errorText'>{errorText}</span>}
        </div>
    )
}

export default memo(SelectTags);