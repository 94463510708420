import React from 'react';
import SideFilter from '../../components-v2/SideFilter/SideFilter';
import Range from './Range';
import MobileSearchBox from '../../components-v2/AlgoHoldingCommonTools/MobileSearchBox';
import SearchBox from '../../components-v2/SearchBox/SearchBox';
import WatchListImport from '../../components-v2/AlgoHoldingCommonTools/watchlist_import';
import FilterButton from '../../components-v2/AlgoHoldingCommonTools/FilterButton';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';


const FundamentalsFilter = ({ ranges, handleToggle, fields, getPureData, filter, clearFiltersHandler, ...props }) => {
    // footer={(
    //     <div className='d-flex align-items-center text-accent-3 gap-3'>
    //         {props.loading && <><p className='m-0'>Loading...</p> <Spinner fontSize='0.35rem' /></>}
    //         <button
    //             disabled={props.loading}
    //             onClick={() => props.getData()}
    //             className={`btn smaller-sm btn-primary font-size-normal flex-1 px-3`}
    //         >Apply Filters</button>
    //     </div>
    // )}
    const watchlist = <WatchListImport algo={props.algo} handleStockChange={props.handleStockChange} isMultiple />
    return (
        <SideFilter
            isResponsive={true}
            responsiveHeader={<h6 className='mt-0 mb-2'>Filters</h6>}
            component={(
                <>
                    <div className='d-flex gap-3 align-items-center w-100'>
                        <MobileSearchBox
                            name='ticker'
                            className='flex-1'
                            label="Search for stocks"
                            onChange={props.handleStockChange}
                            defaultValue={filter.ticker}
                            placeholder='Search for a specific stock'
                            onSubmit={() => props.getData()}
                        />
                        <FilterButton />
                    </div>
                    {watchlist}
                </>
            )}
        >
            <div className='d-flex flex-column gap-3 border-bottom pb-4'>
                <div className='d-lg-flex d-none justify-content-between align-items-center'>
                    <small>Data Filters </small>
                    {watchlist}
                </div>
                <div className='mb-1'>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={filter.specific_search}
                                onChange={handleToggle}
                                name="specific_search"


                                // disabled={true}
                                color="primary"
                            />
                        }
                        label="Specific Search"
                    />

                </div>
                <div className='d-lg-block d-none'>
                    <SearchBox
                        name='ticker'
                        label="Search for a specific stock"
                        onChange={props.handleStockChange}
                        value={filter.ticker}
                    />
                    <button className='btn-text text-accent-2 mt-1 small btn me-auto' onClick={clearFiltersHandler}>Clear Filters</button>
                </div>
                {ranges && fields.map((item, index) => ranges[item.name] && (
                    <Range getPureData={getPureData}
                        onChange={props.handleChange}
                        {...item}
                        key={index}
                        range={ranges[item.name]}
                        checked={!filter[`pure_${item.name}`]}
                        filter={filter}
                    />
                ))}
            </div>
        </SideFilter>
    )
}

export default FundamentalsFilter;
