import { useEffect, useState } from "react"

const useLoaded = () => {
    const [isLoaded, setIsLoaded] = useState(false);
    useEffect(() => {
        window.onload = () => {
            setIsLoaded(true)
        }
    }, [])
    return isLoaded;
}

export default useLoaded;