import React from "react";
import { trackEvent } from '../tiktokTracking';
import InputField from "../ui-v2/InputFieldV2";
import SiteTitle from '../components/site_title'
import Form from "../hoc-v2/Form";
import SocialLogins from "../components-v2/SocialLogins/SocialLogins";
import useAuth from "../hooks/useAuth";
import BoxField from "../ui-v2/BoxField/BoxField";
import { registerAPI } from "../apis/User";
import cookie from 'react-cookies'
import { Link } from 'react-router-dom';
import ActivateEmailResend from "../components-v2/ActivateEmailResend/ActivateEmailResend";
import LoginButton from "../components-v2/LoginButton/LoginButton";
import emailRegex from "../constants/emailRegex";
import Password from "../ui-v2/Password";

const SignUpPage = ({ isPopup }) => {
    useAuth({ isForUnAuthorized: true });
    const user_came_from = cookie.load("initial_landing_page")
    const referred_by = cookie.load("referred_by")
    const component_referred = cookie.load("component_referred")
    const coupon_code = cookie.load("coupon_code")

    trackEvent('Start Sign Up', {
        location: window.location.href,
        time: new Date().toISOString(),
    })
    const onSubmit = formData => {
        const { values, onFailure, onSuccess } = formData;
        
        // Check if passwords match
        if (values.password !== values.confirmPassword) return onFailure("Your passwords don't match.");
        
        // Attempt to register
        registerAPI({
            ...values,
            user_came_from: user_came_from,
            referred_by: referred_by,
            component_referred: component_referred,
            referral: (component_referred !== undefined && component_referred.length > 0),
            coupon_code: coupon_code
        })
        .then(() => {
            // Track successful completion of registration
            trackEvent('Complete Registration', {
                email: values.email,
                time: new Date().toISOString(),
            });

            // Execute onSuccess function with a custom message and a component
            onSuccess(
                <>Thank you for registering. An activation email has been sent to verify your email address. Check your junk folder. <ActivateEmailResend {...values} /></>
            );
        })
        .catch(err => onFailure(err))
    }

    return (
        <>
            <SiteTitle
                title={`StockAlgos - Register`}
                meta_description={`Register for a StockAlgos's account today!`}
                meta_keywords={`StockAlgos, Register`}
            />
            <div>
                <h4 className={`mt-0 mb-2 h5-sm ${isPopup ? 'model-title' : ''}`}>Sign Up</h4>
                <p className='m-0 d-flex gap-1'>or
                    <LoginButton className="btn btn-text" isPopup={isPopup}>sign In</LoginButton>
                </p>
            </div>

            <Form onSubmit={onSubmit} className='d-flex flex-column gap-3'
                footer={props => <button className={`btn mt-1 btn-primary ${props.className}`} disabled={props.disabled}>Create an account</button>
                }>
                <InputField
                    name='name'
                    title='Display Name'
                    required={true}
                    errorText="Name should be minimum 3 characters long!"
                    className='flex-1'
                    minLength={3}
                />
                <InputField
                    title='Email'
                    name='email'
                    type='email'
                    required={true}
                    errorText="Please provide a valid email address!"
                    regex={emailRegex}
                />
                <Password
                    title='Password'
                    name='password'
                    errorText="Password must be minimum 8 characters long!"
                />
                <Password
                    title='Confirm Password'
                    name='confirmPassword'
                    errorText="Confirm Password must be minimum 8 characters long!"
                    className='mb-1'
                />
                <BoxField
                    name="pp_tc_confirmed"
                    className='text-accent-4'
                    label={<>I agree to <Link to='/privacy' className='btn-text btn d-inline-flex'><u>Privacy Policy</u></Link> and <Link to='/terms-and-conditions' className='btn-text btn d-inline-flex' ><u>Terms of service</u>.</Link></>}
                    errorText="Must agree to documents before moving forward."
                    required={true}
                    value={true}
                />
            </Form>
            <SocialLogins />
        </>
    )
}


export default SignUpPage;
