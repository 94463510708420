import React, { Component } from 'react';
import { Chart } from 'react-charts'
import styles from './results_graph.module.scss';
import Model from '../ui-v2/Modal/Model';

class MyChart extends Component {
  state = {
    data: [
      {
        label: 'Profit',
        data: [[0, 3], [1, 1], [2, 5], [3, 6], [4, 4]]
      }],
    axes: [
      { primary: true, type: 'linear', position: 'bottom' },
      { type: 'linear', position: 'left' }
    ]

  }
  componentDidMount() {

    if (this.props.results.length > 0) {
      let arr = []
      this.props.results.map((result, index) => {
        let temp_arr = [index, result.ongoing_profit]
        arr.push(temp_arr)
        if (arr.length === this.props.results.length) {
          this.setState({
            data: [{                   // object that we want to update
              ...this.state.data[0],    // keep all other key-value pairs
              data: arr      // update the value of specific key
            }]
          })
        }
        return true
      })

    }

  }
  componentDidUpdate(prevProps) {
    if (this.props !== prevProps && this.props.results.length > 0) {
      let arr = []
      this.props.results.map((result, index) => {
        let temp_arr = [index, result.total_profit * this.props.volume_of_trades]
        arr.push(temp_arr)
        if (arr.length === this.props.results.length) {
          this.setState({
            data: [{                   // object that we want to update
              ...this.state.data[0],    // keep all other key-value pairs
              data: arr      // update the value of specific key
            }]
          })
        }
        return true
      })

    }

  }

  render() {

    return (

      <Model closeHandler={this.props.close} show={true}>
        <div>
          <div className='d-flex align-items-center'>
            <p className={`${styles.xAxis} my-0 rotate-90`}>{this.props.y_axis}</p>
            <div className={`position-relative flex-1 ${styles.chartWrapper}`}>
              <div className='position-absolute start-0 end-0 bottom-0 top-0'><Chart data={this.state.data} axes={this.state.axes} tooltip /></div>
            </div>
          </div>
          <p className='text-center mb-0'>{this.props.x_axis}</p>
        </div>
      </Model>

    )
  }

}

export default MyChart;
