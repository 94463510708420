import React from "react";
import { Link } from "react-router-dom";
import UserAvatar from "../../UserAvatar/UserAvatar";
import styles from './User.module.scss';

const User = ({ className = '', subtitle, isLoggedIn, ...props }) => (
    <Link to={isLoggedIn ? `/leaderboard/user/${props._id}` : { hash: 'login' }}
        className={`${styles.root} text-normal ${className}`}
        name={props.name}
        subtitle={subtitle}
    >
        <UserAvatar {...props} />
    </Link>
)

export default User;