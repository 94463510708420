import React from 'react';
import LoginButton from '../../LoginButton/LoginButton';
import HowItWorksCard from '../HowItWorksCard/HowItWorksCard';
import styles from './Banner.module.scss';


const Banner = () => (
    <div className={`background small leaderboard-sticky ${styles.root} m-2 m-lg-0 text-surface-1 border-radius-8 overflow-hidden`}>
        <div className={`py-4 px-3 mx-1 ${styles.header}`}>
            <h5 className='mt-0 mb-2 pb-1'>See how well you predict the stock market.</h5>
            <p className='opacity-8 m-0'>Sign up to post predictions and interact with others.</p>
            <LoginButton isLogin={false} className='bg-accent-1-gradient btn btn-primary text-normal w-100 mt-4'>Sign up</LoginButton>
        </div>
        <HowItWorksCard title="Sign up to access following features" className={`mx-1 py-4 ${styles.main}`} />
    </div>
)

export default Banner;