import React from 'react';

const faqs = [
    {
        title: "What is the Leaderboard?", description: (
            <>
                <p className='m-0 text-accent-4'>The <b className='text-normal'>StockAlgos Leaderboard</b> is a platform that ranks and analyzes the most profitable and consistent stock pickers. It’s designed for anyone interested in the stock market, whether you’re a beginner or a seasoned pro.</p>
                <p className='m-0 text-accent-4'>On the leaderboard, users make predictions about stock movements and their accuracy is tracked over time. For example, a user might predict that a particular stock will go up by a certain percentage by a specific date. These predictions are then monitored and the results are updated on the leaderboard.</p>
                <p className='m-0 text-accent-4'>The goal of the StockAlgos Leaderboard is to provide a space where users can find inspiration, learn new skills, and challenge themselves. It’s a great way to see how your stock market predictions stack up against others and learn from the strategies of successful stock pickers. So, if you think you’re good at predicting the stock market, or want to get better at it, the StockAlgos Leaderboard could be a useful tool for you.</p>
            </>
        )
    },
    {
        title: "How does the contest work?", description: (
            <>
                <p className='m-0 text-accent-4'>StockAlgos hosts a monthly stock prediction competition. In this contest, users compete against each other by making predictions about stock movements. The accuracy of these predictions is tracked over time.</p>
                <p className='m-0 text-accent-4'>For example, a user might predict that a particular stock will go up by a certain percentage by a specific date. These predictions are then monitored and the results are updated on the leaderboard.</p>
                <p className='m-0 text-accent-4'>The winners of the competition are announced at the end of each month. This contest provides a great opportunity for users to test their stock market prediction skills and learn from others. It’s a fun and engaging way to challenge yourself and see how your predictions stack up against others.</p>
            </>
        )
    },
    {
        title: "How it Works?", description: (
            <>
                <div className='d-grid gap-2'>
                    <b className="small">Make Predictions</b>
                    <ul className='ol list-unstyled d-grid gap-1'>
                        <li>Pick a Stock.</li>
                        <li>Select how much you think it will move and direction.</li>
                        <li>Select the date for which your prediction will come true.</li>
                        <li>See results <label htmlFor='scoring-system' className="btn-text btn me-1">View Scoring System</label></li>
                    </ul>
                </div>
                <div className='d-grid gap-2'>
                    <b className="small">Interact with others' predictions</b>
                    You can interact with other's predications with likes and comments.
                </div>
                <div className='d-grid gap-2'>
                    <b className="small">Keep an eye on the Leaderboard</b>
                    The leaderboard shows top accurate predictors with their average accuracy(%).
                </div>
                <label className='btn btn-primary me-auto' htmlFor='create-post'>Make a Prediction</label>
            </>
        )
    }
]

export default faqs;