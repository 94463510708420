import React from 'react';
import styles from './StockItem.module.scss';
import ToolTip from '../../../ui-v2/ToolTip/ToolTip'

const StockItem = ({ company_name, ticker, action, onClick, className="" }) => (
    <ToolTip title={company_name}>
        <div className={`${styles.root} ${className}`} ticker={ticker} company={company_name} onClick={onClick}>
            {action}
        </div>
    </ToolTip>
)

export default StockItem;