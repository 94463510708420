import React, { useState, useEffect, useCallback } from 'react';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';


function GoogleMapsComponent({ results = undefined, pins = undefined, onLocationChange, hoveredCardIndex }) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyDvOq_rqiHvqWEqh83Yx4-ZW6ElE48Myqo"  // Make sure to replace with your Google Maps API key
  });

  const [map, setMap] = useState(null);
  const [userLocation, setUserLocation] = useState(null); // Store user's current location

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const location = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
        setUserLocation(location);
        onLocationChange && onLocationChange(location);
      });
    }
  }, [onLocationChange]);

  const onDragEnd = () => {
    const center = map.getCenter();
    const bounds = map.getBounds();

    // Extract the Southwest & Northeast corners of the bounds
    const sw = bounds.getSouthWest();
    const ne = bounds.getNorthEast();

    // You can use these to get the min and max latitudes and longitudes
    const latitudeMin = sw.lat();
    const longitudeMin = sw.lng();
    const latitudeMax = ne.lat();
    const longitudeMax = ne.lng();

    onLocationChange && onLocationChange({
      lat: center.lat(),
      lng: center.lng(),
      latitudeMin: latitudeMin,
      longitudeMin: longitudeMin,
      latitudeMax: latitudeMax,
      longitudeMax: longitudeMax,
    });
  };
  const onLoad = useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds();

    // If there are results, extend the bounds for each result
    // if (results && results.length > 0) {
    //   results.forEach(result => {
    //     bounds.extend({ lat: result.property.address.latitude, lng: result.property.address.longitude  });
    //   });

    //   map.fitBounds(bounds);
    // }

    setMap(map);
  }, [results]);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  return isLoaded ? (
    <GoogleMap
      center={userLocation || { lat: 43.6579334, lng: -79.4340402 }} // Use user's location if available, otherwise fallback to default
      zoom={11}
      onLoad={onLoad}
      onUnmount={onUnmount}
      onDragEnd={onDragEnd}
    >
      {/* Render markers based on results */}
      {results?.map((result, index) => (
        <Marker
          key={index}
          position={{ lat: parseFloat(result.Property.Address.Latitude), lng: parseFloat(result.Property.Address.Longitude) }}
          animation={hoveredCardIndex === index ? window.google.maps.Animation.BOUNCE : null}
        />
      ))}
      {/* {pins.length>0 && pins?.map((result, index) => (
        <Marker
          key={index}
          position={{ lat: parseFloat(result.latitude), lng: parseFloat(result.longitude) }}
        />
      ))} */}



    </GoogleMap>
  ) : <></>
}

export default GoogleMapsComponent;