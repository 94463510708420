import React, { useState } from 'react';
import JoinButton from '../../CompetitionComponents/JoinButton';
import CreateModal from '../../CreateCompitition/CreateModal';
import CopyIcon from './CopyIcon';
import AssignAdmin from '../AssignAdmin';
import moment from 'moment';

export default function HeroSection(props) {
    const [showDetails, setDetails] = useState(false)
    return (
        <>
            {props.currentUser?.status === 'Admin' ? (
                <div className='bg-primary-lighten-1'>
                    <div className='container py-2 text-surface-1 d-flex justify-content-end'>
                        <CopyIcon title='' className='btn-icon btn' />
                        <button onClick={() => setDetails(!showDetails)} className={`btn btn-icon ${showDetails ? 'active' : ''}`}><i className='fas fa-cog'></i></button>
                        <CreateModal
                            {...props}
                            component={({ showPopUp }) => <button onClick={showPopUp} className='btn-icon btn'><i className='fas fa-pencil-alt'></i></button>}
                        />
                        <AssignAdmin
                            component={({ showPopUp }) => (
                                <button className='btn btn-icon' onClick={showPopUp}>
                                    <i className="fas fa-user-plus"></i>
                                </button>
                            )}
                        />
                    </div>
                </div>
            ) :
                (
                    <div className='bg-primary-lighten-1'>
                        <div className='container py-2 text-surface-1 d-flex justify-content-end'>
                            <CopyIcon title='' className='btn-icon btn' />
                            <button onClick={() => setDetails(!showDetails)} className={`btn btn-icon ${showDetails ? 'active' : ''}`}><i className='fas fa-cog'></i></button>

                        </div>
                    </div>
                )
            }
            <div className='py-5 text-white cont bg-primary-gradient'>
                <div className='container-v2 small text-center text-surface-1'>
                    <div className='d-flex text-center align-items-center justify-content-center gap-3'>
                        <h5 className='m-0'>{props.title}</h5>
                    </div>
                    <p className='mt-2 line-height-2 px-lg-5 mx-lg-5 opacity-7'>{props.description}</p>
                    <div className='d-flex flex-wrap flex-md-row  justify-content-center  gap-4 text-center'>

                        <div className='d-flex flex-wrap flex-md-row justify-content-center gap-md-8 gap-4 text-center'>
                            <div className='d-flex gap-inherit'>
                                <div>
                                    <b>Start Date</b>
                                    <p className='mb-0 mt-1 opacity-7'>{moment(new Date(props.start_date).toLocaleDateString()).format('LL')}</p>
                                </div>
                                <div>
                                    <b>End Date</b>
                                    <p className='mb-0 mt-1 opacity-7'>{moment(new Date(props.end_date).toLocaleDateString()).format('LL')}</p>
                                </div>
                            </div>
                        </div>
                        {showDetails && (
                            <div className='d-flex flex-wrap w-100 justify-content-center gap-md-8 gap-4'>
                                <div>
                                    <b>Privacy</b>
                                    <p className='mb-0 mt-1 opacity-7'>{props.privacy}</p>
                                </div>
                                <div>
                                    <b>Participation</b>
                                    <p className='mb-0 mt-1 opacity-7'>{props.participation}</p>
                                </div>
                                <div>
                                    <b>Post visibility</b>
                                    <p className='mb-0 mt-1 opacity-7'>{props.post_visibility}</p>
                                </div>
                                <div>
                                    <b>Email domain</b>
                                    <p className='mb-0 mt-1 opacity-7'>{props.email_domain}</p>
                                </div>
                            </div>
                        )}
                        {!props.currentUser && (
                            <div className='mt-5 w-100'>
                                <JoinButton {...props} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}