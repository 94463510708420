import React, { useState } from "react";
import axios from "axios"
import {
    useStripe,
    useElements,
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement,
} from "@stripe/react-stripe-js";
import toastr from "toastr";
import styles from './styles.module.scss';
import BoxField from "../../ui-v2/BoxField/BoxField";
import useRefresh from "../../contexts/RefreshContext";
import { trackEvent } from '../../tiktokTracking';

const Main = (props) => {
    const stripe = useStripe();
    const elements = useElements();
    const [processing, setProcessing] = useState(false)
    const [cardName, setCardName] = useState('')
    const onRefresh = useRefresh();
    const [isCardNumber, setCardNumber] = useState(false);
    const [isExpirty, setExpirty] = useState(false)
    const [isCVC, setCVC] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!stripe || !elements) {
            toastr.error("Stripe.js has not loaded yet. Please try again.", 'Error')
            setProcessing(false)
            return;
        }

        setProcessing(true);

        const payload = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardNumberElement),
        });

        payload.stripe = stripe;

        if (cardName.length === 0) {
            toastr.error('Card Name is required', 'Error')
            setProcessing(false)
            return
        }

        if (payload.error && payload.error.message.length) {
            toastr.error(payload.error.message, 'Error')
            trackEvent('SubmitForm', {
                form_name: 'Credit Card Form',
                form_id: 'stripe_payment_popup',
                form_type: 'payment',
                form_status: 'error'
            });
            return
        }

        const res = await axios.post('/create_stripe_customer',
            {
                'payment_method': payload.paymentMethod.id,
                'card_name': cardName
            }
        );

        const { status, error } = res.data;
        if (status !== 'success') {
            if (error) toastr.error(error, 'Error')
            else toastr.error(status, 'Error')
        } else {
            toastr.success('You have succesfully added a payment method!', 'Success')

            trackEvent('SubmitForm', {
                form_name: 'Credit Card Form',
                form_id: 'stripe_payment_popup',
                form_type: 'payment',
                form_status: 'success'
            });

            props.onSuccess ? props.onSuccess(res.data) : onRefresh();
        }
        setProcessing(false)
    };

    return (
        <form
            onSubmit={handleSubmit}
            className="d-flex flex-column gap-inherit"
        >
            <div className='d-flex flex-column gap-3 Stripe'>
                <CardNumberElement
                    onChange={e => setCardNumber(e.complete)}
                    className={styles.stripInput}   
                    placeholder='Card Number'
                    style={{focus: {color: "#025ed7"}}}
                />
                <input
                    placeholder='Name on Card'
                    name='card_name'
                    value={cardName}
                    onChange={e => setCardName(e.target.value)}
                    className={styles.stripInput}
                />
                <div className='d-grid col-2 gap-3'>
                    <CardExpiryElement className={styles.stripInput} onChange={e => setExpirty(e.complete)} />
                    <CardCvcElement className={styles.stripInput} onChange={e => setCVC(e.complete)} />
                </div>
            </div>
            <BoxField label='Save this card to my account' defaultChecked={true} />
            <div className="model-footer">
                <button
                    className={`btn btn-primary ${processing ? 'progress-btn' : ''}`}
                    disabled={!cardName || !stripe || !isCardNumber || processing || !isExpirty || !isCVC}
                >Add Payment</button>
            </div>
        </form>
    );
};

export default Main;