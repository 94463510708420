const filters = {
    'Sort':{
      key: 'Sort',
      value: '6-D',
      options: [
        {value: '6-D', label: 'Newest'},
        {value: '6-A', label: 'Oldest'},
        {value: '1-A', label: 'Lowest Price'},
        {value: '1-D', label: 'Highest Price'},
      ]
    } , 
    'Property Type': {
      key: 'PropertySearchTypeId',
      value: 1,
      options: [
        {value: 0, label: 'No Preference'},
        {value: 1, label: 'Residential'},
        {value: 2, label: 'Recreational'},
        {value: 3, label: 'Condo/Strata'},
        {value: 4, label: 'Agriculture'},
        {value: 5, label: 'Parking'},
        {value: 6, label: 'Vacant Land'},
        {value: 8, label: 'Multi Family'},
      ]
    },
    'Transaction Type': {
      key: 'TransactionTypeId',
      value: 2,
      options: [
        {value: 2, label: 'For sale'},
        {value: 3, label: 'For rent'},
      ]
    },
    'Min Price': {
      key: 'PriceMin',
      value: 0,
      type: 'number'
    },
    'Max Price': {
      key: 'PriceMax',
      value: 500000000,
      type: 'number',
    },
    'Beds': {
      key: 'BedRange',
      value: '1-5',
      options: [
        {value: '1-5', label: 'Any'},
        {value: '1-1', label: '1'},
        {value: '1-0', label: '1+'},
        {value: '2-2', label: '2'},
        {value: '2-0', label: '2+'},
        {value: '3-3', label: '3'},
        {value: '3-0', label: '3+'},
        {value: '4-4', label: '4'},
        {value: '4-0', label: '4+'},
        {value: '5-5', label: '5'},
        {value: '5-0', label: '5+'},
      ]
    },
    'Baths': {
      key: 'BathRange',
      value: '1-5',
      options: [
        {value: '1-5', label: 'Any'},
        {value: '1-1', label: '1'},
        {value: '1-0', label: '1+'},
        {value: '2-2', label: '2'},
        {value: '2-0', label: '2+'},
        {value: '3-3', label: '3'},
        {value: '3-0', label: '3+'},
        {value: '4-4', label: '4'},
        {value: '4-0', label: '4+'},
        {value: '5-5', label: '5'},
        {value: '5-0', label: '5+'},
      ]
    },
    'Ownership Type': {
      key: 'OwnershipTypeGroupId', 
      value: 0,
      options: [
        {value: 0, label: 'Any'},
        {value: 1, label: 'Freehold'},
        {value: 2, label: 'Condo/Strata'},
        {value: 3, label: 'Timeshare/Fractional'},
        {value: 4, label: 'Leasehold'},
      ]
    },
    'View Type': {
      key: 'ViewTypeGroupId',
      value: 0,
      options: [
        {value: 0, label: 'Any'},
        {value: 1, label: 'City'},
        {value: 2, label: 'Mountain'},
        {value: 3, label: 'Ocean'},
        {value: 4, label: 'Lake'},
        {value: 5, label: 'River'},
        {value: 6, label: 'Ravine'},
        {value: 7, label: 'Other'},
        {value: 8, label: 'All Water Views'},
      ]
    },
    'Building Type': {
      key: 'BuildingTypeId',
      value: 1,
      options: [
        {value: 0 , label: 'Any'},
        {value: 1 , label: 'House'},
        {value: 2 , label: 'Duplex'},
        {value: 3 , label: 'Triplex'},
        {value: 5 , label: 'Residential Commercial Mix'},
        {value: 6 , label: 'Mobile Home'},
        {value: 12, label: ' Special Purpose'},
        {value: 14, label: ' Other'},
        {value: 16, label: ' Row/Townhouse'},
        {value: 17, label: ' Apartment'},
        {value: 19, label: ' Fourplex'},
        {value: 20, label: ' Garden Home'},
        {value: 27, label: ' Manufactured Home/Mobile'},
        {value: 28, label: ' Commercial Apartment'},
        {value: 29, label: ' Manufactured Home'},
      ]
    },
    'Construction Style': {
      key: 'ConstructionStyleId',
      value: 3,
      options: [
        {value: 0, label: 'Any'},
        {value: 1, label: 'Attached'},
        {value: 3, label: 'Detached'},
        {value: 5, label: 'Semi-detached'},
        {value: 7, label: 'Stacked'},
        {value: 9, label: 'Link'},
      ]
    },
    'Air Condition': {
      key: 'AirCondition',
      value: 0,
      options: [
        {value: 0, label: 'No'},
        {value: 1, label: 'Yes'}
      ]
    },
    'Pool': {
      key: 'Pool',
      value: 0,
      options: [
        {value: 0, label: 'No'},
        {value: 1, label: 'Yes'}
      ]
    },
    'Fire Place': {
      key: 'Fireplace',
      value: 0,
      options: [
        {value: 0, label: 'No'},
        {value: 1, label: 'Yes'}
      ]
    },
    'Garage': {
      key: 'Garage',
      value: 0,
      options: [
        {value: 0, label: 'No'},
        {value: 1, label: 'Yes'}
      ]
    },
    'Water Front': {
      key: 'Waterfront',
      value: 0,
      options: [
        {value: 0, label: 'No'},
        {value: 1, label: 'Yes'}
      ]
    },
    'Acreage': {
      key: 'Acreage',
      value: 0,
      options: [
        {value: 0, label: 'No'},
        {value: 1, label: 'Yes'}
      ]
    },
    'Reference Number': {
      key: 'OpenHouse',
      value: '',
      type: 'number'
    },
    'Open Houses Only': {
      key: 'OpenHouse',
      value: 0,
      options: [
        {value: 0, label: 'No'},
        {value: 1, label: 'Yes'}
      ]
    },
    'Keywords': {
      key: 'Keywords',
      value: '',
      placeholder: 'Waterfront, Garage, Pool...',
      type: 'text'
    },
    'ListingIds': {
      key: 'ListingIds',
      value: '',
      placeholder: 'Comma Separated listing Ids...',
      type: 'text'
    }
  }

  const second_filters = {
    'Sort By': {  
      key: 'sort_by',
      value: 'cheapest_square_foot',
      options: [
        {value: 'cheapest_square_foot', label: 'Cheapest (Land) / Sq. Ft.'},
        {value: 'most_expensive_square_foot', label: 'Most Expensive (Land) / Sq. Ft.'},
        {value:'lowest_square_foot', label: 'Lowest Sq. Ft. (Land)'},
        {value:'highest_square_foot', label: 'Highest Sq. Ft. (Land)'},
        {value:'cheapest_house_square_foot', label: 'Cheapest (House) / Sq. Ft.'},
        {value:'most_expensive_house_square_foot', label: 'Most Expensive (House) / Sq. Ft.'},
        {value:'lowest_house_square_foot', label: 'Lowest Sq. Ft. (House)'},
        {value:'highest_house_square_foot', label: 'Highest Sq. Ft. (House)'},
      ]
    },
  }
  
  module.exports = {
    filters: filters,
    second_filters: second_filters
  }