import React from "react";
import SideFilter from "../../components-v2/SideFilter/SideFilter";
import MLModelManageSignalPopUp from "../MLModelManageSignalPopUp";
import BoxField from "../../ui-v2/BoxField/BoxField";
import ToolTip from "../../ui-v2/ToolTip/ToolTip";
import SelectField from "../../ui-v2/SelectField/SelectField";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import InputField from "../../ui-v2/InputField/InputField";
import TimePicker from "../../ui-v2/TimePicker/TimePicker";
import DatePicker from "../../ui-v2/DatePicker/DatePicker";
import PickersUtilsProvider from "../../ui-v2/PickersUtilsProvider/PickersUtilsProvider";
import moment from "moment";

const MLModelFilters = ({
  new_model,
  handleStartCheck,
  existing_models,
  selected_model,
  running_ml_models,
  handleChange,
  model_name,
  stock_symbol,
  stock_list,
  errors,
  basic_mode,
  handleBasicCheck,
  handleModelChange,
  number_of_weights_produced,
  iterations,
  handleLearningRateChange,
  learning_rate,
  broker_fee,
  starting_amt,
  type,
  handleTrainDateChange,
  training_start_date,
  training_end_date,
  date_list,
  training_start_time,
  training_end_time,
  handleStartTrainTimeChange,
  handleEndTrainTimeChange,
  training_date_err_msg,
  test_end_date,
  test_start_date,
  handleTestDateChange,
  test_start_time,
  test_end_time,
  handleStartTestTimeChange,
  handleEndTestTimeChange,
  test_date_err_msg,
  running,
  handleValidation,
  testModel,
  test_model_running,
  number_of_results,
  est_time_intra_daily,
  expected_number_of_results,
  handleTrainStartChange,
  handleTrainEndChange,
  handleTestStartChange,
  handleTestEndChange,
  handle_auto_ml_run_stop,
  handle_delete_ml_signal,
  trading_type
}) => {
  return (
    <SideFilter
      footer={
        <div className="d-flex gap-3 align-items-center">
          {new_model ? (
            <>
              <button
                className={`btn btn-primary flex-1 ${running ? "progress-btn" : ""
                  }`}
                onClick={handleValidation}
                disabled={running}
              >
                {running ? "Running" : "Create & Run"}
              </button>
              {running && (
                <small>
                  Estimated time: {est_time_intra_daily}min. {number_of_results}
                  /{expected_number_of_results}
                </small>
              )}
            </>
          ) : (
            <button
              className={`btn btn-primary flex-1 ${test_model_running ? "progress-btn" : ""
                }`}
              onClick={testModel}
              disabled={test_model_running}
            >
              Test Model
            </button>
          )}
        </div>
      }
    >
      <div className="pb-4 border-bottom">

        {/* <MLModelManageSignalPopUp running_ml_models={running_ml_models} handle_auto_ml_run_stop={handle_auto_ml_run_stop} handle_delete_ml_signal={handle_delete_ml_signal} /> */}
      </div>
        <>
          <div className="d-flex flex-column gap-2">
            <small className="smaller mb-1 d-block text-accent-4">
              Choose Model
            </small>
            <BoxField
              className='ms-auto'
              title="Create a new model."
              type="radio"
              checked={new_model}
              onChange={handleStartCheck}
              name="new_model"
              label="New Model"
            />

            {existing_models.length > 0 && (
              <BoxField
                title="Select a model that you have created in the past."
                checked={!new_model}
                onChange={handleStartCheck}
                name="new_model"
                label="Existing Model"
                type="radio"
              />
            )}
          </div>
          {(!new_model && existing_models.length > 0 && selected_model) && (
            <SelectField
              value={selected_model.model_name}
              onChange={handleModelChange}
              label="Select Model"
            >
              {existing_models.map((model, index) => (
                <MenuItem value={model} key={index}>{model.model_name}</MenuItem>
              ))}
            </SelectField>
          )}

          <InputField
            name="model_name"
            label="Model Title"
            disabled={!new_model}
            onChange={handleChange}
            value={model_name}
            error={errors["model_name"]}
            title="Give your model a title. This is the name it will be saved by so you can refence it later."
          />

          <SelectField
            value={stock_symbol}
            onChange={handleChange}
            name="stock_symbol"
            disabled={!new_model}
            label="Stock Symbol"
            title="Enter in a stock symbol that you wish to create a machine learning model for."
          >
            {stock_list.map((stock, index) => (
              <MenuItem value={stock} key={index}>{stock.toUpperCase()}</MenuItem>
            ))}
          </SelectField>
          <BoxField
            checked={!basic_mode}
            onChange={handleBasicCheck}
            name="new_model"
            label="Advanced Mode"
          />
          {!basic_mode && (
            <div className="d-flex flex-column gap-3">
              <small className="smaller d-block text-accent-4">ML Data</small>
              <InputField
                name="number_of_weights_produced"
                label="Number of times to run"
                type="number"
                onChange={handleChange}
                value={number_of_weights_produced}
                disabled={!new_model}
              />
              <InputField
                name="iterations"
                label="Iterations"
                type="number"
                onChange={handleChange}
                value={iterations}
                disabled={!new_model}
              />
              <InputField
                name="learning_rate"
                label="Learning Rate"
                onChange={handleLearningRateChange}
                value={learning_rate}
                disabled={!new_model}
              />
              <InputField
                title="Enter the hypothetical broker fee that you would have to pay per trade."
                name="broker_fee"
                label="Broker Fee"
                type="number"
                onChange={handleChange}
                value={broker_fee}
                disabled={!new_model}
              />
              <InputField
                title="Enter the hypothetical broker fee that you would have to pay per trade."
                name="starting_amt"
                label="Starting Amount"
                type="number"
                onChange={handleChange}
                value={starting_amt}
                disabled={!new_model}
              />

                <small className="text-accent-4 smaller mb-1">Trading Type</small>
                <BoxField
                  title="Select this if you would like your model to only buy/sell one unit at a time."
                  type="radio"
                  checked={trading_type === "gradual"}
                  onChange={handleChange}
                  value="gradual"
                  name="trading_type"
                  disabled={!new_model}
                  label="Gradual"
                />
                <BoxField
                  title="Select this if you would like your model to only buy/sell the total amount available based off the starting amount."
                  type="radio"
                  checked={trading_type === "all_in"}
                  onChange={handleChange}
                  value="all_in"
                  name="trading_type"
                  disabled={!new_model}
                  label="All In"
                />

            </div>
          )}
          <div className="d-flex flex-column gap-2">
            <small className="text-accent-4 smaller mb-1">Stock Data</small>
            <BoxField
              title="Select this if you would like your model to use data throughout the day. Ex. 9:30-4:00, so by selecting this you are building a model that can trade throughout the day."
              type="radio"
              checked={type === "intra_day"}
              onChange={handleChange}
              value="intra_day"
              name="type"
              disabled={!new_model}
              label="Intra Day"
            />
            <BoxField
              title="Select this if you would like it to take one data point for each day. You would select this option if you wanted to make one trade a day."
              checked={type === "daily"}
              value="daily"
              name="type"
              label="Daily"
              onChange={handleChange}
              disabled={!new_model}
              type="radio"
            />
          </div>
          {type === "intra_day" ? (
            <>
              <div className="d-flex flex-column gap-3">
                <ToolTip title="The data that your model will use to train itself to learn.">
                  <p className="mt-2 mb-0 fw-mediam">Training Data</p>
                </ToolTip>
                <small className="smaller mb-1 d-block text-accent-4">
                  Date Range
                </small>
                <SelectField
                  value={training_start_date}
                  name="training_start_date"
                  onChange={handleTrainDateChange}
                  label="Start Date"
                  disabled={!new_model}
                >
                  {date_list.map((date, index) => (
                    <MenuItem value={date} key={index}>{date}</MenuItem>
                  ))}
                </SelectField>
                <SelectField
                  value={training_end_date}
                  name="training_end_date"
                  onChange={handleTrainDateChange}
                  label="End Date"
                  disabled={!new_model}
                >
                  {date_list.map((date, index) => (
                    <MenuItem value={date} key={index}>{date}</MenuItem>
                  ))}
                </SelectField>
              </div>
              {!basic_mode && (
                <div className="d-flex flex-column gap-3">
                  <small className="smaller mb-1 d-block text-accent-4">
                    Time Range
                  </small>
                  <PickersUtilsProvider>
                    <TimePicker
                      label="Start Time"
                      value={training_start_time}
                      onChange={handleStartTrainTimeChange}
                      disabled={!new_model}
                    />
                    <TimePicker
                      label="End Time"
                      value={training_end_time}
                      onChange={handleEndTrainTimeChange}
                      disabled={!new_model}
                    />
                  </PickersUtilsProvider>
                  {training_date_err_msg && (
                    <small className="text-accent-2 smaller">
                      Start Date must be less than or equal to End Date
                    </small>
                  )}
                </div>
              )}
              <div className="d-flex flex-column gap-3">
                <ToolTip title="Select the timeframe for the data that you would like to test how well your model has learned from the training data.">
                  <p className="mt-2 mb-0 fw-mediam">Test Data</p>
                </ToolTip>
                <small className="smaller mb-1 d-block text-accent-4">
                  Date Range
                </small>
                <SelectField
                  value={test_start_date}
                  name="test_start_date"
                  onChange={handleTestDateChange}
                  label="Start Date"
                >
                  {date_list.map((date, index) => (
                    <MenuItem value={date} key={index}>{date}</MenuItem>
                  ))}
                </SelectField>
                <SelectField
                  value={test_end_date}
                  name="test_end_date"
                  onChange={handleTestDateChange}
                  label="End Date"
                >
                  {date_list.map((date, index) => (
                    <MenuItem value={date} key={index}>{date}</MenuItem>
                  ))}
                </SelectField>
              </div>
              {!basic_mode && (
                <div className="d-flex flex-column gap-3">
                  <small className="smaller mb-1 d-block text-accent-4">
                    Time Range
                  </small>
                  <PickersUtilsProvider>
                    <TimePicker
                      label="Start Time"
                      value={test_start_time}
                      onChange={handleStartTestTimeChange}
                    />
                    <TimePicker
                      label="End Time"
                      value={test_end_time}
                      onChange={handleEndTestTimeChange}
                    />
                  </PickersUtilsProvider>
                  {test_date_err_msg && (
                    <small className="text-accent-2 smaller">
                      Start Date must be less than or equal to End Date
                    </small>
                  )}
                </div>
              )}
            </>
          ) : (
            <>
              <div className="d-flex flex-column gap-3">
                <ToolTip title="The data that your model will use to train itself to learn.">
                  <p className="mt-2 mb-0 fw-mediam">Training Data</p>
                </ToolTip>
                <ToolTip title="Select the timeframe for the data that you would like to use to train the algorithm.">
                  <small className="smaller mb-1 d-block text-accent-4">
                    Date Range
                  </small>
                </ToolTip>
                <PickersUtilsProvider>
                  <DatePicker
                    label="Start Date (historical data)"
                    format="MM/dd/yyyy"
                    value={moment(training_start_date)}
                    name="training_start_date"
                    disabled={!new_model}
                    onChange={handleTrainStartChange}
                  />
                  <DatePicker
                    label="End Date (historical data)"
                    format="MM/dd/yyyy"
                    value={moment(training_end_date)}
                    name="training_end_date"
                    disabled={!new_model}
                    onChange={handleTrainEndChange}
                  />
                </PickersUtilsProvider>
              </div>
              <div className="d-flex flex-column gap-3">
                <ToolTip title="The data that your model will use to train itself to learn.">
                  <p className="mt-2 mb-0 fw-mediam">Test Data</p>
                </ToolTip>
                <ToolTip title="Select the timeframe for the data that you would like to use to train the algorithm.">
                  <small className="smaller mb-1 d-block text-accent-4">
                    Date Range
                  </small>
                </ToolTip>
                <PickersUtilsProvider>
                  <DatePicker
                    label="Start Date (historical data)"
                    format="MM/dd/yyyy"
                    value={moment(test_start_date)}
                    name="test_start_date"
                    onChange={handleTestStartChange}
                  />
                  <DatePicker
                    label="End Date (historical data)"
                    format="MM/dd/yyyy"
                    value={moment(test_end_date)}
                    name="test_end_date"
                    onChange={handleTestEndChange}
                  />
                </PickersUtilsProvider>
              </div>
            </>
          )}
        </>
    </SideFilter>
  );
};

export default MLModelFilters;
