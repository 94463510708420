import React, { useState } from 'react';
import Step1 from "./Step1/Step1";
// import Step2 from "./Step2/Step2";
import './OnBoardProcess.scss';
import Step3 from './Step3/Step3';
import useModel from '../../hooks/useModel';
import { useEffect } from 'react';
import axios from 'axios'
import ReactDOM from 'react-dom';
import { useApp } from '../../App';
import useLoaded from '../../hooks/useLoaded';
import useQuery from '../../hooks/useQuery';


const OnBoardProcessWrapper = props => {
  const step = props.currentStep

  useModel({ cleanup: () => props.wrapper.className = '', name: 'board' });
  props.wrapper.className = `
    overflow-auto animation-opacity${step === 6 ? '-down' : ''} on-board-process top-0 start-0 w-100 h-100 z-index-7 position-fixed 
    ${step === 3 ? 'header-links-active' :
      step === 4 ? 'algoitem-active' :
        step === 5 ? 'leaderboard-active' : ''
    }
  `;
  useEffect(() => {
    return () => props.wrapper.className = ''
  }, [])
  step === 3 && window.scroll(0, 0)
  return (
    <>
      <div className='model active w-100 h-100 start-0 top-0' onClick={props.onFinish}></div>
      <Step1 {...props} />
      <Step3 {...props} />
    </>
  )
}


const Wrapper = ({ component }) => {
  const {user, isLoggedIn} = useApp();
  const isViewed = isLoggedIn ? (user.user_viewed_onboarding || localStorage.getItem('viewed-board')) : true;
  const [currentStep, setStep] = useState(isViewed ? 7 : 2);
  const onFinishFunc = () => {
    setStep(6)
    localStorage.setItem('viewed-board', true)
    setTimeout(() => {
      setStep(7)
    }, 300)
    axios.get('/update_view_onboarding')
  }
  const wrapper = document.getElementById('on-board-process')
  return (
    <>
      {component(() => setStep(2))}
      {currentStep < 7 && ReactDOM.createPortal((
        <OnBoardProcessWrapper
          onPrevious={() => () => setStep(currentStep - 1)}
          currentStep={currentStep}
          onFinish={onFinishFunc}
          onNext={() => setStep(currentStep + 1)}
          wrapper={wrapper}
          user={user}
          isLoggedIn={isLoggedIn}
        />
      ), wrapper)}
    </>
  )
}

const OnBoardProcess = props => {
  const isMobile = useQuery();
  return isMobile ?  '' : <Wrapper {...props} />;
}

export default OnBoardProcess;
