import React from 'react';
import styles from './Step1.module.scss';

// const Step1 = props => {
//     return (
//         <div className={`border-radius-14 m-auto animation-${props.step === 1 ? 'scale delay-3' : 'opacity-down'} ${styles.root}`}>
//             <img src="/frame-36737.png" alt="" className={styles.img} />
//             <div className="p-5 mx-2 text-center">
//                 <h5 className="mt-0 mb-3">Are you good at predicting the stock market? Let's take some measurements.</h5>
//                 <small className="line-height-2">Here, you can make predictions on where you think individual stocks and the overall market will go and we tell you how accurate you are. <br/> (Psst... your score will be on the Leaderboard with all the other traders making predictions.)</small>
//                 <button className="btn btn-primary mt-5 mb-3 mx-auto" onClick={props.onNext}>
//                     <span className="mx-5 px-1">Let's see</span>
//                 </button>
//                 <small>You can change your display name and adjust email notifications in the settings.</small>
//             </div>
//         </div>
//     )
// }

const Step1 = props => {
    return (
        <div className={`border-radius-14 m-auto animation-${props.step === 1 ? 'scale delay-3' : 'opacity-down'} ${styles.root}`}>
            <img src="/frame-36737.png" alt="" className={styles.img} />
            <div className="p-5 mx-2 text-center">
                <h6 className="mt-0 mb-3">Are you good at predicting the stock market?</h6>
                <h5 className="mt-0 mb-3"> Make predictions and win <strong>cash</strong> prizes!</h5>
                <h6 className="mt-0 mb-3">It's free.</h6>

                <button className="btn btn-primary mt-5 mb-3 mx-auto" onClick={props.onNext}>
                    <span className="mx-5 px-1">Let's see</span>
                </button>
                <small>You can change your display name and adjust email notifications in the settings.</small>
            </div>
        </div>
    )
}

export default Step1;
