import { SHA256 } from 'crypto-js';

const ttq = window.ttq; // Assume ttq is globally injected by some script

export const hashData = (data) => {
  return SHA256(data).toString();
};

export const identifyUser = (email,  externalId) => {
  if(ttq) {
    ttq.identify({
      email: hashData(email),
      external_id: hashData(externalId),
    });
  }
};

export const trackEvent = (eventType, details) => {
  if(ttq) {
    ttq.track(eventType, details);
  }
};
