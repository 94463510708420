import React from 'react';
import { useState } from 'react';
import { useApp } from '../../App';
import LoginButton from '../../components-v2/LoginButton/LoginButton';
import Calendar from '../../icons-v2/Calendar';
import styles from './Banner.module.scss';
import BookCall from './BookCall';
import FeedbackModal from './FeedbackModal';

const Banner = () => {
    const auth = useApp();
    const btnClass = `btn btn-primary text-primary bg-accent-1 fs-normal mx-lg-auto ${styles.btn}`;
    const [isCalendar, setIsCalendar] = useState(false);
    return (
        <>
            <div className={`p-4 px-lg-5 justify-content-between m-md-4 mb-lg-0 mb-1 d-flex flex-column flex-xl-row gap-4 ${styles.root}`}>
                <div className={`text-surface-1 ${styles.main}`}>
                    <h6 className='m-0 h7 font-space-mono'>Welcome to one of the best stock research tools available.</h6>
                    <p className='mb-0 mt-2 medium opacity-8 line-height-2'>
                        We help retail traders by providing access to quality information, education, and an intuitive layout.<br />The resources that we offer will continue to improve so we can even the stock trading playing field.
                    </p>
                </div>
                <div className='mx-auto d-grid col-2 col-xl-1 gap-xl-3 mx-xl-0'>
                    {auth.isLoggedIn ? (
                        <>
                            <button className={btnClass} onClick={() => setIsCalendar(true)}>
                                <Calendar /> Book 1:1 Call
                            </button>
                            <FeedbackModal />
                        </>
                    ) : (
                        <>
                            <LoginButton className={btnClass} isLogin={false}>Sign up</LoginButton>
                            <button className='btn btn-text text-surface-1 px-4 px-xs-0' onClick={() => setIsCalendar(true)}>
                                <Calendar /> Book 1:1 Call
                            </button>
                        </>
                    )}
                </div>
            </div>
            {isCalendar && <BookCall closeHandler={() => setIsCalendar(false)} />}
        </>
    )
}

export default Banner;
