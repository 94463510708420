import React from 'react';
import PriceRange from "../../components-v2/FiltersSection/PriceRange/PriceRange";
import BoxField from '../../ui-v2/BoxField/BoxField';
import ExpandSection from "../../ui-v2/ExpandSection/ExpandSection"

const Range = ({ name, title, range, checked, getPureData, description, filter, ...props }) => {
    return (
        <ExpandSection title={title} tooltip={description}>
            <BoxField className='mb-2 mt-1 d-block' label='Fetch Blank Values?' name={`pure_${name}`} onChange={getPureData} checked={checked === undefined ? true : checked} />
            <PriceRange name={name} {...props} showDollar={name === 'marketcap'} values={filter[name] || [range[0], range[1]]} min={range[0]} max={range[1]} />
        </ExpandSection>
    )
}

export default Range;
