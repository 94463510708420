import React, { useState } from 'react';
import ChevronBottom from '../../icons-v2/ChevronBottom';
import ToolTip from '../ToolTip/ToolTip';
import styles from './ExpandSection.module.scss';

const ExpandSection = ({ title, tooltip, children, isOpen = false, className = '', component, ...props }) => {
    const [isActive, setIsActive] = useState(isOpen);
    return (
        <ToolTip title={tooltip}>
            <div {...props} className={`${styles.root} ${className} ${isActive ? styles.active : ''}`}>
                <button className="btn btn-text w-100 justify-content-between" onClick={() => setIsActive(!isActive)}>
                    {title}
                    <ChevronBottom className={isActive ? 'rotate--90' : ''} />
                </button>
                <div className={styles.main}>{children}</div>
            </div>
        </ToolTip>
    )
}

export default ExpandSection;