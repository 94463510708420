import React from 'react';

const Filter = props => (
    <svg {...props} viewBox="0 0 25 25" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
        <line x1="4.25" y1="7.75" x2="20.75" y2="7.75" />
        <line x1="7.25" y1="12.75" x2="17.75" y2="12.75" />
        <line x1="11.25" y1="17.75" x2="13.75" y2="17.75" />
    </svg>
)


export default Filter;