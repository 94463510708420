import axios from "axios"
import React, { useState } from "react"
import CheckIcon from "../../icons-v2/CheckIcon";


const ActivateEmailResend = props => {
    const [{ isLoading, isSent }, setStatus] = useState({});
    const resend_email = (email) => axios.post('/resend_registration_email',{email:email});
    const onClick = () => {
        setStatus({ isLoading: true });
        // call back

        resend_email(props.email?props.email:props.username)
        setTimeout(() => {
            setStatus({ isSent: true })
        }, 3000)
    }
    return (
        <div className="d-inline-flex">
            {
                isLoading ? <span className="loader text-primary" /> :
                    isSent ? <p className="m-0 text-accent-3">Sent <CheckIcon width="1em" /></p>
                        : <button className="btn btn-text fs-inherit" onClick={onClick}>Send again</button>
            }
        </div>
    )
}


export default ActivateEmailResend;
