import React from 'react';

const Calendar = props => (
    <svg viewBox="0 0 19 19" fill='none' {...props}>
        <g stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
            <path filleule="evenodd" clipeule="evenodd" d="M2.375 10.0743C2.375 4.56751 4.15654 2.73242 9.49962 2.73242C14.8435 2.73242 16.625 4.56751 16.625 10.0743C16.625 15.5812 14.8435 17.4163 9.49962 17.4163C4.15654 17.4163 2.375 15.5812 2.375 10.0743Z" />
            <path d="M2.58594 7.34212H16.4203" />
            <path d="M12.8846 10.4588H12.8915" />
            <path d="M9.50371 10.4588H9.51062" />
            <path d="M6.11699 10.4588H6.1239" />
            <path d="M12.8846 13.5081H12.8915" />
            <path d="M9.50371 13.5081H9.51062" />
            <path d="M6.11699 13.5081H6.1239" />
            <path d="M12.5794 1.5835V4.16591" />
            <path d="M6.42709 1.5835V4.16591" />
        </g>
    </svg>
)

export default Calendar;