import React from "react";
import SiteTitle from '../../components/site_title'
import Banner from "../../components/Banner/Banner";
import useGet from "../../hooks/useGet";
import Main from "./Main/Main";


const MarketPlacePage = () => {
  const { data, Loader, err } = useGet('/algos');
  return (
    <>
      <SiteTitle
        title={`StockAlgos - Marketplace`}
        meta_description={`A marketplace of stock algorithms and tools for you to explore.`}
        meta_keywords={`StockAlgos, Algos, Algorithms, Tools, Stock Algos, Stock Tools, Stock Software,Stock Algorithm, stock Algo, stock education `}
      />
      <Banner />
      <h1 className="d-none">Marketplace</h1>
      {data ? <Main algos={data.data} /> :
        err ? <p className='text-accent-2 mt-4 text-center fw-semibold'>{err}</p> :
          <Loader toMiddle={true} />
      }
    </>
  )
}


export default MarketPlacePage;