import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useApp } from '../App';
import axios from 'axios';
import axiosErrorHandler from '../utility/axiosErrorHandler'; 

const useFollow = (is_following, _id) => {
    const history = useHistory();
    const hash = history.location.hash;
    const app = useApp();
    const { isLoggedIn, user } = app;
    const [isInitial, setInitial] = useState(true);
    const [isFollowing, setFollowing] = useState(is_following);
    const [isUpated, setUpdate] = useState(0)
    const isActive = !isLoggedIn || user._id !== _id


    const onFollow = () => {
        if (!isLoggedIn) return history.push({ hash: 'login' })
        history.replace({ hash: `${isFollowing ? 'un' : ''}follow=${_id}`, search: history.location.search })
        axios.put('/toggle_following', { userId: _id }).catch(axiosErrorHandler)
    }

    useEffect(() => {
        if (isInitial) return setInitial(false);
        if (!isLoggedIn) return
        const [cond, userId] = hash.split('=')
        if (!cond || !cond.includes('follow') || (_id && _id !== userId)) return;
        setFollowing(!cond.includes("un"));
        setUpdate(Math.random());
    }, [hash])
    useEffect(() => {
        if (is_following !== isFollowing) setFollowing(is_following);
    }, [is_following])
    return { ...app, ...history, onFollow, isFollowing, isActive, isUpated }
}

export default useFollow;