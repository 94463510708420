import React, { useState } from 'react';
import Filter from "../../../icons-v2/Filter";
import usePostFilters from '../../../hooks/usePostFilters';
import FiltersV from '../Filters/Filters';

 
const Filters = (props) => {
    const tools = usePostFilters();
    const [active, setActive] = useState(false);
    return (
        <>
            <div className='d-flex p-1 align-items-center border-radius-4 justify-content-end bg-accent-6' onClick={() => setActive(!active)}>
                {props.start}
                <button
                    className={`
                        btn small p-2 btn-hover bg-surface-1 border-radius-inherit 
                        ${active && Object.values(tools.values).join("").trim() !== "" ? "elipse" : ""}
                    `}
                >
                    {props.title}<Filter className='fs-normal' />
                </button>
            </div>
            {active && (
                <form onSubmit={tools.onSubmit} className='w-100 border-radius-4 text-accent-4 bg-accent-6 p-2 p-md-3'>
                    <div className="d-flex gap-3 mb-3 align-items-center">
                        <small>Filter Predictions</small>
                        <button type='reset' onClick={tools.onReset} className='btn btn-text small text-accent-4 me-auto'>Reset</button>
                        <button className='small btn-primary-2'>Apply</button>
                    </div>
                    <FiltersV className='gap-lg-3 gap-2 col-2' {...tools} />
                </form>
            )}
        </>
    )
}

export default Filters;