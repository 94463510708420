import React from 'react';
import { Tooltip } from '@material-ui/core';
import './ToolTip.scss';

const ToolTip = ({ placement = "top", className, children, title, ...props }) => {
    return title && typeof title === 'string' ? (
        <Tooltip
            arrow
            title={title}
            placement={placement}
            className={className}
            {...props}
        >
            {React.isValidElement(children) ? children : <span className={className}>{children}</span>}
        </Tooltip>
    ) : children
}

export default ToolTip;