const moment = require('moment')

function getBusinessDays(date, add, sub,calendar) {
    const curr_date = moment(date)

    let add_dates_arr = [curr_date.format('YYYY-MM-DD')]
    let sub_dates_arr = [curr_date.format('YYYY-MM-DD')]

    for (let i = 0; i < add; i++) {
      let y = 1
      let temp_date_add = moment(add_dates_arr[add_dates_arr.length - 1]).add(y, 'days')
      if (!calendar && temp_date_add.day() === 6 || temp_date_add.day() === 0) {
        while (temp_date_add.day() === 6 || temp_date_add.day() === 0) {
          y++
          temp_date_add = moment(add_dates_arr[add_dates_arr.length - 1]).add(y, 'days')

        }
        add_dates_arr.push(temp_date_add.format('YYYY-MM-DD'))
      } else {
        add_dates_arr.push(temp_date_add.format('YYYY-MM-DD'))
      }


    }
     
    for (let i = 0; i < sub; i++) {
      let y = 1
      let temp_date_subtract = moment(sub_dates_arr[sub_dates_arr.length - 1]).subtract(y, 'days')
      if (!calendar && temp_date_subtract.day() === 6 || temp_date_subtract.day() === 0) {
        while (temp_date_subtract.day() === 6 || temp_date_subtract.day() === 0) {
          y++
          temp_date_subtract = moment(sub_dates_arr[sub_dates_arr.length - 1]).subtract(y, 'days')

        }
        sub_dates_arr.push(temp_date_subtract.format('YYYY-MM-DD'))
      } else {
         
        sub_dates_arr.push(temp_date_subtract.format('YYYY-MM-DD'))
      }


    }

    return add_dates_arr.concat(sub_dates_arr)
}

exports.getBusinessDays = getBusinessDays
