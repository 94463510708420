import React from 'react';
import Model from "../../ui-v2/Modal/Model";
import axios from 'axios';

const DeleteModal = ({ _id, onDelete }) => {
    if (!_id) return;
    const onRemove = () => {
        axios.delete(`/watchlist/${_id}`)
            .then(() => onDelete && onDelete(_id))
            .catch(err => console.log(err))
    }
    return (
        <Model
            component={({ showPopUp }) => (
                <button className='btn btn-primary bg-accent-2' type='button' onClick={showPopUp}>Delete</button>
            )}
            isSmall={true}
            title='Are you sure?'
            actions={hidePopUp => (
                <div className='d-flex gap-4'>
                    <button className='btn btn-text' onClick={hidePopUp}>Cancel</button>
                    <button className='btn btn-text text-accent-2' onClick={onRemove}>Delete</button>
                </div>
            )}
        >
            <p className='m-auto text-accent-4'>Are you sure to delete this watchlist? It can not be recovered once deleted</p>
        </Model>
    );
}

export default DeleteModal;