import React from 'react';
import ToolTip from '../../ui-v2/ToolTip/ToolTip';
import ClockLoader from "react-spinners/ClockLoader";
import StockItem from './StockItem';
import { useLocation } from 'react-router';
import moment from 'moment';
import millify from 'millify'
import useQuery from '../../hooks/useQuery';
import Circle from '../../ui-v2/Circle/Circle';
import ToolSymbol from '../../components-v2/ToolSymbol/ToolSymbol';




const NormalTable = ({ stock_data = [], no_result, graphStock, stepNo }) => {
    const isOpen = !useQuery() && stepNo === 1;
    return (
        <>
            <thead>
                <tr>
                    <th>
                        <ToolTip title="The row number for the data.">
                            No
                        </ToolTip>
                    </th>
                    <th>
                        <ToolTip open={isOpen} title="The name of the security.">
                            <span id='tip-1' className='position-relative'>
                                Name
                                {isOpen && <Circle />}
                            </span>
                        </ToolTip>
                    </th>
                    <th>Coupon</th>
                    <th>
                        <ToolTip title="Coupon expiry date.">
                            <div>Expiry</div>
                        </ToolTip>
                    </th>
                    <th>
                        <ToolTip title="The corresponding coupon symbol on the exchange.">
                            Symbol
                        </ToolTip>
                    </th>

                    <th>
                        <ToolTip title="How the stock behaved on the earnings day.">
                            Exchange
                        </ToolTip>
                    </th> 
                    <th>
                        <ToolTip title="How the stock behaved on the earnings day.">
                            Ask (w/volume)
                        </ToolTip>
                    </th>
                    <th>
                        <ToolTip title="How the stock behaved on the earnings day.">
                            Bid (w/volume)
                        </ToolTip>
                    </th>
                    <th>
                        <ToolTip title="How the stock behaved on the earnings day.">
                            Last
                        </ToolTip>
                    </th>
                    <th>
                        <ToolTip title="How the stock behaved on the earnings day.">
                            Last Traded
                        </ToolTip>
                    </th>

                    <th>
                        <ToolTip title="The stock symbol for the company that had earnings.">
                            Trade Volume
                        </ToolTip>
                    </th>


                </tr>
            </thead>
            <tbody>

                {
                    stock_data.length > 0 ?
                        stock_data.map((stock, index) => (
                            <tr key={index}>
                                <td title='No'>{index + 1}</td>
                                <td title='Name'><div className='description'><span>{stock.title}</span></div></td>
                                <td title='Coupon'>{stock.coupon}</td>
                                <td title='Expiry'>{stock.expiry}</td>
                                <td title='Symbol'><ToolSymbol title={stock.symbol} /></td>

                                <td title='Exchange'>{stock.exchange}</td>
                                <td title='Ask (w/volume)'>{stock.pricedata?.ask} -{stock.pricedata?.asksize} </td>
                                <td title='Bid (w/volume)'>{stock.pricedata?.bid} -{stock.pricedata?.bidsize}</td>
                                <td title='Last'>{stock.pricedata?.last}</td>
                                <td title='Last Traded'>
                                    <div className='description'>
                                        <span>{moment(stock.pricedata?.lasttradedatetime).format('MMMM Do YYYY, h:mm:ss a')}</span>
                                    </div>
                                </td>
                                <td title='Trade Volume'>{stock.pricedata?.tradevolume}</td>


                            </tr>


                        ))
                        :
                        <tr>
                            {
                                no_result &&
                                <td colSpan={20}>
                                    <div className='m-auto'>No Results...</div>
                                </td>
                            }
                        </tr>
                }
            </tbody>
        </>
    )
}

const ExpandableTableWrapper = ({ stock_data = [], graph, page, no_result, graphStock }) => {
    return (
        <>
            <thead>
                <tr>
                    <th></th>
                    <th><div>Symbol</div></th>
                    <th>Transactions</th>
                    <th>Participants</th>
                    <th>Date Range</th>
                    <th>Transaction Type</th>
                    <th>Share Price Range ($)</th>
                    <th>#Shares Total</th>
                </tr>
            </thead>
            <tbody>
                <StockItem />
                <StockItem />
                <StockItem />
                <StockItem />
                <StockItem />
                <StockItem />
                <StockItem />
                <StockItem />
                <StockItem />
            </tbody>
        </>
    )
}

const EarningsStockTable = props => {
    const hash = useLocation().hash;
    return (
        <>
            <div className='table-wrapper product_tool_table'>
                <div className={props.stepNo === 1 ? 'overflow-hidden' : ''}>
                    <table>
                        {hash === "#grouped_data" ? <ExpandableTableWrapper {...props} /> : <NormalTable {...props} />}
                    </table>
                </div>
            </div>
        </>
    )
}

export default EarningsStockTable;
