import React from 'react';
import InputField from '../../ui-v2/InputField/InputField';
import usePost from '../../hooks/usePostV2';
import cookie from 'react-cookies'

const Coupon = (props) => {
    const coupon_code = cookie.load('coupon_code');
    const { isLoading, res, err, onPost, setStatus } = usePost('apply_coupon', { data: { coupon_code }, active: coupon_code });
    const discount = res?.result?.discount_amt
    const error = err || (res && !discount ? res.status : null);
    return (
        <>
            <div>
                <h5 className='mt-0 mb-2'>Have a coupon?</h5>
                <InputField
                    defaultValue={cookie.load('coupon_code')}
                    helperText={error}
                    error={!!error}
                    name="coupon_code"
                    label="Coupon Code"
                    className='text-accent-3'
                    onChange={e => setStatus({ data: { coupon_code: e.target.value } })}
                    endAdornment={isLoading ? <h6 className='loader m-0 text-primary' /> : (
                        discount ? <p className='text-accent-3 m-0'>Applied</p> :
                            <button onClick={() => onPost()} type="button" className='btn-text btn'>Apply</button>
                    )}
                />
            </div>
            {discount && (
                <>
                    <input type='hidden' name='coupon_applied' value={true} />
                    <p className='text-accent-3 m-0'>COUPON APPLIED! {discount}% OFF. PRICE WAS: ${props.price}</p>
                </>
            )}
            <p className='m-0'>You will be billed ${discount ? props.price * (1 - (discount / 100)).toFixed(2) : props.price} USD {props.type === 'subscription' ? (
                <>
                    automatically every month until <br /> you cancel the subscription.
                </>
            ) : ""}</p>
            <p className='m-0'>*Any credits that you have/earn will automatically be applied {props.type === 'subscription' ? "to your subscription" : ""}.</p>
        </>
    )
}

export default Coupon;