import React from 'react';
import SetSubscribeButton from '../PrizeBanner/SetSubscribeButton';
import ConnectStripe from '../../ConnectStripe';

export default function SetupSuscription({ user, prem }) {
    const button = <SetSubscribeButton prem={prem} user={user} />
    return (
        <div className="p-3 card d-flex flex-column small border-top gap-2">
            {prem ?
                <>
                    <b>You're a premium predictor!</b>
                    {button}
                    {/* <EarningsModal component={tools => <button onClick={tools.showPopUp} className='btn btn-primary bg-accent-6 text-normal'>View Earnings</button>} /> */}
                    <ConnectStripe email={user.email} is_connected={user.stripe_developer_acct} />
                </>
                :
                <>
                    <b>Become a pro predictor</b> {button} Get paid for your predictions.
                </>
            }

        </div>
    )
}