import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import SelectField from '../../ui-v2/SelectField/SelectField';
import InputField from '../../ui-v2/InputField/InputField';
import RangePicker from '../../ui/RangePicker';
import SideFilter from '../../components-v2/SideFilter/SideFilter';
import WatchListImport from '../../components-v2/AlgoHoldingCommonTools/watchlist_import';
import SignalsHandler from '../SignalsHandler/SignalsHandler';
import SignalButtons from '../../components-v2/AlgoHoldingCommonTools/SignalButtons';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

// import { useHistory } from 'react-router-dom';

const EarningStockFilter = ({
    handleFilterChange,
    sort_type,
    period,
    period_type,
    selected_type,
    handleSortChange,
    handleStockChange,
    stock_search,
    handleTimeChange,
    time,
    times,
    stepNo,
    algo,
    handleToggle,
    specific_search,
    handleRangeSelect,
    selectionRange,
    date_range_enabled,
    should_calc_options
}) => {
    // const history = useHistory();
    // const { pathname, replace, location } = history;
    // const onRepresentationChanged = e => replace({ pathname, hash: e.target.value, search: location.search })
    return (
        <SideFilter>
            <div className='border-bottom d-grid gap-3 pb-4'>
                <SignalsHandler
                    name="earnings"
                    algo_name="Earnings"
                    component={options => <SignalButtons name="earnings" stepNo={stepNo} {...options} />}
                />
            </div>
            <div className='d-flex justify-content-between'>
                <small>Data Filters</small>
                <WatchListImport handleStockChange={handleStockChange} algo={algo} />
            </div>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={specific_search}
                        onChange={handleToggle}
                        name="specific_search"


                        // disabled={true}
                        color="primary"
                    />
                }
                label="Specific Search"
            />
            <InputField
                fullWidth
                name='stock_search'
                label="Search stock symbol"
                onChange={handleStockChange}
                value={stock_search}
            />

            <div className='d-flex flex-column gap-3'>
                <SelectField
                    value={time}
                    name="time"
                    onChange={handleTimeChange}
                    label="Filter By Time"
                >
                    {times.map((type) => (
                        <MenuItem value={type}>{type}</MenuItem>
                    ))}
                </SelectField>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={date_range_enabled}
                            onChange={handleToggle}
                            name="date_range_enabled"


                            // disabled={true}
                            color="primary"
                        />
                    }
                    label="Use Date Range?"
                />
                {date_range_enabled && <> <small className="text-muted">Note: Date range is inclusive</small>
                    <RangePicker
                        ranges={[selectionRange]}
                        onChange={handleRangeSelect}
                        component={({ show, value }) => (
                            <InputField
                                fullWidth
                                name='period_range'
                                label="Filter By Date Range"
                                type="text"
                                onFocus={show}
                                value={value}
                                title={`Want to earnings ranges?`}
                            />
                        )}
                    />
                </>
                }
                {!date_range_enabled &&
                    <SelectField
                        title="Want to see the earnings for a specific day?"
                        fullWidth
                        value={period}
                        name="period"
                        onChange={handleFilterChange}
                        label="Filter By Earnings Date"
                    >

                        {period_type.map((type) => (
                            <MenuItem value={type}>{type}</MenuItem>
                        ))}
                    </SelectField>
                }
                <SelectField
                    title="You can sort by stocks that have the most participants, most trades, or the cost of the stock purchased."
                    fullWidth
                    value={selected_type}
                    name="selected_type"
                    onChange={handleSortChange}
                    label="Sort By"
                >
                    {sort_type.map((type) => (
                        <MenuItem value={type}>{type}</MenuItem>
                    ))}
                </SelectField>
              
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={should_calc_options}
                                onChange={handleToggle}
                                name="should_calc_options"


                                // disabled={true}
                                color="primary"
                            />
                        }
                        label="Run Options"
                    />
                
            </div>

        </SideFilter>
    )
}

export default EarningStockFilter;
