import React from "react";
import Model from "../../ui-v2/Modal/Model";
import Table from "../../ui-v2/Table/Table";

const HistoryModal = props => {
    let handleActivity = (value) => {
        if (value > 0) {
            return <td className="text-accent-3">BUY</td>;
        } else if (value < 0) {
            return <td className="text-accent-2">SELL</td>;
        } else {
            return <td className="text-accent-4">Hold</td>;
        }

    }
    const handleActivityChange = value => (
        <td>
            {
                value > 0 ?
                    <span className="text-accent-3">{value}</span> :
                    <span className="text-accent-2">{value}</span>
            }
        </td>
    )
    function conver_num_to_string(num) {
        if (num > 1000000000) {
            return (num / 1000000000).toFixed(2) + "B";
        } else if (num > 1000000) {
            return (num / 1000000).toFixed(2) + "M";
        } else if (num > 1000) {
            return (num / 1000).toFixed(2) + "K";
        } else {
            return num;
        }
    }
    return (
        <Model show={true}
            className="container"
            title={
                <>
                    Holding/activity history for {props.company_name}. ({props.stock})
                    <span className='d-block text-accent-4 fw-normal'>Fund Name: {props.name}</span>
                </>
            }
            closeHandler={props.closeHandler}
        >
            <Table className="table-grey">
                <thead>
                    <tr>
                        <th>Period</th>
                        <th>Shares</th>
                        <th>Activity</th>
                        <th>% Change</th>
                        <th>% of Portfolio</th>
                        <th>% Change to Portfolio</th>
                        <th>Reported Priced</th>
                    </tr>
                </thead>
                <tbody>
                    {props.history.map((history, index) => (
                        <tr key={index}>
                            <td><div className="description">{history.period}</div></td>
                            <td>{conver_num_to_string(history.amt_of_shares_owned)}</td>
                            {handleActivity(history.recent_change)}
                            {handleActivityChange(history.recent_change)}
                            <td>{history.percent_portfolio}</td>
                            <td>{history.percent_change_portfolio}</td>
                            <td>${history.avg_purchase_price}</td>
                        </tr>
                    ))}


                </tbody>
            </Table>
        </Model>
    )
}


export default HistoryModal;