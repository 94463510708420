import React, { useState } from "react";
import styles from './Ratings.module.scss';
import Rating from 'react-rating';

const Ratings = ({ ratings, className = '', showNumber, name, onChange, ...props }) => {
    const [rating, setRating] = useState(ratings)
    const onRatingChange = rating => {
        setRating(rating);
        onChange && onChange(rating)
    }
    return (
        <>
            {name && <input type="hidden" name={name} value={rating} />}
            <Rating
                initialRating={rating}
                emptySymbol="fa fa-star-o fa-x rate-empty"
                fullSymbol="fa fa-star fa-x rate-full"
                {...props}
                className={`${className} ${styles.root} d-flex`}
                number={showNumber ? 'true' : ''}
                onChange={onRatingChange}
            />
        </>
    )
}

export default Ratings;