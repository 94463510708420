import React from 'react'

const MagnifierGlassIcon = props => (
    <svg width="16" height="17" {...props} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.6003 7.33627C13.6003 8.60199 13.225 9.83929 12.5218 10.8917C11.8186 11.9441 10.8191 12.7644 9.64979 13.2488C8.48043 13.7332 7.19369 13.86 5.95228 13.6131C4.71087 13.3662 3.57054 12.7568 2.67549 11.8618C1.78044 10.9669 1.17087 9.82664 0.923845 8.58526C0.676825 7.34388 0.803454 6.05713 1.28772 4.88771C1.77199 3.7183 2.59214 2.71874 3.64448 2.01544C4.69681 1.31214 5.93407 0.936672 7.19978 0.936523C8.04027 0.936425 8.87255 1.10189 9.64909 1.42346C10.4256 1.74503 11.1312 2.21642 11.7256 2.8107C12.3199 3.40498 12.7914 4.11052 13.1131 4.88702C13.4347 5.66352 13.6003 6.49578 13.6003 7.33627Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M15.2002 15.3365L11.7202 11.8565" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
)

export default MagnifierGlassIcon;
