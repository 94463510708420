import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useState } from 'react';

const useMain = props => {
    const [values, setValues] = useState(props.values)
    const onFilter = values => {
        const data = {}
        for(let key in values) {
            const value = values[key].trim();
            if(value !== "") data[key] = value;
        };
        props.history.replace({ search: new URLSearchParams(data).toString() });
    }
    const onSubmit = e => {
        e.preventDefault();
        onFilter(values);
    }
    const onChange = e => {
        const newValues = { ...values, [e.target.name]: e.target.value };
        if (props.isRealTime) onFilter(newValues);
        setValues(newValues);
    }
    const onDateChange = date => {
        const newValues = {
            ...values,
            start_date: moment(date.selection.startDate).format('YYYY/MM/DD'),
            end_date: moment(date.selection.endDate).format('YYYY/MM/DD')
        }
        if (props.isRealTime) onFilter(newValues);
        setValues(newValues)
    }
    const onReset = () => {
        setValues({
            stock: "",
            end_date: "",
            start_date: "",
            accuracy: "",
            hashtags: ""
        });
        props.history.replace({ search: "" });
    }
    return { onReset, onSubmit, onDateChange, onChange, onFilter, values }
}

const usePostFilters = (isRealTime = false) => {
    const history = useHistory();
    const search = new URLSearchParams(history.location.search);
    const getSearch = key => search.get(key) || "";
    const values = {
        stock: getSearch('stock'),
        end_date: getSearch('end_date'),
        start_date: getSearch('start_date'),
        accuracy: getSearch('accuracy'),
        hashtags: getSearch('hashtags')
    }
    return useMain({ history, isRealTime, values });
}

export default usePostFilters;