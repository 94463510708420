import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import RangePicker from '../../ui/RangePicker';
import SelectField from '../../ui-v2/SelectField/SelectField';
import InputField from '../../ui-v2/InputField/InputField';
import SideFilter from '../../components-v2/SideFilter/SideFilter';
import WatchListImport from '../../components-v2/AlgoHoldingCommonTools/watchlist_import';
import BoxField from '../../ui-v2/BoxField/BoxField';
import SignalsHandler from '../../live/SignalsHandler/SignalsHandler';
import SignalButtons from './SignalButtons';

const Filter = ({
    handleFilterChange,
    sort_type,
    period,
    period_type,
    selected_type,
    handleSortChange,
    selectionRange,
    handleStockChange,
    handleRangeSelect,
    stock_search,
    changeTableView,
    stepNo,
    tableView,
    algo
}) => {
    return (
        <SideFilter>
            <SignalsHandler
                name="government"
                algo_name="Government"
                component={options => (
                    <div className='d-grid gap-3'>
                        <SignalButtons name="government" stepNo={stepNo} {...options} />
                    </div>
                )}
            />
            <div>
                <div className='d-flex justify-content-between align-items-center mb-3'>
                    <small id='tip-2' className='d-block text-accent-4 position-relative'>Data Filters </small>
                    <WatchListImport handleStockChange={handleStockChange} algo={algo} />
                </div>
                <InputField
                    fullWidth
                    name='stock_search'
                    label="Search stock symbol"
                    onChange={handleStockChange}
                    value={stock_search}
                />
            </div>
            <div className='mb-1'>
                <small className='smaller mb-1 d-block text-accent-4'>Table view</small>
                <BoxField
                    onChange={changeTableView}
                    value='individual'
                    label="Individual Data"
                    defaultChecked={tableView === "individual"}
                    name='data-representation'
                    type='radio'
                    className='my-2 d-block'
                />
                <BoxField
                    onChange={changeTableView}
                    label="Group Data"
                    value='grouped_data'
                    type='radio'
                    name='data-representation'
                    defaultChecked={tableView !== "individual"}
                />
            </div>
            <div className='d-flex flex-column gap-3'>

                <SelectField
                    title="You can sort by stocks that have the most participants, most trades, or the cost of the stock purchased."
                    fullWidth
                    value={selected_type}
                    name="selected_type"
                    onChange={handleSortChange}
                    label="Sort By"
                >
                    {sort_type.map((type) => (
                        <MenuItem value={type}>{type}</MenuItem>
                    ))}
                </SelectField>
                <RangePicker
                    ranges={[selectionRange]}
                    onChange={handleRangeSelect}
                    component={({ show, value }) => (
                        <InputField
                            fullWidth
                            name='signal_name'
                            label="Filter By Date"
                            type="text"
                            onFocus={show}
                            value={value}
                            title="Want to see the insider trading that occurred on a specific date?"
                        />
                    )}
                />
            </div>

        </SideFilter>
    )
}

export default Filter;
