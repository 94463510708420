import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import SelectField from '../../ui-v2/SelectField/SelectField';
import InputField from '../../ui-v2/InputField/InputField';
import SideFilter from '../../components-v2/SideFilter/SideFilter';
import WatchListImport from '../../components-v2/AlgoHoldingCommonTools/watchlist_import';
import SignalButtons from '../../components-v2/AlgoHoldingCommonTools/SignalButtons';
import useQuery from '../../hooks/useQuery';
import SignalsHandler from '../SignalsHandler/SignalsHandler';

// import BoxField from '../../ui-v2/BoxField/BoxField';

const DividendsStockFilter = ({
    handleFilterChange,
    sort_type,
    period,
    period_type,
    selected_type,
    handleSortChange,
    handleStockChange,
    stock_search,
    filterDate,
    filterDate_options,
    handleFilterDateChange,
    stepNo,
    algo
}) => {
    const signal = <SignalsHandler name="dividends" algo_name="Dividends" component={options => <SignalButtons name="dividends" {...options} stepNo={stepNo} />} />
    const isDesktop = useQuery();
    return (
        <SideFilter component={isDesktop && <div className='d-flex mx-auto align-items-center gap-md-4 gap-3'>{signal}</div>}>
            {!isDesktop && (
                <div className='border-bottom pb-4 d-grid gap-3'>
                    {signal}
                </div>
            )}
            <div className='d-flex flex-column gap-3'>
                <div className='d-flex justify-content-between'>
                    <small>Data Filters</small>
                    <WatchListImport handleStockChange={handleStockChange} algo={algo} />
                </div>
                <InputField
                    fullWidth
                    name='stock_search'
                    label="Search stock symbol(s)"
                    onChange={handleStockChange}
                    value={stock_search}
                />
            </div>

            <div className='d-flex flex-column gap-3'>
                <SelectField
                    title="Filter upcoming stocks by ex-dividend day."
                    value={filterDate}
                    name="selected_type"
                    onChange={handleFilterDateChange}
                    label="Filter By Dividend Date"
                >
                    {filterDate_options.map((type) => (
                        <MenuItem value={type}>{type}</MenuItem>
                    ))}
                </SelectField>
                <SelectField
                    title="Filter by how frequently the dividend is paid out."
                    fullWidth
                    value={period}
                    name="period"
                    onChange={handleFilterChange}
                    label="Filter By Period"
                >

                    {period_type.map((type) => (
                        <MenuItem value={type}>{type}</MenuItem>
                    ))}
                </SelectField>
                <SelectField
                    title="You can sort by stocks that have the most participants, most trades, or the cost of the stock purchased."
                    fullWidth
                    value={selected_type}
                    name="selected_type"
                    onChange={handleSortChange}
                    label="Sort By"
                >
                    {sort_type.map((type) => (
                        <MenuItem value={type}>{type}</MenuItem>
                    ))}
                </SelectField>
            </div>

        </SideFilter>
    )
}

export default DividendsStockFilter;
