import React from 'react';
import InputField from "../../ui-v2/InputField/InputField";
import RangePicker from "../../ui/RangePicker";
import Calendar from '../../icons-v2/Calendar';


export default function Step1(props) {
    const start_date = props.start_date ? new Date(new Date(props.start_date).toLocaleDateString()) : new Date();
    const end_date = props.end_date ? new Date(new Date(props.end_date).toLocaleDateString()) : new Date();
    if(!props.end_date) {
        end_date.setMonth(end_date.getMonth() + 1)
    }
    return (
        <>
            <InputField errorText="Competition is required" label='Competition name' required={true} name='title' defaultValue={props.title} />
            <InputField label='Description' name='description' defaultValue={props.description} rows={5} multiline />
            <RangePicker
                ranges={[
                    {
                        startDate: start_date,
                        endDate: end_date,
                        key: "selection",
                    }
                ]}
                minDate={start_date}
                component={({ show, values }) => {
                    return (
                        <div className='d-flex flex-column flex-sm-row gap-inherit'>
                            <InputField
                                fullWidth
                                name='start_date'
                                label="Start date"
                                onFocus={show}
                                value={values[0]} 
                                helperText="Participant can make predictions after the date selected."
                                endAdornment={<button type='button' className='btn-icon btn text-accent-4' onClick={show}><Calendar /></button>} />
                            <InputField
                                fullWidth
                                name='end_date'
                                label="End date"
                                onFocus={show}
                                value={values[1]}
                                helperText="Results will be announced and visible to everyone on the selected date."
                                endAdornment={<button type='button' className='btn-icon btn text-accent-4' onClick={show}><Calendar /></button>} />
                        </div>
                    );
                }}
            />
        </>
    )
}