import React from 'react';

const Heart = props => (
    <svg fill='currentColor' {...props} viewBox="0 0 20 17">
        <path
            d="M2.09519 8.67766C0.148184 6.07021 0.797187 2.15904 4.0422 0.85532C7.28721 -0.448403 9.23422 2.15904 9.88322 3.46277C10.5322 2.15904 13.1282 -0.448403 16.3732 0.85532C19.6183 2.15904 19.6183 6.07021 17.6712 8.67766C15.7242 11.2851 9.88322 16.5 9.88322 16.5C9.88322 16.5 4.0422 11.2851 2.09519 8.67766Z"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)

export default Heart;