import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import UserAvatar from '../UserAvatar/UserAvatar';
import UserProgress from './UserProgress/UserProgress';
import FollowersModal from '../Post/FollowersModal'
import Follow from './Follow';
import styles from './user-progress.module.scss';

const Followings = props => {
    const [followings, setFollowings] = useState(props.followings || 0), hash = useLocation().hash;
    useEffect(() => {
        if (hash.includes('follow')) setFollowings(followings + (hash.includes('unfollow') ? -1 : 1))
    }, [hash])
    return (
        <button onClick={followings > 0 ? props.showPopUp : undefined}
            className='btn btn-text small text-accent-4'
        >
            <b className='text-normal small'>{followings}</b> following
        </button>
    )
}

const UserProgressV4 = ({ className = 'leaderboard-sticky', user, data, isLoggedIn, children, isCurrentUser, premium, is_following, ...props }) => {
    return (
        <div className={`card ${styles.root} ${className}`}>
            <div className='pt-4 d-flex flex-column align-items-center border-bottom text-center'>
                <Link to={`/leaderboard/user/${user._id}`}>
                    <UserAvatar {...user} className={`mx-auto mb-2 ${premium ? "premium" : ""} border-radius-20 h3-h4`} />
                    <b className="fw-semibold d-block">{user.name}</b>
                </Link>
                {/* {premium ? <div className='border mt-2 d-flex align-items-center px-2 py-1 gap-1 border-radius-8'><b>{premium.subscribers}</b> <small className='smaller text-accent-4'>Subscribers</small></div> : ""} */}
                {data && (
                    <p className='d-flex pb-2 align-items-center gap-4 justify-content-center smaller text-accent-4'>
                        {data.followers > -1 && (
                            <FollowersModal userId={user._id} isLoggedIn={isLoggedIn} title="Followers"
                                component={tools => (
                                    <button onClick={data.followers > 0 ? tools.showPopUp : undefined}
                                        className='btn btn-text small text-accent-4'
                                    >
                                        <b className='text-normal small'>{data.followers}</b> followers
                                    </button>
                                )}
                            />
                        )}
                        {data.followings > -1 && (
                            <FollowersModal userId={user._id} isLoggedIn={isLoggedIn} title="Following"
                                component={tools => <Followings {...tools} followings={data.followings} />}
                            />
                        )}
                    </p>
                )}
            </div>
            {data && <UserProgress {...data.position} className="p-3" />}
            {!isCurrentUser && is_following !== undefined && (
                <div className='flex-middle gap-2 px-3 pb-3'>
                    <Follow className='flex-1' _id={user._id} is_following={is_following} />
                    {premium && (premium.subscribed ? (
                        <a href={`#unsubscribe=${user._id}`} className='btn-border-2 bg-gradient-5 flex-1'>
                            Unsubscribe
                        </a>
                    ) : (
                        <a href={isLoggedIn ? `#subscribe=${user._id}` : "#login"} className='btn-primary-2 bg-gradient-5 flex-1'>
                            Subscribe
                        </a>
                    ))}
                </div>
            )}
            {children}
        </div>
    )
}

export default UserProgressV4;
