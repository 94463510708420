import React from 'react';
import Logo from '../../icons-v2/Logo';
import { Link, useLocation } from 'react-router-dom';
import RightLinks from './RightLinks/RightLinks';
import useQuery from '../../hooks/useQuery';
import Main from './main';

const Header = props => {
    const isMobile = useQuery();
    const location = useLocation();
    if(/admin|auth/i.test(location.pathname)) return "";
    const newProps = { ...props, isMobile, location };
    return (
        <header className="StockAlgos-Header">
            <div className={`flex-xl-1 d-md-flex d-none`}>
                <Link to='/' className='ms-5 ps-5'>
                    <Logo />
                </Link>
            </div>
            <Main {...newProps} />
            {!props.user.loading ? <RightLinks {...newProps} /> : <div className='flex-xl-1' />}
        </header>
    )
}

export default Header;