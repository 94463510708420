import React from 'react';
import styles from './LDBanner.module.scss';

const LDBanner = ({ className = '', Root = 'div', title, children, footer, ...props }) => (
    <Root className={`text-surface d-grid background border-radius-8 overflow-hidden ${className}`} {...props}>
        <span className={`${styles.main} d-grid text-surface-1`}>
            {title && <small className='overline text-accent-1'>{title}</small>}
            {children}
        </span>
        {footer && <small className={`bg-accent-1 d-flex align-items-center text-normal gap-2 justify-content-center top-100 fw-semibold ${styles.footer}`}>{footer}</small>}
    </Root>
)

export default LDBanner;