import { useState } from 'react';
import axios from 'axios';
import axiosErrorHandler from '../utility/axiosErrorHandler';


const useLike = ({ commentId, is_liked, total_likes, postId, isTest, checkLogin }) => {
    const [status, setStatus] = useState({ isLiked: is_liked, total: +total_likes || 0 })
    const { isLiked, total } = status;
    const likeHandler = () => {
        if (!checkLogin()) return
        const oldState = { ...status };
        setStatus({ isLiked: !isLiked, total: total + (isLiked ? -1 : 1) })
        const data = { postId };
        if (commentId) data.commentId = commentId
        axios.post('/feed_post_like', data)
            .catch(err => {
                axiosErrorHandler(err)
                setStatus(oldState); 
            })
    }
    return { ...status, likeHandler }
}

export default useLike;