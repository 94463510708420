import React, { Component } from "react";
import InputField from "../../ui-v2/InputField/InputField";
import LoginRegisterPopup from "../AuthPopUp/AuthPopUp.jsx"
import '../../ui/PopUp/PopUp.scss';
import viral_styles from './styles.module.scss'
import axios from 'axios'
import { AppContext } from '../../App';
import Model from "../../ui-v2/Modal/Model";
import toastr from 'toastr';
import "toastr/build/toastr.min.css"
import Category from "../../icons-v2/Category";

class RenderViral_Container extends React.Component {

  static contextType = AppContext;
  render() {
    return (
      <RenderViral
        {...this.props}
        {...this.context} />
    )
  }
}

class RenderViral extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show_popup: false,
      viral_components: [],
      openButton: "",
      email_fields: {},
      name_fields: {},
      rendered: false,
      show_login: true,
      viral_component_id:""

    }
  }

  async componentDidMount() {
    if (this.props.all_viral && this.props.all_viral.length > 0) {

      let specific_virals = this.props.all_viral.filter((x) => x.location.includes(this.props.location))

      let chosen_viral = await specific_virals.length > 0 ? specific_virals[Math.floor(Math.random() * specific_virals.length)] : []

      let open_button = await (chosen_viral.elements !== undefined && chosen_viral.elements.length > 0) ? chosen_viral.elements.filter((x) => x.viral_type === "Open Popup Button") : []

      this.setState({
        viral_components: chosen_viral,
        viral_component_id:chosen_viral._id,
        openButton: open_button.length > 0 ? open_button[0] : "",
        rendered: true
      })
    }
  }
  async componentDidUpdate(prevProps) {
    if (prevProps !== this.props && this.props.all_viral && this.props.all_viral.length > 0 && !this.state.rendered) {

      let specific_virals = this.props.all_viral.filter((x) => x.location.includes(this.props.location))

      let chosen_viral = await specific_virals.length > 0 ? specific_virals[Math.floor(Math.random() * specific_virals.length)] : []

      let open_button = await (chosen_viral.elements !== undefined && chosen_viral.elements.length > 0) ? chosen_viral.elements.filter((x) => x.viral_type === "Open Popup Button") : []

      this.setState({
        viral_components: chosen_viral,
        viral_component_id:chosen_viral._id,
        openButton: open_button.length > 0 ? open_button[0] : "",
        rendered: true
      })
    }
  }

  process_array = (array, parent_arr = 0) => {
    let elms = []
    for (let i = 0; i < array.length; i++) {
      let item = this.render_elements(array[i], parent_arr, i,this.state.viral_component_id)
      elms.push(item)

    }
    return elms


  }
  openPopup = (e) => {
    this.setState({
      show_popup: true,
      show_login: true
    })
  }
  closePopup = (e) => {
    this.setState({
      show_popup: false,
      show_login: false
    })
  }
  show_viral_func = (e) => {
    this.setState({
      show_login: false,
      show_popup: true
    })
  }

  handleFieldChange = (e, type, index_ref) => {
    this.setState(prevState => ({
      [type]: {
        ...prevState[type],
        [index_ref]: e.target.value
      }
    }))
  }
  create_viral_analytic = (element_id, result_type) => {
    if (this.state.viral_components !== undefined) {

      axios.post('/viral_analytic/create', {
        component_id: this.state.viral_components._id,
        element_id: element_id,
        result_type: result_type
      })
    }

  }
  onCloseWithoutSubmit = () => {
    axios.post('/viral_analytic/create', {
      component_id: this.state.viral_components._id,
      result_type: "Close Without Submit"
    })
    this.setState({
      show_popup: false
    })
  }

  submitReferrals = (element_id) => {
    let email_arr = Object.values(this.state.email_fields)
    let name_arr = Object.values(this.state.name_fields)
    let email_template = this.state.viral_components.elements.filter((x) => x.viral_type === "Email Template")
    axios.post('/viral_analytic/create', {
      component_id: this.state.viral_components._id,
      email_arr: email_arr.length > 0 ? email_arr : [],
      name_arr: name_arr.length > 0 ? name_arr : [],
      element_id: element_id,
      result_type: "Form Submitted"
    })
    axios.post('/submit_referrals', {
      email_arr: email_arr,
      name_arr: name_arr,
      component_id: this.state.viral_components._id,
      email_template: email_template
    }).then((result) => {

      if (result.data.status === "success") {
        toastr.success('Referral Email Sent!', 'Success')
      } else {
        toastr.error("Could not send email(s)", 'Error')
      }


      this.setState({
        show_popup: false
      })
    })
  }

  render_elements = (element, parent_index, container_index, component_id) => {
    switch (element.viral_type) {
      case 'Open Popup Button':

        return (
          <Button
            className={element.className}
            onClick={this.openPopup}
            css={element.css}
            text={element.text}
            button_type="opener"
            create_viral_analytic={this.create_viral_analytic}
            element_id={element.id}
            result_type="Popup Opened"
            isGlobalClass={element.isGlobalClass}
          />
        )
      case 'Send Referral Button':
        return (
          <Button
            className={element.className}
            onClick={this.submitReferrals}
            css={element.css}
            text={element.text}
            button_type='submit'
            create_viral_analytic={this.create_viral_analytic}
            element_id={element.id}
            result_type="Form Submitted"
            isGlobalClass={element.isGlobalClass}
          />
        )
      case 'Text':
        return (
          <Text
            className={element.className}
            css={element.css}
            text={element.text}
            create_viral_analytic={this.create_viral_analytic}
            element_id={element.id}
            result_type='Element Appeared'
            isGlobalClass={element.isGlobalClass}
          />
        )
      case 'Name Field':
        return (
          <InputField2
            className={element.className}
            handleChange={this.handleFieldChange}
            index={`${parent_index}-${container_index}`}
            css={element.css}
            text={element.text}
            create_viral_analytic={this.create_viral_analytic}
            element_id={element.id}
            result_type='Field Filled'
            viral_type={"name_fields"}
            index_ref={`${parent_index}-${container_index}`}
            isGlobalClass={element.isGlobalClass}
          />
        )
      case 'Email Field':
        return (
          <InputField2
            className={element.className}
            handleChange={this.handleFieldChange}
            index={`${parent_index}-${container_index}`}
            css={element.css}
            text={element.text}
            create_viral_analytic={this.create_viral_analytic}
            element_id={element.id}
            result_type="Email Filled"
            viral_type={"email_fields"}
            index_ref={`${parent_index}-${container_index}`}
            isGlobalClass={element.isGlobalClass}
          />
        )
      case 'Popup':
        return <Popup
          className={element.className}
          onClose={this.closePopup}
          onCloseWithoutSubmit={this.onCloseWithoutSubmit}
          render_elements={this.render_elements}
          css={element.css}
          children={element.children}
          onSubmit={this.onSubmit}
          show_popup={this.state.show_popup}
          process_array={this.process_array}
          create_viral_analytic={this.create_viral_analytic}
          element_id={element.id}
          result_type='Element Appeared'
          isGlobalClass={element.isGlobalClass}
          component_id={component_id}
          user={this.props.user}
        />

      case 'Container':
        return (
          <Container
            className={element.className}
            css={element.css}
            render_elements={this.render_elements}
            children={element.children}
            process_array={this.process_array}
            create_viral_analytic={this.create_viral_analytic}
            element_id={element.id}
            result_type="Element Appeared"
            isGlobalClass={element.isGlobalClass}
          />
        )
      case 'NEW ELEMENT':
        return <></>
      default:
        return <></>
    }

  }

  render() {
    const { openButton } = this.state
    let mappable_elements = (this.state.viral_components.elements !== undefined && this.state.viral_components.elements.length > 0) ? this.process_array(this.state.viral_components.elements) : []
    return (
      <React.Fragment>
        {!this.state.show_popup ?
          <>
            {typeof this.state.openButton === 'object' &&
              <Button
                className={openButton.className}
                onClick={this.openPopup}
                css={openButton.css}
                text={openButton.text}
                button_type="opener"
                create_viral_analytic={this.create_viral_analytic}
                element_id={openButton.id}
                result_type="Popup Opened"
                isGlobalClass={openButton.isGlobalClass}
              />
            }
          </>
          :
          <>
            {this.props.isLoggedIn && mappable_elements.length > 0 && mappable_elements.map((elm) => elm)}
            {(!this.props.isLoggedIn && this.state.show_login) && <LoginRegisterPopup show_viral_func={this.show_viral_func} onClose={this.closePopup} />}
          </>
        }
      </React.Fragment>
    );
  }
}



class Button extends Component {
  // constructor(props) {
  //   super(props);

  // }
  componentDidMount() {
    this.refs.theDiv.setAttribute('style', this.props.css);
    this.props.create_viral_analytic(this.props.element_id, this.props.button_type === "opener" ? "Component Appeared" : "Element Appeared")
  }

  componentDidUpdate() {
    this.refs.theDiv.setAttribute('style', this.props.css);
  }
  render() {

    // var style = {this.props.css} as React.CSSProperties;
    return (
      <button ref="theDiv" className={this.props.isGlobalClass ? this.props.className : viral_styles[this.props.className]} type={this.props.button_type} onClick={() => { this.props.button_type !== "opener" ? this.props.onClick(this.props.element_id) : this.props.onClick(); this.props.button_type === "opener" && this.props.create_viral_analytic(this.props.element_id, this.props.result_type) }} >{this.props.text}</button>
    );
  }
}

class Text extends Component {
  // constructor(props) {
  //   super(props);

  // }
  componentDidMount() {
    this.refs.theDiv.setAttribute('style', this.props.css);
    this.props.create_viral_analytic(this.props.element_id, this.props.result_type)
  }

  componentDidUpdate() {
    this.refs.theDiv.setAttribute('style', this.props.css);
  }
  render() {
    return (
      <p ref="theDiv" className={this.props.isGlobalClass ? this.props.className : viral_styles[this.props.className]}  >{this.props.text}</p>
    );
  }
}
class InputField2 extends Component {
  // constructor(props) {
  //   super(props);

  // }
  componentDidMount() {
    this.refs.theDiv.setAttribute('style', this.props.css);

    this.props.create_viral_analytic(this.props.element_id, "Element Appeared")
  }

  componentDidUpdate() {
    this.refs.theDiv.setAttribute('style', this.props.css);
  }
  render() {
    return (
      <div ref="theDiv" >
        <InputField className={this.props.isGlobalClass ? this.props.className : viral_styles[this.props.className]} index={this.props.index} label={this.props.text} onChange={(event) => this.props.handleChange(event, this.props.viral_type, this.props.index_ref)}  ></InputField>
      </div>
    );
  }
}
class Container extends Component {
  // constructor(props) {
  //   super(props);

  // }
  componentDidMount() {
    this.refs.theDiv.setAttribute('style', this.props.css);
    this.props.create_viral_analytic(this.props.element_id, this.props.result_type)
  }

  componentDidUpdate() {
    this.refs.theDiv.setAttribute('style', this.props.css);
  }
  render() {
    let mappable_elements = (this.props.children !== undefined && this.props.children.length > 0) ? this.props.process_array(this.props.children) : []
    return (
      <div className={this.props.isGlobalClass ? this.props.className : viral_styles[this.props.className]} ref="theDiv">
        {mappable_elements.length > 0 && mappable_elements.map((elm) => elm)}
      </div>
    );
  }
}


class Popup extends Component {
  constructor(props) {
    super(props);
    this.state={
      show_copied:false
    }

  }
  componentDidMount() {
    this.props.create_viral_analytic(this.props.element_id, this.props.result_type)
  }

  componentDidUpdate() {
  }
  copyToClipboard = (component_id, user_id)=>{
    navigator.clipboard.writeText(`https://stockalgos.com/home?component_id=${this.props.component_id}&referrer_id=${this.props.user._id}`)
      this.props.create_viral_analytic(this.props.element_id, "Link Copied")
    this.setState({
      show_copied:true
    })
  }
  render() {
    const mappable_elements = (this.props.children !== undefined && this.props.children.length > 0) ? this.props.process_array(this.props.children) : []
    return this.props.show_popup && (
      <Model className={this.props.isGlobalClass ? this.props.className : viral_styles[this.props.className]} show={this.props.show_popup} closeHandler={this.props.onClose}>
        {mappable_elements.length > 0 && mappable_elements.map((elm) => elm)}
        <div>
          <button type='button' onClick={this.copyToClipboard} className="btn gap-1 btn-text text-end"><Category />Copy Invitation link</button>
          <div className='d-flex gap-1 text-accent-4'><button className='btn btn-text opacity-0'><Category /></button><small>Anyone can redeem your referal with this link.</small></div>
          {this.state.show_copied && <p style={{color:"green"}}>Link successfully copied!</p>}
        </div>
      </Model>
    )
  }
}





export default RenderViral_Container;
