import React from 'react';
import SideFilter from '../../components-v2/SideFilter/SideFilter';
// import ToolTip from '../../ui-v2/ToolTip/ToolTip';
// import BoxField from '../../ui-v2/BoxField/BoxField';
import InputField from '../../ui-v2/InputField/InputField';
import Spinner from '../../ui-v2/Spinner/Spinner';
import SelectField from '../../ui-v2/SelectField/SelectField';
import { MenuItem } from '@material-ui/core';
import FetchStockList from './fetch_stock_lists';

const OptionsBestFilter = ({
    initialized = true,
    handleStockChange,
    stocks,
    type,
    selected_filter_best_option,
    filter_best_option_list,
    handleChange,
    loading,
    run_func,
    lower_range,
    upper_range,
    load_stock_list,
    started,
    stop_func

}) => {
    return (
        <SideFilter
            footer={(
                <div className='d-flex align-items-center text-accent-3 gap-3'>
                    {initialized && (
                        loading ? (
                            <>
                                <p className='m-0'>Running...</p>
                                <Spinner fontSize='0.35rem' />
                                <button className='btn btn-primary fs-normal flex-1 bg-accent-2'>Stop</button>
                            </>
                        ) : (
                            <>
                                <button className={`btn btn-primary flex-1 ${started ? 'bg-accent-2' : ''}`} onClick={!started ? run_func : stop_func}>{!started ? "Run" : "Stop"}</button>
                                {started && <>Running <Spinner fontSize='0.35rem' /></>}
                            </>
                        )
                    )}
                </div>
            )}
        >
            <div className='d-flex flex-column gap-3 border-bottom pb-3'>
                <FetchStockList
                    load_stock_list={load_stock_list}
                />

                <InputField
                    name='stocks'
                    label="Stock Symbols"

                    onChange={handleStockChange}
                    value={stocks}
                />


                <>
                    {/*
                  <div className='d-grid col-2 overflow-initial'>
                      <ToolTip title="Select this if you would like to filter for call options.">
                          <BoxField name="type"
                              type='radio'
                              onChange={handleChange}
                              value="Bullish"
                              label='Bullish'
                              checked={type === 'Bullish'}
                          />
                      </ToolTip>
                      <ToolTip title="Select this if you would like to filter for put options.">
                          <BoxField
                            name="type"
                              type='radio'
                              value="Bearish"
                              onChange={handleChange}
                              label='Bearish'
                              checked={type === 'Bearish'}
                          />
                      </ToolTip>
                  </div>
                  */}
                    <InputField
                        title='Enter in the minimum amount you would like to pay for a contract. This will make sure that the current ask price of the contract is above the number that you enter.'
                        name='lower_range'
                        label="Calculate the Buying Volume within a lower range (%)"
                        type="number"
                        onChange={handleChange}
                        value={lower_range}
                    />
                    <InputField
                        title='Enter in the minimum amount you would like to pay for a contract. This will make sure that the current ask price of the contract is above the number that you enter.'
                        name='upper_range'
                        label="Calculate the Selling Volume within a upper range (%)"
                        type="number"
                        onChange={handleChange}
                        value={upper_range}
                    />

                </>
                <div className='d-flex flex-column gap-3'>

                    <SelectField
                        title="Select the sorting mechanism that you would like to use for the results."
                        value={selected_filter_best_option}
                        name="selected_filter_best_option"
                        onChange={handleChange}
                        label="Best Option Filter"
                    >
                        {filter_best_option_list.map((filter, index) => (
                            <MenuItem key={index} value={Object.values(filter)[0]}>{Object.keys(filter)[0]}</MenuItem>
                        ))}
                    </SelectField>

                </div>
            </div>





        </SideFilter>
    )
}

export default OptionsBestFilter;
