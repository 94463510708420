import React, { useEffect, useRef } from 'react';
import styles from './step3.module.scss';
import img from '../../../assets/StockAlgos_Onboarding_marketplace_SparkVideo.gif';
import img2 from '../../../assets/onboard-process-2.png';
import ArrowRightIcon from '../../../icons-v2/ArrowRightIcon';

const Layout1 = ({ onNext, onFinish }) => (
    <>
        <div className='px-5 pt-5'>
            <h6 className='subtitle mt-0 mb-2'>Marketplace</h6>
            <small>Here you will find all of our available tools and algorithms and learn what they do and how to use them.</small>
        </div>
        <img alt='' src={img} className='w-100' />
        <div className='bg-primary-lighten-1 text-surface-1 py-3 px-4 d-flex'>
            <div className='flex-1'>
                <button className='btn btn-text text-surface-1 opacity-5' onClick={onFinish}>Skip the tour</button>
            </div>
            <ul className={`list-unstyled d-flex gap-2 ${styles.dots} align-items-center`}>
                <li></li>
                <li></li>
                <li></li>
            </ul>
            <div className='flex-1'>
                <button className='btn me-auto btn-text text-surface-1' onClick={onNext}>Next<ArrowRightIcon /></button>
            </div>
        </div>
    </>
)

const Layout2 = ({ onNext, onFinish }) => {
    return (
        <>
            <div className='px-5 pt-5'>
                <h6 className='subtitle mt-0 mb-2'>Product Description</h6>
                <small>View price details, customer ratings, and short description. Play the video in the top LEFT for a guided demo.</small>
            </div>
            <img alt='' src={img2} className={`${styles.img2} m-auto`} />
            <div className='bg-primary-lighten-1 text-surface-1 py-3 px-5 d-flex'>
                <div className='flex-1'>
                    <button className='btn btn-text text-surface-1 opacity-5' onClick={onFinish}>Skip the tour</button>
                </div>
                <ul className={`list-unstyled d-flex gap-2 ${styles.dots} align-items-center`}>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
                <div className='flex-1'>
                    <button className='btn me-auto btn-text text-surface-1' onClick={onNext}>
                        Next<ArrowRightIcon />
                    </button>
                </div>
            </div>
        </>
    )
}

const Layout3 = ({ onFinish }) => {
    return (
        <>
            <div className='px-5 pt-5'>
                <h6 className='subtitle mt-0 mb-2'>Predict the Stock Market</h6>
                <small>Predict any stock, we measure it for you, and compare your accuracy with others.</small>
            </div>
            <img alt='' src='/Final-output.gif' className='w-100 m-auto' />
            <div className='bg-primary-lighten-1 text-surface-1 py-3 px-5 d-flex'>
                <div className='flex-1'></div>
                <ul className={`list-unstyled d-flex gap-2 ${styles.dots} align-items-center`}>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
                <div className='flex-1'>
                    <button className='btn me-auto btn-text text-surface-1' onClick={onFinish}>Done</button>
                </div>
            </div>
        </>
    )
}


const Step3 = ({ currentStep, ...props }) => {
    const ref = useRef();
    const onResize = () => {
        const elm = ref.current;
        if (!elm) return;
        const style = elm.style;
        const triangleStyle = elm.childNodes[0].style;
        if (currentStep <= 3) {
            const rect = document.getElementById('marketplace-link').getBoundingClientRect();
            style.left = (rect.left - 36) + 'px';
            style.top = rect.bottom + 'px';
            triangleStyle.marginLeft = (rect.width - 52) + 'px';
        }
        else if (currentStep === 4) {
            const elm = document.getElementById('algo-list').childNodes[0];
            if (!elm) return;
            const rect = elm.getBoundingClientRect();
            style.left = (rect.right + 16) + 'px';
            setTimeout(() => {
                style.top = (rect.top + 32) + 'px';
            }, 300)
        }
        else if (currentStep === 5) {
            const creditSystem = document.getElementById('leaderboard-link');
            const rect = creditSystem.getBoundingClientRect();
            style.left = (rect.right - elm.offsetWidth) + 'px';
            triangleStyle.marginLeft = (elm.offsetWidth - (rect.width / 2) - 8) + 'px';
            setTimeout(() => {
                style.top = (rect.bottom + 20) + 'px';
            }, 300)

        }

    }
    useEffect(() => {
        window.addEventListener('resize', onResize);
        return () => window.removeEventListener('resize', () => { })
    }, [])
    useEffect(() => {
        setTimeout(() => {
            onResize()
        }, 100)

    }, [currentStep]);
    return (
        <div className={`
                ${currentStep === 3 ? styles.layout1 : currentStep === 4 ? `${styles.layout2} d-flex` : styles.layout3}
                ${currentStep > 2 ? 'active' : ''}
                ${styles.root} mt-2
            `}
            ref={ref}
        >
            <div className={`${styles.triangle} position-relative z-index-1`}></div>
            <div className={`bg-surface-2 border-radius-8 d-flex flex-1 flex-column elevation-3 overflow-hidden bg-surface-2 ${styles.main}`}>
                {currentStep === 3 && <Layout1 {...props} />}
                {currentStep === 4 && <Layout2 {...props} />}
                {currentStep === 5 && <Layout3 {...props} />}
            </div>
        </div>
    )
}

export default Step3;
