import React from 'react';
import axios from 'axios'
import URL from 'url-parse';
import moment from "moment";
import Pagination from '../../ui-v2/Pagination/Pagination';
import withAlgoProductToolsLayout from '../../hoc-v2/withAlgoProductToolsLayout/withAlgoProductToolsLayout';
import StockTable from '../../components-v2/AlgoHoldingCommonTools/StockTable';
import Filter from '../../components-v2/AlgoHoldingCommonTools/SideFilter';
import TipCard from '../../ui-v2/TipCard/TipCard';
import { Link } from 'react-router-dom';
import FilterButton from '../../components-v2/AlgoHoldingCommonTools/FilterButton';

class Insiders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: new URL(window.location.href, true),
      stock_data: [],
      sort_type: ["Date", "Number of transactions", "Number of participants", "Most Value"],
      period: "All transactions",
      period_type: ["All transactions", "Buy transactions", "Sale transactions"],
      selected_type: "Date",
      orig_stock_data: [],
      scraping: false,
      created: false,
      page: 0,
      totalLength: 0,
      num_buys: 0,
      num_sells: 0,
      date: 'All',
      dates: ['All'],
      selectionRange: {
        startDate: new Date(moment().subtract(30, 'days')),
        endDate: new Date(moment()),
        key: 'selection',
      },
      rangeView: false,
      process: false,
      stock_search: this.props.symbols,
      loadingTable: false,
      no_result: false,
      number_of_days_add: 2,
      number_of_days_sub: 2,
      tableView: 'grouped_data',
      getting_data: false,
      show_tut: false
    }
    this.getData = this.getData.bind(this)
    this.handleRangeSelect = this.handleRangeSelect.bind(this)
    this.changeTableView = this.changeTableView.bind(this)
  }

  changeTableView(e) {
    this.setState({
      tableView: e.target.value
    }, () => {
      this.getData()
    })
  }

  handleRangeSelect(e) {
    this.setState({
      selectionRange: e.selection,
      rangeView: false
    }, () => {
      this.getData()
    })
  }


  componentDidMount() {
    if (this.props.state) return this.setState(this.props.state);
    this.getData()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState !== this.state) {
        this.props.setState(this.state.stock_data)
    }
}

  show_tutorial = () => {
    this.setState({
      stepNo: 1
    })
  }

  initializeDateRange = () => {
    this.setState({
      rangeView: true
    }, () => {
      document.addEventListener('mousedown', this.handleClick, false)
    })
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false)
  }
  handleClickOutside = () => {
    this.setState({
      rangeView: false
    })
  }

  getFilterData = () => {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.getData();
    }, 500);
  }


  getData(data = {}) {

    const { date, selected_type, period, selectionRange, stock_search, page, tableView } = this.state
    const json = {
      date: date,
      filter: period,
      sort: selected_type,
      range: selectionRange,
      stock_search: stock_search.split(','),
      page: page,
      tableView: tableView,
      ...data,
    }
    this.setState({
      stock_data: [],
      process: true,
      loadingTable: true,
      no_result: false,
      ...data
    })

    axios.post('/get_insider_data', {
      ...json
    }).then((response) => {


      const res = response.data

      if (res.status === "success") {
        this.setState({
          stock_data: res.results.rlt,
          dates: res.results.dates,
          totalLength: res.results.total,
          num_buys: res.results.num_buys,
          num_sells: res.results.num_sells,
          getting_data: false
        })
        if (res.results.rlt.length === 0) {
          this.setState({
            no_result: true,
            getting_data: false
          })
        }
      }
    }).finally(() => {
      this.setState({
        process: false,
        loadingTable: false,
      })
    })

  }

  handleChange = (e) => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleSortChange = (e) => {
    let val = e.target.value
    this.setState({
      selected_type: val,
      page: 0,
    })
    setTimeout(() => {

      this.getData()
    }, 100)
  }

  handleFilterChange = (e) => {
    let { name, value } = e.target
    this.setState({
      [name]: value,
      page: 0,
    })
    setTimeout(() => {

      this.getData()
    }, 100)
  }

  fetchData = (e) => {
    this.setState({
      scraping: true,
    })
    axios.get('/fetch_insiders_stocks').then((response) => {
      if (response.data.success) {
  
        this.getData()
      }
    }).finally(() => {
      this.setState({
        scraping: false,
      })
    })
  }

  handleStockChange = (e, values) => {
    const { value, name } = e.target
    this.setState({
      [name]: values?.join(',') || value,
      page: 0,
    }, this.getFilterData)
  }
componentDidUpdate(prevProps, prevState) {
  if (prevProps.symbol_list !== this.props.symbol_list) {
    this.setState({
      stock_search: this.props.symbol_list,
      page: 0,
    }, () => {
      this.getData()
    })
  }
}


  render() {
    const { loadingTable, scraping, page, totalLength, num_buys,num_sells } = this.state
    const tipProps = this.props.tipProps;
    const stepNo = tipProps.stepNo;
    this.props.getState && this.props.getState(this.state);
    return (
      <>
        <TipCard id='tip-1'
          totalSteps={3}
          activeStep={1}
          {...tipProps}
          className='mt-3 me-0'
        >Hover over an item for an explanation of its function.</TipCard>
        <TipCard id='tip-2'
          {...tipProps}
          totalSteps={3}
          activeStep={2}
        >Filter the data based on your desired criteria (ie. by date, by stock, by transaction type, etc.). View the data as grouped by company, or by individual transactions.</TipCard>
        <TipCard id='tip-3'
          {...tipProps}
          totalSteps={3}
          activeStep={3}
        >Opt in for email signals so you can stay informed and never miss a trade. <Link to='/' className='btn btn-text text-surface-1'>Learn how to use</Link></TipCard>

        <Filter
          changeTableView={this.changeTableView}
          createSignal={this.createSignal}
          handleFilterChange={this.handleFilterChange}
          handleSortChange={this.handleSortChange}
          handleStockChange={this.handleStockChange}
          handleRangeSelect={this.handleRangeSelect}
          initializeDateRange={this.initializeDateRange}
          stepNo={stepNo}
          {...this.state}
          {...this.props}
        />

        <div className='m-lg-5 m-3 overflow-hidden d-flex flex-column flex-1'>
          {
            scraping &&
            <span className="">Now it's scraping and update Database. It will takes 2 - 3 minutes.</span>
          }
          {(scraping || loadingTable) && <div className='bar-loader mb-3' />}
          <div className='d-flex text-accent-4 mb-4 gap-2 justify-content-between align-items-center'>
            <small>{totalLength} Results </small>
            <small>Transactions: {num_buys} Buys, {num_sells} Sells </small>
            <FilterButton />
          </div>
          <StockTable
            stepNo={stepNo}
            filter_transaction={this.state.period}
            {...this.state}
          />
          <Pagination
            pageCount={Math.ceil(totalLength / 10)}
            initialPage={page}
            forcePage={page}
            onPageChange={data => this.getData({ page: data.selected })}
            className='mt-3'
          />
        </div>
      </>
    )
  }
}
export default withAlgoProductToolsLayout(Insiders, 'Insiders', { showTips: true });
