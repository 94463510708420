import React from 'react';
import axios from 'axios';
import Player from './Player/Player';

const Movie = props => {
    const [state, setState] = React.useState({ imgs: props.imgs ? [...props.imgs] : [] });
    const { total = -1, imgs } = state;
    const length = imgs.length;

    const get_images = React.useCallback(async () => {
        const script_arr = props.message.split('.').filter(text => text.trim() !== "");
        const total = (script_arr.length / 2).toFixed();
        setState({ imgs, total });
        const newImgs = imgs;
        script_arr.map(async (_, index) => {
            if (index % 2 === 0 || index == script_arr.length - 1) {
                const text = script_arr.slice(index, index + 2).join(" ").trim();

                // const img = (await axios.post('/open_ai_image', { text, number: 1 })).data.other_images[0];
                const img = (await axios.post('/open_ai_image', { text, number: 1 }))

                newImgs.push({ img: img.data.other_images[0], index: index, text: text });
                setState({ imgs: [...newImgs], total });
            }
        })
    }, [])

    return (
        <>
            {length > 0 && (
                <span className='d-grid col-xxl-4 gap-3 col-lg-3 mt-3 col-md-2'>
                    {imgs.sort((a, b) => a.index - b.index).map((item, index) => (
                        <span key={index}>
                            <img src={item.img} key={index} alt='' className='w-100' />
                            <span>{item.text}</span>
                        </span>
                    ))}
                </span>
            )}
            
        </>
    )
}

export default Movie;
