import React, { useState, useEffect } from 'react';
import axios from 'axios'
import SideFilter from '../../components-v2/SideFilter/SideFilter';
import ToolTip from '../../ui-v2/ToolTip/ToolTip';
import BoxField from '../../ui-v2/BoxField/BoxField';
import InputField from '../../ui-v2/InputField/InputField';
import Spinner from '../../ui-v2/Spinner/Spinner';
import SelectField from '../../ui-v2/SelectField/SelectField';
import { MenuItem } from '@material-ui/core';
import PriceRange from "../../components-v2/FiltersSection/PriceRange/PriceRange";
import WatchListImport from '../../components-v2/AlgoHoldingCommonTools/watchlist_import';


const OptionsBestFilter = ({ order_placed }) => {
    const [orderDateTime, setOrderDateTime] = useState('');
    const [steps, setSteps] = useState([{ stock_search: '', order_type: '', type: '', strike_type: '', strike: '', delta_range: '',  contract_expiry: '', date_list: [], quantity_per_contract: '' }]);
    const [loading, setLoading] = useState(false);

    const handleChange = (event, index) => {
        const { name, value } = event.target;
        const newSteps = [...steps];
        newSteps[index][name] = value;
        setSteps(newSteps);
    };
    const fetchInitialData = (stock_search, index) => {

        setLoading(true);

        axios.post('/fetch_initial_options_data', { stock_search: [stock_search]}).then((response) => {
            if (response.data.status === "success") {
                const newSteps = [...steps];
                newSteps[index]['date_list'] = response.data.result;
                setSteps(newSteps);
            }

            setLoading(false);
        }).catch(error => {

            setLoading(false);
        });
    }
    


    const addStep = () => {
        setSteps([...steps, { stock_search: '', order_type: '', type: '', strike_type: '', strike: '', delta_range: '', contract_expiry: '', date_list: [],quantity_per_contract: '' }]);
    };
    const handleDateTimeChange = (event) => {
        setOrderDateTime(event.target.value);
    }
    const placeOrder = () => {
        // Include datetime in the order object
        const order = { datetime: orderDateTime, steps };
        // Here you have your order as array of steps.

        // Send the data to the server as a POST request
        axios.post('/create_option_order', order)
            .then(response => {

                order_placed();

            })
            .catch(error => {

            });
    };
    return (
        <SideFilter
            footer={(
                <div className='d-flex align-items-center text-accent-3 gap-3'>
                    <button

                        onClick={placeOrder}
                        className='btn btn-primary flex-1'
                    >Place Order</button>
                </div>
            )}
        >
            <InputField
                name='datetime'
                type='datetime-local'
                label="Enter Date and Time"

                onChange={handleDateTimeChange}
                value={orderDateTime}
            />
            {steps.map((step, index) => (
                <div key={index} className='d-flex flex-column gap-3 border-bottom pb-3'>
                    <div className='d-flex justify-content-between'>
                        <small>Order Info</small>
                    </div>
                    <p>Step {index + 1}</p>
                    <InputField
                        name='stock_search'
                        label="Enter Stock Symbol"
                        onChange={event => handleChange(event, index)}
                        value={step.stock_search}
                    />
                    <InputField
                        name='quantity_per_contract'
                        label="Enter quantity per contract"
                        onChange={event => handleChange(event, index)}
                        value={step.quantity_per_contract}
                    />
                    <button onClick={(event)=>fetchInitialData(step.stock_search, index)}>Fetch Dates</button>
                    <div className='d-grid col-2 overflow-initial'>
                        <ToolTip title="Select this if you would like to filter for call options.">
                            <BoxField name="order_type"
                                type='radio'
                                onChange={event => handleChange(event, index)}
                                value="buy"
                                label='Buy'
                                checked={step.order_type === 'buy'}
                            />
                        </ToolTip>
                        <ToolTip title="Select this if you would like to filter for call options.">
                            <BoxField name="order_type"
                                type='radio'
                                onChange={event => handleChange(event, index)}
                                value="sell"
                                label='Sell'
                                checked={step.order_type === 'sell'}
                            />
                        </ToolTip>
                    </div>
                    <div className='d-grid col-2 overflow-initial'>
                        <ToolTip title="Select this if you would like to filter for call options.">
                            <BoxField name="type"
                                type='radio'
                                onChange={event => handleChange(event, index)}
                                value="calls"
                                label='Call Options'
                                checked={step.type === 'calls'}
                            />
                        </ToolTip>
                        <ToolTip title="Select this if you would like to filter for put options.">
                            <BoxField
                                name="type"
                                type='radio'
                                value="puts"
                                onChange={event => handleChange(event, index)}
                                label='Put Options'
                                checked={step.type === 'puts'}
                            />
                        </ToolTip>
                        <ToolTip title="Select this if you would like to filter for put options.">
                            <BoxField
                                name="type"
                                type='radio'
                                value="both"
                                onChange={event => handleChange(event, index)}
                                label='Both Options'
                                checked={step.type === 'both'}
                            />
                        </ToolTip>
                    </div>
                    <div className='d-grid col-2 overflow-initial'>
                        <ToolTip title="Select this if you would like to filter for call options.">
                            <BoxField name="strike_type"
                                type='radio'
                                onChange={event => handleChange(event, index)}
                                value="specific"
                                label='Specific Strike'
                                checked={step.strike_type === 'specific'}
                            />
                        </ToolTip>
                        <ToolTip title="Select this if you would like to filter for put options.">
                            <BoxField
                                name="strike_type"
                                type='radio'
                                value="delta_range"
                                onChange={event => handleChange(event, index)}
                                label='Delta Range'
                                checked={step.strike_type === 'delta_range'}
                            />
                        </ToolTip>
                        <ToolTip title="Select this if you would like to filter for put options.">
                            <BoxField
                                name="strike_type"
                                type='radio'
                                value="current_position"
                                onChange={event => handleChange(event, index)}
                                label='Current Position'
                                checked={step.strike_type === 'current_position'}
                            />
                        </ToolTip>
                    </div>
                    {step.strike_type === 'specific' && (
                        <>
                            <InputField
                                name='strike'
                                type='number'
                                label='Strike'
                                onChange={event => handleChange(event, index)}
                                value={step.strike}
                            />
                            {step.date_list.length > 0 && (
                                <SelectField
                                    name="contract_expiry"
                                    label='Contract Expiry'
                                    onChange={event => handleChange(event, index)}
                                    value={step.contract_expiry}
                                >
                                    <MenuItem value='all'>All</MenuItem>
                                    {step.date_list.map((date, dateIndex) => (
                                        <MenuItem key={dateIndex} value={date.value}>{date.title}</MenuItem>
                                    ))}
                                </SelectField>
                            )}
                        </>
                    )}

                    {step.strike_type === 'delta_range' && (
                        <PriceRange
                            name='delta_range'
                            label='Delta Range'
                            onChange={event => handleChange(event, index)}
                            value={step.delta_range}
                        />
                    )}
                </div>
            ))}
            <div>
                <button
                    onClick={addStep}
                    className='btn btn-secondary'
                >Add Step</button>
            </div>
        </SideFilter>
    )
}

export default OptionsBestFilter;
