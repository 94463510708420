import React from 'react';
import { useApp } from '../../../App';
import styles from './ChatRoom.module.scss';
import UserAvatar from '../../../components-v2/UserAvatar/UserAvatar';
import Movie from './Movie';

const ChatRoom = ({ chat }) => {
    const user = useApp().user;
    return React.useMemo(() => (
        <div className={`flex-1 p-lg-5 p-md-4 p-3 d-flex flex-column gap-3 overflow-auto ${styles.root}`}>
            {chat.map((item, index) => (
                <React.Fragment key={index}>
                    <div>
                        {item.isRobot ? <i className="fas fa-robot" /> : <UserAvatar {...user} />}
                        <p className='flex-1'>
                            {item.message}
                            {item.isRobot && <Movie {...item} />}
                        </p>
                    </div>
                    {index + 1 === chat.length && <button className='opacity-0' autoFocus />}
                </React.Fragment>
            ))}
        </div>
    ), [chat])
}


export default ChatRoom;
