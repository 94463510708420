import React from "react";
import { Link } from "react-router-dom";
import InputField from "../ui-v2/InputFieldV2";
import SiteTitle from '../components/site_title'
import Form from "../hoc-v2/Form";
import SocialLogins from "../components-v2/SocialLogins/SocialLogins";
import useAuth from "../hooks/useAuth";
import { loginAPI } from "../apis/User";
import ActivateEmailResend from "../components-v2/ActivateEmailResend/ActivateEmailResend";
import LoginButton from "../components-v2/LoginButton/LoginButton";
import axiosErrorHandler from "../utility/axiosErrorHandler";
import Password from "../ui-v2/Password";
import emailRegex from "../constants/emailRegex";

const LoginPage = ({ isPopup }) => {
  const { auth } = useAuth({ isForUnAuthorized: !isPopup });
  const onSubmit = formData => {
    loginAPI(formData.values)
      .then(user => auth.persistUser(user))
      .catch(err => {
        const message = axiosErrorHandler(err);
        formData.onFailure(message.includes('Account is inactive') ? <>Account is inactive. You need to verify your email address. Activation email was sent when you registered. Didn't receive the email? <ActivateEmailResend {...formData.values} /></> : message)
      });
  }
  return (
    <>
      <SiteTitle
        title={`StockAlgos - Login`}
        meta_description={`Login to your StockAlgos's account`}
        meta_keywords={`StockAlgos, Login`}
      />
      <div>
        <h4 className={`mt-0 mb-2 h5-sm ${isPopup ? 'model-title' : ''}`}>Sign In</h4>
        <p className='m-0 d-flex gap-1'>or
          <LoginButton className='btn btn-text' isLogin={false} isPopup={isPopup}>create an account!</LoginButton>
        </p>
      </div>

      <Form onSubmit={onSubmit} className='d-flex flex-column gap-3'
        footer={props => (
          <div className='d-flex mt-1 flex-column flex-sm-row gap-3 justify-content-between align-items-md-center'>
            <Link className='btn-text' to='/auth/forgot-password'>Forgot you password?</Link>
            <button className={`btn btn-primary ${props.className}`} disabled={props.disabled}>Sign in</button>
          </div>
        )}
      >
        <InputField
          title='Email'
          name='username'
          type='email'
          required={true}
          regex={emailRegex}
          errorText="Please provide a valid email address!"
        />
        <Password
          label='Password'
          minLength={6}
          required={true}
          name='password'
          errorText="Password must be minimum 6 characters long!"
          className='mb-1'
          title="Password"
        />
      </Form>
      <SocialLogins />
    </>
  )
}


export default LoginPage;
