import React from 'react';
import StockItem from '../../components-v2/AlgoHoldingCommonTools/StockItemV2';
import Table from '../../ui-v2/Table/Table';
import InfoIconV2 from '../../icons-v2/InfoIconV2';

const TR = props => {
    function conver_num_to_string(num) {
        if (num > 1000000000) {
            return (num / 1000000000).toFixed(2) + "B";
        } else if (num > 1000000) {
            return (num / 1000000).toFixed(2) + "M";
        } else if (num > 1000) {
            return (num / 1000).toFixed(2) + "K";
        } else {
            return num;
        }
    }
    return(

    <StockItem
        component={() => (
            <>
                <td className='fw-semibold'>{props.superInvestor.name}</td>
                <td>{props.superInvestor.portfolio_value_string}</td>
                <td>{props.superInvestor.number_stocks_held}</td>
                <td>
                    <button className='btn btn-icon' onClick={props.showSector}>
                        <i className="fas fa-chart-pie text-accent-4 fs-normal"></i>
                    </button>
                </td>
                <td>{props.superInvestor.stats.portfolio_date}</td>
            </>
        )}
    >
        <Table className='p-0'>
            <thead className='text-uppercase'>
                <tr>
                    <th>Stock</th>
                    <th><div className='description'>%of Portfolio</div></th>
                    <th>Shares</th>
                    <th>Reported Price</th>
                    <th>Value</th>
                    <th>History</th>
                </tr>
            </thead>
            <tbody>
                {props.superInvestor.holdings.map((holding, index) => (
                    <tr key={index}>
                        <td>{holding.symbol}</td>
                        <td>{holding.percent_portfolio}</td>
                        <td>{conver_num_to_string(holding.amt_of_shares_owned)}</td>
                        <td>${holding.avg_purchase_price}</td>
                        <td>${conver_num_to_string(holding.value)}</td>
                        <td className='text-center'>
                            <button className='btn-icon btn text-accent-4' onClick={()=>props.showHistory(holding.symbol,holding.company_name,holding.history)}><InfoIconV2 /></button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    </StockItem>
)}

export default TR;