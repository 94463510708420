import React from 'react';
import axios from 'axios';
import URL from 'url-parse';
import LineChart from '../../ui-v2/LineChart/LineChart';
import { AppContext } from '../../App'
import moment from "moment";
import CoorelationFilter from './CoorelationFilter';
import withAlgoProductToolsLayout from '../../hoc-v2/withAlgoProductToolsLayout/withAlgoProductToolsLayout';
import FilterButton from '../../components-v2/AlgoHoldingCommonTools/FilterButton';
import StockTable from './StockTable';


class Correlation_Container extends React.Component {

  static contextType = AppContext;
  render() {
    return (
      <Correlation
        {...this.props}
        {...this.context} />
    )
  }
}

class Correlation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: new URL(window.location.href, true),
      stocks: ['AAPL', 'TSLA'],
      find_similar_stocks_to: 'AAPL',
      comparable_stock_data: [],
      stock_data: [],
      start_date: "2020-08-11",
      end_date: "2020-08-11",
      correlation: 0,
      isLoading: false,
      error_msg: "",
      layout: 'graph',

    }
  }
  findMostSimilarStocks = () => {
    axios.post('/find_most_correlated_stocks', {
      symbol: this.state.find_similar_stocks_to
    }).then((response) => {
      let compare_stock_data = response.data.data;
      
      compare_stock_data = compare_stock_data.map((stock) => {
        let stocks = stock.stocks;
        // Find the stock that does not match the target symbol
        let similarStock = stocks.find(obj => obj.ticker.toLowerCase() !== this.state.find_similar_stocks_to.toLowerCase());
  
        if (!similarStock) {
          // Handle the case where no similar stock is found
          return {
            ...stock,
            correlation: (stock.correlation * 100).toFixed(2),
            ticker: null,
            company_name: null,
            marketcap: null
          };
        }
  
        return {
          ...stock,
          correlation: (stock.correlation * 100).toFixed(2),
          ticker: similarStock.ticker,
          company_name: similarStock.company_name,
          marketcap: similarStock.marketcap
        };
      });
  
      this.setState({
        comparable_stock_data: compare_stock_data
      });
    }).catch((error) => {
      console.error("Error fetching most correlated stocks:", error);
      // Optionally, you could set an error state here
      // this.setState({ error: 'Failed to fetch correlated stocks' });
    });
  };
  componentDidMount() {
    if (this.props.state) return this.setState(this.props.state);
    var start_date = new Date();
    start_date.setMonth(start_date.getMonth() - 1);
    var compare_stock_data = [];

    this.findMostSimilarStocks()

    this.setState({
      start_date: moment(start_date).format('YYYY-MM-DD'),
      end_date: moment(Date.now()).format('YYYY-MM-DD'),
      comparable_stock_data: compare_stock_data

    })
  }
  handleChange = (e) => {
    console.log(e.target.value, e.target.name)
    this.setState({
      [e.target.name]: e.target.value
    },
      () => {
        if (e.target.name === 'find_similar_stocks_to') {
          this.findMostSimilarStocks()
        }
      }
    )
  }
  handleStartChange = (date) => {
    this.setState({
      start_date: moment(date).format('YYYY-MM-DD')
    })
  }
  handleEndChange = (date) => {
    this.setState({
      end_date: moment(date).format('YYYY-MM-DD')
    })
  }

  addStock = () => {
    this.setState(prevState => ({
      stocks: [...prevState.stocks, ''] // you may change '' to a default stock symbol if you prefer
    }));
  }

  handleStockChange = (index, value) => {
    this.setState(prevState => {
      const stocks = [...prevState.stocks];
      stocks[index] = value;
      return { stocks };
    });
  }

  submitStocks = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    this.setState({ stocks: this.state.stocks.map(stock => stock.toUpperCase()) });


    axios.post('/get_correlation', {
      stocks: this.state.stocks.map(stock => stock.toUpperCase()),
      start_date: this.state.start_date,
      end_date: this.state.end_date,
    }).then((response) => {
      if (response.data.status === "success" || response.data.status === "partial") {
        this.setState({
          stock_data: response.data.data,
          correlation: response.data.correlation.toFixed(2),
          isLoading: false,
          error_msg: response.data.status === "partial" ? `Could not fetch data for: ${response.data.failedSymbols.join(', ')}` : ""
        });
      } else {
        this.setState({
          error_msg: "We could not find data on one or more of the stock symbols you searched.",
          isLoading: false
        });
      }
    }).catch(error => {
      this.setState({
        error_msg: "An error occurred while fetching the data.",
        isLoading: false
      });
    });
  }


  render() {
    this.props.getState && this.props.getState(this.state);
    this.props.setResponsive(this.state.stock_data.length > 0);

    return (
      <>
        <CoorelationFilter
          handleChange={this.handleChange}
          handleStartChange={this.handleStartChange}
          handleEndChange={this.handleEndChange}
          submitStocks={this.submitStocks}
          addStock={this.addStock}
          handleStockChange={this.handleStockChange}
          {...this.state}
        />
        {this.state.layout !== 'table' ?
          <div className='p-3 p-lg-5'>
            <div className='d-flex justify-content-between'>
              <small className='m-0 text-accent-4'>correlation: {this.state.correlation}</small>
              {this.state.stock_data.length > 0 && <FilterButton />}
            </div>
            {this.state.error_msg && <div className='error'>{this.state.error_msg}</div>}
            <LineChart
              className='my-md-5 my-3'
              data={this.state.stock_data}
              labels={["Date", ...this.state.stocks.map(stock => `Price Change (%) ${stock}`)]}
              dataKeys={this.state.stocks.map(stock => ({
                dataKey: stock,
                stroke: '#' + Math.floor(Math.random() * 16777215).toString(16), // generates a random color
                activeDot: { r: 8 }
              }))}
            >
            </LineChart>
          </div>
          : <StockTable {...this.state} />
        }

      </>
    )
  }
}

export default withAlgoProductToolsLayout(Correlation_Container, 'Correlation');
