import React, { useEffect, useRef, useState } from 'react';
import ChevronBottom from '../../icons-v2/ChevronBottom';
import styles from './Select.module.scss';
import Portal from '../../hoc-v2/Portal';
import dependentTagPositionHandler from '../../utility/dependentTagPositionHandler';
import useQuery from '../../hooks/useQuery';
import useModel from '../../hooks/useModel';

const Select = ({ label, selectClass, children, value, isMultiple, onChange, className = '', dark, isNormal = false, icon = <ChevronBottom className='small' />, ...props }) => {
    const [v, setValue] = useState([]);
    const [active, setActive] = useState(false);
    const inpRef = useRef();
    const btnRef = useRef();
    const isMatched = useQuery();
    useModel({ shouldWork: isMatched && active, changed: [active] })
    const onSelect = e => {

        const elm = e.target;
        if (elm.nodeName !== "LI") return;
        const value = elm.getAttribute('value');
        let newValues;
        if (v.includes(value)) newValues = v.filter(v => value !== v);
        else if (isMultiple) newValues = [...v, value];
        else newValues = [value];
        setValue(newValues);
        if (!onChange) return setActive(false);
        e.target = inpRef.current;
        inpRef.current.value = value;
        setActive(false);
        onChange(e, newValues);
    }
    useEffect(() => value && setValue(value), [value])
    useEffect(() => {
        const inp = inpRef.current;
        if (!inp || isNormal) return;
        const options = inp.nextSibling.children;
        for (let option of options) v.includes(option.getAttribute('value')) ? option.classList.add(styles.active) : option.classList.remove(styles.active)
    }, [v])

    useEffect(() => {

        if (isMatched) return;
        const btn = btnRef.current;
        const inp = inpRef.current;
        if (!btn || !inp) return;
        const unSubscribe = dependentTagPositionHandler(btn, inp.nextSibling);
        return unSubscribe;

    }, [])
    return (
        <>
            <button ref={btnRef}
                className={`btn btn-text gap-1 ${className}`}
                onBlur={() => active && setActive(false)}
                onClick={() => setActive(!active)}
            >
                {label} {icon}
            </button>
            <Portal id="options">
                <input type="hidden" value={value} ref={inpRef} {...props} />
                <ul onClick={onSelect}
                    className={`
                        elevation-3 z-index-8 py-2 border-radius-8 list-unstyled position-fixed
                        ${v ? styles.filled : ""}
                        ${styles.root}
                        ${isNormal ? styles.normal : ''}
                        ${active ? styles.active : ''}
                        ${selectClass}
                        ${dark ? `${styles.dark} text-surface-1` :  'bg-surface-1'}
                    `}
                >
                    {children}
                </ul>
                {isMatched && <div className='position-fixed start-0 top-0 bottom-0 end-0 bg-normal opacity-2 z-index-7' />}
            </Portal>
        </>
    )
}

export const Option = ({ children, ...props }) => <li {...props}>{children}</li>

export default Select;
