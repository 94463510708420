import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import cookie from 'react-cookies'
import setCSS from "../utility/setCSS";

const useInitial = () => {
    const history = useHistory();
    
    useEffect(() => {
        const params = new URLSearchParams(history.location.search);
        const referred_by = params.get('referrer_id');
        const component_referred = params.get('component_id')
        const couponCode = params.get('coupon_code');
        if(referred_by && component_referred) {
            cookie.save('referred_by', referred_by);
            cookie.save('component_referred', component_referred);
        }
        if (couponCode) {
            cookie.save('coupon_code', couponCode);
          }
        window.addEventListener('resize', setCSS);
        setCSS();
        return () => {
            window.removeEventListener('resize', setCSS);
        }
    }, [])
    return null;
}

export default useInitial;