import React from 'react';

const ImportIcon = props => (
    <svg stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" viewBox="0 0 16 16" fill="none" {...props}>
        <path d="M9.86071 8.08089H1.83337" />
        <path d="M7.90955 6.13672L9.86155 8.08072L7.90955 10.0247" />
        <path d="M4.83923 5.08683C5.05923 2.70016 5.95257 1.8335 9.5059 1.8335C14.2399 1.8335 14.2399 3.3735 14.2399 8.00016C14.2399 12.6268 14.2399 14.1668 9.5059 14.1668C5.95257 14.1668 5.05923 13.3002 4.83923 10.9135" />
    </svg>
)

export default ImportIcon;