import React from 'react';
import axios from 'axios'
import URL from 'url-parse';
import moment from "moment";
import Graph from '../graph_module';
import { getBusinessDays } from '../../service/logic'
import withAlgoProductToolsLayout from '../../hoc-v2/withAlgoProductToolsLayout/withAlgoProductToolsLayout';
import DividendsStockFilter from './DividendsStockFilter';
// import ManageSignalPopUp from './ManageSignalPopUp';
import DividendsStockTable from './DividendsStockTable';
import Pagination from '../../ui-v2/Pagination/Pagination';
import { Link } from 'react-router-dom';
import TipCard from '../../ui-v2/TipCard/TipCard';


class Dividends extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: new URL(window.location.href, true),
      stock_data: [],
      sort_type: ["Date", "Biggest Payout", "Biggest Payout Percent"],
      period: "All",
      period_type: ["All", "Quarterly", "Monthly", "Semi-Annual", "Annual"],
      selected_type: "Date",
      orig_stock_data: [],
      scraping: false,
      page: 0,
      totalLength: 10,
      filterDate: moment().format('YYYY-MM-DD'),
      filterDate_options: ["All", moment().format('YYYY-MM-DD')],
      created: false,
      loadingTable: false,
      stock_search: this.props.symbols,
      no_result: false,
      number_of_days_add: 2,
      number_of_days_sub: 2,
      unique_date: "",
      graph_title: "",
      graph_subtitle: "",
      show_graph: false,
      stock_graph_data: [],
      graph: {},
      loading: true,

      selected_stock: ""
    }
    this.handlePageClick = this.handlePageClick.bind(this)
    this.getData = this.getData.bind(this)
  }

  componentDidMount() {
    // this.fetchData()
    if (this.props.state) return this.setState(this.props.state);
    axios.get('/get_dividends_data_dates').then((response) => {
      this.setState({
        filterDate_options: [...response.data.dates]
      }, () => {
        const dates = [...response.data.dates]
        let { filterDate } = this.state

        if (dates.indexOf(filterDate) === -1) {
          let exist = 0
          for (let i = 1; i < 30; i += 1) {
            const date = moment(filterDate).add(i, "days").format('YYYY-MM-DD')
            if (dates.indexOf(date) !== -1) {
              filterDate = date

              exist = 1
              break;
            }
          }
          if (exist) {
            this.setState({
              filterDate: filterDate,
              loading: false
            }, () => {
              this.getData()
            })
          } else {
            this.setState({
              filterDate: 'All',
              loading: false
            }, () => {
              this.getData()
            })
          }
        } else {
          this.setState({
            loading: false
          }, () => {
            this.getData()
          })

        }

      })

    })
  }


  getData() {
    if (!this.state.loading) {
      const json = {
        page: this.state.page,
        sort: this.state.selected_type,
        period: this.state.period,
        date: this.state.filterDate,
        stock_search: this.state.stock_search.length > 0 ? this.state.stock_search.split(',') : ""
      }
      this.setState({
        loadingTable: true,
        stock_data: [],
        no_result: false
      })

      axios.post('/get_dividend_data', {
        ...json
      }).then((response) => {

        this.setState({
          totalLength: response.data.total,
          stock_data: response.data.result,

        })
        if (response.data.result.length === 0) {
          this.setState({
            no_result: true
          })
        }

      }).finally(() => {
        this.setState({
          loadingTable: false,
        })
      })
    }

  }

  handlePageClick(data) {
    this.setState({
      page: data.selected
    })
    setTimeout(() => {
      this.getData()
    }, 100)
  }
  handleChange = (e) => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleSortChange = (e) => {
    let val = e.target.value
    this.setState({
      selected_type: val,
      page: 0,
    })
    setTimeout(() => {
      this.getData()
    }, 100)
  }

  handleFilterDateChange = (e) => {
    this.setState({
      filterDate: e.target.value,
      page: 0,
    })
    setTimeout(() => {
      this.getData()
    }, 100)
  }

  handleFilterChange = (e) => {
    let val = e.target.value
    this.setState({
      period: val,
      page: 0,
    })
    setTimeout(() => {
      this.getData()
    }, 100)
  }

  fetchData = (e) => {
    this.setState({
      scraping: true,
    })
    axios.get('/fetch_dividend_stocks').then((response) => {
      this.setState({
        orig_stock_data: response.data.result,
        stock_data: response.data.result
      })
    }).finally(() => {
      this.setState({
        scraping: false,
      })
    })
  }

  get(n) {
    if (n === undefined) return '-'
    if (n.length === 0) return '-'
    return n
  }

  handleStockChange = (e, values) => {
    const { value, name } = e.target
    this.setState({
      [name]: values?.join(',') || value,
      page: 0,
    }, () => {
      setTimeout(() => {
        if (value === this.state.stock_search) {
          this.getData()
        }
      }, 300)
    })
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.symbol_list !== this.props.symbol_list) {
      this.setState({
        stock_search: this.props.symbol_list,
        page: 0,
      }, () => {
        this.getData()
      }
      )
    }
  }

  getGraphStock = async (days_add, days_sub, dates) => {
    let stock = this.state.selected_stock
    this.setState({ isLoading: true });
    if (days_sub) {

      axios.post('/get_stock_price_data', {
        stock_symb: stock,
        dates: dates,
        loading: true
      }).then((response) => {
        this.setState({
          stock_graph_data: response.data.data.reverse(),

        }, () => {

          this.setState({
            loading: false,
            show_graph: true,
            graph: {}
          })
        })
      })

    } else {


      axios.post('/get_stock_price_data', {
        stock_symb: stock,
        dates: getBusinessDays(this.state.unique_date, days_add, days_sub),
        loading: true
      }).then((response) => {
        this.setState({
          stock_graph_data: response.data.data.reverse(),

        }, () => {

          this.setState({
            loading: false,
            show_graph: true,
            graph: {}
          })
        })
      })
    }


  }

  graphStock = async (e, index, stock, date, title, am_pm) => {

    e.preventDefault()
    if (this.state.graph.index !== undefined) return;

    this.setState({
      selected_stock: stock,
      graph: {
        index: index,
        symb: stock
      }
    })

    axios.post('/get_stock_price_data', {
      stock_symb: stock,
      dates: getBusinessDays(date, this.state.number_of_days_add, this.state.number_of_days_sub),
      loading: true
    }).then((response) => {
      this.setState({
        stock_graph_data: response.data.data,
        unique_date: date,
        graph_title: title,
        graph_subtitle: stock,
        selected_stock: stock
      }, () => {

        this.setState({
          loading: false,
          show_graph: true,
          graph: {}
        })
      })
    })
  }

  closePopup = () => {
    this.setState({
      show_graph: false
    })
  }

  render() {
    const { loadingTable, scraping, page, totalLength } = this.state
    const tipProps = this.props.tipProps;
    const stepNo = tipProps.stepNo;
    this.props.getState && this.props.getState(this.state);
    return (
      <React.Fragment>
        <TipCard id='tip-1'
          totalSteps={3}
          activeStep={1}
          {...tipProps}
          className='mt-3 me-0'
        >Hover over an item for an explanation of its function.</TipCard>
        <TipCard id='tip-2'
          {...tipProps}
          totalSteps={3}
          activeStep={2}
        >Filter the data based on your desired criteria (ie. by date, by stock, by transaction type, etc.). View the data as grouped by company, or by individual transactions.</TipCard>
        <TipCard id='tip-3'
          {...tipProps}
          totalSteps={3}
          activeStep={3}
        >Opt in for email signals so you can stay informed and never miss a trade. <Link to='/' className='btn btn-text text-surface-1'>Learn how to use</Link></TipCard>
        <DividendsStockFilter
          handleFilterChange={this.handleFilterChange}
          handleSortChange={this.handleSortChange}
          handleStockChange={this.handleStockChange}
          handleFilterDateChange={this.handleFilterDateChange}
          {...this.state} {...this.props}
          stepNo={stepNo}
        />
        {
          this.state.show_graph &&
          <Graph
          date= {this.state.unique_date}
          symbol={this.state.selected_stock}
          text={this.state.graph_title}
          close={this.closePopup}
          />
        }


        <div className='p-lg-5 p-3 overflow-hidden d-flex flex-column flex-1'>
          {
            scraping &&
            <span className="__ml-2">Now it's scraping and update database. It will take 5 - 9 minutes.</span>
          }
          {(scraping || loadingTable) && <div className='bar-loader mb-3' />}
          
          <DividendsStockTable
            graphStock={this.graphStock}
            stepNo={stepNo}
            {...this.state}
          />
          <Pagination
            pageCount={Math.ceil(totalLength / 10)}
            initialPage={page}
            forcePage={page}
            onPageChange={this.handlePageClick}
            className='mt-3'
          />
        </div>
      </React.Fragment>
    )
  }
}

export default withAlgoProductToolsLayout(Dividends, 'Ex-Dividend Analysis', { showTips: true });
