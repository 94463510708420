import React, { useState } from 'react';
import AddIcon from "../../../icons-v2/AddIcon";
import Thundar from '../../../icons-v2/Thundar';
import Select, { Option } from '../../../ui-v2/Select/Select';
import DyanamicWatchlistModal from '../../DyanamicWatchlistModal/DyanamicWatchlistModal';
import EditCustomModal from '../EditCustomModal';

const CreateWatchList = ({ setState, watchlist, ...props }) => {
    const [modal, setModal] = useState(0)
    const closeHandler = () => setModal(0)
    const onCreate = item => {
        setState({ watchlist: [...watchlist, item], selectedItem: item });
        setModal(0)
    }

    return (
        <>
            {modal === 2 && <DyanamicWatchlistModal onDynamicCreate={onCreate} closeHandler={closeHandler} show={true} {...props} />}
            {modal === 1 && <EditCustomModal onEdit={onCreate} closeHandler={closeHandler} />}
            <Select
                dark
                icon={null}
                isNormal
                className='small'
                label={<><AddIcon className='smaller' /> Create</>}
                onChange={e => setModal(+e.target.value)}
            >
                <Option value='1'>Custom Watchlist</Option>
                <Option value='2' className='gap-3'>Dynamic Watchlist <Thundar className='small me-auto' /></Option>
            </Select>
        </>
    )
}

export default CreateWatchList;
