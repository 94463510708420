import React from 'react';
import ToolTip from '../../ui-v2/ToolTip/ToolTip';

const SaleBar = ({ revenue, broker_fees, profit, buy_hold_profit }) => {
    return (
        <div className='p-3 p-lg-4 p-xl-5 d-grid col-xl-4 col-md-2 gap-lg-4 gap-3 border-bottom'>
            <div className='d-grid gap-1'>
                <p className='m-0'>
                    <ToolTip title="The hypothetical revenue you would have made not including broker expenses.">
                        Revenue
                    </ToolTip>
                </p>
                <h5 className='m-0 h6-sm'>{parseFloat(revenue).toFixed(2)}</h5>
            </div>
            <div className='d-grid gap-1'>
                <p className='m-0'>
                    <ToolTip title="The approximate cost of your broker fees if they charge you for each buy and sell that occurred.">
                        Cost of Broker Fees:
                    </ToolTip>
                </p>
                <h5 className='m-0 h6-sm'>{parseFloat(broker_fees).toFixed(2)}</h5>
            </div>
            <div className='d-grid gap-1'>
                <p className='m-0'>
                    <ToolTip title="The revenue subtracted by the cost of the broker fees.">
                        Profit
                    </ToolTip>
                </p>
                <h5 className='m-0 h6-sm'>{parseFloat(profit).toFixed(2)}</h5>
            </div>
            <div className='d-grid gap-1'>
                <p className='m-0'>
                    <ToolTip title="This displays the approximate amount you would have earned if you bought the stock at the beginning of the trading period and sold at the end of the trading period.">
                        Buy and Hold Profit:
                    </ToolTip>
                </p>
                <h5 className='m-0 h6-sm'>{parseFloat(buy_hold_profit).toFixed(2)}</h5>
            </div>
        </div>
    )
}

export default SaleBar;