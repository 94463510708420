import React from 'react';
import Table from "../../ui-v2/Table/Table"
import TR_STOCK from "./TR_FOR_STOCK"
import TR from './TR';
import HistoryModal from './HistoryModal';
import SectorAnalysisModal from './SectorAnalysisModal';
import styles from './StockTable.module.scss';

const Main = props => {
    const [stockHistory, setHistory] = React.useState(null);
   
    return (
        <>
        {stockHistory && <HistoryModal {...stockHistory} closeHandler={() => setHistory(null)} />}
            {props.stocks.length > 0 && props.stocks.map((stock, index) => (
                <TR_STOCK
                    key={index}
                    {...stock}
                    showHistory={(stock, company_name, holdings) => setHistory({ stock: stock, company_name: company_name, name: company_name, history: holdings })}
                />
            ))
            }

        </>
    )

}



const headings = [
    { title: 'Ticker', key: 'ticker' },
    { title: 'Company Name', key: 'company_name' },
    { title: <>Total Shares Owned <br /> By Institutions</>, key: 'total_shares_owned_inst' },
    { title: <>Total Monetary Value <br /> Owned by Institutions</>, key: 'total_value_inst' },
    { title: <>Average <br />Purchase Price</>, key: 'avg_purchase_price_based_on_value' },
    { title: <>Average Amount <br /> of Shares Owned</>, key: 'avg_amount_of_shares_owned' },
    { title: <>Average <br /> Value</>, key: 'avg_value' },
    { title: <>Average Percent <br /> of Portfolio</>, key: 'avg_percent_portfolio' },
    { title: <>Average Recent <br /> Change</>, key: 'avg_recent_change' }
];

const StockTable = props => (
    <>
        {props.loadingTable ? <div className='bar-loader m-3' /> : <small className='text-accent-4 mx-5 mt-3'>{props.totalLength} Results</small>}
        <Table className='product_tool_table mt-2'>
            <thead className='text-uppercase position-relative z-index-1'>
                <tr>
                    <th></th>
                    {headings.map(item => (
                        <th key={item.key}
                            onClick={() => props.onSort(item.key)}
                            className={`${styles.heading} ${props.sort === item.key ? `${styles.active} ${props.desc ? '' : styles.desc}` : ''}`}
                        >{item.title}</th>
                    ))}

                </tr>
            </thead>
            <tbody>
                <Main {...props} />
            </tbody>
        </Table>
    </>
)

export default StockTable;