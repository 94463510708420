import React from 'react';
import Card from "./Card";
import LI from './LI';

const Management = props => (
    <Card title='Management'>
        <ul>
            <LI title='Return on Equity' tooltip={`a measure of financial performance calculated by dividing net income by shareholders' equity. ROE is considered a gauge of a corporation's profitability and how efficient it is in generating profits. Lots of other factors to consider when basing an investment decision around ROE.`} value={props.returnonequity} end='%' />
            <LI title='Return on Capital' tooltip={`a ratio that measures how well a company turns capital (e.g. debt, equity) into profits. In other words, ROC is an indication of whether a company is using its investments effectively to maintain and protect their long-term profits and market share against competitors.`} value={props.returnoncapital} end='%' />
            <LI title='Return on Assets' tooltip={`Return on assets is a metric that indicates a company's profitability in relation to its total assets. ROA can be used by management, analysts, and investors to determine whether a company uses its assets efficiently to generate a profit.`} value={props.returnonassets} end='%' />
        </ul>
        <ul>
            <LI title='Return on Equity LTM' tooltip='Return on Equity Last Twelve Months' value={props.returnonequityLTM} end='%' />
            <LI title='Return on Capital LTM' tooltip='Return on Capital Last Twelve Months' value={props.returnoncapitalLTM} end='%' />
            <LI title='Return on Assets LTM' tooltip='Return on Assets Last Twelve Months' value={props.returnonassetsLTM} end='%' />
        </ul>
    </Card>
)

export default Management;
