import React from 'react';
import axios from 'axios';
import URL from 'url-parse';
import { useApp } from '../../App'
import Inputs from './inputs';
import './styless.scss';
import withAlgoProductToolsLayout from '../../hoc-v2/withAlgoProductToolsLayout/withAlgoProductToolsLayout';

const Swiper_Container = props => {
  const app = useApp();
  return <Swiper {...props} {...app} />
}

class Swiper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: new URL(window.location.href, true),
      fb_email: "",
      fb_pass: "",
      app: "bmbl",
      bumble_running: false,
      tinder_running: false,
      // socket:useApp().socket,
      profiles_liked: [],
      direction: 'right',
      ip_address: ''
    }
    this.props.socket.on("PROFILE_LIKED", (profile) => {

      switch (profile.name) {
        case "Tinder Closed":
          this.setState({
            tinder_running: false
          })

          break;
        case "Bumble Closed":
          this.setState({
            bumble_running: false
          })

          break;
        default:
          let temp_arr = this.state.profiles_liked
          temp_arr.unshift(profile)
          this.setState({
            profiles_liked: temp_arr
          })

      }

    })
  }
  // componentDidMount() {
  //   this.state.socket.emit("USER_CONNECTED", this.props.user._id)
  //
  // }
  getData = async () => {
    const res = await axios.get('https://geolocation-db.com/json/')

    this.setState({
      ip: res.data.IPv4
    })

  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  on_run = (e) => {
    e.preventDefault();
    if (this.state.app === "bmbl") {
      axios.post('/run_bmbl', {
        username: this.state.fb_email,
        password: this.state.fb_pass,
        direction: this.state.direction,
        ip: this.state.ip

      })
      this.setState({
        bumble_running: true
      })
    } else if (this.state.app === "shpr") {
      axios.post('/run_shapr', {
        username: this.state.fb_email,
        password: this.state.fb_pass,
        direction: this.state.direction
      })
    } else {
      axios.post('/run_tndr', {
        username: this.state.fb_email,
        password: this.state.fb_pass,
        direction: this.state.direction,
        ip: this.state.ip

      })
      this.setState({
        tinder_running: true
      })
    }

  }

  render() {
    return (
      <>
        <Inputs
          handleChange={this.handleChange}
          on_run={this.on_run}
          {...this.state}
        />
        <div className='table-wrapper product_tool_table p-lg-5' style={{ height: "30px", display: "unset", overflow: "unset" }}>

          <table>
            <thead>
              <tr>
                <th><div>Bumble ({this.state.profiles_liked.filter((x) => x.platform === "Bumble").length}):{this.state.bumble_running ? <p> Running<span className="dot bg-accent-3" /></p> : <p> Paused<span className="dot bg-accent-2"></span></p>}</div></th>
                <th><div>Tinder ({this.state.profiles_liked.filter((x) => x.platform === "Tinder").length}):{this.state.tinder_running ? <p> Running<span className="dot bg-accent-3" /></p> : <p> Paused<span className="dot bg-accent-2"></span></p>}</div></th>
              </tr>
            </thead>
          </table>
          <div className='table-wrapper product_tool_table p-lg-5' >
            <div>
              <table>
                <thead>
                  <tr>
                    <th><div>Name</div></th>
                    <th>Platform</th>
                    <th>Direction</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.profiles_liked.map((option, index) => (
                    <tr key={index}>
                      <td title='Expiration Date'><div>{option.name}</div></td>
                      <td title='Stock Symbol'>{option.platform}</td>
                      <td title='Direction'>{option.direction ? option.direction : ""}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>


        </div>


      </>
    )
  }
}

export default withAlgoProductToolsLayout(Swiper_Container, 'Auto Swiper');
