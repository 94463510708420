import React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import URL from 'url-parse';
import { AppContext } from '../App'
import styles from './basic_sentiment.module.scss';
import { Fragment } from 'react';
import SideFilter from '../components-v2/SideFilter/SideFilter';
import InputField from '../ui-v2/InputField/InputField'
import withAlgoProductToolsLayout from '../hoc-v2/withAlgoProductToolsLayout/withAlgoProductToolsLayout';

class Sentiment_Container extends React.Component {

  static contextType = AppContext;
  render() {
    return (
      <Sentiment
        {...this.props}
        {...this.context} />
    )
  }
}

class Sentiment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: new URL(window.location.href, true),
      stock_search: "text",
      results: 2,
      error:""

    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  getResults = (e) => {
    e.preventDefault();
    axios.post('/sentiment_calculator', {
      text: this.state.text
    }).then((response) => {
      if (response.data.status==="success"){
        this.setState({
          results: response.data.result,
          error:""
        })
      }else {
        this.setState({
          error:"An error occurred please try different text."
        })
      }

    })
  }
  componentDidMount() {
    if(this.props.state) return this.setState(this.props.state);
  }

  render() {
    this.props.getState && this.props.getState(this.state);
    return (
      <>
        <SideFilter>
          <InputField
            multiline
            rows={5}
            name='text'
            label="Enter Text to Analyze"
            variant="outlined"
            onChange={this.handleChange}
            value={this.state.text}
            placeholder="Enter the text that you would like this algorithm to analyze"
          />
          <button
            className='btn btn-primary'
            onClick={this.getResults}
          >
            Analyze Text
          </button>
        </SideFilter>
        {this.state.results !== 2 &&
          <div className='flex-1 p-3 p-md-4 p-lg-5'>
            <p className='m-0'>Results</p>
            <h5 className='h6-sm my-3'>Score: <strong> {this.state.results.score}</strong> - {this.state.results.score === 0 ? "The text seems to be neutral" : ""} {(this.state.results.score !== 0 && this.state.results.score > 0) ? "The text seems to be positive" : ""} {(this.state.results.score !== 0 && this.state.results.score < 0) ? "The text seems to be negative" : ""}</h5>
            <div className={styles.scoreWrapper}>
              <div className='d-grid col-2 text-center'>
                <small className='overline'>Word</small>
                <small className='overline'>Word Score</small>
                {this.state.error.length>0 && <p className='text-accent-2'>{this.state.error}</p>}
                {this.state.results.calculation.map((result) => (
                  <Fragment>
                    <p className='m-0'>{Object.keys(result)}</p>
                    <p className='m-0'>{Object.values(result)}</p>
                  </Fragment>
                ))}
              </div>
            </div>
          </div>
        }
      </>
    )
  }
}


export default withAlgoProductToolsLayout(Sentiment_Container, 'Sentiment Analyzer', {isResponsive: false});
