import React from 'react';
import { Helmet } from "react-helmet";

const SiteTitle = props => (
  <Helmet>
    <title>{props.title}</title>
    <meta name="description" content={props.meta_description} />
    <meta name="keywords" content={props.meta_keywords} />
  </Helmet>
)

export default SiteTitle;
