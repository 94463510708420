import React, { useEffect, useState } from "react";
import useModel from "../../../hooks/useModel"
import Step1 from "./Step1";
import styles from './GetStartedModal.module.scss';
import axios from "axios";

const GetStartedModal = ({isLoggedIn, ...props}) => {
    const [step, setStep] = useState(1);
    useModel({ shouldWork: step > 0, changed: [step] });
    const onFinish = () => axios.post('/active_leaderboard');
    const onNext = () => {
        const next = step + 1;
        setStep(next);
        if(isLoggedIn) onFinish();
        else localStorage.setItem('activated_on_leaderboard', true);
        setTimeout(() => {
            props.changeUser({ activated_on_leaderboard: true })
        }, 200);
    }
    const newProps = { step, onNext };
    useEffect(() => {
        if(isLoggedIn && localStorage.getItem('activated_on_leaderboard')) onFinish();
    }, [isLoggedIn])
    return step > 0 ? (
        <div className={
            `position-fixed top-0 d-flex align-items-center justify-content-center bottom-0 start-0 end-0 animation-opacity${step === 2 ? '-down' : ''} ${styles.root}`
        }>
            <Step1 {...newProps} />
        </div>
    ) : ""
}

export default GetStartedModal;