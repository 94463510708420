export default {
    chat: [
        { 
            message: `Stockalgos.com is a website that offers a variety of tools and algorithms for stock market analysis and trading. It aims to help retail traders by providing access to quality information, education, and an intuitive layout. The website has several features, such as:

        Marketplace: where you can browse, buy, and sell stock tools and algorithms. The marketplace has a wide range of tools, such as neural net algorithm, options screener, fundamentals, insiders, congress trading, and more. Each tool has a rating, a description, a price, and a free trial option. You can also play a tutorial video to learn how to use the tool.
        The marketplace is designed to help retail traders by providing access to quality information, education, and an intuitive layout. The resources that are offered will continue to improve so that the stock trading playing field can be evened and the middle class can be empowered. You can sort the tools by top rated, newest, payment type, or category. You can also see the reviews and feedback from other users who have used the tools.
        Leaderboard: This is where users can make stock predictions, win prizes, and rank on the leaderboard. The leaderboard shows the top accurate predictors with their average accuracy percentage. Users can post their predictions by picking a stock, selecting how much they think it will move and the direction, and selecting the date for which their prediction will come true. Users can also interact with other users’ predictions by liking and commenting on them. The website uses a scoring system to calculate the accuracy of each prediction based on the price movement of the stock.
        Blog: This is where users can read articles and news about the stock market, investing, trading, and more. The blog covers topics such as stock market trends, strategies, tips, reviews, and interviews with experts.
        Dashboard: This is where users can access their watchlists and easily navigate between the apps they own.
        Settings: Users can manage their subscriptions, email notifications, and more in the 'My Account' Section that can be found when clicking the top User Icon in the top right.
        Referral System: Have a friend that you think will benefit from this platform? Feel free to invite them and get credits that can be used on the site.
        Hedge Fund Analysis: This tool lets you track the portfolios and stock picks of famous investors, such as Warren Buffett, George Soros, and Ray Dalio. You can see what stocks they own, buy, sell, or hold, and compare their performance and strategies1.
        Fundamentals: This tool helps you evaluate companies based on their fundamental ratios and metrics, such as revenue, earnings, cash flow, debt, and more. You can also filter and sort the stocks by various criteria1.
        Congress Trading: This tool aggregates all the trading executed by congress members. You can see what stocks they are buying or selling, and how their trades affect the market1.
        Insiders: This tool gives you an overview of how insiders, such as CEOs, CFOs, directors, and other key employees, are trading the stock market. You can easily find the stocks that have the most insider activity and the largest transactions1.
        Government Awarded Contracts: This tool shows you which publicly traded stocks have been awarded government contracts. You can see the details of the contracts, such as the amount, duration, agency, and more1.
        Earnings: This tool shows you a stock’s earnings report and tracks its historical performance on and before earnings report dates. You can also see the analysts’ estimates and consensus for the earnings1.
        FDA Stock Analysis: This tool helps you find stocks with upcoming FDA dates and the details on their drugs, market potential, and competitors. You can also see how the stocks perform before and after the FDA approval or rejection1.
        Options Screener: This tool helps you examine option chains and find the best call options based on your selected criteria. You can also see the implied volatility, delta, gamma, theta, and more1.
        AI Playground: This tool allows you to generate various types of content using artificial intelligence models. You can generate essays, graphics, poems, songs, code, and more1.
        Neural Net Algorithm: This tool uses a neural network to analyze extensive data sets and determine correlations. You can use this tool to find patterns and trends in the stock market1.
        Ex-Dividend Analysis: This tool shows you upcoming dividend payout dates and tracks historical performance on and before payout dates. You can also see the dividend yield, payout ratio, frequency, and history1.
        Momentum Trader: This tool helps you use the market’s momentum and trade using a multi-day tool that connects to your trading account. You can set your own parameters and let the tool execute your trades automatically1.
        Options Time Decay: This tool helps you identify options with the same strike on different expiry dates. You can use this tool to compare the time decay of different options and find the best ones to buy or sell1.
        Stock Correlation Tool: This tool helps you easily compare two stocks to determine their data correlations. You can use this tool to find pairs of stocks that move together or opposite to each other1.
        Senate Trading: This tool helps you track trading by U.S. Senators and get notified when new trades are executed. You can also see how their trades affect their net worth and the market1.
        House of Representatives Trading: This tool helps you track trading by U.S. House of Representatives members and get notified when new trades are executed. You can also see how their trades affect their net worth and the market1.
        Stockalgos.com is a platform that aims to empower the middle class and even the stock trading playing field. It claims to offer cutting-edge tools, algorithms and strategies that can help users achieve their financial goals.`, role: 'user', hidden: true
        }
    ],
    suggetions: [
        { suggestion: "What is stockalgos.com?" },
        { suggestion: "How can I win the global leaderboard competition?" },
        { suggestion: "What does the fundamentals tool do?" },
        { suggestion: "Teach me about stocks." },
    ]
}