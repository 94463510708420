import React from 'react';
import styles from './AlgoItemV2.module.scss';
import { useHistory } from 'react-router-dom';
import ArrowRightIcon from '../../icons-v2/ArrowRightIcon';
import ImageWrapper from '../../ui-v2/ImageWrapper/ImageWrapper';
import PlayIcon from '../../icons-v2/PlayIcon';
import VideoWrapper from '../VideoWrapper/VideoWrapper';

const AlgoItemV2 = ({ to, stockAlgoName, image_url, type, video_url, short_description, institutional_name, className = '', children }) => {
    const push = useHistory().push;
    const onClick = () => push(to)
    return (
        <div className={`cursor-pointer text-accent-4 elevation-1 position-relative elevation-3-hover ${className} flex-1 d-flex flex-column ${styles.root} `}>

            <div onClick={onClick} className={`${styles.main} position-relative d-flex flex-column flex-1 bg-surface-1 z-index-1`}>
                <h5 className='d-flex text-normal justify-content-between align-items-center'>{institutional_name?institutional_name:stockAlgoName} <ArrowRightIcon className='text-primary' /></h5>
                {children}
                <div className={`m-0 d-webkit-box lines-4 dangerouslySetInnerHTML ${styles.blog}`} dangerouslySetInnerHTML={{ __html: short_description }} />
                {type?.length > 0 && <span className={`${styles.badge} bg-surface-1 text-uppercase position-absolute text-normal fw-semibold`}>{type}</span>}
            </div>
        </div>
    )
}

export default AlgoItemV2;