import React, { useMemo, useRef, useState } from 'react';
import ChevronBottom from '../../../icons-v2/ChevronBottom';
import Header from './Header';
import styles from './RightDrawer.module.scss'
import './animation.scss';
import Fundamentals from './stock/Fundamentals';
import Stats from './stock/Stats';
import Assets from './stock/Assets';
import Dividends from './stock/Dividends';
import ValuationMeasures from './stock/ValuationMeasures';
import Management from './stock/Management';
import FinancialStrength from './stock/FinancialStrength';
import IncomeStatements from './stock/IncomeStatements';
import Profitability from './stock/Profitability';

let isScrolling = false;

const RightDrawer = props => {
    const ref = useRef();
    const [checked, setChecked] = useState('Fundamentals')
    const onScroll = e => {
        if (isScrolling) return;
        const parentY = e.target.getBoundingClientRect().y;
        const children = e.target.children;
        for (let i = 0; i < children.length; i++) {
            const child = children[i];
            const rect = child.children[0].getBoundingClientRect();
            if ((rect.y + rect.height) > parentY) {
                if (checked !== child.id) setChecked(child.id);
                break;
            }
        }
    }
    const onChecked = (e) => {
        const elm = ref.current;
        const value = e.target.value;
        if (!elm || value === checked) return;
        for (let child of elm.children) {
            if (child.id === value) {
                isScrolling = true;
                child.scrollIntoView();
                setChecked(value);
                setTimeout(() => {
                    isScrolling = false;
                }, 500)
                break;
            }
        }
    }
    return (
        <>
            <div className='position-fixed h-100 top-0 start-0 w-100 bg-normal opacity-2 d-lg-none z-index-7' onClick={props.onClose}></div>
            <div className={`${styles.root} __Fundamentals-RigthDrawer z-index-7 d-flex top-0 h-100 overflow-hidden`}>
                <div className={styles.btnWrapper} onClick={props.onClose}>
                    <button className='btn bg-surface-1 small border rotate-270 btn-icon'><ChevronBottom /></button>
                </div>
                <div className={`d-grid bg-surface-1 flex-1 overflow-auto elevation-3  pb-lg-4 pb-3  ${styles.main}`}>
                    <Header company_name={props.company_name} symbol={props.ticker} checked={checked} onChecked={onChecked} />
                    <div ref={ref} onScroll={onScroll} className={`overflow-auto d-inherit ${styles.cards}`}>
                        {useMemo(() => (
                            <>
                                <Fundamentals {...props} />
                                <Stats {...props} />
                                <Assets {...props} />
                                <Dividends {...props} />
                                <ValuationMeasures {...props} />
                                <Management {...props} />
                                <FinancialStrength {...props} />
                                <IncomeStatements {...props} />
                                <Profitability {...props} />
                            </>
                        ), [props])}
                    </div>
                </div>
            </div>
        </>
    )
}

export default RightDrawer;
