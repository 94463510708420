import React, { Component } from 'react';
import Spinner from '../ui-v2/Spinner/Spinner';
import styles from './MLModelSignalRow.module.scss';

class MLModelSignalRow extends Component {
    state = {
        isLoading: false
    }
    startSignal = () => {
        this.setState({ isLoading: true });
        // axios code
    }

    render() {
        const {
            model_name,
            stock_symbol,
            traning_date_start,
            traning_date_end,
            predicted_return,
            status,
            selected_index,
            type,
            model_id,
            signal_id
            // actual_return,
            // test1_date_start,
            // test1_date_end,
            // test1_test_return
        } = this.props;

        const { isLoading } = this.state;

        return (
            <tr>
                <td title="Model Name">{model_name}</td>
                <td title='Stock Symbol'>{stock_symbol}</td>
                <td title='Training Date Start'>{traning_date_start}</td>
                <td title='Training Date End'>{traning_date_end}</td>
                <td title='Predicted Return (train)'>{predicted_return}</td>

                <td title='Action' className={styles.row}>
                    {status==="Active" ?
                        (
                            <div className='d-flex text-accent-3 gap-2 align-items-center font-weight-normal'>
                                <Spinner fontSize='0.35rem' />
                                <i className='fw-mediam'>Running</i>
                                <button className='btn btn-primary bg-accent-2 small' onClick={(event) =>
                                  this.props.handle_auto_ml_run_stop(
                                    event,
                                    "Paused",
                                    selected_index,
                                    type,
                                    predicted_return,
                                    model_id
                                  )
                                }>Stop</button>
                            </div>
                        ) :
                        <>
                        <button className='btn btn-primary small' onClick={(event) =>
                          this.props.handle_auto_ml_run_stop(
                            event,
                            "Active",
                            selected_index,
                            type,
                            predicted_return,
                            model_id
                          )
                        }>Start</button>
                        <button style={{color:"red"}} className='btn btn-primary small' onClick={(event) =>
                          this.props.handle_delete_ml_signal(
                            event,
                            signal_id
                          )
                        }>Delete</button>
                        </>
                    }
                </td>
            </tr>
        )
    }
}

export default MLModelSignalRow;
