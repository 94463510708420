import React from 'react';
import styles from './Circle.module.scss';

const Circle = ({className, to = 'center'}) => (
    <span className={`
    ${className} position-absolute d-lg-block d-none text-primary-lighten-1 ${styles.root}
        ${to === 'center' ? 'translate-middle' : `translate-middle-y top-50 ${to === 'right' ? styles.right : ''}`} 
    `}></span>
)

export default Circle;