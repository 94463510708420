import React from 'react';
import { NavLink } from "react-router-dom";
import NavDropDown from "../../Header/NavDropDown/NavDropDown";
import LogoutModal from '../../LogoutModal';
import UserAvatar from "../../UserAvatar/UserAvatar";
import HeaderLink from "../HeaderLink";
import styles from './UserDropDown.module.scss';

const UserDropDown = ({ user, ...props }) => {
    return (
        <NavDropDown className={styles.root}
            component={(isActive, closeHandler, toggleHandler) => (
                <HeaderLink onBlur={closeHandler}
                    onClick={toggleHandler}
                    className={isActive ? 'active' : ''}
                    title={<><UserAvatar {...user} />{user.name}</>}
                />
            )}>
            <li>
                <NavLink to='/my-account'>My Account</NavLink>
            </li>
            <li>
                <NavLink to='/help-and-support'>Help & Support</NavLink>
            </li>
            <li>
                <NavLink to='/privacy'>Privacy policy</NavLink>
            </li>
            <li>
                <LogoutModal {...props} />
            </li>

        </NavDropDown>
    )
}

export default UserDropDown;
