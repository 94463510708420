import React from 'react';
import SymbolTool from '../../components-v2/SymbolTool/SymbolTool';
import ChevronBottom from '../../icons-v2/ChevronBottom';
import getNumber from '../../utility/getNumber';
import styles from './StockItem.module.scss';

const StockItem = props => {
    return (
        <tr className={styles.root} onClick={props.onClick}>
            <td><ChevronBottom onClick={props.onClick} className={`text-accent-4 transition ${props.active ? 'rotate--90' : ''}`} /></td>
            <td>
                <div>
                    <SymbolTool
                        ticker= {props.ticker}
                        date={props.date}
                        title={`Stock chart for ${props.ticker}`}
                    />
                </div>
            </td>
            {props.fields.map(({name}, index) => {
                const isLongNumber = name === 'marketcap' || name === 'peratio' || name === 'pricetosales' || name === 'pbratio' || name === 'beta' || name === 'stddev'|| name === 'dividendyield'|| name === 'diff_from_high'|| name === 'diff_from_low';
                return (
                    <td key={index}>
                        {isLongNumber ? getNumber(props[name]) : props[name]}
                    </td>
                )
            })}


        </tr>
    )
}

export default StockItem;
