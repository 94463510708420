import React from 'react';
import FilterButton from '../../components-v2/AlgoHoldingCommonTools/FilterButton';
import ToolTip from '../../ui-v2/ToolTip/ToolTip';

const MomentumTraderStockData = ({ stock }) => {
    return stock.length > 0 ? (
        <div className='p-md-4 px-lg-5 mb-3'>
            <div className="d-flex justify-content-between align-items-center">
                {stock.length} <FilterButton />
            </div>
            <div className='table-wrapper product_tool_table'>
                <div>
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    <div>
                                        <ToolTip title="Whether or not a Buy or a Sell order would have been placed.">
                                            Signal
                                        </ToolTip>
                                    </div>
                                </th>

                                <th>
                                    <ToolTip title="The stock price that the transaction would have occurred at.">
                                        Price
                                    </ToolTip>
                                </th>

                                <th>
                                    <ToolTip title="The relative highest price of the stock since the last trade. If this price - the current stock price is greater than the sell differential, it will likely execute a sell. ">
                                        Highest Value
                                    </ToolTip>
                                </th>

                                <th>
                                    <ToolTip title="The relative lowest price of the stock since the last trade. If the current stock price - this price is greater than the buy differential, it will likely execute a buy.">
                                        Lowest Value
                                    </ToolTip>
                                </th>

                                <th>
                                    <ToolTip title="How much you would have made if you bought and sold.">
                                        Trade Profit
                                    </ToolTip>
                                </th>

                                <th>
                                    <ToolTip title="A tally of how much profit you would have made.">
                                        Total Profit
                                    </ToolTip>
                                </th>

                                <th>
                                    <ToolTip title="The cash that you would have throughout the trading sequence">
                                        Money Balance
                                    </ToolTip>
                                </th>

                                <th>
                                    <ToolTip title="The total value of your portfolio including the current price of the stock if it is still owned.">
                                        Total Balance (stock + money)
                                    </ToolTip>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {stock.map((stock, index) => (
                                <tr key={index}>
                                    {stock.signal === "buy" ?
                                        <>
                                            <td>{stock.signal.toUpperCase()}</td>
                                            <td>{parseFloat(stock.stock_price).toFixed(2)}</td>
                                            <td>{parseFloat(stock.highest_value).toFixed(2)}</td>
                                            <td>{parseFloat(stock.lowest_value).toFixed(2)}</td>
                                            <td>-</td>
                                            <td>{parseFloat(stock.total_profit).toFixed(2)}</td>
                                            <td>{parseFloat(stock.current_amount_with_shares).toFixed(2)}</td>
                                            <td>{parseFloat(stock.total_balance).toFixed(2)}</td>
                                        </>
                                        :
                                        <>
                                            <td>{stock.signal.toUpperCase()}</td>
                                            <td>{parseFloat(stock.stock_price).toFixed(2)}</td>
                                            <td>{parseFloat(stock.highest_value).toFixed(2)}</td>
                                            <td>{parseFloat(stock.lowest_value).toFixed(2)}</td>
                                            <td>{parseFloat(stock.trade_profit).toFixed(2)}</td>
                                            <td>{parseFloat(stock.total_profit).toFixed(2)}</td>
                                            <td>{parseFloat(stock.current_amount).toFixed(2)}</td>
                                            <td>{parseFloat(stock.total_balance).toFixed(2)}</td>
                                        </>

                                    }

                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    ) : null;
}


export default MomentumTraderStockData;