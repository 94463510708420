import React from 'react';

const Table = ({ children, className = '', header, ...props }) => (
    <div className={`${className} table-wrapper`} {...props}>
        {header}
        <div>
            <table>
                {children}
            </table>
        </div>
    </div>
)

export default Table;