import React from 'react';
import Circle from '../../ui-v2/Circle/Circle';
import ToolTip from '../../ui-v2/ToolTip/ToolTip';
import { useApp } from '../../App';
import { Link } from 'react-router-dom';

const SignalButtons = ({ createSignal, manageSignal, signals_data, stepNo, name }) => {
    const isLoggedIn = useApp().isLoggedIn;
    const buttonProps = {
        children: (
            <>
                Create Signal
                {stepNo === 3 && <Circle className='ms-1 start-0' to='' />}
            </>
        ),
        id: 'tip-3',
        className: 'btn fs-normal position-relative btn-primary'
    }
    return (
        <>
            <ToolTip title={`Want to receive an email that will filter out ${name} trading based off of parameters you enter? Create a signal.`}>
                {isLoggedIn ? <button onClick={createSignal} {...buttonProps} /> : <Link to='#register' {...buttonProps} />}
            </ToolTip>
            {isLoggedIn && signals_data.length > 0 &&
                <ToolTip title="Manage your existing email notifications for this app.">
                    <button
                        className='btn btn-text fs-normal'
                        onClick={manageSignal}
                    >
                        Manage Signals
                    </button>
                </ToolTip>
            }
        </>
    )
}

export default SignalButtons;