import React, { useEffect, useRef } from 'react';
import Slider from '@material-ui/core/Slider';
import styles from './PriceRange.module.scss';
import InputField from '../../../ui-v2/InputField/InputField';
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment';

const PriceRange = ({ min = 0, max = 1000, onChange, name, values, showDollar = false, show_slider = true, ...props }) => {
    const [value, setValue] = React.useState([min, max]);

    const ref = useRef();

    const handleChange = (e, newValue) => {
        const { target, ...event } = e;
        event.target = ref.current;
        setValue(newValue);
        onChange && onChange(event, newValue);
    }

    const onMinChange = e => {
        const minValue = e.target.value;
        const newValue = [minValue, value[1]];
        if ((minValue >= min && minValue < max) || minValue === '-') {
            if (minValue >= newValue[1]) newValue[1] = minValue + 1;
            handleChange(e, newValue);
        }
    }
    const onMaxChange = e => {
        const maxValue = e.target.value;
        const newValue = [value[0], maxValue];
        if (maxValue > min && maxValue <= max) {
            if (maxValue <= newValue[0]) newValue[0] = maxValue - 1;
            handleChange(e, newValue);
        }
    }
    useEffect(() => {
        if (values?.length === 2 && (values[0] !== value[0] || values[1] !== value[1])) setValue(values);
    }, [values])
    return (
        <>
            {show_slider &&
                <div className='px-2'>
                    <Slider
                        value={value}
                        onChange={handleChange}
                        className={`text-primary ${styles.root}`}
                        {...props}
                        min={min}
                        max={max}
                        name={name}
                    />
                </div>
            }
            <input type="hidden" value={`${value}`} ref={ref} name={name} />
            <div className={`d-flex gap-3 justify-content-between align-items-center ${styles.inputWrapper}`}>
                <InputField
                    id="inputToTriggerByJS"
                    startAdornment={showDollar && <InputAdornment position="start">$</InputAdornment>}
                    className='bg-surface-1' label='Min' value={value[0]}
                    onChange={onMinChange}
                    min={min}
                    max={value[1]}
                />
                <span className='text-accent-4'>to</span>
                <InputField
                    startAdornment={showDollar && <InputAdornment position="start">$</InputAdornment>}
                    className='bg-surface-1'
                    label='Max'
                    value={value[1]}
                    onChange={onMaxChange}
                    min={value[0]}
                    max={max}
                />
            </div>
        </>
    );
}


export default PriceRange;
