import React from 'react';
import moment from 'moment'
import Card from "./Card";
import getNumber from '../../../../utility/getNumber';
import LI from './LI';

const Dividends = props => (
    <Card title='Dividends'>
        <ul>
            <LI title='Dividends Rate' tooltip={`the amount of money issued yearly in the form of a dividend. A dividend is just the amount of money distributed from a company to its shareholders.`} value={props.dividendrate} />
            <LI title='Dividend Yield' tooltip={`expressed as a percentage, is a financial ratio that shows how much a company pays out in dividends each year relative to its stock price.`} value={getNumber(props.dividendyield, 3)} end='%' />
            <LI title='Dividend growth over 3 years' tooltip={`The average dividend yield increase as a percent over the last N years. Dividend growth calculates the annualized average rate of increase in the dividends paid by a company. Calculating the dividend growth rate is necessary for using a dividend discount model for valuing stocks.`} value={props.dividend3years} />
        </ul>
        <ul>
            <LI title='Dividend growth over 5 years' tooltip={`The average dividend yield increase as a percent over the last N years. Dividend growth calculates the annualized average rate of increase in the dividends paid by a company. Calculating the dividend growth rate is necessary for using a dividend discount model for valuing stocks.`} value={props.dividend5years} />
            <LI title='Payment Type' tooltip='How dividend is paid out.' value={props.paymenttype} />
            <LI title='Ex Dividend Date' tooltip={`is the trading date on (and after) which the dividend is not owed to a new buyer of the stock. To be eligible for the dividend, you must buy the stock at least two business days before the date of record and own it by the close one business day before the ex-date.`} value={props.exdividenddate ? moment(props.exdividenddate).format('LL') : ''} />
        </ul>
    </Card>
)

export default Dividends;
