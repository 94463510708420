import React from "react";
import axios from "axios";
import URL from "url-parse";
import moment from "moment";
import MLModelFilters from "./MLModelFilters";
import withAlgoProductToolsLayout from "../../hoc-v2/withAlgoProductToolsLayout/withAlgoProductToolsLayout";
import MLTable from './MLTable';

class Options extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: new URL(window.location.href, true),
      stock_symbol: "goog",
      errors: {},
      new_model: true,
      load_time: moment(Date.now()).format(),
      running: false,
      basic_mode: true,
      type: "daily",
      trading_type:"gradual",
      // type:"daily",
      existing_models: [],
      selected_model: "",
      model_name: "",
      number_of_weights_produced: 5,
      // learning_rate: ["0.03", "0.04", "0.05", "0.06", "0.07", "0.08", "0.09", "0.1"],
      learning_rate: ["0.03"],
      iterations: 10,
      date_list: [],
      initialized: false,
      broker_fee: 0.5,
      starting_amt: 10000,

      training_start_date: "2020-04-25",
      training_end_date: "2020-06-26",
      training_start_time: new Date(moment().set({ hour: 9, minute: 30 })),
      training_end_time: new Date(moment().set({ hour: 16, minute: 0 })),
      training_dates: [],
      training_date_err_msg: false,

      test_start_date: "2020-08-11",
      test_end_date: "2020-09-12",
      test_start_time: new Date(moment().set({ hour: 9, minute: 30 })),
      test_end_time: new Date(moment().set({ hour: 16, minute: 0 })),
      test_dates: [],
      test_date_err_msg: false,

      sorted_results: [],
      sort_by: "Newest",
      stock_list: [],

      graph_data: [],
      time_arr: [],
      graph_dates: [],
      graph_title: "",
      test_model_running: false,
      already_fetching_updates: false,
      running_ml_models: [],
    };
  }
  componentDidMount() {
    if (this.props.state) return this.setState(this.props.state);
    this.handle_fetch_ml_running();
    axios.get("/get_existing_models").then((response) => {
      this.setState({
        existing_models: response.data.result,
      });
    });
    axios.get("/stock_list").then((response) => {
      this.setState(
        {
          stock_list: response.data.arr,
        },
        () => {
          this.handleInitialize();

        }
      );
    });
    // var start_train = new Date();
    // start_train.setMonth(start_train.getMonth() - 3);
    // var end_train = new Date();
    // end_train.setMonth(end_train.getMonth() - 1);
    //
    // var start_test = new Date();
    // start_test.setMonth(start_test.getMonth() - 1);
    // this.setState({
    //   training_start_date: start_train,
    //   training_end_date: end_train,
    //   test_start_date: start_test,
    //   test_end_date: Date.now(),
    // });

  }
  handleSortResults = async () => {
    function newest(a, b) {
      return new Date(b.created_on) - new Date(a.created_on);
    }
    function best_train(a, b) {
      return b.predicted_return - a.predicted_return;
    }
    function best_test(a, b) {
      return b.total_actual_return - a.total_actual_return;
    }
    if (
      typeof this.state.selected_model === "object" &&
      this.state.selected_model.results_array.length > 0
    ) {
      if (this.state.sort_by === "Newest") {
        // eslint-disable-next-line
        var x = this.state.selected_model.results_array.sort(newest);
        this.setState({
          selected_model: { ...this.state.selected_model, results_array: x },
        });
      } else if (this.state.sort_by === "Highest Predicted Return") {
        // eslint-disable-next-line
        var x = this.state.selected_model.results_array.sort(best_train);
        this.setState({
          selected_model: { ...this.state.selected_model, results_array: x },
        });
      } else if (this.state.sort_by === "Highest Actual Return") {
        // eslint-disable-next-line
        var x = this.state.selected_model.results_array.sort(best_test);
        this.setState({
          selected_model: { ...this.state.selected_model, results_array: x },
        });
      }
    }
  };
  handle_date_type = () => {

    if (this.state.type === "daily") {
      var start_train = new Date();
      start_train.setMonth(start_train.getMonth() - 3);
      var end_train = new Date();
      end_train.setMonth(end_train.getMonth() - 1);

      var start_test = new Date();
      start_test.setMonth(start_test.getMonth() - 1);
      this.setState({
        training_start_date: start_train,
        training_end_date: end_train,
        test_start_date: start_test,
        test_end_date: Date.now(),
      });
    } else {
      axios
        .post("/fetch_dates_for_data", {
          stock_symbol: this.state.stock_symbol,
        })
        .then((response) => {
          let data_length = response.data.result.length;
          this.setState({
            training_start_date: moment(
              response.data.result[data_length - 4]
            ).format("YYYY-MM-DD"),
            training_end_date: moment(
              response.data.result[data_length - 3]
            ).format("YYYY-MM-DD"),
            test_start_date: moment(
              response.data.result[data_length - 2]
            ).format("YYYY-MM-DD"),
            test_end_date: moment(
              response.data.result[data_length - 1]
            ).format("YYYY-MM-DD"),
          });
        });
    }
  }
  handleModelChange = (e) => {
    this.setState(
      {
        ...e.target.value,
        selected_model: e.target.value,
      },
      () => {
        this.handleSortResults();
        if (this.state.type === "daily") {
          var start_test = new Date();
          start_test.setMonth(start_test.getMonth() - 1);
          this.setState({
            test_start_date: start_test,
            test_end_date: Date.now(),
          });
        } else {
          axios
            .post("/fetch_dates_for_data", {
              stock_symbol: this.state.stock_symbol,
            })
            .then((response) => {
              let data_length = response.data.result.length;
              this.setState({
                training_start_date: moment(
                  this.state.selected_model.training_arr[0]
                ).format("YYYY-MM-DD"),
                training_end_date: moment(
                  this.state.selected_model.training_arr[
                  this.state.selected_model.training_arr.length - 1
                  ]
                ).format("YYYY-MM-DD"),
                test_start_date: moment(
                  response.data.result[data_length - 2]
                ).format("YYYY-MM-DD"),
                test_end_date: moment(
                  response.data.result[data_length - 1]
                ).format("YYYY-MM-DD"),
              });
            });
        }
        // this.handleInitialize()
      }
    );
  };
  handleInitialize = () => {
    axios
      .post("/fetch_dates_for_data", {
        stock_symbol: this.state.stock_symbol,
      })
      .then((response) => {
        if (response.data.status === "success") {
          let data_length = response.data.result.length;
          this.setState(
            {
              date_list: response.data.result,
              training_start_date: moment(
                response.data.result[data_length - 4]
              ).format("YYYY-MM-DD"),
              training_end_date: moment(
                response.data.result[data_length - 3]
              ).format("YYYY-MM-DD"),
              test_start_date: moment(
                response.data.result[data_length - 2]
              ).format("YYYY-MM-DD"),
              test_end_date: moment(
                response.data.result[data_length - 1]
              ).format("YYYY-MM-DD"),
              err_msg: "",
              initialized: true,
            },
            () => {
              let start_date = new Date(this.state.training_start_date);
              let end_date = new Date(this.state.training_end_date);
              end_date.setHours(end_date.getHours() + 24);
              this.setState({
                training_dates: this.state.date_list.filter((date) =>
                  moment(date).isBetween(start_date, end_date)
                ),
              });
              let test_start_date = new Date(this.state.test_start_date);
              let test_end_date = new Date(this.state.test_end_date);
              test_end_date.setHours(test_end_date.getHours() + 24);
              this.setState({
                test_dates: this.state.date_list.filter((date) =>
                  moment(date).isBetween(test_start_date, test_end_date)
                ),
              });
            }
          );
        } else {
          this.setState({
            err_msg: "Data for this stock does not exist.",
          });
        }
        this.handle_date_type()
      });
  };
  handleLearningRateChange = (e) => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value.split(","),
    });
  };

  handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState(
      {
        [name]: value,
      },
      () => {
        if (name === "sort_by") {
          this.handleSortResults();
        }
        if (name === "type" && value === "intra_day") {
          this.handleInitialize();
        }
        if (name === "type" && value === "daily") {
          var start_train = new Date();
          start_train.setMonth(start_train.getMonth() - 3);
          var end_train = new Date();
          end_train.setMonth(end_train.getMonth() - 1);

          var start_test = new Date();
          start_test.setMonth(start_test.getMonth() - 1);
          this.setState({
            training_start_date: start_train,
            training_end_date: end_train,
            test_start_date: start_test,
            test_end_date: Date.now(),
          });
        }
      }
    );
  };
  handleTrainDateChange = (e) => {
    e.preventDefault();
    let name = e.target.name;
    let value = e.target.value;
    if (
      name === "training_end_date" &&
      new Date(this.state.training_start_date) <= new Date(value)
    ) {
      this.setState(
        {
          [name]: e.target.value,
          training_date_err_msg: false,
        },
        () => {
          let start_date = new Date(this.state.training_start_date);
          let end_date = new Date(this.state.training_end_date);
          end_date.setHours(end_date.getHours() + 24);
          this.setState({
            training_dates: this.state.date_list.filter((date) =>
              moment(date).isBetween(start_date, end_date)
            ),
          });
        }
      );
    } else {
      this.setState({
        training_date_err_msg: true,
      });
    }

    if (
      name === "training_start_date" &&
      new Date(value) <= new Date(this.state.training_end_date)
    ) {
      this.setState(
        {
          [name]: e.target.value,
          training_date_err_msg: false,
        },
        () => {
          let start_date = new Date(this.state.training_start_date);
          let end_date = new Date(this.state.training_end_date);
          end_date.setHours(end_date.getHours() + 24);
          this.setState({
            training_dates: this.state.date_list.filter((date) =>
              moment(date).isBetween(start_date, end_date)
            ),
          });
        }
      );
    } else if (
      name === "training_start_date" &&
      new Date(value) > new Date(this.state.training_end_date)
    ) {
      this.setState({
        training_date_err_msg: true,
      });
    }
  };

  handleTestDateChange = (e) => {
    e.preventDefault();
    let name = e.target.name;
    let value = e.target.value;
    if (
      name === "test_end_date" &&
      new Date(this.state.test_start_date) <= new Date(value)
    ) {
      this.setState(
        {
          [name]: e.target.value,
          test_date_err_msg: false,
        },
        () => {
          let start_date = new Date(this.state.test_start_date);
          let end_date = new Date(this.state.test_end_date);
          end_date.setHours(end_date.getHours() + 24);
          this.setState({
            test_dates: this.state.date_list.filter((date) =>
              moment(date).isBetween(start_date, end_date)
            ),
          });
        }
      );
    } else {
      this.setState({
        test_date_err_msg: true,
      });
    }

    if (
      name === "test_start_date" &&
      new Date(value) <= new Date(this.state.test_end_date)
    ) {
      this.setState(
        {
          [name]: e.target.value,
          test_date_err_msg: false,
        },
        () => {
          let start_date = new Date(this.state.test_start_date);
          let end_date = new Date(this.state.test_end_date);
          end_date.setHours(end_date.getHours() + 24);
          this.setState({
            test_dates: this.state.date_list.filter((date) =>
              moment(date).isBetween(start_date, end_date)
            ),
          });
        }
      );
    } else if (
      name === "test_start_date" &&
      new Date(value) > new Date(this.state.test_end_date)
    ) {
      this.setState({
        test_date_err_msg: true,
      });
    }
  };

  handleTrainStartChange = (date) => {
    this.setState({
      training_start_date: moment(date).format("YYYY-MM-DD"),
    });
  };
  handleTrainEndChange = (date) => {
    this.setState({
      training_end_date: moment(date).format("YYYY-MM-DD"),
    });
  };

  handleTestStartChange = (date) => {
    this.setState({
      test_start_date: moment(date).format("YYYY-MM-DD"),
    });
  };
  handleTestEndChange = (date) => {
    this.setState({
      test_end_date: moment(date).format("YYYY-MM-DD"),
    });
  };

  handleStartTrainTimeChange = (date) => {
    // let temp_date = moment(date).format('H:mm');
    this.setState({
      training_start_time: date,
    });
  };
  handleEndTrainTimeChange = (date) => {
    // let temp_date = moment(date).format('H:mm');
    this.setState({
      training_end_time: date,
    });
  };
  handleStartTestTimeChange = (date) => {
    // let temp_date = moment(date).format('H:mm');
    this.setState({
      test_start_time: date,
    });
  };
  handleEndTestTimeChange = (date) => {
    // let temp_date = moment(date).format('H:mm');
    this.setState({
      test_end_time: date,
    });
  };

  handleCheck = (e) => {
    this.setState({
      [e.target.name]: e.target.checked,
    });
  };

  handleValidation = async (event) => {
    let errors = {};
    let formIsValid = true;

    //Opportunity Name
    if (this.state.model_name.length === 0) {
      formIsValid = false;
      errors["model_name"] = "Please enter a model name";
    }
    if (this.state.stock_symbol.length === 0) {
      formIsValid = false;
      errors["stock_symbol"] = "Please enter a stock symbol";
    }
    if (
      this.state.existing_models.some(
        (x) => x.model_name === this.state.model_name
      )
    ) {
      formIsValid = false;
      errors["model_name"] =
        "You already have a model with this name. Please give your model a unique name.";
    }
    this.setState(
      {
        errors: errors,
      },
      () => {
        if (formIsValid) {
          this.createAndRun(event);
        }
      }
    );
  };

  handleStartCheck = (e) => {


    this.setState(
      {
        new_model: !this.state.new_model,
      },
      () => {
        if (this.state.new_model) {
          this.setState({
            type: "daily",
            model_name: "",
            selected_model: "",
            initialized: false,
          }, () => {
            this.handle_date_type()
          });
          // this.handleInitialize()

        } else {
          this.setState({
            selected_model: this.state.existing_models[0],
            ...this.state.existing_models[0],
          });
        }
      }
    );
  };
  handleBasicCheck = (e) => {
    this.setState({
      basic_mode: !this.state.basic_mode,
    });
  };

  createAndRun = (e) => {
    e.preventDefault();
    this.setState(
      {
        running: true,
      },
      () => {
        if (this.state.type === "daily") {
          axios
            .post("/create_and_run_ml_model_daily", {
              trading_type:this.state.trading_type,
              stock_symbol: this.state.stock_symbol,
              type: this.state.type,

              model_name: this.state.model_name,

              training_start_date: this.state.training_start_date,
              training_end_date: moment(this.state.training_end_date).format(
                "YYYY-MM-DD"
              ),

              test_start_date: this.state.test_start_date,
              test_end_date: moment(this.state.test_end_date).format(
                "YYYY-MM-DD"
              ),
              learning_rate: this.state.learning_rate.map(Number),
              number_of_weights_produced: this.state.number_of_weights_produced,
              iterations: this.state.iterations,
              broker_fee: this.state.broker_fee,
              starting_amt: this.state.starting_amt,
            })
            .then((response) => {
              if (response.data.status === "success") {
                axios.get("/get_existing_models").then(async (response_1) => {
                  this.setState(
                    {
                      existing_models:
                        (await response_1.data.result.length) > 0
                          ? response_1.data
                          : [],
                      running: false,
                      new_model: false,
                    },
                    () => {
                      if (this.state.existing_models.length > 0) {
                        var start_test = new Date();
                        start_test.setMonth(start_test.getMonth() - 1);

                        this.setState({
                          test_start_date: start_test,
                          test_end_date: Date.now(),
                          selected_model: this.state.existing_models.filter(
                            (x) => x._id === response.data.model_id
                          )[0],
                          ...this.state.existing_models.filter(
                            (x) => x._id === response.data.model_id
                          )[0],
                        });
                      }
                    }
                  );
                });
              }
            });
        } else {
          axios
            .post("/create_and_run_ml_model_intra", {
              trading_type:this.state.trading_type,
              stock_symbol: this.state.stock_symbol,
              type: this.state.type,

              model_name: this.state.model_name,

              training_dates: this.state.training_dates,
              training_start_time: this.state.training_start_time,
              training_end_time: this.state.training_end_time,

              test_dates: this.state.test_dates,
              test_start_time: this.state.test_start_time,
              test_end_time: this.state.test_end_time,

              learning_rate: this.state.learning_rate.map(Number),
              number_of_weights_produced: this.state.number_of_weights_produced,
              iterations: this.state.iterations,
              broker_fee: this.state.broker_fee,
              starting_amt: this.state.starting_amt,
            })
            .then((response) => {
              if (response.data.status === "success") {
                axios.get("/get_existing_models").then(async (response_1) => {
                  this.setState(
                    {
                      existing_models:
                        (await response_1.data.result.length) > 0
                          ? response_1.data
                          : [],
                      running: false,
                      new_model: false,
                    }, () => {
                      this.setState({
                        selected_model: this.state.existing_models.filter(
                          (x) => x._id === response.data.model_id
                        )[0],
                        ...this.state.existing_models.filter(
                          (x) => x._id === response.data.model_id
                        )[0],
                      })
                    })

                })
              }
            });
        }
      }
    );
    if (!this.state.already_fetching_updates) {
      this.setState({
        already_fetching_updates: true,
      });
      this.start_fetching_updates(this.state.model_name);
    }
  };
  start_fetching_updates = (model_name) => {
    setTimeout(
      function () {
        //Start the timer
        axios.get("/get_existing_models").then((response) => {
          if (response.data.status === "success" && response.data.result.length > 0) {

            this.setState(
              {
                existing_models: response.data.result,
              },
              () => {
                this.setState(
                  {
                    ...this.state.existing_models.filter(
                      (x) => x.model_name === model_name
                    ),
                    selected_model: this.state.existing_models.filter(
                      (x) => x.model_name === model_name
                    )[0],
                  },
                  () => {
                    this.handleSortResults();
                    if (this.state.selected_model?.results_array === undefined || this.state.selected_model?.results_array.length < (this.state.learning_rate.length * this.state.number_of_weights_produced)) {
                      this.start_fetching_updates(model_name);
                    }


                  }
                );
              }
            );
          } else {
            if (this.state.selected_model.results_array.length < (this.state.learning_rate.length * this.state.number_of_weights_produced)) {
              this.start_fetching_updates(model_name);
            }
          }
        });
      }.bind(this),
      10000
    );
  };
  fetching_updates = (model_name) => {
    //Start the timer
    axios.get("/get_existing_models").then((response) => {
      if (response.data.status === "success") {
        this.setState(
          {
            existing_models: response.data.result,
          },
          () => {
            this.setState(
              {
                ...this.state.existing_models.filter(
                  (x) => x.model_name === model_name
                ),
                selected_model: this.state.existing_models.filter(
                  (x) => x.model_name === model_name
                )[0],
              },
              () => {
                this.handleSortResults();
              }
            );
          }
        );
      }
    });
  };

  testModel = (e) => {
    this.setState({
      test_model_running: true,
    });
    if (this.state.selected_model.type === "daily") {
      axios
        .post("/test_ml_model_daily", {
          stock_symbol: this.state.selected_model.stock_symbol,
          model_name: this.state.selected_model.model_name,
          model_id: this.state.selected_model._id,
          test_start_date: moment(this.state.test_start_date).format(
            "YYYY-MM-DD"
          ),
          test_end_date: moment(this.state.test_end_date).format("YYYY-MM-DD"),
          broker_fee: this.state.broker_fee,
          starting_amt: this.state.starting_amt,
        })
        .then((response) => {
          this.fetching_updates(this.state.selected_model.model_name);

          this.setState({
            test_model_running: false,
          });
        });
    } else {
      e.preventDefault();
      let start_date = new Date(this.state.test_start_date);
      let end_date = new Date(this.state.test_end_date);
      end_date.setHours(end_date.getHours() + 24);

      let dates = this.state.date_list.filter((date) =>
        moment(date).isBetween(start_date, end_date)
      );

      axios
        .post("/test_ml_model_intra", {
          stock_symbol: this.state.selected_model.stock_symbol,
          model_name: this.state.selected_model.model_name,
          model_id: this.state.selected_model._id,
          test_start_date: moment(this.state.test_start_date).format(
            "YYYY-MM-DD"
          ),
          test_end_date: moment(this.state.test_end_date).format("YYYY-MM-DD"),
          dates: dates,
          broker_fee: this.state.broker_fee,
          starting_amt: this.state.starting_amt,
        })
        .then((response) => {
          this.fetching_updates(this.state.selected_model.model_name);
          this.setState({
            test_model_running: false,
          });
        });
    }
  };
  handle_fetch_ml_running = () => {
    axios.get("/handle_ml_model_running_user_fetch").then((response) => {
      if (response.data.status === "success") {
        this.setState({
          running_ml_models: response.data.results,
        });
      }
    });
  };
  handle_delete_ml_signal = async (e, signal_id) => {
    e.preventDefault();
    axios
      .post("/handle_delete_ml_signal", {
        signal_id: signal_id
      })
      .then((response) => {
        this.handle_fetch_ml_running();
      });
  };
  handle_auto_ml_run_stop = async (e, action, index, type, predicted_return, model_id) => {
    e.preventDefault();
    axios
      .post("/handle_ml_model_running", {
        model_id: this.state.selected_model._id,
        index: index,
        type: type,
        action: action,
        predicted_return: predicted_return
      })
      .then((response) => {
        this.handle_fetch_ml_running();
      });
  };

  render() {
    // var number_of_days =
    //   moment(this.state.training_end_date).diff(
    //     moment(this.state.training_start_date),
    //     "days"
    //   ) + 1;
    // var number_of_test_days = moment(this.state.test_end_date).diff(moment(this.state.test_start_date), 'days') + 1
    var intra_number_of_days = this.state.training_dates.length;
    // var intra_number_of_test_days = this.state.test_dates.length
    // var est_time_daily = this.state.type==="daily"? (1.13+(1*(number_of_days*1.2)*(number_of_test_days*1.51)*((this.state.iterations-1)*3)*((this.state.learning_rate.length-1)*1.33)*this.state.number_of_weights_produced)/60).toFixed(1) :""
    // var est_time_daily =
    //   this.state.type === "daily"
    //     ? (
    //         (this.state.learning_rate.length *
    //           this.state.number_of_weights_produced *
    //           number_of_days) /
    //         90
    //       ).toFixed(1)
    //     : "";
    var est_time_intra_daily =
      this.state.type === "intra_day"
        ? (
          ((8 + 2 * intra_number_of_days) *
            this.state.iterations *
            this.state.learning_rate.length *
            this.state.number_of_weights_produced) /
          60
        ).toFixed(1)
        : "";
    let expected_number_of_results =
      this.state.learning_rate.length * this.state.number_of_weights_produced;
    let number_of_results =
      typeof this.state.selected_model === "object" &&
        this.state.selected_model.results_array.length > 0
        ? this.state.selected_model.results_array.length
        : 0;

    this.props.getState && this.props.getState(this.state);
    const isDataFetched = typeof this.state.selected_model === "object" && this.state.selected_model.results_array.length > 0;
    this.props.setResponsive(isDataFetched);
    return (
      <>
        <MLModelFilters
          {...this.state}
          est_time_intra_daily={est_time_intra_daily}
          expected_number_of_results={expected_number_of_results}
          number_of_results={number_of_results}
          handleStartCheck={this.handleStartCheck}
          handleChange={this.handleChange}
          handleBasicCheck={this.handleBasicCheck}
          handleModelChange={this.handleModelChange}
          handleLearningRateChange={this.handleLearningRateChange}
          handleTrainDateChange={this.handleTrainDateChange}
          handleStartTrainTimeChange={this.handleStartTrainTimeChange}
          handleEndTrainTimeChange={this.handleEndTrainTimeChange}
          handleTestDateChange={this.handleTestDateChange}
          handleValidation={this.handleValidation}
          testModel={this.testModel}
          handleTrainStartChange={this.handleTrainStartChange}
          handleTrainEndChange={this.handleTrainEndChange}
          handleTestStartChange={this.handleTestStartChange}
          handleTestEndChange={this.handleTestEndChange}
          handle_auto_ml_run_stop={this.handle_auto_ml_run_stop}
          handle_delete_ml_signal={this.handle_delete_ml_signal}
        />
        <div className='p-lg-5 p-3 d-flex flex-column flex-1'>
          <p className="mt-0">
            Harness the power of a neural net. Feed it previous data so it
            learns and then test to see how well it learned.
          </p>
          {isDataFetched && (
            <MLTable {...this.state}
              fetch_graph={this.fetch_graph}
              handleChange={this.handleChange}
            />
          )}
        </div>
      </>
    );
  }
}

export default withAlgoProductToolsLayout(Options, "Neural Net Algorithm");
