import React, { useState } from 'react';
import ChevronBottom from '../../icons-v2/ChevronBottom';
import styles from './StockItem.module.scss';

const StockItem = props => {
    const [isExpanded, setIsExpanded] = useState(false);
    return (
        <>
            <tr onClick={() => setIsExpanded(!isExpanded)} className={`${styles.root} ${isExpanded ? styles.active : ''}`}>
                <td><ChevronBottom className={`text-accent-4 ${isExpanded ? 'rotate-180' : ''}`} /></td>
                <td>PMVP</td>
                <td>4</td>
                <td>Participants</td>
                <td>2021-07-29  |  2021-08-02</td>
                <td><button className='badge text-accent-3'>Buy</button></td>
                <td>35.53 - 45.16</td>
                <td><div>1,000 - 31,937</div></td>
            </tr>
            {isExpanded && (
                <tr className={`border-bottom ${styles.expandedRow}`}>
                    <td colSpan={8} className='p-0'>
                        <table className='primary-table'>
                            <thead className="no-opacity">
                                <tr>
                                    <th></th>
                                    <th><div>Number</div></th>
                                    <th>Owner</th>
                                    <th>Date</th>
                                    <th>Transaction</th>
                                    <th>Share Price ($)</th>
                                    <th>#Shares</th>
                                    <th>Value ($)</th>
                                    <th>#Shares Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><ChevronBottom className='opacity-0' /></td>
                                    <td>1</td>
                                    <td>Hillstrand Kris W</td>
                                    <td>2021-07-29</td>
                                    <td><div className='d-flex gap-2'><button className='badge'>Option Exercise</button></div></td>
                                    <td>0.00</td>
                                    <td>18,055</td>
                                    <td>0</td>
                                    <td>55,454</td>
                                </tr>
                                <tr>
                                    <td><ChevronBottom className='opacity-0' /></td>
                                    <td>2</td>
                                    <td>Hillstrand Kris W</td>
                                    <td>2021-07-29</td>
                                    <td>
                                        <div className='d-flex gap-2'>
                                            <button className='badge text-accent-2'>Option Exercise</button>
                                            <button className='badge text-accent-3'>Buy</button>
                                        </div>
                                    </td>
                                    <td>0.00</td>
                                    <td>18,055</td>
                                    <td>0</td>
                                    <td>55,454</td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            )}
        </>
    )
}

export default StockItem;