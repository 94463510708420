import React from 'react';

const PlayIcon = props => (
    <svg viewBox="0 0 18 18" fill="none" {...props}>
        <g filter="url(#filter0_b_4535_25323)">
            <path d="M18 9C18 11.3869 17.0518 13.6761 15.364 15.364C13.6761 17.0518 11.3869 18 9 18C6.61305 18 4.32387 17.0518 2.63604 15.364C0.948212 13.6761 0 11.3869 0 9C0 6.61305 0.948212 4.32387 2.63604 2.63604C4.32387 0.948212 6.61305 0 9 0C11.3869 0 13.6761 0.948212 15.364 2.63604C17.0518 4.32387 18 6.61305 18 9ZM7.63875 5.72963C7.55464 5.66974 7.45567 5.63416 7.35269 5.62678C7.2497 5.61941 7.14667 5.64052 7.05488 5.68781C6.9631 5.73509 6.8861 5.80673 6.83232 5.89487C6.77854 5.98301 6.75006 6.08425 6.75 6.1875V11.8125C6.75006 11.9158 6.77854 12.017 6.83232 12.1051C6.8861 12.1933 6.9631 12.2649 7.05488 12.3122C7.14667 12.3595 7.2497 12.3806 7.35269 12.3732C7.45567 12.3658 7.55464 12.3303 7.63875 12.2704L11.5763 9.45787C11.6492 9.40584 11.7086 9.33715 11.7496 9.2575C11.7906 9.17786 11.812 9.08958 11.812 9C11.812 8.91042 11.7906 8.82214 11.7496 8.7425C11.7086 8.66285 11.6492 8.59416 11.5763 8.54213L7.63875 5.72963Z" fill="currentColor" />
        </g>
        <defs>
            <filter id="filter0_b_4535_25323" x="-10" y="-10" width="38" height="38" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feGaussianBlur in="BackgroundImageFix" stdDeviation="5" />
                <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_4535_25323" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_4535_25323" result="shape" />
            </filter>
        </defs>
    </svg>


)


export default PlayIcon;