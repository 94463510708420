import React, { useState } from "react";
import InputField from "./InputFieldV2";
import Eye from "../icons-v2/Eye";

const Password = props => {
    const [type, setType] = useState('password');
    return (
        <InputField
            {...props}
            type={type}
            minLength={8}
            required={true}
            end={(
                <button
                    className='btn btn-icon no-padding'
                    onClick={() => setType(type === 'password' ? 'text' : 'password')}
                    type='button'
                >
                    <Eye className={type === "password" ? "" : "bg-accent-6"} />
                </button>
            )}
        />
    )
}

export default Password;