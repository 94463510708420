import React from 'react';
import styles from './LineChart.module.scss';

import {
    LineChart as Chart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label
} from 'recharts';

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip">
                <p className="label">{`Date: ${label}`}</p>
                <p className="intro">{`Value: $${payload[0].value}`}</p>
            </div>
        );
    }

    return null;
};

const LineChart = ({ children, className, legend = true, data, labels = [], dataKeys = [], ...props }) => {
    return (
        <div className={`${className} ${labels[1] ? "yAxis" : ''} ${styles.root}`}>
            <div className={styles.main}>
                <ResponsiveContainer>
                    <Chart data={data} {...props}>
                        <CartesianGrid strokeDasharray="3 3" />
                        {labels[1] && <YAxis><Label value={labels[1]} angle={90} /></YAxis>}
                        {children}
                        <Tooltip />
                        <XAxis dataKey="date" />
                        <Legend />
                        {dataKeys.map((dataKey, index) =>
                            <Line {...dataKey} key={index} />
                        )}
                    </Chart>
                </ResponsiveContainer>
                {labels[0] && <p className={styles.xAxisLabel}>{labels[0]}</p>}
            </div>
        </div>
    )
}

export default LineChart;
