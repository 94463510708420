import React from 'react';
import Thundar from '../../../icons-v2/Thundar';
import Select, { Option } from '../../../ui-v2/Select/Select';
import OpenIn from '../CreateWatchlist/OpenIn';
import EditWatchlist from '../EditWatchlist/EditWatchlist';
import StockList from '../StockList/StockList';

const Main = props => {
    const { name, watchlist_type, stocks } = props.selectedItem;
    return (
        <>
            <div className='bg-primary-lighten-5 px-3 py-2'>
                <small className='smaller text-accent-4 pt-1'>Select watchlist</small>
                <Select dark
                    value={name}
                    className='justify-content-between text-normal subtitle w-100 fw-semibold pt-1 mb-2'
                    label={
                        <span className='d-flex text-wrap text-end align-items-center ns-gap'>
                            {name}
                            {watchlist_type === "dynamic" && <Thundar className='small' />}
                        </span>
                    }
                >
                    {props.watchlist.map(item => (
                        <Option className='gap-3' value={item.name} key={item._id}
                            onClick={() => props.setState({ selectedItem: item })}
                        >
                            {item.name}
                            {item.watchlist_type === "dynamic" && <Thundar className='small svg me-auto' />}
                        </Option>
                    ))}
                </Select>
                <div className='d-flex gap-2 pb-1'>
                    <EditWatchlist {...props} />
                    <OpenIn stocks={stocks} algos={props.algos} />
                </div>
            </div>
            <StockList {...props} />
        </>
    )
}

export default Main;