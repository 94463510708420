import React from 'react';
import FollowUsers from './FollowUsers';
import Explore from './Explore';


const MyFeed = props => {
    const [active, setActive] = React.useState(props.stats.followings > 4);
    return active ? <Explore is_following={true} api='/fetch_global_feeds?isFollow=true' {...props} /> : (
        <FollowUsers {...props} unlockFeed={() => setActive(true)} />
    )
}


export default MyFeed;
