import React from 'react';
import axios from 'axios'
import URL from 'url-parse';
import { AppContext } from '../../App'
import withAlgoProductToolsLayout from '../../hoc-v2/withAlgoProductToolsLayout/withAlgoProductToolsLayout';

import LineChart2 from '../../ui-v2/LineChart/LineChart';
import FilterButton from '../../components-v2/AlgoHoldingCommonTools/FilterButton';
import GoogleMapsComponent from './map';
import Select from '../../ui-v2/Select/Select';
import { MenuItem } from '@material-ui/core';
import SelectField from '../../ui-v2/SelectField/SelectField';
import SideFilter from '../../components-v2/SideFilter/SideFilter';
import InputField from '../../ui-v2/InputField/InputField';
const { filters, second_filters } = require('./filters')

class RealEstate_Container extends React.Component {
  static contextType = AppContext;
  render() {
    return (
      <RealEstate
        {...this.props}
        {...this.context} />
    )
  }
}


class RealEstate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: new URL(window.location.href, true),
      avgPrice: 0,
      avgLandSize: 0,
      avgHouseSize: 0,
      avgLandCostPerSquareFoot: 0,
      avgHouseCostPerSquareFoot: 0,
      results: [],
      pins: [],
      filters: filters,
      location: {},
      second_filters: second_filters,
      hoveredCardIndex: null,
      loading: false


    }
    this.handleChange = this.handleChange.bind(this);
    this.fetchDataFromAPI = this.fetchDataFromAPI.bind(this);
    this.handleMapLocationChange = this.handleMapLocationChange.bind(this);
    this.filter = this.filter.bind(this);
    this.custom_sort = this.custom_sort.bind(this);
    this.handleCardHoverEnter = this.handleCardHoverEnter.bind(this);
    this.handleCardHoverLeave = this.handleCardHoverLeave.bind(this);
    this.fetch_approximate_rental_price = this.fetch_approximate_rental_price.bind(this);
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })

  }
  fetchDataFromAPI = () => {
    let location = this.state.location;
    let new_obj = Object.entries(this.state.filters).map(([key, value]) => ({ [value.key]: value.value }));
    new_obj = Object.assign({}, ...new_obj);
    const params = {
      lat: location.lat,
      lng: location.lng,
      zoomLevel: 11,
      latitudeMax: location.latitudeMax,
      longitudeMax: location.longitudeMax,
      latitudeMin: location.latitudeMin,
      longitudeMin: location.longitudeMin,
      currentPage: 1,
      recordsPerPage: 75,
      ...new_obj

    };
    this.setState({ loading: true })
    axios.get('/fetch_realtor_data', { params })
      .then(response => {

        this.setState({
          avgPrice: response.data.averageListingPrice,
          avgLandSize: response.data.averageLandSize,
          avgHouseSize: response.data.averageHouseSize,
          avgLandCostPerSquareFoot: response.data.averageLandCostPerSquareFoot,
          avgHouseCostPerSquareFoot: response.data.averageHouseCostPerSquareFoot,
          results: response.data.Results,
          pins: response.data.Pins,
          loading: false
        });
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      });
  }

  handleMapLocationChange = (location) => {
    this.setState({
      location: location
    });
    // this.fetchDataFromAPI(location);
  }

  custom_sort = async () => {
    let temp = this.state.results;
    let sort_by = this.state.second_filters['Sort By'].value;

    const safeValue = (obj, path) => {
      return path.split('.').reduce((o, p) => (o && o[p]) ? o[p] : null, obj);
    }

    switch (sort_by) {
      case 'cheapest_square_foot':
        temp.sort((a, b) => {
          let aValue = safeValue(a, 'Land.CostPerSquareFoot');
          let bValue = safeValue(b, 'Land.CostPerSquareFoot');
          if (aValue === null) return 1;
          if (bValue === null) return -1;
          return aValue - bValue;
        });
        break;
      case 'most_expensive_square_foot':
        temp.sort((a, b) => {
          let aValue = safeValue(a, 'Land.CostPerSquareFoot');
          let bValue = safeValue(b, 'Land.CostPerSquareFoot');
          if (aValue === null) return 1;
          if (bValue === null) return -1;
          return bValue - aValue;
        });
        break;
      case 'lowest_square_foot':
        temp.sort((a, b) => {
          let aValue = safeValue(a, 'Land.SizeTotal');
          let bValue = safeValue(b, 'Land.SizeTotal');
          if (aValue === null) return 1;
          if (bValue === null) return -1;
          return aValue - bValue;
        });
        break;
      case 'highest_square_foot':
        temp.sort((a, b) => {
          let aValue = safeValue(a, 'Land.SizeTotal');
          let bValue = safeValue(b, 'Land.SizeTotal');
          if (aValue === null) return 1;
          if (bValue === null) return -1;
          return bValue - aValue;
        });
        break;
      case 'cheapest_house_square_foot':
        temp.sort((a, b) => {
          let aValue = safeValue(a, 'Building.CostPerSquareFoot');
          let bValue = safeValue(b, 'Building.CostPerSquareFoot');
          if (aValue === null) return 1;
          if (bValue === null) return -1;
          return aValue - bValue;
        });
        break;
      case 'most_expensive_house_square_foot':
        temp.sort((a, b) => {
          let aValue = safeValue(a, 'Building.CostPerSquareFoot');
          let bValue = safeValue(b, 'Building.CostPerSquareFoot');
          if (aValue === null) return 1;
          if (bValue === null) return -1;
          return bValue - aValue;
        });
        break;
      case 'lowest_house_square_foot':
        temp.sort((a, b) => {
          let aValue = safeValue(a, 'Building.SizeInterior');
          let bValue = safeValue(b, 'Building.SizeInterior');
          if (aValue === null) return 1;
          if (bValue === null) return -1;
          return aValue - bValue;
        });
        break;
      case 'highest_house_square_foot':
        temp.sort((a, b) => {
          let aValue = safeValue(a, 'Building.SizeInterior');
          let bValue = safeValue(b, 'Building.SizeInterior');
          if (aValue === null) return 1;
          if (bValue === null) return -1;
          return bValue - aValue;
        });
        break;
      default:
        break;
    }

    this.setState({
      results: temp
    });
  }



  filter() {
    let data = {}
    const { filters } = this.state
    for (var key in filters) {
      const item = filters[key]
      data = {
        ...data,
        [item.key]: item.value
      }
    }
    this.fetchDataFromAPI();
  }

  changeTextInputValue(filters_arr, key, e) {
    let filters = filters_arr
    filters[key].value = e.target.value
    this.setState({
      [filters_arr]: filters
    })
  }

  changeSelectInputValue(filters_arr, key, e) {
    let filters = filters_arr
    filters[key].value = e.target.value
    this.setState({
      [filters_arr]: filters
    })
  }

  renderSelectField(filters_arr, key) {
    let object = filters_arr[key]
    const label = key,
      value = object.value,
      options = object.options
    return (
      <SelectField label={label} value={value} onChange={(e) => this.changeSelectInputValue(filters_arr, key, e)}>
        {options.map((item, index) => <MenuItem value={item.value} key={index}>{item.label}</MenuItem>)}
      </SelectField>

    )
  }

  renderTextField(filters_arr, key) {
    let object = filters_arr[key]
    const label = key,
      value = object.value,
      type = object.type,
      placeholder = object.placeholder
    return <InputField label={label} type={type} placeholder={placeholder} value={value} onChange={(e) => this.changeTextInputValue(key, e)} />
  }

  renderField(filters_arr, key) {
    if (filters_arr[key].options !== undefined) {
      return this.renderSelectField(filters_arr, key)
    }
    return this.renderTextField(filters_arr, key)
  }

  renderFilters(filters_arr) {
    let rlt = []
    const _this = this
    Object.keys(filters_arr).forEach(function (key) {
      rlt.push(
        <div key={key} className="col-12 pl-0 pr-0">
          {_this.renderField(filters_arr, key)}
        </div>
      );
    })
    return rlt
  }
  convertNumToPrice = (num) => {
    if (num){
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return 0
    }
    // return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  handleCardHoverEnter = (index) => {
    this.setState({
      hoveredCardIndex: index
    });
  }

  handleCardHoverLeave = () => {
    this.setState({
      hoveredCardIndex: null
    });
  }

  fetch_approximate_rental_price = (e, listing, id) => {
    e.preventDefault();
    axios.post('/fetch_relevant_rental_price', { listing })
      .then(response => {
        let new_listing = response.data.listing
        let new_results = this.state.results.map((item, index) => {
          if (index === id) {
            return new_listing
          }
          return item
        })
        this.setState({
          results: new_results
        })
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      });
  }


  render() {
    const { results, pins } = this.state;


    const formGroupStyle = {
      marginBottom: '10px',

    };

    const convertNumToPrice = (num) => {
      if (num){
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return 0
      }
   
    }

  





    return (
      <>
        <SideFilter footer={<button disabled={this.state.loading} className={`${this.state.loading ? 'progress-btn' : ''} btn btn-primary w-100`} onClick={this.filter}>Fetch</button>}>
          <small className='text-accent-4'>Filters</small>
          {this.renderFilters(filters)}
        </SideFilter>
        <div className='p-lg-5 p-3 overflow-md-hidden d-flex flex-column flex-1'>
          <p className='m-0 text-accent-4 d-flex justify-content-between align-items-center'>Number of results:{results.length} <FilterButton /></p>
          <p className='mt-1 mb-3 text-accent-4'>Avg Cost: ${convertNumToPrice(this.state.avgPrice?.toFixed(2))}, avg Land Size: {this.state.avgLandSize?.toFixed(2)} SqFT (${this.state.avgLandCostPerSquareFoot?.toFixed(2)}/ft2), avg House Size: {this.state.avgHouseSize?.toFixed(2)} SqFT (${this.state.avgHouseCostPerSquareFoot?.toFixed(2)}/ft2)</p>
          <div className='d-grid gap-lg-5 gap-lg-4 gap-3 flex-1'>
            <div className='flex-2 flex-container' style={{minHeight: '20rem'}}>
              <GoogleMapsComponent results={results} pins={pins} onLocationChange={this.handleMapLocationChange} hoveredCardIndex={this.state.hoveredCardIndex} />
            </div>
            <div className='flex-1 overflow-md-hidden d-flex flex-column'>
              <div className='d-flex mb-3 align-items-center gap-3'>
                <small>Search parameters</small>
              </div>
              <div className='d-flex gap-3'>
                <div className='flex-1'>
                  {this.renderFilters(second_filters)}
                </div>
                <button className="btn btn-primary" onClick={this.custom_sort}>Sort</button>
              </div>
              <div className='d-grid overflow-md-auto gap-3 mt-3'>
                {results.map((result, index) => (
                  <div className="p-3" style={{overflow:"undefined"}} key={index}>
                    <div className="card-body" onMouseEnter={() => this.handleCardHoverEnter(index)}
                      onMouseLeave={this.handleCardHoverLeave}>
                      <div className='d-flex gap-3 justify-content-between'>
                        <h5 className="card-title m-0">{result.Property.Address.AddressText}</h5>
                        <h6 className="m-0 text-accent-4">{result.Property.Price}</h6>
                      </div>
                      <p>Total Land:{result.Land.SizeTotal?.toFixed(2)} SqFT</p>
                      <p>Cost Per SqFt:${result.Land.CostPerSquareFoot?.toFixed(2)}</p>
                      <p>Total House:{result.Building.SizeInterior?.toFixed(2)} SqFT</p>
                      <p>Cost Per SqFt:${result.Building.CostPerSquareFoot?.toFixed(2)}</p>
                      {result.avg_rental_price &&
                        <>
                          <p>Low End Rental Price:${result.lowest_rental_price?.toFixed(2)}</p>
                          <p>Avg Rental Price:${result.avg_rental_price?.toFixed(2)}</p>
                          <p>High End Rental Price:${result.highest_rental_price?.toFixed(2)}</p>
                        </>
                      }
                      <div className='d-flex align-items-center gap-3'>
                        <button className='btn btn-primary' onClick={(event) => this.fetch_approximate_rental_price(event, result, index)}>Fetch Rental Price</button>
                        <a className='btn-text' href={`https://www.realtor.ca/${result.RelativeDetailsURL}`} target='_blank'>View Details</a>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </>
    );

  }
}

export default withAlgoProductToolsLayout(RealEstate_Container, 'RealEstate');
