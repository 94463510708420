import React from 'react';
import axios from 'axios'
import URL from 'url-parse';
import withAlgoProductToolsLayout from '../../hoc-v2/withAlgoProductToolsLayout/withAlgoProductToolsLayout';
import IPOSFIlters from './IPOSFIlters';
import Pagination from '../../ui-v2/Pagination/Pagination';
import moment from "moment";
import Graph from '../graph_module';
import { getBusinessDays } from '../../service/logic'
import { Link } from 'react-router-dom';
import TipCard from '../../ui-v2/TipCard/TipCard';
import StockTable from './StockTable';

class Ipos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: new URL(window.location.href, true),
      stock_data: [],
      sort_type: ["Date", "Percent", "Price", "Shares"],
      period: "All",
      period_type: [],
      selected_type: "Date",
      orig_stock_data: [],
      scraping: false,
      page: 0,
      totalLength: 10,
      stock_search: '',
      loadingTable: false,
      no_result: false,
      graph: {},
      unique_date: "",
      graph_title: "",
      graph_subtitle: "",
      show_graph: false,
      number_of_days_add: 2,
      number_of_days_sub: 2,
      stock_graph_data: [],
      selected_stock: "",
    }
    this.handlePageClick = this.handlePageClick.bind(this)
    this.getData = this.getData.bind(this)
  }

  componentDidMount() {
    if (this.props.state) return this.setState(this.props.state);
    this.getData()
  }


  getData() {
    const json = {
      page: this.state.page,
      sort: this.state.selected_type,
      period: this.state.period,
      stock_search: this.state.stock_search,
    }
    this.setState({
      loadingTable: true,
      no_result: false
    })
    axios.post('/get_ipos_data', {
      ...json
    }).then((response) => {
      // console.log(response);
      this.setState({
        totalLength: response.data.total,
        stock_data: response.data.rlt,
        period_type: response.data.dates
      })
      if (response.data.rlt.length === 0) {
        this.setState({
          no_result: true
        })
      }
    }).finally(() => {
      this.setState({
        scraping: false,
        loadingTable: false
      })
    })
  }

  handlePageClick(data) {
    this.setState({
      page: data.selected
    })
    setTimeout(() => {
      this.getData()
    }, 100)
  }
  handleChange = (e) => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleSortChange = (e) => {
    let val = e.target.value
    this.setState({
      selected_type: val,
      page: 0,
    })
    setTimeout(() => {
      this.getData()
    }, 100)
  }

  handleStockChange = (e) => {
    const { value } = e.target
    this.setState({
      [e.target.name]: e.target.value,
      page: 0,
    }, () => {
      setTimeout(() => {
        if (value === this.state.stock_search) {
          this.getData()
        }
      }, 300)
    })
  }

  handleFilterChange = (e) => {
    let val = e.target.value
    this.setState({
      period: val,
      page: 0,
    })
    setTimeout(() => {
      this.getData()
    }, 100)
  }

  fetchData = (e) => {
    this.setState({
      scraping: true,
    })
    axios.get('/fetch_ipos_stocks').then((response) => {
      this.getData()
    }).finally(() => {
      this.setState({
        scraping: false,
      })
    })
  }

  graphStock = async (e, index, stock, date, title) => {
    console.log('graphStock', index, stock, date, title)
    e.preventDefault()
    if (this.state.graph.index !== undefined) return;

    this.setState({
      selected_stock: stock,
      graph: {
        index: index,
        symb: stock
      }
    })

    axios.post('/get_stock_price_data', {
      stock_symb: stock,
      dates: getBusinessDays(date, this.state.number_of_days_add, this.state.number_of_days_sub),
      loading: true
    }).then((response) => {

      this.setState({
        stock_graph_data: response.data.data,
        unique_date: date,
        graph_title: title,
        graph_subtitle: stock
      }, () => {

        this.setState({
          loading: false,
          show_graph: true,
          graph: {}
        })
      })
    })
  }

  closePopup = () => {
    this.setState({
      show_graph: false
    })
  }

  render() {
    const { loadingTable, scraping, page, totalLength } = this.state
    const tipProps = this.props.tipProps;
    const stepNo = tipProps.stepNo;
    this.props.getState && this.props.getState(this.state);
    return (
      <>
        <TipCard id='tip-1'
          totalSteps={3}
          activeStep={1}
          {...tipProps}
          className='mt-3 me-0'
        >Hover over an item for an explanation of its function.</TipCard>
        <TipCard id='tip-2'
          {...tipProps}
          totalSteps={3}
          activeStep={2}
        >Filter the data based on your desired criteria (ie. by date, by stock, by transaction type, etc.). View the data as grouped by company, or by individual transactions.</TipCard>
        <TipCard id='tip-3'
          {...tipProps}
          totalSteps={3}
          activeStep={3}
        >Opt in for email signals so you can stay informed and never miss a trade. <Link to='/' className='btn btn-text text-surface-1'>Learn how to use</Link></TipCard>
        
        <IPOSFIlters
          handleFilterChange={this.handleFilterChange}
          handleSortChange={this.handleSortChange}
          handleStockChange={this.handleStockChange}
          {...this.state}
          stepNo={stepNo}
        />
        <div className='p-lg-5 p-3 overflow-auto flex-1'>
          {
            scraping &&
            <span>Now it's scraping and update database. It will takes 3 - 5 minutes.</span>
          }
          {(scraping || loadingTable) && <div className='bar-loader mb-3' />}
          {this.state.show_graph &&
             <Graph
             date= {this.state.unique_date}
             symbol={this.state.selected_stock}
             text={this.state.graph_title}
             close={this.closePopup}
             />
          }
          <StockTable {...this.state} graphStock={this.graphStock} stepNo={stepNo} />
          <Pagination
            pageCount={Math.ceil(totalLength / 10)}
            initialPage={page}
            forcePage={page}
            onPageChange={this.handlePageClick}
            className='mt-3'
          />
        </div>
      </>
    )
  }
}


export default withAlgoProductToolsLayout(Ipos, 'Ipos', { showTips: true });
