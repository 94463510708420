import React from 'react';
import styles from './styles.module.scss';

const Switch = ({ label, className, ...props }) => (
    <label className={`${className} ${styles.root}`}>
        <input type='checkbox' {...props} className='d-none' />
        <span className={styles.main} />
        {label && label}
    </label>
)

export default Switch;