import React from 'react';
import Model from '../../ui-v2/Modal/Model';
import moment from "moment";
import EditIcon from "../../icons/EditIcon";
import TrashIcon from '../../icons-v2/TrashIcon';
import getSignalsFields from '../../utility/getSignalsFields';
import AddIcon from '../../icons-v2/AddIcon';
import ToolTip from '../../ui-v2/ToolTip/ToolTip';

const ManageSignalPopUp = ({ signals_data, createSignal, name, editSignal, deleteSignal, zindex = 1, ...props }) => {
    const fields = getSignalsFields(name || new URLSearchParams(window.location.search).get('algo')) || [];
    return (
        <Model {...props} title='Manage Signal' zIndex={zindex} show={true} closeHandler={props.close}>
            <ToolTip title='Create Signal'>
                <button onClick={createSignal}
                    className='btn btn-icon mb-lg-5 mb-4 position-absolute bottom-0 translate-middle-x start-50 h6 bg-primary text-surface-1'
                >
                    <AddIcon />
                </button>
            </ToolTip>
            <div className='table-wrapper position-relative'>
                <div>
                    <table>
                        <thead>
                            <tr>
                                <th><div>No</div></th>
                                <th>Signal Name</th>
                                <th>Symbol</th>
                                {fields.map((item, index) => <th key={index}>{item.title}</th>)}
                                <th>Created On</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {signals_data.map((data, index) => (
                                <tr key={index}>
                                    <td><div>{index + 1}</div></td>
                                    <td>{data.parameters.signal_name}</td>
                                    <td>{data.parameters.stock_search}</td>
                                    {fields.map((item, i) => <td key={`${index}-${i}`}>{data.parameters[item.state_name]}</td>)}
                                    <td title='Created On'>{moment(data.createdOn).format('LLL')}</td>
                                    <td className="signal_edit" title='Actions'>
                                        <span className='d-flex'>
                                            <button className='btn-icon btn text-accent-4' onClick={() => editSignal(data, index + 1)}>
                                                <EditIcon />
                                            </button>
                                            <button className='btn-icon btn text-accent-2' onClick={() => deleteSignal(data, index + 1)}>
                                                <TrashIcon />
                                            </button>
                                        </span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </Model>
    )
}

export default ManageSignalPopUp;
