import React, { useState } from 'react';
import moment from 'moment'
import ChevronBottom from '../../icons-v2/ChevronBottom';
import styles from './StockItem.module.scss';
import ToolSymbol from '../../components-v2/ToolSymbol/ToolSymbol';

const StockItem = props => {
    const [isExpanded, setIsExpanded] = useState(false);
    const { option, bids, asks, participants, long_term_participants } = props
    return (
        <>
        <tr onClick={() => setIsExpanded(!isExpanded)} className={`${styles.root} ${isExpanded ? styles.active : ''}`}>
            <td><ChevronBottom className={`text-accent-4 ${isExpanded ? 'rotate-180' : ''}`} /></td>

          <td title='Stock Symbol'><ToolSymbol title={option.stock} /></td>
          <td title='Stock Symbol'>{option.last_price}</td>
          <td title='Stock Symbol'>{option.bid_vol}</td>
          <td title='Stock Symbol'>${option.largest_bid_strike.price} ({option.largest_bid_strike.volumeshares}, {((option.largest_bid_strike.volumeshares/option.bid_vol)).toFixed(2)*100}%)</td>
          <td title='Stock Symbol'>{option.ask_vol}</td>
          <td title='Stock Symbol'>${option.largest_ask_strike.price} ({option.largest_ask_strike.volumeshares}, {(option.largest_ask_strike.volumeshares/option.ask_vol).toFixed(2)*100}%)</td>
          <td title='Stock Symbol'>{option.ratio?.toFixed(3)}</td>


        </tr>
        {isExpanded && (
          <>
            <tr className={`border-bottom ${styles.expandedRow}`}>
                <td colSpan={4} className='p-0'>
                    <table className='primary-table'>
                    <caption>Bids</caption>
                        <thead className="no-opacity">
                            <tr>

                                <th></th>
                                <th><div>MMID</div></th>
                                <th>Price</th>
                                <th>Tick</th>
                                <th>Date</th>
                                <th>Volume</th>

                            </tr>
                        </thead>
                        <tbody>
                            {
                                bids.map((item, index) => {
                                    return (
                                        <tr>

                                            <td><div>{index + 1}</div></td>
                                            <td>{item.mmid}</td>
                                            <td>{item.price}</td>
                                            <td>{item.tick}</td>
                                            <td>{item.timestamp}</td>
                                            <td>{item.volumeshares}</td>

                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </td>
                <td colSpan={4} className='p-0'>
                    <table className='primary-table'>
                    <caption>Asks</caption>
                        <thead className="no-opacity">
                            <tr>

                                <th></th>
                                <th><div>MMID</div></th>
                                <th>Price</th>
                                <th>Tick</th>
                                <th>Date</th>
                                <th>Volume</th>

                            </tr>
                        </thead>
                        <tbody>
                            {
                                asks.map((item, index) => {
                                    return (
                                        <tr>

                                            <td><div>{index + 1}</div></td>
                                            <td>{item.mmid}</td>
                                            <td>{item.price}</td>
                                            <td>{item.tick}</td>
                                            <td>{item.timestamp}</td>
                                            <td>{item.volumeshares}</td>

                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </td>
            </tr>
            {participants &&
            <tr className={`border-bottom ${styles.expandedRow}`}>
                <td colSpan={8} className='p-0'>
                    <table className='primary-table'>
                    <caption>Participants</caption>
                        <thead className="no-opacity">
                            <tr>

                                <th></th>
                                <th><div>ID</div></th>
                                <th>Name</th>
                                <th>1 Year Net Volume</th>
                                <th>1 Year Net Value</th>
                                <th>Today Net Volume</th>
                                <th>Today Net Value</th>
                                <th>Today Buying</th>
                                <th>Today Selling</th>

                            </tr>
                        </thead>
                        <tbody>
                            {
                                participants.map((item, index) => {
                                    return (
                                        <tr>

                                            <td><div>{index + 1}</div></td>
                                            <td>{item.id}</td>
                                            <td>{item.pname}</td>
                                            <td>{long_term_participants.filter((x)=>x.id===item.id)[0].netvol}</td>
                                            <td>{long_term_participants.filter((x)=>x.id===item.id)[0].netval}</td>
                                            <td>{item.netvol}</td>
                                            <td>{item.netval}</td>
                                            <td>
                                            Volume: {item.buy?.volume}<br/>
                                            Vol (%): {item.buy?.volpct}<br/>
                                            VWAP: {item.buy?.vwap}<br/>
                                            Value: {item.buy?.value}<br/>
                                            # trans: {item.buy?.trans}<br/>
                                            </td>
                                            <td>
                                            Volume: {item.sell?.volume}<br/>
                                            Vol (%): {item.sell?.volpct}<br/>
                                            VWAP: {item.sell?.vwap}<br/>
                                            Value: {item.sell?.value}<br/>
                                            # trans: {item.sell?.trans}<br/>
                                            </td>

                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </td>
                </tr>
              }


            </>
        )}
        </>
    )
}

export default StockItem;
