import axios from "axios";
import { getBusinessDays } from "../service/logic"

const getStockPrice = async ({stock, date, number_of_days_add, number_of_days_sub}) => {
    return axios.post('/get_stock_price_data', {
        stock_symb: stock,
        dates: getBusinessDays(date, number_of_days_add, number_of_days_sub),
        loading: true
    }).then((response) => response.data.data)
}

export default getStockPrice;