import React, { useState, createContext } from "react";

const MinimizedContext = createContext({ pages: [], addPageToMinimize: title => { }, removePageFromMinimize: title => {} });

export const MinimizedContextProvider = ({ children }) => {
    const [pages, setPages] = useState(JSON.parse(localStorage.getItem('minimized-pages')) || []);

    const storeToLocalStorage = newPages => {
        localStorage.setItem('minimized-pages', JSON.stringify(newPages));
        return setPages(newPages);
    }

    const addPageToMinimize = newPage => {
        const existingPage = pages.find(page => page.pathname === newPage.pathname);
        if(existingPage) {
            existingPage.state = newPage.state;
            existingPage.hash = newPage.hash;
            storeToLocalStorage([...pages]);
        }
        else storeToLocalStorage([...pages, newPage]);
    }
    const removePageFromMinimize = pathname => storeToLocalStorage(pages.filter(page => page.pathname !== pathname));

    return (
        <MinimizedContext.Provider value={{ pages, addPageToMinimize, removePageFromMinimize }}>
            {children}
        </MinimizedContext.Provider>
    )
}

export default MinimizedContext;