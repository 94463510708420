import ToolTip from "../../../../ui-v2/ToolTip/ToolTip";
import React from 'react';

const LI = ({ title, tooltip, value, start = '', end = '' }) => (
    <li>
        <ToolTip title={tooltip}>
            <span>{title}</span>
        </ToolTip>
        {value ? (start + value + end) : 'N/A'}
    </li>
)

export default LI;