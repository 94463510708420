import React from 'react';
import StockItem from '../../components-v2/AlgoHoldingCommonTools/StockItemV2';
import Table from '../../ui-v2/Table/Table';
import InfoIconV2 from '../../icons-v2/InfoIconV2';

const TR = props => {
    function convert_num_to_string(num) {
        if (num > 1000000000) {
            return (num / 1000000000).toFixed(2) + "B";
        } else if (num > 1000000) {
            return (num / 1000000).toFixed(2) + "M";
        } else if (num > 1000) {
            return (num / 1000).toFixed(2) + "K";
        } else {
            return num;
        }
    }
    return(

    <StockItem
        component={() => (
            <>
                <td className='fw-semibold'>{props.ticker}</td>
                <td>{props.company_name}</td>
                <td>{convert_num_to_string(props.total_shares_owned_inst)}</td>
                <td>{convert_num_to_string(props.total_value_inst)}</td>
                <td>{props.avg_purchase_price_based_on_value?.toFixed(2)}</td>

                <td>{convert_num_to_string(props.avg_amount_of_shares_owned)}</td>
                <td>{convert_num_to_string(props.avg_value)}</td>
                <td>{props.avg_percent_portfolio.toFixed(2)}</td>
                <td>{props.avg_recent_change.toFixed(2)}</td>

               
            </>
        )}
    >

        <Table className='p-0'>
            <thead className='text-uppercase'>
                <tr>
                    <th>Manager</th>
                    <th><div className='description'>%of Portfolio</div></th>
                    <th><div className='description'>Recent Change</div></th>
                    <th><div className='description'>Shares</div></th>
                    <th><div className='description'>Avg Purchase Price</div></th>
                    <th><div className='description'>Value</div></th>
                    <th><div className='description'>History</div></th>
                </tr>
            </thead>
            <tbody>
                {props.managers?.map((superInvestor, index) => (    
                    <tr key={index}>
                        <td>{superInvestor.manager_name}</td>
                        <td>{superInvestor.percent_portfolio}</td>
                        <td>{superInvestor.recent_change}</td>
                        <td>{convert_num_to_string(superInvestor.amt_of_shares_owned)}</td>
                        <td>{superInvestor.avg_purchase_price?.toFixed(2)}</td>
                        <td>{convert_num_to_string(superInvestor.value)}</td>
                        <td className='text-center'>
                            <button className='btn-icon btn text-accent-4' onClick={()=>props.showHistory(props.ticker,superInvestor.manager_name,superInvestor.history)}><InfoIconV2 /></button>
                        </td>

                        
                    </tr>
                ))}
            </tbody>
        </Table>
    </StockItem>
)}

export default TR;