import React, { useState } from 'react';
import useModel from '../../hooks/useModel';
import styles from './DropCard.module.scss';

const DropCard = ({ className = '', header, height = '28.5625rem', children, position = 'left', cleanUp, component, footer }) => {
    const [isActive, setIsActive] = useState(false);
    useModel({ shouldWork: isActive, changed: [isActive] })
    const onClose = () => {
        cleanUp && cleanUp();
        isActive && setIsActive(false)
    }
    return (
        <>
            {isActive && (
                <button type='button' onClick={onClose} className={`position-fixed btn top-0 start-0 end-0 bottom-0 ${styles.btn}`} />
            )}
            {component && component(isActive, setIsActive)}
            <div className={`position-relative ${isActive ? 'z-index-7' : ''}`}>
                <div className={`
                ${isActive ? styles.active : ''} ${styles.root} ${className}
                ${position === 'left' ? 'start-0' : 'end-0'}
                bg-surface-1 text-normal mt-lg-3 d-flex flex-column elevation-3 overflow-hidden
            `}
                    style={{ height }}
                >
                    {header && header(onClose)}
                    <div className='flex-1 overflow-auto d-flex flex-column'>
                        {children}
                    </div>
                    {footer && footer(onClose)}
                </div>
            </div>
        </>
    )
}

export default DropCard;