import React from 'react';
import { useSymbols } from '../../../contexts/SymbolContext';
import Select, { Option } from '../../../ui-v2/Select/Select';

const OpenIn = ({ algos, stocks }) => {
    const symbolsHandler = useSymbols().symbolsHandler;
    const skipTools = [
        'fundamentals',
        'dividends',
        'insiders',
        'congress',
        'senator',
        'house',
        'government',
        'earnings',
        'convertible_debt',
        'options_best',
        'article_analyzer'
    ]
    return (
        <Select label='Open in' className='small badge' selectClass='small'>
            {algos.map((vals) => {
                const val = vals.algoId;
                return skipTools.includes(val.url_link) && (
                    <Option key={val._id}
                        value={val._id}
                        onClick={() => symbolsHandler(stocks, vals.algoId)}
                    >
                        {val.stockAlgoName}
                    </Option>
                )
            })}
        </Select>
    )
}

export default OpenIn;