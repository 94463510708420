import React from 'react';
import styles from './ReactChart.module.scss';
import { Chart } from 'react-charts'

const ReactChart = props => (
    <div className='d-flex flex-wrap'>
        <p className={`${styles.yAxis} small-sm ms-md-3 ms-2 my-auto text-accent-4 rotate-270`}>{props.y_axis}</p>
        <div className={`position-relative flex-1 ${styles.chartWrapper}`}>
                <Chart data={props.data} axes={props.axes} series={props.series} tooltip />
        </div>
        <p className='text-center w-100 small-sm text-accent-4 mb-0'>{props.x_axis}</p>
    </div>
)

export default ReactChart;