import React from 'react';
import Logo from '../../icons-v2/Logo';
import { Link, useLocation } from 'react-router-dom';
import styles from './withHeader.module.scss';
import RightLinks from './RightLinks/RightLinks';
import useQuery from '../../hooks/useQuery';
import Main from './main';

const Header = props => {
    const isMobile = useQuery();
    const location = useLocation();
    const newProps = { ...props, isMobile, location };
    const excludePath = /admin|auth/i.test(location.pathname);
    return (
        <header className={`d-${excludePath ? 'none' : 'flex'} header-height gap-5 z-index-7 text-surface-1 bg-primary-darken-4 position-sticky top-0`}>
            <div className={`flex-xl-1 my-auto me-lg-5 me-md-4 me-3 d-md-flex d-none`}>
                <Link to='/' className='ms-5 ps-5'>
                    <Logo className={styles.logo} />
                </Link>
            </div>
            <Main {...newProps} />
            <RightLinks {...newProps} />
        </header>
    )
}

export default Header;