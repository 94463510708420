import React from 'react'
import usePost from '../../hooks/usePostV2'

const Main = props => {
    const isSent = props.currentUser && props.currentUser.status === 'Pending'
    const { res, isLoading, onPost } = usePost(`leaderboard-competition/join/${props._id}`, {
        cb: () => props.participation === "Auto-allow" ? props.join() : null
    })
    return res ? (
        <div className='bg-surface-1-gradient p-3 max-w-sm mx-auto'>
            <b>Your request is sent.</b>
            <p className='mb-0 mt-1 opacity-7'>The organiser will let you in.</p>
        </div>
    ) : (
        <button
            onClick={() => props.isLoggedIn ? onPost() : props.history.replace({hash: 'login'})}
            disabled={isLoading}
            className={`btn-primary mx-auto btn bg-accent-1-gradient fw-semibold ${isLoading ? 'progress-btn' : ''}`}
        >Join Competition</button>
    )
}

const JoinButton = props => {
    if (props.email_domain !== "All" && props.user && props.isLoggedIn && !props.user.email.includes(props.email_domain)) {
        return <p className='m-0 fw-semibold text-accent-1 fs-normal text-center'>Only {props.email_domain} are allowed to join this competition</p>
    }
    return <Main {...props} />
}

export default JoinButton;