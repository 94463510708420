import React from 'react';

export default function Daimond_2(props) {
    return (
        <svg {...props} width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_8244_69377)">
                <path d="M20.5 5.51684H0.5L3.94828 1.37891H17.0517L20.5 5.51684Z" fill="#E9F1FC" />
                <path d="M6.36301 5.51684L3.94922 1.37891H10.5009L6.36301 5.51684Z" fill="#9ABFEF" />
                <path d="M10.5 18.6211L0.5 5.51758H20.5L10.5 18.6211Z" fill="#CCDFF7" />
                <path d="M10.5 18.6211L0.5 5.51758H6.36207L10.5 18.6211Z" fill="#9ABFEF" />
                <path d="M14.6379 5.51684L17.0517 1.37891H10.5L14.6379 5.51684Z" fill="#9ABFEF" />
                <path d="M10.5 18.6211L20.5 5.51758H14.6379L10.5 18.6211Z" fill="#9ABFEF" />
            </g>
            <defs>
                <clipPath id="clip0_8244_69377">
                    <rect width="20" height="20" fill="white" transform="translate(0.5)" />
                </clipPath>
            </defs>
        </svg>
    )
}