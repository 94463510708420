import React from 'react';
import App from "../App";
import { MinimizedContextProvider } from "./MinimizedPagesContext";
import { RefreshContextProvider } from "./RefreshContext";
import { SymbolContextProvider } from './SymbolContext';

export default function GlobalContext(props) {
    return (
        <RefreshContextProvider>
            <App>
                <SymbolContextProvider>
                    <MinimizedContextProvider>
                        {props.children}
                    </MinimizedContextProvider>
                </SymbolContextProvider>
            </App>
        </RefreshContextProvider>
    )
}