import React from 'react';
import InputField from '../InputField/InputField';
import AddIcon from '../../icons-v2/AddIcon';

const Search = props => {
    const onClear = e => {
        const input = e.target.previousSibling;
        e.target = input;
        e.target.value = ""
        props.onChange && props.onChange(e);
    }
    return (
        <InputField
            {...props}
            endAdornment={props.value && (
                <button type='button'
                    onClick={onClear}
                    className='btn-icon btn rotate-45 smaller'
                >
                    <AddIcon />
                </button>
            )}
        />
    )
}

export default Search;