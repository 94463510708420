import React, { useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import MinimizedContext from '../../contexts/MinimizedPagesContext';
import AddIcon from '../../icons-v2/AddIcon';
import styles from './MinimizedPagesBar.module.scss';

const MinimizedPagesBar = () => {
    const { pages, removePageFromMinimize } = useContext(MinimizedContext);
    const push = useHistory().push;
    const location = useLocation();
    const currentRoute = location.pathname + location.search;
    return (
        <div className={`position-fixed d-flex bottom-0 z-index-6 ${styles.root}`}>
            {pages.map(({ title, pathname, hash }, index) => currentRoute !== pathname && (
                <div key={index} className={`bg-primary-darken-4 d-flex align-items-center text-surface-1 flex-1 ${styles.item}`}>
                    <h6 className={`m-0 ${styles.title}`}>{title}</h6>
                    <button onClick={() => push(pathname + hash)} className='btn btn-icon me-auto opacity-5 ms-1'><span className='icon-squar'></span></button>
                    <button onClick={() => removePageFromMinimize(pathname)} className='btn btn-icon opacity-5 no-padding'><AddIcon className='rotate-45' /></button>
                </div>
            ))}
        </div>
    )
}

export default MinimizedPagesBar;