import React from 'react';
import axios from 'axios'
import URL from 'url-parse';
import moment from "moment";
import withAlgoProductToolsLayout from '../../hoc-v2/withAlgoProductToolsLayout/withAlgoProductToolsLayout';
import OptionsBestFilter from './OptionsBestFilter';
import ToolSymbol from '../../components-v2/ToolSymbol/ToolSymbol';
import FilterButton from '../../components-v2/AlgoHoldingCommonTools/FilterButton';


class Options extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: new URL(window.location.href, true),
      // stock_search: ["aapl", "spxl", "tsla", "amzn", "msft", "goog", "meta", "v", "bcs", "nflx", "khc", "td", "ge", "f", "bac", "amd", "ua", "znga", "kgc", "mat", "cs", "amcr", "MMM", "ABT", "spot", "cldr", "bili", "tlry", "roku", "ba", "ge", "ccl", "mu"],//"spxl"

      stock_search: this.props.symbols === '' ? ["aapl"] : this.props.symbols.split(','),
      stock_data: [],
      current_stock_price: 0,
      date_list: [],
      date_value: "all",
      initialized: false,
      min_traded_volume: 5,
      minutes_till_execute: 1,
      ledger: [],
      lastBuy: "",
      signal: "",
      min_ask: 0,
      max_ask: 10000,
      max_cost_to_execute: 300000,
      minimum_profit_to_execute: -100000,
      traded_within_number_days: 100,

      start: false,
      end: true,
      sell_ran: true,

      volume_to_trade: 1,
      activate_live_trading: false,
      authenticated: false,

      selected_filter_best_option: "roi_if_change",
      search_param: "pg",
      stock_search_number: 5,
      loading: false,
      loading_trader: false,
      type: "calls",

      fetch_error_msg: "No results were found, please try again.",
      show_fetch_error: false,
      risk_free_rate: 0,

      transaction_type: "Buy",
      expected_change: 5,
      mpt_object: {},



    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleStockChange = (e, values) => {
    this.setState({
      [e.target.name]: values || e.target.value.split(',')
    })
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.symbol_list !== this.props.symbol_list) {
    this.setState({
      stock_search: this.props.symbol_list
    })
  }
  }



  confirm_authentication = () => {
    this.setState({
      authenticated: true
    })

  }
  handleStartDateChange = (date) => {
    this.setState({
      start_date: moment(date).format('YYYY-MM-DD')
    })
  }
  handleEndDateChange = (date) => {
    this.setState({
      end_date: moment(date).format('YYYY-MM-DD')
    })
  }

  find_stock_symbols = (e) => {
    e.preventDefault()
    axios.post('/find_stocks', {
      search_param: this.state.search_param,
      stock_search_number: this.state.stock_search_number
    }).then((response) => {
      if (response) {
        this.setState({})
      }
    })
  }

  fetchInitialData = (e) => {
    this.setState({
      loading: true,
    })
    let filtered_arr = this.state.stock_search
    axios.post('/fetch_initial_options_data', { stock_search: filtered_arr.slice(0, 100) }).then((response) => {
    
      if (response.data.status === "success") {
        this.setState({
          date_list: response.data.result,
          stock_search: response.data.stock_arr,
          initialized: true,
          loading: false,
          show_fetch_error: false
        })
      } else {
        this.setState({
          show_fetch_error: true,
          initialized: false,
          loading: false,
        })

      }

    })
  }

 

 

  handleCheck = (e) => {
    this.setState({
      [e.target.name]: e.target.checked
    })
  }

  fetchOptions = () => {
    this.setState({
      start: true,
      end: false,
      loading_trader: true

    })
    axios.post('/fetch_options_list',
      {
        stock_search: this.state.stock_search,
        date_value: this.state.date_value,
        min_traded_volume: this.state.min_traded_volume,
        min_ask: this.state.min_ask,
        max_ask: this.state.max_ask,
        selected_filter_best_option: this.state.selected_filter_best_option,
        max_cost_to_execute: this.state.max_cost_to_execute,
        minimum_profit_to_execute: this.state.minimum_profit_to_execute,
        traded_within_number_days: this.state.traded_within_number_days,
        risk_free_rate: this.state.risk_free_rate,
        transaction_type: this.state.transaction_type,
        type: this.state.type,
        expected_change: this.state.expected_change
      }).then((response) => {
        if (response.data.status === "success") {
          this.setState({
            ledger: response.data.result,
            sell_ran: false,
            loading_trader: false,
            mpt_object: response.data.max_pain_obj,

          }
            // ,()=>{
            //    setTimeout(() => {
            //     this.fetchOptions()
            //   }, this.state.minutes_till_execute * 60 * 1000);
            // }
          )
        } else {
          this.setState({
            start: false,
            end: true,
            initialized: false,
            loading_trader: false,
            show_fetch_error: true,
            ledger: [],
            date_list: []
          })
        }
      })

  }
  componentDidMount() {
    if (this.props.state) return this.setState(this.props.state);
    axios.get('/fetch_risk_free_rate').then((response) => {
      this.setState({
        risk_free_rate: response.data.result
      })
    })
  }
  get_upcoming_stocks_with_earnings = () => {
    axios.get("/get_upcoming_stocks_with_earnings").then((response) => {
      this.setState({
        stock_search: response.data.result,
        initialized: false
      })
    })
  }

  handle_filter_change = (event, values) => {


    this.setState({ min_ask: values[0], max_ask: values[1] })
  }

  render() {

    let filter_best_option_list = [
      { "Smallest +/-": "plus_minus" },
      { "Smallest +/- (%)": "plus_minus_percent" },
      //do for these
      { "Largest +/-": "plus_minus_large" },
      { "Largest +/- (%)": "plus_minus_percent_large" },

      { "Smallest BS +/-": "plus_minus_bs" },
      { "Smallest BS +/- (%)": "plus_minus_percent_bs" },
      { "Largest BS +/-": "plus_minus_large_bs" },
      { "Largest BS +/- (%)": "plus_minus_percent_large_bs" },
      //stop
      { "Return if Executed Now": "roi" },
      { "Return if Change": "roi_if_change" },
      { "Highest IV": "highest_iv" },
      { "Lowest IV": "lowest_iv" },
      {"Largest Diff to MPT": "largest_diff_to_mpt"},
      {"Smallest Diff to MPT": "smallest_diff_to_mpt"},
    ]
    //   Volume Actives: va
    // Dollar Gainers: dg
    // Dollar Losers: dl
    // Dollar Volume: dv
    // Percent Gainers: pg
    // let stock_search = ["va", "dg", "dl", "dv", "pg"]
    this.props.getState && this.props.getState(this.state);
    this.props.setResponsive(this.state.ledger.length > 0)
    return (
      <>
        <OptionsBestFilter {...this.state}
          {...this.props}
          handleStockChange={this.handleStockChange}
          fetchInitialData={this.fetchInitialData}
          unIntializedHanlder={() => this.setState({ initialized: false, date_list: [] })}
          handleChange={this.handleChange}
          filter_best_option_list={filter_best_option_list}
          fetchOptions={this.fetchOptions}
          handleCheck={this.handleCheck}
          get_upcoming_stocks_with_earnings={this.get_upcoming_stocks_with_earnings}
          handle_filter_change={this.handle_filter_change}
        />
        {/* Only works for stocks listed on NYSE */}
        {this.state.ledger.length > 0 &&
          <div className='p-lg-5 p-md-4 p-3'>
            <div className='d-flex justify-content-between mb-3'>
              <small className='text-accent-4'>Total Results {this.state.ledger.length}</small>
              <FilterButton />
            </div>
            <div className='table-wrapper product_tool_table'>
              <div>
                {this.state.type === "calls" ?
                  <table>
                    <thead>
                      <tr>
                        <th><div>Expiration Date</div></th>
                        <th>Stock Symbol</th>
                        <th>Current Stock Price</th>
                        <th>Strike</th>
                        <th>Volume</th>
                        <th>Bid</th>
                        <th>Ask</th>
                        <th>Last Price</th>
                        <th>IV</th>
                        <th>BS Price</th>
                        <th>Break Even</th>
                        <th>+/-</th>
                        <th>+/- %</th>
                        <th>MPT (week)</th>
                        <th>MPT (all)</th>
                        <th style={{ textAlign: "center" }}>If stock goes {this.state.transaction_type === "Buy" ? "up" : "down"} by <br /> {this.state.expected_change}%</th>
                        <th>ROI if target hit</th>
                        <th>Cost to Execute</th>
                        <th>Profit if Executed Now</th>

                        <th>Last Trade Date</th>

                      </tr>
                    </thead>
                    <tbody>
                      {this.state.ledger.map((option, index) => (
                        <tr key={index}>
                          <td title='Expiration Date'><div>{option.contract_date}</div></td>
                          <td title='Stock Symbol'><ToolSymbol title={option.stock_symb} /></td>
                          <td title='Current Stock Price'>{option.current_stock_price.toFixed(2)}</td>
                          <td title='Strike'>{option.strike.toFixed(2)}</td>
                          <td title='Volume'>{option.volume}</td>
                          <td title='Bid'>{option.bid?.toFixed(2)}</td>
                          <td title='Ask'>{option.ask?.toFixed(2)}</td>
                          <td title='Last Price'>{option.last_price.toFixed(2)}</td>
                          <td title='IV'>{option.iv_value.toFixed(2)}</td>
                          <td title='BS Price'>{option.black_scholes_value.toFixed(2)} - ({option.bs_diff.toFixed(2)})</td>

                          <td title='Break Even'>{option.breakeven.toFixed(2)}</td>
                          <td title='+/-'>{option.plus_minus.toFixed(3)}</td>
                          <td title='+/- %'>{option.plus_minus_percent.toFixed(3)}</td>
                          <td title='MPT'>{this.state.mpt_object[option.stock_symb][option.contract_date]}</td>
                          <td title='MPT'>{this.state.mpt_object[option.stock_symb]["all"]}</td>
                          <td title='Option changed'>{option.option_contract_referenced.contract.strike}@{option.option_contract_referenced.contract.expirydate}<br />B:${option.option_contract_referenced.pricedata.bid} A:${option.option_contract_referenced.pricedata.ask}</td>
                          <td title='Roi if changed'>{option.roi_if_change.toFixed(2)}</td>
                          <td title='Cost to Execute'>{option.cost_to_execute.toFixed(2)}</td>
                          <td title='Profit if Executed'>{-1 * option.profit_if_executed.toFixed(2)} ({-1 * option.roi.toFixed(3)}%)</td>

                          <td title='Last Trade Date'>{moment(option.last_trade_date).format("LLL")}</td>


                        </tr>
                      ))}
                    </tbody>
                  </table>
                  :

                  <table>
                    <thead>
                      <tr>
                        <th><div>Expiration Date</div></th>
                        <th>Stock Symbol</th>
                        <th>Current Stock Price</th>
                        <th>Strike</th>
                        <th>Volume</th>
                        <th>Bid</th>
                        <th>Ask</th>
                        <th>Last Price</th>
                        <th>IV</th>
                        <th>BS Price</th>
                        <th>Break Even</th>
                        <th>+/-</th>
                        <th>+/- %</th>
                        <th>MPT</th>
                        <th style={{ textAlign: "center" }}>If stock goes {this.state.transaction_type === "Buy" ? "down" : "up"} by <br /> {this.state.expected_change}%</th>
                        <th>ROI if target hit</th>
                        <th>Cost to Execute</th>
                        <th>Profit if Executed Now</th>
                        <th>Last Trade Date</th>

                      </tr>
                    </thead>
                    <tbody>
                      {this.state.ledger.map((option) => (
                        <tr key="name">
                          <td title='Expiration Date'><div>{option.contract_date}</div></td>
                          <td title='Stock Symbol'>{option.stock_symb}</td>
                          <td title='Current Stock Price'>{option.current_stock_price.toFixed(2)}</td>
                          <td title='Strike'>{option.strike.toFixed(2)}</td>
                          <td title='Volume'>{option.volume}</td>
                          <td title='Bid'>{option.bid?.toFixed(2)}</td>
                          <td title='Ask'>{option.ask?.toFixed(2)}</td>
                          <td title='Last Price'>{option.last_price.toFixed(2)}</td>

                          <td title='IV'>{option.iv_value.toFixed(2)}</td>
                          <td title='BS Price'>{option.black_scholes_value.toFixed(2)} - ({option.bs_diff.toFixed(2)})</td>
                          <td title='Break Even'>{option.breakeven.toFixed(2)}</td>
                          <td title='+/-'>{option.plus_minus.toFixed(3)}</td>
                          <td title='+/- %'>{option.plus_minus_percent.toFixed(3)}</td>
                          <td title='MPT'>{this.state.max_pain_obj[option.stock_symb][option.contract_date]}</td>
                          <td title='Option changed'>{option.option_contract_referenced.contract.strike}@{option.option_contract_referenced.contract.expirydate}<br />B:${option.option_contract_referenced.pricedata.bid} A:${option.option_contract_referenced.pricedata.ask}</td>
                          <td title='Roi if changed'>{option.roi_if_change.toFixed(2)}</td>
                          <td title='Cost to Execute'>{option.cost_to_execute.toFixed(2)}</td>
                          <td title='Profit if Executed'>{-1 * option.profit_if_executed.toFixed(2)} ({-1 * option.roi.toFixed(3)}%)</td>
                          <td title='Last Trade Date'>{moment(option.last_trade_date).format("LLL")}</td>

                        </tr>
                      ))}
                    </tbody>
                  </table>
                }
              </div>
            </div>
          </div>
        }
      </>
    )
  }
}

export default withAlgoProductToolsLayout(Options, 'Options Screener');
