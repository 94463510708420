import React from "react";
import AlgoItem from "../../components-v2/AlgoItem/AlgoItem0";


const Item =  props => {
    const val = props.algoId
    return val ? (
        <AlgoItem
            to={((props.trial > 0 && props.status === "Trial") || (props.status === "Active" || val.status === undefined || val.status === null || val.status === "Free for a Limited Time" || val.status === "Regular")) ? `/algo_page?algo=${val.url_link}&algo_id=${val._id}` : `/marketplace/${val.url_link}`}
            chart={val.image_url}
            title={val.stockAlgoName}
            video_url={val.video_url}
            username={val.userId.name}
            price={val.priceDetails.price}
            ratings={val.review}
            tags={val.tags}
            status={props.status}
            description={val.description}
            payment_type={val.priceDetails.type}
            trial={props.trial}
            isDashbaord={true}
            algo_id={val._id}
            type={val.productType}
        />
    ) : ""
}

export default Item;