import React, { useState } from 'react';
import DyanamicWatchlistModal from '../../DyanamicWatchlistModal/DyanamicWatchlistModal';
import EditCustomModal from '../EditCustomModal';

const EditWatchlist = ({ selectedItem, setState, watchlist, algos }) => {
    const [isModal, setIsModal] = useState(false);

    const closeHandler = () => setIsModal(false);

    const updateHandler = (watchlist, selectedItem) => {
        setState({ watchlist, selectedItem });
        closeHandler();
    }

    const onUpdate = item => {
        const updatedItem = { ...selectedItem, ...item };
        const itemIndex = watchlist.findIndex(item => item._id === selectedItem._id);
        watchlist[itemIndex] = updatedItem;
        updateHandler(watchlist, updatedItem);
    }

    const onDelete = _id => {
        const newWatchlist = watchlist.filter(item => item._id !== _id);
        updateHandler(newWatchlist, newWatchlist[0]);
    }

    return (
        <>
            {isModal && (selectedItem.watchlist_type === "dynamic" ? (
                <DyanamicWatchlistModal
                    onDynamicCreate={onUpdate}
                    closeHandler={closeHandler}
                    watchlistItem={selectedItem}
                    show={true}
                    algos={algos}
                    onDelete={onDelete}
                />
            ) : (
                <EditCustomModal closeHandler={closeHandler} onEdit={onUpdate} onDelete={onDelete} {...selectedItem} />
            ))}
            <button className='badge btn-text small cursor-pointer' onClick={() => setIsModal(true)}>Edit</button>
        </>
    )
}

export default EditWatchlist;