import React from 'react';
import axios from 'axios'
import URL from 'url-parse';
import moment from "moment";
import withAlgoProductToolsLayout from '../../hoc-v2/withAlgoProductToolsLayout/withAlgoProductToolsLayout';
import OptionsOrderFilter from './OptionsOrderFilter';
import ToolSymbol from '../../components-v2/ToolSymbol/ToolSymbol';
import FilterButton from '../../components-v2/AlgoHoldingCommonTools/FilterButton';

class Options extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: new URL(window.location.href, true),
      optionPositions: [],
      currentOrders: [],
      profitLoss: [],

    }
  }

  componentDidMount() {

    axios.get('/get_option_positions').then(response => {

      this.setState({optionPositions: response.data});

    });
    axios.get('/get_current_option_orders').then(response => {

      this.setState({currentOrders: response.data});
    });
    // axios.get('/api/profit-loss').then(response => {
    //   this.setState({profitLoss: response.data});
    // });
  }
  order_placed = () => {
    axios.get('/get_current_option_orders').then(response => {

      this.setState({currentOrders: response.data});
    });
  }


  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleStockChange = (e, values) => {
    this.setState({
      [e.target.name]: values || e.target.value.split(',')
    })
  }
  handle_run_option_orders = (e, should_specific_order, specific_order) => {
    e.preventDefault();
    axios.post('/run_option_orderer',{should_specific_order,specific_order}).then(response => {

    });
  }



  render() {
    const positionStyle = { border: '1px solid #eee', padding: '10px', borderRadius: '5px', marginBottom: '10px', display:"flex", justifyContent: 'space-between' };

    return (
      <>
      <OptionsOrderFilter order_placed={this.order_placed} />

       
        <div style={{width:'100%', display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ width: '32%', padding: '20px', border: '1px solid #000', borderRadius: '5px', boxSizing: 'border-box', margin: '10px' }}>
            <h5>Current Option Positions</h5>
            {this.state.optionPositions.map((position, i) => (
              <div key={i} style={positionStyle}>
                <p><strong>Symbol:</strong> {position.contract.symbol}</p>
                <p><strong>Strike:</strong> {position.contract.strike}</p>
                <p><strong>Type:</strong> {position.contract.right}</p>
                <p><strong>Expiry:</strong> {position.contract.expiry}</p>
                <p><strong>Position:</strong> {position.pos}</p>
                <p><strong>Avg Cost:</strong> {position.avgCost}</p>
              </div>
            ))}
          </div>
          <div style={{ width: '32%', padding: '20px', border: '1px solid #000', borderRadius: '5px', boxSizing: 'border-box', margin: '10px' }}>
          <button onClick={(event)=>this.handle_run_option_orders(event)}>Run All Option Orders</button>
          <h5>Current Orders</h5>
            {this.state.currentOrders.map((order,i) => (
              <div key={i} style={positionStyle}>
                {order.steps.map(step => (
                  <>
                  <p><strong>Symbol:</strong> {step.stock_search}</p>
                  <p><strong>Type:</strong> {step.order_type}</p>
                  <p><strong>Strike Type:</strong> {step.strike_type}</p>
                  <p><strong>Strike:</strong> {step.strike}</p>
                  <p><strong>Expiry:</strong> {moment(step.contract_expiry).format('LLL')}</p>
                  <p><strong>Quantity:</strong> {step.quantity_per_contract}</p>
                  <p><strong>Type:</strong> {step.type}</p>
                  



                  </>
                ))}
              <button onClick={(event)=>this.handle_run_option_orders(event, true,order._id)}>Run this Order</button>
              </div>
            ))}
          </div>
          <div style={{ width: '32%', padding: '20px', border: '1px solid #000', borderRadius: '5px', boxSizing: 'border-box', margin: '10px' }}>
          <h5>P/L from Option Trades</h5>
            {this.state.profitLoss.map(pl => (
              <p>{pl}</p>
            ))}
          </div>
        </div>
      </>
    )
  }
}

export default withAlgoProductToolsLayout(Options, 'Options Screener');
