import React from 'react';

export default function ClockV2(props) {
    return (
        <svg width="208" height="203" {...props} viewBox="0 0 208 203" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M101.468 202.936C157.507 202.936 202.936 157.507 202.936 101.468C202.936 45.4287 157.507 0 101.468 0C45.4287 0 0 45.4287 0 101.468C0 157.507 45.4287 202.936 101.468 202.936Z" fill="#717272" />
            <path d="M106.241 202.936C162.281 202.936 207.709 157.507 207.709 101.468C207.709 45.4287 162.281 0 106.241 0C50.2022 0 4.77344 45.4287 4.77344 101.468C4.77344 157.507 50.2022 202.936 106.241 202.936Z" fill="#B8B8B8" />
            <rect x="13.6624" y="8.89062" width="185.158" height="185.158" rx="92.5789" fill="#E9F1FC" />
            <path d="M105.231 102.665L61.676 63.8379L107.252 100.271L105.231 102.665Z" fill="currentColor" />
            <path d="M107.976 100.371L128.077 174.16L104.507 102.566L107.976 100.371Z" fill="currentColor" />
            <path d="M106.242 108.328C110.03 108.328 113.101 105.257 113.101 101.469C113.101 97.6803 110.03 94.6094 106.242 94.6094C102.453 94.6094 99.3823 97.6803 99.3823 101.469C99.3823 105.257 102.453 108.328 106.242 108.328Z" fill="currentColor" />
            <g stroke="currentColor" strokeWidth="2.82608" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round">
                <path d="M106.241 8.89062V28.5704" />
                <path d="M106.241 174.369V194.049" />
                <path d="M198.819 101.469H179.139" />
                <path d="M33.343 101.469H13.6626" />
                <path d="M28.0979 51.8281L44.7099 62.3808" />
                <path d="M167.773 140.561L184.385 151.113" />
                <path d="M155.884 23.3262L145.331 39.9375" />
                <path d="M67.1523 163.002L56.5996 179.613" />
                <path d="M184.385 51.8281L167.773 62.3808" />
                <path d="M44.7099 140.561L28.0979 151.113" />
                <path d="M56.5996 23.3262L67.1523 39.9375" />
                <path d="M145.331 163.002L155.884 179.613" />
            </g>
        </svg>
    )
}