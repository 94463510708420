import React from 'react';
import styles from './styles.module.scss';

const TextFieldV3 = ({ label, Icon, className = "", ...props }) => (
    <label className={`${styles.root} ${className}`}>
        <b>{label}</b>
        {Icon && <Icon />}
        <input {...props} />
    </label>
)

export default TextFieldV3;