import React, { useRef } from 'react';
import ToggleText from '../../../ui-v2/ToggleText/ToggleText';

const data = ['Fundamentals', 'Stats', 'Assets', 'Dividends', 'Valuation Measures', 'Management', 'Financial Strength']

const Header = ({checked, onChecked, company_name, symbol}) => {
    const ref = useRef();
    return (
        <div className='d-inherit gap-inherit pt-lg-4 pt-3 position-sticky top-0 bg-surface-1'>
            <div className='mb-4'>
                <h5 className='m-0'>{company_name} </h5>
                <small>{symbol} [NASD]</small>
            </div>
            <div className='d-flex border-bottom overflow-auto gap-4' style={{scrollBehavior: 'smooth'}} ref={ref}>
                {data.map((item, index) => <ToggleText onChange={onChecked} key={index} checked={item === checked} className='small' title={item} name='fundamentals_filter' />)}
            </div>
        </div>
    )
}

export default Header;
