import React, { useState } from 'react';
import Graph from '../../live/graph_module';
import ClockLoader from "react-spinners/ClockLoader";
import axios from 'axios';
import { getBusinessDays } from '../../service/logic';
import moment from 'moment';
import axiosErrorHandler from '../../utility/axiosErrorHandler';
import toastr from "toastr";



const SymbolTool = ({ ticker, date, title }) => {
    const [state, setState] = useState({});
    const {showGraph=false } = state;

    const toggleGraph = () => {
        setState({  showGraph: !showGraph });
    }


    
    return (
        <>
        <button  className='badge d-flex align-items-center gap-2' onClick={toggleGraph}>
                {ticker}
               
            </button>
            {showGraph  && (
            <Graph date={date} symbol={ticker} text={title} close={toggleGraph} />
            )}
            
        </>
    )
}

export default SymbolTool;
