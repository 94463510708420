import React, { Component, Fragment } from 'react';
import BoxField from '../../ui-v2/BoxField/BoxField';
import InputField from '../../ui-v2/InputField/InputField';
import axios from 'axios'
import SelectField from '../../ui-v2/SelectField/SelectField';
import ToolTip from '../../ui-v2/ToolTip/ToolTip';
import { MenuItem } from '@material-ui/core';
import ChevronBottom from '../../icons-v2/ChevronBottom';

class StockListSelector extends Component {
  state = {
    selected_filter_best_option: "va",
    number_display_results: 10,
    exchange: "tsv"
  }

  handleChange = e => {
    let name = e.target.name
    let value = e.target.value

    this.setState({
      [name]: value
    })
  }
  fetch_stock_lists = () => {
    axios.post("/fetch_stock_lists", { ...this.state }).then((response) => {
      this.props.load_stock_list(response.data.results)


    })
  }

  render() {
    let filter_best_option_list = [
      { "None": "null" },
      { "Volume Actives": "va" },
      { "Dollar Gainers": "dg" },
      { "Dollar Losers": "dl" },
      { "Dollar Volume": "dv" },
      { "Percent Gainers": "pg" },
      { "Percent Losers": "pl" },
      { "Annual High": "ah" },
      { "Annual Low": "al" },
      { "Options Volume": "ov" },



    ]
    let exchange = [
      { "None": "null" },
      { "NASDAQ": "nsd" },
      { "NYSE": "nye" },
      { "TSX": "tsx" },
      { "TSX-V": "tsv" },
      // {"CSE":"cnq"},
      // {"CSE Pure":"pure"},
      // {"ALPHA":"alpha"},
      // {"NYSE American":"amx"},
      // {"OTC":"oto"},




    ]

    return (
      <>
        <div className='d-grid col-2 overflow-initial gap-3'>
          <ToolTip title="Select a predefined list of stocks">
            <SelectField
              value={this.state.selected_filter_best_option}
              name="selected_filter_best_option"
              onChange={this.handleChange}
              label="Predefined Lists"
            >
              {filter_best_option_list.map((filter, index) => (
                <MenuItem key={index} value={Object.values(filter)[0]}>{Object.keys(filter)[0]}</MenuItem>
              ))}
            </SelectField>
          </ToolTip>
          <ToolTip title="Select the exchange">
            <SelectField
              value={this.state.exchange}
              name="exchange"
              onChange={this.handleChange}
              label="Exchange "
            >
              {exchange.map((filter, index) => (
                <MenuItem key={index} value={Object.values(filter)[0]}>{Object.keys(filter)[0]}</MenuItem>
              ))}
            </SelectField>
          </ToolTip>
          <InputField
            name='number_display_results'
            label="# of stocks"
            type="number"
            onChange={this.handleChange}
            value={this.state.number_display_results}
          />
          <button className='btn-icon btn h5 text-surface-1 bg-primary me-auto' onClick={this.fetch_stock_lists}>
            <ChevronBottom className='rotate-270' />
          </button>
        </div>
      </>
    )
  }
}

export default StockListSelector;
