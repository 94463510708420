import React, { useState } from "react";
import styles from './tabs.module.scss';

const Tabs = ({ options = [], value, className = "", onTabChange }) => {
    const [title, setTitle] = useState(value || options[0]);
    const index = options.findIndex(option => option === title);
    const width = 100 / options.length;
    const onChange = (option, index) => {
        setTitle(option);
        setTimeout(() => (
            onTabChange && onTabChange(option, index)
        ), 180)
    }
    return (
        <div className={`${styles.root} small d-flex position-relative ${className}`}>
            <div className="position-absolute transition bg-surface-1 top-0 bottom-0 elevation-1"
                style={{
                    left: `${width * index}%`,
                    width: `${width}%`
                }}
            />
            {options.map((option, i) => (
                <button key={i}
                    onClick={() => onChange(option, i)}
                    className={`btn py-2 fs-inherit flex-1 z-index-1 fw-semibold ${index === i ? "cursor-text" : "btn-text text-accent-4"}`}
                >{option}</button>
            ))}
        </div>
    )
}

export default Tabs;