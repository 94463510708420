import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import SelectField from '../../ui-v2/SelectField/SelectField';
import InputField from '../../ui-v2/InputField/InputField';
import SideFilter from '../../components-v2/SideFilter/SideFilter';
import BoxField from '../../ui-v2/BoxField/BoxField';
import { useHistory } from 'react-router-dom';
import Circle from '../../ui-v2/Circle/Circle';
import WatchListImport from '../../components-v2/AlgoHoldingCommonTools/watchlist_import';
import MobileSearchBox from '../../components-v2/AlgoHoldingCommonTools/MobileSearchBox';
import FilterButton from '../../components-v2/AlgoHoldingCommonTools/FilterButton';


const ConDebtStockFilters = ({
    handleStockChange,
    stock_search,
    changeTableView,
    handleModelChange,
    selected_val,
    loading,
    display_exchange_list,
    selected_security,
    handleSecurityChange,
    security_type_list,
    stepNo,
    filter_best_option_list,
    handleChange,
    algo
}) => {

    const watchlist = <WatchListImport handleStockChange={handleStockChange} algo={algo} />;
    const searchProps = { name: 'stock_search', label: 'Search', onChange: handleStockChange, value: stock_search, disabled: loading };

    return (
        <SideFilter
            responsiveHeader={<h6 className='mt-0 mb-1'>Filters</h6>}
            component={
                <>
                    <div className='d-flex gap-3 align-items-center w-100'>
                        <MobileSearchBox {...searchProps}
                            className='flex-1'
                        />
                        <FilterButton />
                    </div>
                    {watchlist}
                </>
            }
        >
            <div className='d-lg-block d-none'>
                <div className='d-flex justify-content-between mb-3'>
                    <small>Data Filters</small>
                    {watchlist}
                </div>
                <InputField fullWidth
                    {...searchProps}
                />
            </div>

            <div className='d-flex flex-column gap-3'>

                <SelectField
                    value={selected_security}
                    name="selected_security"
                    onChange={handleChange}
                    label="Security Type"
                >
                    {filter_best_option_list.map((filter, index) => (
                        <MenuItem key={index} value={Object.values(filter)[0]}>{Object.keys(filter)[0]}</MenuItem>
                    ))}
                </SelectField>
            </div>

            <div className='d-flex flex-column gap-3'>
                <SelectField
                    value={selected_val}
                    onChange={handleModelChange}
                    disabled={loading}
                    label="Select Exchange(s)"
                >
                    {display_exchange_list.map((ex) => (
                        <MenuItem value={ex}>{ex}</MenuItem>
                    ))}
                </SelectField>
            </div>

        </SideFilter>
    )
}

export default ConDebtStockFilters;
