import axios from 'axios';

export const getAlgos = () => axios.get('/algos');
export const getFilterAlgos = params => axios.post('/marketplace/search', params);

export const checkAlgoPurchased = (data = { algoId: "", userId: "" }) => axios.post("/checkPurchase/", data);
export const checkAlgoAccess = (data = { algoId: "", userId: "" }) => axios.post("/confirmAccess/", data);
export const getAlgo = (algoId = "") => axios.get(`/algos/${algoId}`);
export const getAlgoByName = (algoName = "") => axios.get(`/algo_by_name?algo_name=${algoName}`);

export const purchaseFreeAlgo = data => axios.post("/myPurchases/save", data)
export const purchaseAlgSubscription = (algo_id) => axios.post('/create_subscription', {algo_id})
export const purchaseOneTimeAlgo = algo_id => axios.post('/create_charge', {algo_id})

export const checkStripeUser = () => axios.get('/is_stripe_customer');
// export const getAlgo = (algoId, userId = "") => {
//     console.clear();
//
//     return Promise.all([
//         axios.get(`/algos/${algoId}`),
//         axios.post("/checkPurchase/", { algoId, userId })
//     ])
//         .then(responses => ({
//             ...responses[0].data.data,
//             ...responses[1].data,
//             isMyAlgo: algoId === userId,
//             totalReviewRating: responses[0].data.totalReviewRating,
//             user_reviewed: responses[0].data.user_reviewed
//         }))
// }
