import React from 'react';

const TrashIcon = props => (
    <svg {...props} width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.7411 6.96094C13.7411 13.6435 14.703 16.6641 8.23314 16.6641C1.7625 16.6641 2.74425 13.6435 2.74425 6.96094" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6.74996 13.1673L6.74996 7.33398" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10.0833 13.1673L10.0833 7.33398" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M14.9711 4.40073H1.51233" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M11.0959 4.39981C11.0959 4.39981 11.5364 1.26172 8.24113 1.26172C4.94669 1.26172 5.38716 4.39981 5.38716 4.39981" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
)

export default TrashIcon;