const toNumber = (num, decimal) => +(num.toFixed(decimal))

const getNumber = (num, decimal = 3) => {
    if (!+num) return;
    const m = 1000000;
    const b = m * 1000;
    const t = b * 1000;
    if (num >= t) return `${toNumber(num / t, decimal)}T`;
    if (num >= b) return `${toNumber(num / b, decimal)}B`;
    if (num >= m) return `${toNumber(num / m, decimal)}M`;
    return toNumber(num, decimal);
}

export default getNumber;