import React, { useState } from 'react';
import styles from './CookiesBar.module.scss';
import ReactDOM from 'react-dom';
import cookie from 'react-cookies'

const CookiesBar = () => {
    const [isCookiesAccepted, setIsCookiesAccepted] = useState(cookie.load("isCookiesAccepted"));
    var two_weeks = new Date();
    two_weeks.setDate(two_weeks.getDate()+14);
    const saveCookie = () => {
        cookie.save('isCookiesAccepted', true,
      {
        expires:two_weeks
      });
        setIsCookiesAccepted(true);
    }
    return !isCookiesAccepted ? ReactDOM.createPortal(
        <div className={`animate position-fixed gap-2 align-items-center p-3 m-lg-3 d-flex flex-lg-row flex-column justify-content-between align-items-center ${styles.root}`}>
            <p className='m-0 pl-lg-3'>This website uses cookies to provide you with a great user experience. By using it, you accept our <a href='/use-of-cookies' className='btn-text' target='_blank'>use of cookies</a></p>
            <button className='btn btn-text' onClick={saveCookie}>Okay</button>
        </div>,
        document.getElementById('cookies')
    ) : null;
}

export default CookiesBar;
