import React from 'react';
import AddIcon from '../../../icons-v2/AddIcon';
import styles from './SearchWrapper.module.scss';
import SearchField from '../../../ui-v2/SearchField/SearchField';

const SearchWrapper = ({ className = '', onClear, value, component, isRounded = false, ...props }) => (
    <SearchField className={`${isRounded ? styles.rounded : styles.root} ${className} small`} value={value} {...props}>
        {value !== '' && (
            <button className={`btn-icon btn smaller ${isRounded ? 'position-absolute start-0 ms-2 top-50 translate-middle-y' : 'no-spacing'}`} onClick={onClear} type='button'>
                <AddIcon className='rotate-45 text-accent-4' />
            </button>
        )}
    </SearchField>
)

export default SearchWrapper;