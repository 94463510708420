import React, { useMemo } from 'react';
import useStockSearch from '../../../hooks/useStockSearch';
import AddIcon from '../../../icons-v2/AddIcon';
import MagnifierGlassIcon from '../../../icons-v2/MagnifierGlassIcon';
import InputField from '../../../ui-v2/InputField/InputField';
import styles from './main.module.scss';
import Wrapper from './Wrapper';

const Main = ({ setStocks, stocks }) => {
    const { value, results, ...sProps } = useStockSearch({ limit: 5 });
    const onChange = e => {
        const { checked, name } = e.target;
        const newStocks = checked ? [...stocks, results[name]] : stocks.filter(item => item.ticker !== name);
        setStocks(newStocks);
    }
    const stockKeys = useMemo(() => stocks.map(item => item.ticker), [stocks])
    const iProps = { onChange, stockKeys }
    return (
        <>
            {useMemo(() => (
                <InputField
                    value={value}
                    label='Search & add stock'
                    startAdornment={<MagnifierGlassIcon className='text-accent-4' />}
                    onChange={sProps.onChange}
                    endAdornment={value !== '' && (
                        <button type='button'
                            onClick={sProps.onClear}
                            className='btn btn-icon rotate-45 text-accent-5 small'
                        >
                            <AddIcon className='text-accent-4' />
                        </button>
                    )}
                />
            ), [value])}
            <div className={`d-flex gap-3 ${styles.main}`}>
                <Wrapper stocks={results} {...iProps} title='Select stock' />
                <div className='border-left' />
                <Wrapper stocks={stocks} {...iProps} title={`Stocks selected (${stocks.length})`} />
            </div>
        </>
    )
}


export default Main;