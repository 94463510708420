import React from 'react';

const ChainV2 = props => (
    <svg {...props} viewBox="0 0 19 9" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
        <path d="M6 4.5H12.9999" />
        <path d="M7.75052 7.99986H4.83391C3.90568 7.99986 3.01545 7.63112 2.35909 6.97475C1.70273 6.31839 1.33398 5.42817 1.33398 4.49993C1.33398 3.57169 1.70273 2.68147 2.35909 2.02511C3.01545 1.36874 3.90568 1 4.83391 1H7.75052" />
        <path d="M11.25 7.99986H14.1666C15.0948 7.99986 15.9851 7.63112 16.6414 6.97475C17.2978 6.31839 17.6665 5.42817 17.6665 4.49993C17.6665 3.57169 17.2978 2.68147 16.6414 2.02511C15.9851 1.36874 15.0948 1 14.1666 1H11.25" />
    </svg>
)

export default ChainV2;