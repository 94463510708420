import React, { useMemo, useState } from 'react';
import styles from './faqs.module.scss';
import faqs from './faq';
import Model from '../../../ui-v2/Modal/Model';

export default function FAQs() {
    const [faq, setFaq] = useState(null)
    return (
        <>
            {faq && (
                <Model show={true}
                    isNormal={true}
                    title={faq.title}
                    closeHandler={() => setFaq(null)}
                >{faq.description}</Model>
            )}
            {useMemo(() => (
                <div className={styles.root}>
                    <b>FAQs</b>
                    {faqs.map((faq, i) => <button onClick={() => setFaq(faq)} key={i}>{faq.title}</button>)}
                </div>
            ), [])}
        </>
    )
}