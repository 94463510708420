import React, { Component, useMemo } from "react";
import ManageSignalPopUp from "../../components-v2/AlgoHoldingCommonTools/ManageSignalPopUp";
import Signals from "../../components/signals";
import axios from "axios";
import getSignalsFields from "../../utility/getSignalsFields";
import DelelteSignalModal from "./DeleteSignalModal";

class Main extends Component {
    state = {
        signals_data: [],
        modalValue: {},
        done: false,
        manageMode: false,
        type: 'create'
    }

    createSignal() {
        this.setState({
            signal: true,
            type: 'create',
            modalValue: {}
        })
    }

    editSignal = (data) => {
        this.setState({
            signal: true,
            type: 'update',
            modalValue: data.parameters,
            id: data._id
        })
    }

    deleteSignal = (data, index) => {
        this.setState({
            type: 'delete',
            id: data._id,
            deleteNo: index
        })
    }

    manageSignal = () => {
        this.setState({
            manageMode: !this.state.manageMode
        })
    }

    createSignal = () => {
        this.setState({
            signal: true,
            type: 'create',
            modalValue: {}
        })
    }
    getSignalData() {
        axios.post('/get_signal_existing', {
            algo_name: this.props.algo_name
        }).then((res) => {
            if (res.data.success) {
                this.setState({ signals_data: res.data.signals })
            }
            return []
        })
    }
    onDelete = () => {
        const signals_data = this.state.signals_data.filter(item => item._id !== this.state.id)
        this.setState({ signals_data })
    }
    componentDidMount = this.getSignalData
    render() {
        const newProps = {
            editSignal: this.editSignal,
            createSignal: this.createSignal,
            deleteSignal: this.deleteSignal,
            manageSignal: this.manageSignal,
            signals_data: this.state.signals_data,
            close: this.manageSignal,
            name: this.props.name || this.props.algo_name
        }
        const { modalValue, ...state_2 } = this.state;
        return (
            <>
                {this.props.component && this.props.component(newProps)}
                {this.state.type === "delete" && (
                    <DelelteSignalModal
                        id={this.state.id}
                        index={this.state.index}
                        closeHandler={() => this.setState({ type: 'create' })}
                        onDelete={this.onDelete}
                    />
                )}
                {this.state.signal && (
                    <Signals
                        algo_name={this.props.algo_name}
                        name={modalValue.signal_name}
                        number={modalValue.number_of_results}
                        {...modalValue}
                        type={this.state.type}
                        filter_objects={
                            getSignalsFields(
                                newProps.name,
                                this.props.signalValues ? { ...this.props.signalValues, ...modalValue } : modalValue
                            )
                        }
                        signalID={this.state.id}
                        onSuccess={() => {
                            this.setState({
                                signal: false
                            })
                            this.getSignalData()
                        }}
                        close={() => {
                            this.setState({
                                signal: false,
                                done: false,
                            })
                        }}
                    />
                )}
                {this.state.manageMode && <ManageSignalPopUp {...newProps} />}
            </>
        );
    }
}

const SignalsHandler = props => useMemo(() => <Main {...props} />, [props.signalValues])

export default SignalsHandler;