import SelectField from "../../ui-v2/SelectField/SelectField";
import React, { Fragment, useMemo, useState } from 'react';
import MenuItem from "@material-ui/core/MenuItem";
import FilterButton from "../../components-v2/AlgoHoldingCommonTools/FilterButton";
import ToolTip from "../../ui-v2/ToolTip/ToolTip";
import moment from "moment/moment";
import InsertChartIcon from "@material-ui/icons/InsertChart";
import Table from "../../ui-v2/Table/Table";
import MLResultsGraph_Daily from "../ml_results_graph_daily";
import MLResultsGraph_Intra from "../ml_results_graph_intra";

const MLTable = props => {
    const format = date => moment(date).format("YYYY-MM-DD")
    const formatter = (date, days = 1) => moment(date).add(days, "days").format("YYYY-MM-DD")
    const model = props.selected_model;
    const [graph, setGraph] = useState(null);
    const graphProps = graph ?
        {
            ...graph,
            x_axis: "Time",
            y_axis: "Price",
            model_id: model._id,
            close: () => setGraph(null),
            broker_fee: props.broker_fee,
            starting_amt: props.starting_amt
        } : null;

    const setIntraGraph = (dates, index, title) => {
        const startDate = new Date(dates[0]);
        const endDate = new Date(dates[1]);
        endDate.setHours(endDate.getHours() + dates[0] === dates[1] ? 48 : 24)
        dates = props.date_list.filter(date => moment(date).isBetween(startDate, endDate));
        setGraph({ dates, index, title });
    }
    return (
        <>
            {useMemo(() => (
                graphProps && (
                    graph.type === "daily" ? <MLResultsGraph_Daily {...graphProps} /> : (
                        <MLResultsGraph_Intra {...graphProps} />
                    )
                )
            ), [graph])}
            {useMemo(() => (
                <>
                    <div className='d-flex align-items-center justify-content-between overflow-initial mb-3'>
                        <SelectField
                            fullWidth={false}
                            label='Sort By'
                            value={props.sort_by}
                            name="sort_by"
                            onChange={props.handleChange}
                            style={{ minWidth: '15rem' }}
                            className='me-lg-auto'
                        >
                            <MenuItem value="Newest">Newest</MenuItem>
                            <MenuItem value="Highest Predicted Return">Highest Predicted Return</MenuItem>
                            <MenuItem value="Highest Actual Return">Highest Actual Return</MenuItem>
                        </SelectField>
                        <FilterButton />
                    </div>
                </>
            ), [props.sort_by])}
            {useMemo(() => (
                <>

                    <Table className="product_tool_table">
                        <thead>
                            <tr>
                                {/*
                              <ToolTip
                                title="Would you like to have this model start/stop running?"
                                placement="top"
                              >
                                <td>Action</td>
                              </ToolTip>
                              */}
                                <ToolTip
                                    title="The stock the model uses."
                                    placement="top"
                                >
                                    <td>Stock</td>
                                </ToolTip>
                                <ToolTip
                                    title="The date the model started training from."
                                    placement="top"
                                >
                                    <td>Train Date Start</td>
                                </ToolTip>
                                <ToolTip
                                    title="The date the model finished training."
                                    placement="top"
                                >
                                    <td>Train Date End</td>
                                </ToolTip>
                                <ToolTip
                                    title="The predicted return for how the model performed over the training period."
                                    placement="top"
                                >
                                    <td>
                                        {props.type === "intra_day" && "Daily "}Predicted
                                        Return (train)
                                    </td>
                                </ToolTip>
                                <ToolTip
                                    title="The average return over all of the test periods."
                                    placement="top"
                                >
                                    <td>Actual Return</td>
                                </ToolTip>
                                {model.results_array[0].test_data.map(
                                    (result, index) => (
                                        <Fragment key={index}>
                                            <ToolTip
                                                title="The start date for the test period."
                                                placement="top"
                                            >
                                                <th>Test{index + 1} Date Start</th>
                                            </ToolTip>
                                            <ToolTip
                                                title="The end date for the test period."
                                                placement="top"
                                            >
                                                <th>Test{index + 1} Date End</th>
                                            </ToolTip>
                                            <ToolTip
                                                title="The return over the test period. Intra day models provide the daily average return while Daily models show the return over the whole period. "
                                                placement="top"
                                            >
                                                <th>Test{index + 1} Test Return</th>
                                            </ToolTip>
                                        </Fragment>
                                    )
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {model.results_array.map((result, index) => (
                                <tr key={index}>
                                    {/*
                                <td>
                                  {props.running_ml_models.length > 0 &&
                                    props.running_ml_models.some(
                                      (x) =>
                                        x.selected_index === result.index &&
                                        model._id ===
                                        x.model_id._id &&
                                        x.status === "Active"
                                    ) ? (
                                    <div className='d-flex gap-3'>
                                      <span>Running...</span>
                                      <button
                                        className='btn btn-primary bg-accent-2 small'
                                        onClick={(event) =>
                                          this.handle_auto_ml_run_stop(
                                            event,
                                            "Paused",
                                            result.index,
                                            model.type,
                                            result.predicted_return,
                                            model._id
                                          )
                                        }
                                      >
                                        Stop
                                      </button>
                                    </div>
                                  ) : (
                                    <button
                                      className='btn btn-primary small'
                                      onClick={(event) =>
                                        this.handle_auto_ml_run_stop(
                                          event,
                                          "Active",
                                          result.index,
                                          model.type,
                                          result.predicted_return,
                                          model._id
                                        )
                                      }
                                    >
                                      Start
                                    </button>
                                  )}
                                </td>
                                */}
                                    <td>
                                        {model.stock_symbol}
                                    </td>
                                    {model.type === "daily" ? (
                                        <>
                                            <td>
                                                {formatter(model.training_start_date)}
                                            </td>
                                            <td>
                                                {formatter(model.training_end_date, 2)}
                                            </td>
                                            <td>
                                                {result.predicted_return !== undefined &&
                                                    result.predicted_return.toFixed(2)}
                                                <br />
                                                <InsertChartIcon
                                                    onClick={() => setGraph({
                                                        dates: [
                                                            formatter(model.training_start_date),
                                                            formatter(model.training_end_date, 2)
                                                        ], type: "daily",
                                                        title: "Training Results",
                                                        index: result.index
                                                    })}
                                                />
                                            </td>
                                        </>
                                    ) :
                                        (
                                            <>
                                                <td>
                                                    {formatter(model.training_arr[0])}
                                                </td>
                                                <td>
                                                    {formatter(model.training_arr[model.training_arr.length - 1])}
                                                </td>
                                                <td>
                                                    {result.predicted_return !== undefined &&
                                                        result.predicted_return.toFixed(2)}
                                                    <br />
                                                    <InsertChartIcon
                                                        onClick={() => setIntraGraph(model.training_arr, result.index, "Training Results")}
                                                    />
                                                </td>
                                            </>
                                        )
                                    }

                                    <td>{result.total_actual_return?.toFixed(2)}</td>
                                    {result.test_data.map((test, index) => (
                                        <Fragment key={index}>
                                            <td>
                                                {format(test.test_start_date)}
                                            </td>
                                            <td>
                                                {format(test.test_end_date)}
                                            </td>
                                            {model.type === "daily" ? (
                                                <td>
                                                    {test.actual_return.toFixed(2)}
                                                    <br />
                                                    <InsertChartIcon
                                                        onClick={() => setGraph({
                                                            dates: [format(test.test_start_date), format(test.test_end_date)],
                                                            type: "daily",
                                                            title: "Test Results",
                                                            index: result.index
                                                        })
                                                        }
                                                    />
                                                </td>
                                            ) : (
                                                <td>
                                                    {test.actual_return.toFixed(2)}
                                                    <br />
                                                    <InsertChartIcon
                                                        onClick={() => setIntraGraph(
                                                            [test.test_start_date, test.test_end_date],
                                                            result.index,
                                                            "Test Results"
                                                        )
                                                        }
                                                    />
                                                </td>
                                            )}
                                        </Fragment>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </>
            ), [model])}
        </>
    )
}

export default MLTable;
