import React, { useState, useEffect } from "react"
import InputField from "../../ui-v2/InputField/InputField"
import SelectField from "../../ui-v2/SelectField/SelectField"
import RangePicker from "../../ui/RangePicker"
import PickersUtilsProvider from '../../ui-v2/PickersUtilsProvider/PickersUtilsProvider';
import DatePicker from '../../ui-v2/DatePicker/DatePicker';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment-timezone';
import axios from "axios";
import LineChart from "../../ui-v2/LineChart/LineChart";
import { YAxis } from "recharts";
import Table from "../../ui-v2/Table/Table";

const start_Date = moment().subtract(30, 'days').toDate();
const end_Date = new Date();
// end_Date.setDate(end_Date.getDate() + 7)




export default function StockInformation({ className = "", ...props }) {
    const [state, setState] = useState({
        stockSymbol: "AAPL",
        loading: false,
        startDate: start_Date,
        endDate: end_Date,
        expiration_date: "2023-11-24",
        step_array: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        step: 0,
        data: [],
        chart_data: [],
        ...props
    })

    const { startDate, endDate, stockSymbol, loading, data, expiration_date, step, chart_data, step_array } = state
    const dateValue = `${moment(startDate).format('YYYY-MM-DD')} | ${moment(endDate).format('YYYY-MM-DD')}`
    const updateChartData = (newStep, fetchedData = data) => {
        let temp_chart_data = fetchedData.map(item => ({
            name: item.days_till_expiration,
            [stockSymbol]: item.data[newStep]?.sum_as_percent_of_stock_price
        }));

        setState(prevState => ({ ...prevState, chart_data: temp_chart_data }));
    };
    useEffect(() => {
        if (props.info) {
            props.info.startDate = new Date(props.info.startDate);
            props.info.endDate = new Date(props.info.endDate);
            setState(prevState => ({
                ...prevState,
                ...props.info, // This will overwrite any matching keys in the state with those from info
                // If you need to handle specific properties differently, do it here
            }));
        }
    }, [props.info]);
    useEffect(() => {
        updateChartData(step);
    }, [step]);
    const fetchData = async () => {
        try {
            const payload = {
                stock: state.stockSymbol,
                start_date: moment(state.startDate).format('YYYY-MM-DD'),
                end_date: moment(state.endDate).format('YYYY-MM-DD'),
                expiration_date: state.expiration_date,
            };

            const response = await axios.post('/fetch_historic_option_data', payload);
            let initial_data = response.data;

            let temp_chart_data = initial_data.map(item => ({
                name: item.days_till_expiration,
                [stockSymbol]: item.data[step]?.sum_as_percent_of_stock_price
            }));

            setState(prevState => ({ ...prevState, data: initial_data, loading: false }));
            updateChartData(state.step, initial_data);
        } catch (error) {
            console.error("Fetching data failed:", error);
            setState({ ...state, loading: false });
        }
    };

    const onRun = () => {
        setState({ ...state, loading: true });
        fetchData();
    };
    const handleDateChange = (date) => {
        const userTimezone = moment.tz.guess();
        const convertedDate = moment(date).tz(userTimezone).format('YYYY-MM-DD');
        setState({ ...state, expiration_date: convertedDate });
    };
    return (
        <div className={`${className} d-flex flex-column gap-4`}>
            <div className="d-flex flex-column  gap-2">
                <InputField label="Stock Symbol"
                    value={stockSymbol}
                    name="stockSymbol"
                    className="w-normal"
                    onChange={e => setState({ ...state, stockSymbol: e.target.value })}
                />
                <RangePicker
                    ranges={[{ startDate, endDate }]}
                    onChange={e => setState({ ...state, ...e.selection })}
                    component={({ show }) => (
                        <InputField
                            className="w-normal"
                            name='date_range'
                            label="Filter By Date"
                            onFocus={show}
                            value={dateValue}
                            readOnly
                            onClick={show}
                        />
                    )}
                />
                <PickersUtilsProvider>
                    <DatePicker
                        value={expiration_date}
                        onChange={handleDateChange}
                        label="Expiration Date"
                        format="yyyy-MM-dd"
                        inputVariant="outlined"
                        className="w-normal"
                    />
                </PickersUtilsProvider>

                {/* <InputField label="Expiration Date"
                    value={expiration_date}
                    name="expiration_date"
                    className="w-normal"
                    onChange={e => setState({ ...state, expiration_date: e.target.value })}
                /> */}

                <SelectField
                    title="Step"
                    value={step}
                    name="step"
                    onChange={e => setState({ ...state, step: e.target.value })}
                    label="Step"
                >
                    {step_array.map((type, index) => <MenuItem key={index} value={type}>{type}</MenuItem>)}
                </SelectField>

                <button disabled={loading}
                    onClick={onRun}
                    className={`btn me-auto btn-primary ${loading ? 'progress-btn' : ''}`}
                >{!loading && "Run"}</button>
            </div>
            {stockSymbol && (
                <>
                    <div>
                        <h6 className="mt-0 mb-1">{stockSymbol}</h6>
                        <small className="smaller">{dateValue}</small>
                    </div>
                    {data && (
                        <>
                            <LineChart className="smaller yAxis" data={chart_data}
                                legend={false}
                                dataKeys={[
                                    {
                                        "dataKey": stockSymbol,
                                        "stroke": "#3d25e2",
                                        "activeDot": {
                                            "r": 8
                                        }
                                    },
                                ]}
                            >
                                <YAxis tickFormatter={(value) => `$${value}`} />
                            </LineChart>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Days Till Expiry</th>
                                        <th>Current Stock Price</th>
                                        <th>Call Strike</th>
                                        <th>Call Price</th>
                                        <th>Put Strike</th>
                                        <th>Put Price</th>
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item, index) => (
                                        <tr key={index}>
                                            <td className="py-3">{item.days_till_expiration}</td>
                                            <td>{item.currentStockPrice}</td>
                                            <td>{item.data[step].callStrike}</td>
                                            <td>{item.data[step].call_ask}</td>
                                            <td>{item.data[step].putStrike}</td>
                                            <td>{item.data[step].put_ask}</td>
                                            <td>{item.data[step].sum} ({item.data[step].sum_as_percent_of_stock_price})</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </>
                    )}
                </>
            )}
        </div>
    )
}