import React, { Component, useEffect } from "react";
import { identifyUser, trackEvent } from '../../tiktokTracking';
import axios from "axios";
import toastr from 'toastr'
import ListTile from "../../ui/ListTile/ListTile";
import Model from '../../ui-v2/Modal/Model';
import BoxField from '../../ui-v2/BoxField/BoxField';
import ArrowRightIcon from '../../icons-v2/ArrowRightIcon';
import successImg from '../../assets/purchaseComplete.svg';
import styles from './styles.module.scss';
import Purchase from "../Purchase/Purchase";
import InputField from '../../ui-v2/InputField/InputField';
import cookie from 'react-cookies'

const OrderConfirmation = ({ algo, onRunAlgo, close, purchase, transaction }) => {
  let order = {
    amount: 0,
    tracking: '',
    type: 'SALE',
    number: 0,
  };

// Was used for shareasale
  // useEffect(() => {
  //   // Update the URL with the proper values for your ShareASale tracking pixel
  //   const shareASalePixelURL = `https://shareasale.com/sale.cfm?amount=${transaction.amount.toFixed(2)}&tracking=${transaction.purchase_id}&transtype=sale&merchantID=139893`;
  //   console.log(shareASalePixelURL,transaction)
  //   // Create an image element and set its src attribute to the ShareASale tracking pixel URL
  //   const trackingPixel = new Image(1, 1);
  //   trackingPixel.src = shareASalePixelURL;

  //   // Clean up the tracking pixel on component unmount
  //   return () => {
  //     trackingPixel.src = '';
  //   };
  // }, [algo, onRunAlgo, close, transaction]);

  return (
    <div>
      <Model
        title="Purchase completed"
        closeHandler={close}
        className={styles.root}
        show={true}
        small={true}
      >
        <div className="d-inherit flex-1 flex-column text-center align-items-center gap-inherit">
          <div>
            <img src={successImg} alt="" className="w-100" />
          </div>
          {/* <h2>Thank you for your order!</h2>
          <p>Your order number is: {order.number}</p> */}
          <p className="m-md-0 m-auto">
            You can view this item in My Algos tab. <br /> Contact us if you
            need any help.
          </p>
          <button className="btn btn-primary mb-3 mb-md-0" onClick={onRunAlgo}>
            Open Algo
          </button>
        </div>
      </Model>
    </div>
  );
};

class ConfirmPaymentPopUp extends Component {

  constructor(props) {
    super(props)
    this.state = {
      processing: false,
      successPurchase: false,
      credits: 0,
      apply_credits: false,
      error: '',

      coupon_code: "",
      coupon_applied:false,
      coupon_discount:0,
      coupon_error:false,
      coupon_error_message:"",
      purchase_json:null,
      transaction_json:null
    }
    this.renderContent = this.renderContent.bind(this)
    this.onChange = this.onChange.bind(this)
    this.handleCoupon = this.handleCoupon.bind(this)
  }
  componentDidMount() {
    const loadedCouponCode = cookie.load('coupon_code');
    if (loadedCouponCode) {
      this.setState({ coupon_code: loadedCouponCode },()=>this.handleCoupon());
      
    }
    axios.get('/account_credits').then((response) => {
      this.setState({
        credits: response.data.result
      })
    })
    trackEvent('AddToCart', {
      value: this.props.algo.priceDetails.price,
      currency: 'USD',
      contents: [{
        algo_name: this.props.algo.stockAlgoName,
      }]
    });
    
  }
  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    })
  }
  handleCoupon = () => {
    const couponCode = this.state.coupon_code.trim();
    this.setState({
      processing: true,
      error:''
    })
    axios.post('/apply_coupon', { algo_id: this.props.algo._id, coupon_code: couponCode }).then((response) => {
      if (response.data.status === "success") {
        toastr.success('Succesfully applied coupon!', 'Success')
        this.setState({
          coupon_applied: true,
          coupon_discount: response.data.result.discount_amt,
          coupon_error:false,
          coupon_error_message:""
        })
      } else {
        toastr.error(response.data.status, 'Error')
        this.setState({
          coupon_error:true,
          coupon_error_message:response.data.status
        })
      }
    }).finally(() => {
      this.setState({
        processing: false,
      })
    })
  }


  handleSubscription = () => {

    this.setState({
      processing: true,
      error:''
    })
    axios.post('/create_subscription', { algo_id: this.props.algo._id, coupon_applied:this.state.coupon_applied,coupon_code:this.state.coupon_code }).then((response) => {
      if (response.data.status === "success") {
        toastr.success('Succesfully purchased this algo!', 'Success')
        trackEvent('CompletePayment', {
          value: this.props.algo.priceDetails.price,
          currency: 'USD',
          contents: [{
            algo_name: this.props.algo.stockAlgoName,
          }]})
        this.setState({
          successPurchase: true,
          purchase_json:response.data.purchase,
          transaction_json:response.data.transaction ,
        })
        this.props.onConfirmation()
        let purchase_price = this.state.coupon_applied?this.props.algo.priceDetails.price*(1- (this.state.coupon_discount/100)):this.props.algo.priceDetails.price
        this.props.setPurchaseState(purchase_price)
        // setTimeout(() => {
        //   window.location.reload()
        // }, 1000)
      } else {
        this.setState({
          successPurchase: false,
          error: response.data.error
        })
        toastr.error(response.data.status, 'Error')
      }
    }).finally(() => {
      this.setState({
        processing: false,
      })
    })


  }
  handleOneTimeCost = () => {
    this.setState({
      processing: true
    })
    axios.post('/create_charge', { algo_id: this.props.algo._id, apply_credits: this.state.apply_credits }).then((response) => {
      if (response.data.status === "success") {
        toastr.success('Succesfully purchased this algo!', 'Success')
        this.setState({
          successPurchase: true
        })
        this.props.onConfirmation()
        // setTimeout(() => {
        //   window.location.reload()
        // }, 1000)
      } else {
        toastr.error(response.data.status, 'Error')
      }
    }).finally(() => {
      this.setState({
        processing: false,
      })
    })
  }


  onClick = async () => {
    if (this.props.price !== 0) {
      if (this.props.algo.priceDetails.type === "Monthly Subscription") {
        this.handleSubscription()
      } else {
        this.handleOneTimeCost()
      }
    } else {
      this.setState({
        processing: true,
      })
      const userId = localStorage.getItem("userId");
      const savePurchaseAlgoData = {
        userId: userId,
        algoId: this.props.algo._id,
      };
      axios.post("/myPurchases/save", savePurchaseAlgoData)
        .then((response) => {
          // toastr.success('Successfuly added to your dashboard!', 'Success')
   

          this.props.onConfirmation()
          window.location.reload()
        }).finally(() => {
          this.setState({
            processing: false,
            successPurchase: true
          })
        });
    }

  };
  handleToggle = e => {
    let name = e.target.name
    let value = e.target.value

    this.setState({
      [name]: value === "true" ? true : false,
    })
  }

 

  renderContent() {
    const { algo, purchase_now, trial } = this.props
    const { successPurchase, processing } = this.state
    const isFree = algo.priceDetails.type === 'Free';
    const successModel = this.state.purchase_json && this.state.transaction_json ? (
      <OrderConfirmation
        algo={this.props.algo}
        onRunAlgo={this.props.onRunAlgo}
        close={this.props.close}
        purchase={this.state.purchase_json}
        transaction={this.state.transaction_json}
      />
    ) : null;
    if (isFree) {
      return successPurchase ? successModel : (
        <Model
          isNormal={true} show={true}
          title='Purchase'
          closeHandler={this.props.close}
          actions={() => successPurchase ?
            <button className='btn btn-primary flex-1' onClick={this.props.onRunAlgo}>Lets rock & roll</button> :
            <button
              disabled={processing}
              className={`btn btn-primary w-100 w-sm-initial ${processing ? "progress-btn" : ''}`}
              onClick={this.onClick}
            >
              Get <ArrowRightIcon />
            </button>
          }
        >
          <ListTile title={algo.stockAlgoName}
            imageURL={algo.image_url}
            subtitle="Free"
          />
        </Model>
      );
    } else {
      let label = ''
      let price_text = this.state.coupon_applied?`$${(algo.priceDetails.price* (1-(this.state.coupon_discount/100))).toFixed(2)}`:`$${algo.priceDetails.price.toFixed(2)}`;
      if (algo.priceDetails.type === "Monthly Subscription") {
        label = `You will be billed for ${price_text} (USD) automatically every month until you cancel the subscription.`
        if (algo.priceDetails.offerFreeTrial) {
          let days = algo.priceDetails.number_free_days
          if (trial !== 'forever' && trial > 0) {
            days = trial
          }
          label = `You will have ${days} days to use this algo. Then you will be billed ${price_text} (USD) automatically every month until you cancel the subscription.`
          if (purchase_now || trial < 0) {
            label = `You will be billed ${price_text} (USD) automatically every month until you cancel the subscription.`
          }
        }
      } else {
        label = `You will be billed a one time fee of $${algo.priceDetails.price.toFixed(2)}(USD).`
        if (algo.priceDetails.offerFreeTrial) {
          let days = algo.priceDetails.number_free_days
          if (trial !== 'forever' && trial > 0) {
            days = trial
          }
          label = `You will have ${days} days to use this algo. Then you will be pay a one time fee of ${price_text}(USD).`
          if (purchase_now || trial < 0) {
            label = `You will be pay a one time fee of ${price_text}(USD).`
          }
        }
      }
      return successPurchase ? successModel : (
        <Model show={true}
          title={this.props.title || 'You’re about to add this to your dashboard.'}
          closeHandler={this.props.close}
          actions={() => (
            <>
              <button disabled={processing} className="btn btn-text" onClick={this.props.close}>Cancel</button>
              <button disabled={processing} className={`btn btn-primary ${processing ? "progress-btn" : ''}`} onClick={this.onClick}>Confirm</button>
            </>
          )}
        >
          <div>
            <h5 className="mt-0 mb-2">Item Details</h5>
            <ListTile title={algo.stockAlgoName}
              imageURL={algo.image_url}
              subtitle={algo.userId.name}
              trailingTitle={`$${parseFloat(algo.priceDetails.price).toFixed(2)}`}
              trailingSubtitle={algo.priceDetails.type.toUpperCase()}
            />
          </div>
          <Purchase showPaymentPopUp={this.props.showPaymentPopUp} />
          <div>
                <h5 className='mt-0 mb-2'>Have a coupon?</h5>
                <InputField label="Coupon Code" onChange={this.onChange} name="coupon_code" value={this.state.coupon_code} endAdornment={<button onClick={this.handleCoupon} className='btn-text btn'>Apply</button>} />
                <p className='text-danger'>{this.state.coupon_error}</p>
            </div>

          <p className='m-0' style={{ maxWidth: '30rem' }}>{label}</p>
          {this.state.coupon_applied && <p style={{color:"green"}}>COUPON APPLIED! {this.state.coupon_discount}% OFF. PRICE WAS: ${algo.priceDetails.price}</p>}
          
          {(!isFree && this.state.credits > 0 && algo.priceDetails.type === "Direct Charge") && <BoxField name='apply_credits' type='radio' defaultChecked={this.state.apply_credits} value={this.state.apply_credits} label={"Would you like to apply credits to this purchase?"} onChange={this.handleToggle} />}
          {(algo.priceDetails.type === "Monthly Subscription") && <p>*Any credits that you have/earn will automatically be applied to your subscription.</p>}
          {this.state.error.length > 0 && <p style={{color:"red"}} className='text-danger'>{this.state.error}. Contact admin@stockalgos.com if the problem persists.</p>}
        </Model>
      )
    }
  }

  render() {
    return this.renderContent();
  }
}

export default ConfirmPaymentPopUp;
