const data = [
    {
        "name": "March 18, 2022",
        "aapl": 0,
        "tsla": 0
    },
    {
        "name": "March 21, 2022",
        "aapl": 0.8537681632825576,
        "tsla": 1.7417861627290077
    },
    {
        "name": "March 22, 2022",
        "aapl": 2.08005919458038,
        "tsla": 7.905250893918284
    },
    {
        "name": "March 23, 2022",
        "aapl": 0.8233621267412851,
        "tsla": 0.5161074773357203
    },
    {
        "name": "March 24, 2022",
        "aapl": 2.2677867582721,
        "tsla": 1.4823190862215185
    },
    {
        "name": "March 25, 2022",
        "aapl": 0.3734095328668496,
        "tsla": -0.3234937721905076
    },
    {
        "name": "March 28, 2022",
        "aapl": 0.5036658625019188,
        "tsla": 8.03450781631678
    },
    {
        "name": "March 29, 2022",
        "aapl": 1.9134401396318759,
        "tsla": 0.7079773813665352
    },
    {
        "name": "March 30, 2022",
        "aapl": -0.6649547124794146,
        "tsla": -0.507467125697526
    },
    {
        "name": "March 31, 2022",
        "aapl": -1.7775794166039334,
        "tsla": -1.498186834415191
    },
    {
        "name": "April 1, 2022",
        "aapl": -0.17181318268247636,
        "tsla": 0.6486627835633911
    },
    {
        "name": "April 4, 2022",
        "aapl": 2.3693442988852453,
        "tsla": 5.611335795817242
    },
    {
        "name": "April 5, 2022",
        "aapl": -1.894196347296603,
        "tsla": -4.730886840816678
    },
    {
        "name": "April 6, 2022",
        "aapl": -1.845079422427504,
        "tsla": -4.16949210848476
    },
    {
        "name": "April 7, 2022",
        "aapl": 0.18040912319839314,
        "tsla": 1.099678692054786
    },
    {
        "name": "April 8, 2022",
        "aapl": -1.19089288480824,
        "tsla": -3.0049391539929644
    },
    {
        "name": "April 11, 2022",
        "aapl": -2.551588042838224,
        "tsla": -4.8328113860965205
    },
    {
        "name": "April 12, 2022",
        "aapl": 1.1523402714932045,
        "tsla": 1.129181301839552
    },
    {
        "name": "April 13, 2022",
        "aapl": 1.634253808081745,
        "tsla": 3.5888325213374634
    },
    {
        "name": "April 14, 2022",
        "aapl": -2.9988269835267616,
        "tsla": -3.65523197890799
    },
    {
        "name": "April 18, 2022",
        "aapl": -0.1330909367271894,
        "tsla": 1.9583734010152305
    }
]

export default data;