import React from "react";
import Model from "../../ui-v2/Modal/Model";
import Main from "./Main";

const StripePaymentPopup = (props) => (
  <Model
    title="Payment Details"
    closeHandler={props.close}
    show={true}
    isNormal={true}
  >
    <Main {...props} />
  </Model >
)

export default StripePaymentPopup;