import React from "react";
import { render } from "react-snapshot";
import "./scss-v2/index.scss";
import Router from "./Router";
import { unregister } from "./registerServiceWorker";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Router as BroweserRouter } from "react-router-dom";
import "react-app-polyfill/ie9"; // For IE 9-11 support
import "react-app-polyfill/ie11"; // For IE 11 support
import history from "./history";

const { NODE_ENV } = process.env;

// const stripePromise = loadStripe("pk_test_6pRNASCoBOKtIshFeQd4XMUh");
const pk_key = NODE_ENV === 'production' ? "pk_live_51HUMMvAk9ADU60Eevz4W1m06zhIuuZWgP2W0xafULGcIM0deOyZ2u24TncZfV8Nr2UekkfwIWfxsizNxAmqnGZ7w000OgK3eAG" : "pk_test_51HUMMvAk9ADU60EekCKWoNbymsjXKbqnuYvMKdL8M5iVXspzh0TLbUK7rMka5U3Ui1ouBFV3ZIbomlomyCxITBuz005PjIP9k0";
// const pk_test = process.env.NODE_ENV === 'production'?"pk_test_51HUMMvAk9ADU60EekCKWoNbymsjXKbqnuYvMKdL8M5iVXspzh0TLbUK7rMka5U3Ui1ouBFV3ZIbomlomyCxITBuz005PjIP9k0":"pk_test_51HUMMvAk9ADU60EekCKWoNbymsjXKbqnuYvMKdL8M5iVXspzh0TLbUK7rMka5U3Ui1ouBFV3ZIbomlomyCxITBuz005PjIP9k0"

const stripePromise = loadStripe(pk_key);


render(

  <BroweserRouter history={history}>
    <Elements stripe={stripePromise}>
      <Router />
    </Elements>
  </BroweserRouter>,
  document.getElementById("root")
);

unregister();
