import React from 'react';
import axios from 'axios'
import URL from 'url-parse';
import moment from "moment";
import withAlgoProductToolsLayout from '../../hoc-v2/withAlgoProductToolsLayout/withAlgoProductToolsLayout';
import OptionsBestFilter from './OptionsBestFilter';
import ToolSymbol from '../../components-v2/ToolSymbol/ToolSymbol';
import FilterButton from '../../components-v2/AlgoHoldingCommonTools/FilterButton';


class Options extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: new URL(window.location.href, true),
      // stock_search: ["aapl", "spxl", "tsla", "amzn", "msft", "goog", "meta", "v", "bcs", "nflx", "khc", "td", "ge", "f", "bac", "amd", "ua", "znga", "kgc", "mat", "cs", "amcr", "MMM", "ABT", "spot", "cldr", "bili", "tlry", "roku", "ba", "ge", "ccl", "mu"],//"spxl"

      stock_search: ["aapl"],
      stock_data: [],
      current_stock_price: 0,
      date_list: [],
      date_value: "all",
      initialized: true,
      min_traded_volume: 1,
      minutes_till_execute: 1,
      ledger: [],
      lastBuy: "",
      signal: "",
      min_bid: 0,
      max_bid: 10000,

      max_cost_to_execute: 3000000,
      minimum_profit_to_execute: -1000000,
      traded_within_number_days: 100,

      start: false,
      end: true,
      sell_ran: true,

      volume_to_trade: 1,
      activate_live_trading: false,
      authenticated: false,

      selected_filter_best_option: "largest_implied_volatility_diff",
      search_param: "pg",
      stock_search_number: 5,
      loading: false,
      loading_trader: false,
      type: "calls",

      fetch_error_msg: "No results were found, please try again.",
      show_fetch_error: false,
      risk_free_rate: 0,

      transaction_type: "Buy",
      minimum_days_between_contracts: 0,
      minimum_amount_of_days_till_expiry: 20,
      maximum_amount_of_days_till_expiry: 1500,
      pl_price_change_as_percent: 5,

      compare_type: "subsequent"



    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }
  load_stock_list = (list) => {
    this.setState({
      stock_search: list
    })
  }

  handleStockChange = (e) => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value.split(',')
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.symbol_list !== this.props.symbol_list) {
      this.setState({
        stock_search: this.props.symbol_list
      })
    }
  }



  confirm_authentication = () => {
    this.setState({
      authenticated: true
    })

  }
  handleStartDateChange = (date) => {
    this.setState({
      start_date: moment(date).format('YYYY-MM-DD')
    })
  }
  handleEndDateChange = (date) => {
    this.setState({
      end_date: moment(date).format('YYYY-MM-DD')
    })
  }

  find_stock_symbols = (e) => {
    e.preventDefault()
    axios.post('/find_stocks', {
      search_param: this.state.search_param,
      stock_search_number: this.state.stock_search_number
    }).then((response) => {
      if (response) {
        this.setState({})
      }
    })
  }

  fetchInitialData = (e) => {
    this.setState({
      loading: true,
    })
    axios.post('/fetch_initial_options_data', { stock_search: this.state.stock_search }).then((response) => {
      if (response.data.status === "success") {
        this.setState({
          date_list: response.data.result,
          initialized: true,
          loading: false,
          show_fetch_error: false
        })
      } else {
        this.setState({
          show_fetch_error: true,
          initialized: false,
          loading: false,
        })

      }

    })
  }


  endTrade = () => {

    this.setState({
      start: false,
      end: true,
      loading_trader: false

    })

  }

  handleCheck = (e) => {
    this.setState({
      [e.target.name]: e.target.checked
    })
  }

  startTrading = () => {
    this.setState({
      start: true,
      end: false,
      loading_trader: true

    })
    axios.post('/options_strike_comparer',
      {
        stock_search: this.state.stock_search,
        date_value: this.state.date_value,
        min_traded_volume: this.state.min_traded_volume,
        min_bid: this.state.min_bid,
        max_bid: this.state.max_bid,
        selected_filter_best_option: this.state.selected_filter_best_option,
        max_cost_to_execute: this.state.max_cost_to_execute,
        minimum_profit_to_execute: this.state.minimum_profit_to_execute,
        traded_within_number_days: this.state.traded_within_number_days,
        risk_free_rate: this.state.risk_free_rate,
        transaction_type: this.state.transaction_type,
        type: this.state.type,
        minimum_days_between_contracts: this.state.minimum_days_between_contracts,
        minimum_amount_of_days_till_expiry: this.state.minimum_amount_of_days_till_expiry,
        maximum_amount_of_days_till_expiry: this.state.maximum_amount_of_days_till_expiry,
        pl_price_change_as_percent: this.state.pl_price_change_as_percent,
        compare_type: this.state.compare_type

      }).then((response) => {
        if (response.data.status === "success") {
          this.setState({
            ledger: response.data.result,
            sell_ran: false,
            loading_trader: false,

          }
          )
        } else {
          this.setState({
            start: false,
            end: true,
            initialized: false,
            loading_trader: false,
            show_fetch_error: true,
            ledger: [],
            date_list: [],

          })
        }
      })

  }
  componentDidMount() {
    if (this.props.state) return this.setState(this.props.state);
    axios.get('/fetch_risk_free_rate').then((response) => {
      this.setState({
        risk_free_rate: response.data.result
      })
    })
  }


  render() {

    let filter_best_option_list = [
      { "Smallest bid Diff": "small_bid_diff" },
      { "Largest bid Diff": "large_bid_diff" },

      { "Smallest ask Diff": "small_ask_diff" },
      { "Largest ask Diff": "large_ask_diff" },

      { "Smallest bid ask Diff": "small_bid_ask_diff" },
      { "Largest bid ask Diff": "large_bid_ask_diff" },
      { "ROI": "roi" },
      { "Annualized Roi": "annualized_roi" },
      { "Amount Made": "hypothetical_amt_made" },
      { "Largest IV Diff": "largest_implied_volatility_diff" }
    ]
    //   Volume Actives: va
    // Dollar Gainers: dg
    // Dollar Losers: dl
    // Dollar Volume: dv
    // Percent Gainers: pg
    // let stock_search = ["va", "dg", "dl", "dv", "pg"]
    this.props.getState && this.props.getState(this.state);
    this.props.setResponsive(this.state.ledger.length > 0);
    return (
      <>
        <OptionsBestFilter {...this.state}
          handleStockChange={this.handleStockChange}
          fetchInitialData={this.fetchInitialData}
          unIntializedHanlder={() => this.setState({ date_list: [] })}
          handleChange={this.handleChange}
          filter_best_option_list={filter_best_option_list}
          startTrading={this.startTrading}
          handleCheck={this.handleCheck}
          load_stock_list={this.load_stock_list}

        />
        {/* Only works for stocks listed on NYSE */}
        {this.state.ledger.length > 0 &&
          <div className='p-lg-5 p-md-4 p-3'>
            <div className='d-flex justify-content-between align-items-center mb-3'>
              <small className='text-accent-4'>Total Results {this.state.ledger.length}</small>
              <FilterButton />
            </div>
            <div className='table-wrapper product_tool_table'>
              <div>
                <table>
                  <thead>
                    <tr>
                      <th><div>Expiration Date</div></th>
                      <th>Stock Symbol</th>
                      <th>Current Stock Price</th>
                      <th>Strike</th>
                      <th>Bid</th>
                      <th>Ask</th>
                      <th>Last Price</th>
                      <th>Implied Vol</th>
                      {/*
                      <th>Bid Diff</th>
                      <th>Ask Diff</th>
                      */}
                      <th>Implied Vol Diff</th>
                      <th>Bid Ask Diff</th>
                      <th>Time Diff</th>
                      <th>ROI</th>
                      <th>ROI (breakdown)</th>
                      <th>Annualized</th>
                      <th>~Amount Made</th>
                      <th>Breakeven</th>
                      <th>Low End Strike -{this.state.pl_price_change_as_percent}%</th>
                      <th>High End Strike +{this.state.pl_price_change_as_percent}%</th>



                    </tr>
                  </thead>
                  <tbody>
                    {this.state.ledger.map((option, index) => (
                      <tr key={index}>
                        <td title='Expiration Date'>{option.expiry_date}<br />{option.after_option.expiry_date}</td>
                        <td title='Stock Symbol'><ToolSymbol title={option.stock_symb} /></td>
                        <td title='Current Stock Price'>{option.current_stock_price.toFixed(2)}</td>
                        <td title='Strike'>{option.strike}<br />{option.after_option.strike}</td>
                        <td title='Bid'>{option.bid?.toFixed(2)}<br />{option.after_option.bid}</td>
                        <td title='Ask'>{option.ask?.toFixed(2)}<br />{option.after_option.ask}</td>
                        <td title='Last Price'>{option.last_price?.toFixed(2)}<br />{option.after_option.last_price}</td>
                        <td title='Implied Volatility'>{option.implied_volatility?.toFixed(2)}<br />{option.after_option.implied_volatility.toFixed(2)}</td>
                        {/*
                        <td title='Bid Diff'>{option.bid_diff?.toFixed(2)}</td>
                        <td title='Ask Diff'>{option.ask_diff?.toFixed(2)}</td>
                        */}
                        <td title='Implied Volatility Diff'>{option.implied_volatility_diff?.toFixed(2)}</td>
                        <td title='Bid Ask Diff'>{option.bid_ask_diff?.toFixed(2)}</td>
                        <td title='Time Diff'>{option.time_diff?.toFixed(2)}</td>
                        <td title='ROI'>{option.roi?.toFixed(2)}</td>
                        <td title='ROI'>Sell:{option.roi_obj.exp_1} @ ${option.roi_obj.re_sell}<br />Buy:{option.roi_obj.exp_2} @ ${option.roi_obj.re_buy}</td>
                        <td title='Annualized ROI'>{option.annualized_roi?.toFixed(2)}</td>
                        <td title='Amount Made'>{option.hypothetical_amt_made?.toFixed(2)}</td>
                        <td title='Breakeven'>Price {this.state.type === "calls" ? "drops" : "increases"} by{option.higher_diff.toFixed(2)}</td>
                        <td title='Low End'>{option.low_end_obj.strike} ({option.low_end_obj.roi.toFixed(2)})<br />Sell:{option.low_end_obj.exp_1} @ ${option.low_end_obj.re_sell}<br />Buy:{option.low_end_obj.exp_2} @ ${option.low_end_obj.re_buy} </td>
                        <td title='High End'>{option.high_end_obj.strike} ({option.high_end_obj.roi.toFixed(2)})<br />Sell:{option.high_end_obj.exp_1} @ ${option.high_end_obj.re_sell}<br />Buy:{option.high_end_obj.exp_2} @ ${option.high_end_obj.re_buy} </td>



                      </tr>
                    ))}
                  </tbody>
                </table>

              </div>
            </div>
          </div>
        }
      </>
    )
  }
}

export default withAlgoProductToolsLayout(Options, 'Options Striker');
