import React from 'react';
import axios from 'axios'
import URL from 'url-parse';
import moment from "moment";
import withAlgoProductToolsLayout from '../../hoc-v2/withAlgoProductToolsLayout/withAlgoProductToolsLayout';
import Pagination from '../../ui-v2/Pagination/Pagination';
import Filter from '../../components-v2/AlgoHoldingCommonTools/SideFilterGov';
import StockTable from './StockTable';
import { Link } from 'react-router-dom';
import TipCard from '../../ui-v2/TipCard/TipCard';

class Government extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: new URL(window.location.href, true),
      stock_data: [],
      sort_type: ["Date", "Number of transactions", "Number of participants", "Most Value"],
      period: "All transactions",
      period_type: ["All transactions", "Purchase transactions", "Sale transactions"],
      selected_type: "Date",
      orig_stock_data: [],
      scraping: false,
      created: false,
      page: 0,
      totalLength: 0,
      date: 'All',
      dates: ['All'],
      done: false,
      manageMode: false,
      selectionRange: {
        startDate: new Date(moment().subtract(120, 'days')),
        endDate: new Date(moment()),
        key: 'selection',
      },
      
      rangeView: false,
      process: false,
      stock_search: this.props.symbols,
      loadingTable: false,
      no_result: false,
      unique_date: "",
      graph_title: "",
      graph_subtitle: "",
      number_of_days_add: 2,
      number_of_days_sub: 2,
      tableView: 'grouped_data'
    }
    this.handlePageClick = this.handlePageClick.bind(this)
    this.getData = this.getData.bind(this)
    this.handleRangeSelect = this.handleRangeSelect.bind(this)
    this.changeTableView = this.changeTableView.bind(this)
  }

  changeTableView(e) {
    this.setState({
      tableView: e.target.value
    }, () => {
      this.getData()
    })
  }

  handleRangeSelect(e) {
    this.setState({
      selectionRange: e.selection,
      rangeView: false
    }, () => {
      this.getData()
    })
  }

  componentDidMount() {
    if (this.props.state) return this.setState(this.props.state);
    this.getData();
  }

  initializeDateRange = () => {
    this.setState({
      rangeView: true
    }, () => {
      document.addEventListener('mousedown', this.handleClick, false)
    })


  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false)
  }
  handleClick = (e) => {
    if (this.node !== null && this.node.contains(e.target)) {
      return
    } else {
      this.handleClickOutside();
    }
  }
  handleClickOutside = () => {
    this.setState({
      rangeView: false
    })
  }

  getData() {
    const { page, selected_type, period, selectionRange, stock_search, tableView } = this.state
    const json = {
      filter: period,
      page: page,
      sort: selected_type,
      range: selectionRange,
      stock_search: stock_search.split(','),
      tableView: tableView
    }
    this.setState({
      stock_data: [],
      process: true,
      loadingTable: true,
      no_result: false
    })
    axios.post('/get_government_data', {
      ...json
    }).then((response) => {

      const res = response.data
      if (res.success) {
        this.setState({
          stock_data: res.rlt,
          dates: res.dates,
          totalLength: res.total
        })
        if (res.rlt.length === 0) {
          this.setState({
            no_result: true
          })
        }
      }
    }).finally(() => {
      this.setState({
        process: false,
        loadingTable: false,
      })
    })
  }

  handlePageClick(data) {
    this.setState({
      page: data.selected
    })
    setTimeout(() => {
      this.getData()
    }, 100)
  }
  handleChange = (e) => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleSortChange = (e) => {
    let val = e.target.value
    this.setState({
      selected_type: val,
      page: 0,
    })
    setTimeout(() => {
      this.getData()
    }, 100)
  }

  handleFilterChange = (e) => {
    let { name, value } = e.target
    this.setState({
      [name]: value,
      page: 0,
    })
    setTimeout(() => {
      this.getData()
    }, 100)
  }

  handleStockChange = (e, values) => {
    this.setState({
      [e.target.name]: values?.join(',') || e.target.value,
      page: 0,
    }, () => {
      setTimeout(() => {
        this.getData()
      }, 100)
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.symbol_list !== this.props.symbol_list) {
      this.setState({
        stock_search: this.props.symbol_list
      },() => {
        this.getData()
      })
    }
  }

  render() {
    const { loadingTable, totalLength, scraping, page, period_type, sort_type } = this.state
    const tipProps = this.props.tipProps;
    const stepNo = tipProps.stepNo;
    this.props.getState && this.props.getState(this.state);
    return (
      <>
        <TipCard id='tip-1'
          totalSteps={3}
          activeStep={1}
          {...tipProps}
          className='mt-3 me-0'
        >Hover over an item for an explanation of its function.</TipCard>
        <TipCard id='tip-2'
          {...tipProps}
          totalSteps={3}
          activeStep={2}
        >Filter the data based on your desired criteria (ie. by date, by stock, by transaction type, etc.). View the data as grouped by company, or by individual transactions.</TipCard>
        <TipCard id='tip-3'
          {...tipProps}
          totalSteps={3}
          activeStep={3}
        >Opt in for email signals so you can stay informed and never miss a trade. <Link to='/' className='btn btn-text text-surface-1'>Learn how to use</Link></TipCard>
        
        <Filter
          {...this.state} {...this.props}
          changeTableView={this.changeTableView}
          handleFilterChange={this.handleFilterChange}
          handleSortChange={this.handleSortChange}
          handleStockChange={this.handleStockChange}
          handleRangeSelect={this.handleRangeSelect}
          initializeDateRange={this.initializeDateRange}
          stepNo={stepNo}
        />

        <div className='p-lg-5 p-3 overflow-hidden d-flex flex-column flex-1'>
          {
            scraping &&
            <span>Now it's scraping and update Database. It will takes 2 - 3 minutes.</span>
          }
          {(scraping || loadingTable) && <div className='bar-loader mb-3' />}
          <StockTable
            {...this.state}
            stepNo={stepNo}
          />
          <Pagination
            pageCount={Math.ceil(totalLength / 10)}
            initialPage={page}
            forcePage={page}
            onPageChange={this.handlePageClick}
            className='mt-3'
          />
        </div>
      </>
    )
  }
}


export default withAlgoProductToolsLayout(Government, 'Government Awarded Contracts', { showTips: true });
