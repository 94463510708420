import React, { useState } from 'react';
import ArrowRightIcon from "../../../icons-v2/ArrowRightIcon";
import Model from "../../../ui-v2/Modal/Model";
import Pagination from "../../../ui-v2/Pagination/Pagination";
import Table from "../../../ui-v2/Table/Table";
import User from "../User/User";
import styles from './TopLeaderBoards.module.scss';
import useGet from '../../../hooks/useGet';
import Follow from '../Follow';
import SelectV3, { Option } from '../../../ui-v2/SelectV3';

const UserBoard = props => (
    <tr>
        <td>{props.position}</td>
        <td><User isLoggedIn={true} {...props.userId} /></td>
        <td>{props.guess_accuracy?.toFixed(2)}%</td>
        <td><Follow _id={props.userId._id} is_following={props.userId.is_following} /></td>
    </tr>
)

const Main = ({ closeHandler, ...props }) => {
    const { data, onParamsChange, params } = useGet('/fetch_suggested_followers', { limit: 10, page: 1, show: props.show });
    return (
        <Model
            closeHandler={closeHandler}
            show={true}
            isNormal={true}
            title='Leaderboard'
        >
            <SelectV3
                value={params.show}
                className='me-auto text-accent-4' onChange={e => onParamsChange({ show: e.target.value })}
            >
                <Option value='ALL'>All</Option>
                <Option value='MONTH'>Month</Option>
                <Option value='WEEK'>Week</Option>
            </SelectV3>
            <div className={`${styles.wrapper} d-grid`}>
                {data && (
                    <>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Pos.</th>
                                    <th>
                                        <div>User</div>
                                    </th>
                                    <th>Accuracy</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody className={styles.wrapper}>
                                {data.results.map(item => <UserBoard {...item} key={item._id} />)}
                            </tbody>
                        </Table>
                        <div className="d-flex justify-content-center">
                            <Pagination
                                pageCount={Math.ceil(data.total / 10)}
                                initialPage={0}
                                onPageChange={page => onParamsChange({ page: page.selected + 1 })}
                                className={`small ${styles.pagination}`}
                            />
                        </div>
                    </>
                )}
            </div>
        </Model>
    )
}

const TopLeaderBoards = ({ title = 'View all', className = '', ...props }) => {
    const [open, setOpen] = useState(false)
    return (
        <>
            <button type="button"
                onClick={() => props.isLoggedIn ? setOpen(true) : props.history.push({ hash: 'register' })}
                className={`btn btn-text ${className}`}
            >
                {title} <ArrowRightIcon />
            </button>
            {open && <Main {...props} closeHandler={() => setOpen(false)} />}
        </>
    )
}

export default TopLeaderBoards;
