import React from 'react';
import SideFilter from '../../components-v2/SideFilter/SideFilter';
import WatchListImport from '../../components-v2/AlgoHoldingCommonTools/watchlist_import';
// import InputField from '../../ui-v2/InputField/InputField';
import BoxField from '../../ui-v2/BoxField/BoxField';
// import SelectField from '../../ui-v2/SelectField/SelectField';
// import MenuItem from '@material-ui/core/MenuItem';
import Search from '../../ui-v2/Search/Search';

const Filters = props => {
    
    return (
        <SideFilter>
            <div className='d-flex gap-2 justify-content-between align-items-center flex-wrap'>
                <small>Data Filters</small>
                <WatchListImport handleStockChange={props.handleStockChange} algo={props.algo} />
                <Search
                    fullWidth
                    name='stock_search'
                    label="Search by Stock"
                    onChange={props.handleStockChange}
                    value={props.stock_search}
                />
                <Search
                    fullWidth
                    label="Search by Super Investor"
                    value={props.super_investor_search}
                    name='super_investor_search'
                    onChange={props.handleStockChange}
                />
                
            </div>
            <div>
                <small className='mb-1'>Table View</small>
                <BoxField
                    label="By Stock"
                    type='radio'
                    name='data-representation'
                    value="stock"
                    checked={props.tableView === "stock"}
                    onChange={props.changeTableView}
                />
                <BoxField
                    label="By Superinvestors"
                    type='radio'
                    className='my-2 d-block'
                    name='data-representation'
                    value="super_investor"
                    checked={props.tableView === "super_investor"}
                    onChange={props.changeTableView}
                />
                
            </div>
            {/* <SelectField label="Sort Filters">
                <MenuItem value="All">All</MenuItem>
            </SelectField> */}
        </SideFilter>
    )
}

export default Filters;