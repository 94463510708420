import React from 'react';
import axios from 'axios'
import URL from 'url-parse';
import moment from "moment";
import withAlgoProductToolsLayout from '../../hoc-v2/withAlgoProductToolsLayout/withAlgoProductToolsLayout';
import Level2Filter from './Level2Filter';
import StockItem from './StockItem';
import ChevronBottom from '../../icons-v2/ChevronBottom';
import FilterButton from '../../components-v2/AlgoHoldingCommonTools/FilterButton';

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
class Level2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: new URL(window.location.href, true),
      // stock_search: ["aapl", "spxl", "tsla", "amzn", "msft", "goog", "meta", "v", "bcs", "nflx", "khc", "td", "ge", "f", "bac", "amd", "ua", "znga", "kgc", "mat", "cs", "amcr", "MMM", "ABT", "spot", "cldr", "bili", "tlry", "roku", "ba", "ge", "ccl", "mu"],//"spxl"

      stocks: ["aapl", "goog", "amzn"],
      ledger: [],
      initialized: true,

      lower_range: 3,
      upper_range: 3,
      selected_filter_best_option: "bullish",
      loaded_list: "",
      started: false




    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleStockChange = (e) => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value.split(',')
    })
  }

  load_stock_list = (list) => {
    this.setState({
      stocks: list
    })
  }




  handleCheck = (e) => {
    this.setState({
      [e.target.name]: e.target.checked
    })
  }

  componentDidMount() {
    axios.get('/fetch_risk_free_rate').then((response) => {
      this.setState({
        risk_free_rate: response.data.result
      })
    })
  }
  handle_run = () => {
    this.setState({
      started: true
    }, () => {
      this.run_func()
    })
  }

  run_func = () => {

    if (this.state.started) {
      axios.post("/get_level_two", { ...this.state }).then((response) => {
        this.setState({
          ledger: response.data.results,
        }, async () => {
          await delay(60000)
          this.run_func()
        })
      });

    }
  }
  stop_func = () => {
    this.setState({
      started: false
    })
  }

  render() {

    let filter_best_option_list = [
      { "Most Bullish": "bullish" },
      { "Most Bearish": "bearish" }
    ]

    this.props.setResponsive(this.state.ledger.length > 0);

    return (
      <>
        <Level2Filter {...this.state}
          handleStockChange={this.handleStockChange}

          unIntializedHanlder={() => this.setState({ initialized: false, date_list: [] })}
          handleChange={this.handleChange}
          filter_best_option_list={filter_best_option_list}
          run_func={this.handle_run}
          handleCheck={this.handleCheck}
          load_stock_list={this.load_stock_list}
          stop_func={this.stop_func}
          isDataFetched={this.state.ledger.length > 0}

        />
        {/* Only works for stocks listed on NYSE */}
        {this.state.ledger.length > 0 &&
          <div>
            <div className='d-flex justify-content-between'>
              <small className='text-accent-4'>Total Results {this.state.ledger.length}</small>
              <FilterButton />
            </div>
            <div className='table-wrapper product_tool_table p-md-4 p-3 p-lg-5'>
              <div>
                <table>
                  <thead>
                    <tr>
                      <th>Show Breakdown</th>
                      <th>Stock Symbol</th>
                      <th>Current Stock Price</th>
                      <th>Bid Vol</th>
                      <th>Strongest Bid Price</th>
                      <th>Ask Vol</th>
                      <th>Strongest Ask Price</th>
                      <th>Ratio (bid/ask)</th>


                    </tr>
                  </thead>
                  <tbody>
                    {this.state.ledger.map((option) => (
                      <>
                        <StockItem option={option} bids={option.bid_breakdown} asks={option.ask_breakdown} participants={option.participants} long_term_participants={option.long_term_participants} />
                      </>
                    ))}
                  </tbody>
                </table>

              </div>
            </div>
          </div>
        }
      </>
    )
  }
}

export default withAlgoProductToolsLayout(Level2, 'Level Two Screener');
