import React, { useState, createContext } from "react";
import { useContext } from "react";
import { useHistory } from "react-router-dom";


const SymbolContextContext = createContext(({
    symbols: '',
    symbolsHandler: (symbols = [], pathname = '') => { },
    onClear: () => { }
}));


export const SymbolContextProvider = ({ children }) => {
    const [symbols, setSymbols] = useState('');
    const history = useHistory();
    const symbolsHandler = (symbols, algo) => {
        setSymbols(symbols.map(item => item.ticker).join(','));
        history.push(`/algo_page?algo=${algo.url_link}&algo_id=${algo._id}`);
    }
    return (
        <SymbolContextContext.Provider value={{
            symbols,
            symbolsHandler,
            onClear: () => setSymbols(''),
            history,
        }}>
            {children}
        </SymbolContextContext.Provider>
    )
}

export const useSymbols = () => useContext(SymbolContextContext);