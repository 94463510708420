import React, { useState } from 'react';
import BoxField from "../../ui-v2/BoxField/BoxField";
import InputField from '../../ui-v2/InputField/InputField';

const InputFields = props => (
    <div>
        <b>{props.title}</b>
        <div className='d-grid col-lg-2 gap-2 overflow- mt-2'>
            {props.options.map(({ label, helperText, ...option }, index) => (
                <BoxField key={index}
                    {...option}
                    type='radio'
                    name={props.name}
                    defaultChecked={props.value ? props.value === option.value : index === 0}
                    label={<>{label} <span className='d-block text-accent-4'>{helperText}</span></>}
                />
            ))}
        </div>
    </div>
)


const EmailDomain = (props) => {
    const [selectedOption, setSelectedOption] = useState(props.email_domain ? "Specific domain only" : "All");
    const [emailDomainValue, setEmailDomainValue] = useState(props.email_domain || '');

    return (
        <>
            <InputFields 
                value={selectedOption} 
                title='Email domain' 
                name="email_domain"
                options={[
                    {
                        label: 'All',
                        value: 'All',
                        helperText: 'All types of domains are allowed',
                        onChange: () => setSelectedOption('All')
                    },
                    {
                        value: 'Specific domain only',
                        label: 'Specific domain only',
                        helperText: 'Only particular email domains will be allowed',
                        onChange: () => setSelectedOption('Specific domain only')
                    },
                ]}
            />
            {selectedOption === "Specific domain only" && 
                <InputField 
                    value={emailDomainValue} 
                    label='@example.com' 
                    required={true} 
                    errorText="Email domain is not valid!" 
                    pattern="@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}" 
                    name="email_domain" 
                    onChange={(e) => setEmailDomainValue(e.target.value)}  
                />
            }
        </>
    );
}

export default function Step2(props) {
    return (
        <>
            <InputFields title='Privacy' name='privacy' value={props.privacy}
                options={[
                    { label: 'Public', value: 'Public', helperText: 'Anyone can participate' },
                    { label: 'Private', value: 'Private', helperText: 'Only invited participants' },
                ]}
            />
            <InputFields title='Participation' name='participation' value={props.participation}
                options={[
                    { label: 'Auto-allow', value: 'Auto-allow', helperText: 'Anyone with the link can join the competition' },
                    { label: 'Manual', value: 'Manual', helperText: 'Admit participants on my approval only' },
                ]}
            />
            <EmailDomain email_domain={props.email_domain} />
            <div className='d-flex flex-column gap-2'>
                <b>Post visibility</b>
                <BoxField defaultChecked={props.post_visibility === 'All' || !props.post_visibility} type='radio' label="All" value="All" name="post_visibility" />
                <BoxField defaultChecked={props.post_visibility === 'Participants only'} type='radio' label="Participants only" value="Participants only" name="post_visibility" />
                <BoxField defaultChecked={props.post_visibility === 'None'} type='radio' label="None. Participants can see their predictions only." value="None" name="post_visibility" />
            </div>
        </>
    )
}