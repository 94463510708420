import React from 'react';
import CreateModal from '../../CreateCompitition/CreateModal';
import Lottie from "lottie-react";
import animationData from '../../../lottie/background.json'
import Competitions from './Competitions';
import UserProgressV4 from '../UserProgress';



const LeftCard = ({ user, data }) => (
    <UserProgressV4 className=" " user={user} data={data} premium={data?.premium}>
        {user.email === 'admin@stockalgos.com' && (
            <div className='border-t px-3 pb-3 pt-5 text-center position-relative'>
                <Lottie className='absolute-full' loop={true} animationData={animationData} />
                <CreateModal
                    component={({ showPopUp }) => (
                        <button onClick={showPopUp}
                            className='btn-text position-relative mb-1 mt-5 btn small mx-auto'
                        >Create a Competition</button>
                    )}
                />
                <p className='m-0 position-relative smaller'>Now you can create a group specific stock prediction competition.</p>
            </div>
        )}
        <Competitions />
    </UserProgressV4>
)

export default LeftCard;
