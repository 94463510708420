import React from 'react';
import styles from './ListTile.module.scss';

const ListTile = ({ title, leading, className = "", trailing, ...props }) => (
    <div className={`d-flex gap-2 align-items-center ${styles.root} ${className}`} {...props}>
        <span className={`${styles.leading} border-radius-50`}>{leading}</span>
        <small className='ms-auto text-normal'>{title}</small>
        <span className='me-2 text-nowrap d-flex gap-3'>{trailing}</span>
    </div>
)

export default ListTile;