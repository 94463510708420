import React, { useEffect, useState } from 'react';
import ContestModal from '../ContestModal/ContestModal';
import styles from './PrizeBanner.module.scss';
import PrizeWinnerModal from './PrizeWinnerModal';
import Daimond_Large from '../../../icons-v2/Daimond_Large';
import SetSubscribeButton from './SetSubscribeButton';

let timer;

const PrizeBanner = (props) => {
    const [active, setActive] = useState(false);
    const handleTimer = (active = false) => {
        setActive(active);
        clearInterval(timer);
        timer = setInterval(() => {
            active = !active;
            setActive(active);
        }, 5000);
    }
    useEffect(() => {
        handleTimer();
    }, [])
    return (
        <div className='full-column m-2 m-lg-0 overflow-hidden text-surface-1 position-relative'>
            <div className={`relative d-flex position-relative transition ${styles.root}`} style={{ left: active ? '-100%' : '0' }}>
                <div className={`d-flex flex-column align-items-md-center flex-md-row gap-3 p-4 border-radius-8 justify-content-between ${styles.banner1}`}>
                    <div>
                        <small className='text-accent-1 overline'>Predict to win Monthly prizes</small>
                        <h5 className='mt-1 mb-0'>$500, $300 and $200 for top three predictors.</h5>
                    </div>
                    <ContestModal
                        component={({ showPopUp }) => (
                            <div className='d-grid gap-2'>
                                <button onClick={showPopUp}
                                    className='btn btn-primary bg-accent-1-gradient fs-normal text-normal'
                                >Contest details</button>
                                <PrizeWinnerModal />
                            </div>
                        )}
                    />
                </div>
                <div className={`d-flex flex-column align-items-md-center flex-md-row gap-3 p-4 pb-5 border-radius-8 ${styles.banner2}`}>
                    <Daimond_Large />
                    <div className='ms-auto'>
                        <small className='text-accent-1 overline'>People can now pay you for your research and predictions</small>
                        <h5 className='mt-1 mb-0'>Start earning for sharing your predictions.</h5>
                    </div>
                    <SetSubscribeButton {...props} />
                </div>
            </div>
            <div className='position-absolute bottom-0 translate-middle-x start-50 mb-3 d-flex'>
                <button onClick={() => handleTimer(false)} disabled={!active} className={`btn small btn-icon no-padding fas fa-circle ${active ? 'opacity-3' : 'no-opacity'}`}></button>
                <button onClick={() => handleTimer(true)} disabled={active} className={`btn small btn-icon no-padding fas fa-circle ${active ? 'no-opacity' : 'opacity-3'}`}></button>
            </div>
        </div>
    )
}


export default PrizeBanner;