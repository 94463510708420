import React, { useState, useEffect } from 'react';
import { MenuItem } from "@material-ui/core";
import InputField from "../../ui-v2/InputField/InputField";
import SelectField from "../../ui-v2/SelectField/SelectField";

const SignalField = props => {
    const [value, setValue] = useState(props.value);
    const newProps = {
        name: props.state_name,
        label: props.title, title: props.tooltip,
        value,
        required: typeof props.required === 'boolean' ? props.required : true,
        errorText: 'This field is required!',
        onChange: e => setValue(e.target.value)
    };
    let Root;
    if (props.type) {
        Root = InputField;
        newProps.type = props.type
    }
    else {
        Root = SelectField
        newProps.children = props.array.map((option, index) => <MenuItem value={option} key={index}>{option}</MenuItem>)
    }

    useEffect(() => {
        if (value !== props.value) setValue(props.value);
    }, [props.value]);
    return <Root {...newProps} />
}

export default SignalField;