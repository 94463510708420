import React, { Suspense, useMemo } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { useApp } from "./App";
import MarketPlacePage from "./live/MarketPlacePage/MarketPlacePage";
import MarketPlacePageInstitutional from "./live/MarketPlaceInstitutional/MarketPlacePage";
import Dashboard from "./live/Dashboard/DashboardPage";
import CookiesBar from "./components/CookiesBar/CookiesBar";
import MinimizedPagesBar from './components-v2/MinimizedPagesBar/MinimizedPagesBar';
import Footer from "./components-v2/Footer/Footer";
import lazyRoutes from "./LazyRoutes";
import Spinner from "./ui-v2/Spinner/Spinner";
import LeaderboardPage from "./live/LeaderboardPage/LeaderboardPage";
import Top5LeaderBoardsPage from "./live/Top5LeaderBoardsPage/Top5LeaderBoardsPage";
import AuthPopUp from "./live/AuthPopUp/AuthPopUp";
import Header from "./components-v2/HeaderV2";
import HeaderInstitutional from "./components-v2/HeaderV2_insta";
import useInitial from "./hooks/useInitial";
import ToolsSubscription from "./live/MarketPlacePage/ToolsSubscription/ToolsSubscription";
import GlobalContext from "./contexts";
import AlgoRoute from "./AlgoRoute";
import CopyBanner from "./components-v2/CopyBanner";
import Homepage from "./live/SubscriptionPage/SubscriptionPage";

const RouterCom = () => (
  <GlobalContext>
    <Routings />
  </GlobalContext>
)


const Main = () => useMemo(() => (
  <Suspense fallback={<Spinner toMiddle={true} />}>
    <AlgoRoute />
    <Switch>
      {lazyRoutes.map((route, index) => <Route {...route} key={index} />)}
      {/* <Route path='/go-unlimited' component={Homepage} /> */}
      <Route path='/go-unlimited' component={Dashboard} />
      <Route path='/leaderboard/top-10' component={Top5LeaderBoardsPage} />
      <Route path='/leaderboard' component={LeaderboardPage} />
      <Route path='/institutional' component={MarketPlacePageInstitutional} />
      <Route path='/algo_page' component={() => <></>} />
      {/* <Route exact path={["/", 'marketplace', 'home']} component={MarketPlacePage} /> */}
      <Route exact path={["/", 'dashboard', 'home','marketplace']} component={Dashboard} />
      <Route path="*" component={() => {
         window.location.href = '/404';
         return ""
      }} />
    </Switch>
  </Suspense>
), [])

const Routings = () => {
  useInitial();
  const auth = useApp();
  const { user, isLoggedIn } = auth;
  const location = useHistory().location;
  return (
    <>
      {useMemo(() => (
        location.pathname === '/institutional' ? <HeaderInstitutional {...auth} /> : <Header {...auth} />
      ), [auth])}
      {useMemo(() => !(user && user.loading) ? (
        <>
          <ToolsSubscription {...auth} />
          <Main />
        </>
      ) : <Spinner toMiddle />, [user, isLoggedIn, location])}
      <MinimizedPagesBar />
      <AuthPopUp isLoggedIn={isLoggedIn} />
      {useMemo(() => (
        <>
          <CopyBanner />
          <CookiesBar />
          <Footer />
        </>
      ), [])}
    </>
  )
}

export default RouterCom;