import React from 'react';
import Follow from '../Follow';
import User from '../User/User';

const UserItem = props => (
    <div className='d-flex align-items-center'>
        <User {...props.userId} isLoggedIn={props.isLoggedIn} />
        <p className='my-0 ms-3 me-auto'>{props.guess_accuracy?.toFixed(2)}%</p>
        <Follow _id={props.userId._id} is_following={props.userId.is_following} />
    </div>
)

export default UserItem;