import React, { useState, useMemo } from "react";
import htmlToString from '../../utility/htmlToString';
import WatchListImport from '../../components-v2/AlgoHoldingCommonTools/watchlist_import';
import SearchField from "../../ui-v2/SearchField/SearchField";
import styles from './DashboardMain.module.scss'
import Switch from "../../ui-v2/Switch";
import Product from "./Product";
import { Link } from "react-router-dom";

function Main(props) {
    const [items, setItems] = useState([...props.algos]);
    const [state, setState] = useState({});
    const [stock_search, setStockSearch] = useState('');

    const handleStockChange = (e) => {
        setStockSearch(e.target.value);
    }


    const getAlgoState = (id) => state[id] || { algo: null };

    const getAlgoStateForChat = (id) => {
        const algoState = getAlgoState(id);
        if (algoState.algo) {
            const { userId, ...restAlgo } = algoState.algo;
            restAlgo.description = htmlToString(restAlgo.description);
            return { ...algoState, algo: restAlgo };
        }
        return algoState;
    };

    const updateState = (id, data) => {
        const newAlgoState = { ...getAlgoState(id), ...data };
        const newState = { ...state, [id]: newAlgoState };
        setState(newState);
    };
    const tempAlgo = {
        watchlist: true
    }

    return (
        <>
            <div className='border-bottom overflow-hidden d-flex gap-2 align-items-center p-3'>
                <SearchField placeholder="Search by Stock Symbol" onChange={handleStockChange} value={stock_search} name='search' className={`flex-1  ${styles.searchField}`}>
                    <span className="position-absolute start-0 ms-3">
                        <WatchListImport handleStockChange={handleStockChange} algo={tempAlgo} forDashboard={true} />
                    </span>
                </SearchField>
                <div className="d-flex text-accent-4 me-auto ms-2 small align-items-center gap-2">
                    Dashboard <Switch onChange={props.handleToggle} checked /> Separate Tools
                </div>
                {props.isLoggedIn ? (
                    <button
                    className="btn small btn-text"
                    onClick={() => props.setState(state => ({ ...state, isMenu: true }))}
                >Watchlist <i className="fas fa-chevron-right" />
                </button>
                ): (
                    <Link
                    href="#register"
                    className="btn small btn-text"
                >Watchlist <i className="fas fa-chevron-right" />
                </Link>
                )}
            </div>
            {items.map(item => <Product key={item._id} id={item._id} algo={item.algoId} symbol_list={stock_search} setState={(data) => updateState(item._id, data)} />)}
        </>
    );
}


export default function Algos(props) {
    if (props.sort != undefined) props.algos.sort((a, b) => props.sort.indexOf(a.algoId._id) - props.sort.indexOf(b.algoId._id))
    return <Main {...props} />
}