import React from 'react';
import AddIcon from '../../icons-v2/AddIcon';
import styles from './SideFilter.module.scss'

const SideFilter = ({ className, children, footer, responsiveHeader, component, ...props }) => {
    return (
        <>
            {component && (
                <div className='px-3 pt-4 d-flex gap-3 p-lg-4 d-lg-none flex-wrap' >
                    {component}
                </div>
            )}
            <div className={`d-flex z-index-6 ${styles.root}`} {...props}>
                <div className='border-right flex-1 d-flex flex-column bg-surface-1'>
                    <div className='d-flex flex-column overflow-auto flex-1 gap-3 gap-md-4 p-3 p-lg-4 p-xl-5'>
                        <div className='d-lg-none'>
                            <div className='header-height tool-filter-item' />
                            {responsiveHeader}
                        </div>
                        {children}
                    </div>
                    {footer && (
                        <div className={`p-3 p-lg-4 px-xl-5 elevation-1 bg-surface-2 ${styles.footer}`}>{footer}</div>
                    )}
                </div>
                <label className='mt-5 pt-5 mx-3 tool-filter-item' htmlFor='tool-filter-checkbox'>
                    <span className='header-height mt-5 mt-md-0 d-block' />
                    <span className='btn btn-icon mt-3 mt-md-2 bg-surface-1 rotate-45'><AddIcon /></span>
                </label>
            </div>
        </>
    )
}

export default SideFilter;
