import React, { useState } from 'react';
import Tabs from '../../../ui-v2/Tabs/Tabs';
import PostModal from '../PostModal/PostModal';
import Explore from './Explore';
import Filters from './Filters';
import MyFeed from './MyFeed';
import usePostFilters from '../../../hooks/usePostFilters';
import Subscribed from './Subscribed';

const options = {
    "My Feed": MyFeed,
    "Explore": Explore,
    // "Subscribed": Subscribed
};

const dTab = localStorage.getItem('Leaderboard-Feed');
const keys = Object.keys(options);
const Master = props => {
    const [{ posts = [], tab }, setStatus] = useState({
        tab: dTab && options[dTab] ? dTab : "My Feed"
    });
    const tools = usePostFilters();
    const Root = options[tab];
    
    
    const onTabChange = tab => {
        localStorage.setItem('Leaderboard-Feed', tab)
        setStatus({ tab });
    }
    return (
        <>
            {props.isLoggedIn && (
                <>
                    <PostModal {...props}
                        premium={props.stats.premium}
                        onCreatePost={post => setStatus({ posts: [post, ...posts], tab })}
                    />
                    <div className='d-flex gap-2 flex-wrap'>
                        <div className='bg-accent-6 flex-1 d-flex p-1 border-radius-4'>
                            <Tabs className='flex-1' options={keys} value={tab} onTabChange={onTabChange} />
                        </div>
                        <Filters {...props} />
                    </div>
                </>
            )}
            <Root posts={posts} {...props} />
            {Object.values(tools.values).join("").trim() && <button className='mt-2 btn-primary-2 mx-auto' onClick={tools.onReset}>Reset Filters</button>}
        </>
    )
}

const Main = props => (
    <div className='d-grid mb-auto gap-lg-2 gap-1'>
        {props.isLoggedIn && props.stats.data ? <Master {...props} stats={props.stats.data} /> : <Explore {...props} />}
    </div>
)

export default Main;