import React from "react";
import axios from "axios";
import URL from "url-parse";
import moment from "moment";
import ResultsGraph from "../results_graph";
import MomentumTraderFilter from "./MomentumTraderFilter";
import MomentumTraderStockData from "./MomentumTraderStockData";
import SaleBar from "../../components/SaleBar/SaleBar";
import withAlgoProductToolsLayout from "../../hoc-v2/withAlgoProductToolsLayout/withAlgoProductToolsLayout";

class Options extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: new URL(window.location.href, true),
      stock: ["gme"], //"spxl"
      starting_amount: 1000,
      volume_of_trades: 1,
      buy_differential: 1,
      sell_differential: 1,
      broker_fee: 0,
      date_list: [],
      initialized: false,
      real_time: false,
      start: false,
      end: true,
      ledger: [],
      profit: 0,
      total_spent_broker_fees: 0,
      trade_frequency: 1,
      profit_if_untraded: 0,
      revenue: 0,

      activate_live_trading: false,
      authenticated: false,
      date_value: "",
      start_time: new Date(moment().set({ hour: 9, minute: 30 })),
      end_time: new Date(moment().set({ hour: 16, minute: 0 })),

      show_graph: false,
      please_wait_show: false,
      stock_list: [],

      settings: false,
    };
  }

  handleChange = (e) => {
    e.preventDefault();
    let name = e.target.name;
    this.setState(
      {
        [name]: e.target.value,
      },
      () => {
        if (name === "date_value") {
          this.handleStartTime(this.state.start_time);
          this.handleEndTime(this.state.end_time);
        }
      }
    );
  };

  handleStockChange = (e) => {
    e.preventDefault();
    this.setState(
      {
        [e.target.name]: e.target.value.split(","),
      },
      () => {
        this.handleInitialize();
      }
    );
  };

  handleRealTime = (e) => {
    this.setState({
      real_time: true,
    });
  };
  // Changes realtime
  handlePastTime = (e) => {
    this.setState({
      real_time: false,
    });
  };

  confirm_authentication = () => {
    this.setState({
      authenticated: true,
    });
  };
  handleSelectDate = (date) => {
    this.setState({
      selected_date: moment(date).format("YYYY-MM-DD"),
    });
  };
  handleStartTime = async (date) => {
    let temp_date = await moment(date).format("H:mm");
    let combined_date = await new Date(
      moment(this.state.date_value + " " + temp_date)
    );
    this.setState({
      start_time: combined_date,
    });
  };

  handleEndTime = (date) => {
    let temp_date = moment(date).format("H:mm");
    let combined_date = new Date(
      moment(this.state.date_value + " " + temp_date)
    );
    this.setState({
      end_time: combined_date,
    });
  };

  handleCheck = (e) => {
    this.setState({
      [e.target.name]: e.target.checked,
    });
  };

  handleInitialize = () => {
    axios
      .post("/fetch_dates_for_data", {
        stock_symbol: this.state.stock,
      })
      .then((response) => {
        if (response.data.status === "success") {
          this.setState(
            {
              date_list: response.data.result,
              date_value: moment(
                response.data.result[response.data.result.length - 1]
              ).format("YYYY-MM-DD"),
              err_msg: "",
              initialized: true,
            },
            () => {
              this.handleStartTime(new Date(this.state.start_time));
              this.handleEndTime(new Date(this.state.end_time));
            }
          );
        } else {
          this.setState({
            err_msg: "Data for this stock does not exist.",
          });
        }
      });
  };

  handleRun = () => {
    this.setState({
      please_wait_show: true,
    });
    axios
      .post("/run_momentum_trader_past", {
        starting_amount: this.state.starting_amount,
        volume_of_trades: this.state.volume_of_trades,
        buy_differential: this.state.buy_differential,
        sell_differential: this.state.sell_differential,
        broker_fee: this.state.broker_fee,
        stock: this.state.stock,
        date_value: this.state.date_value,
        start_time: this.state.start_time,
        end_time: this.state.end_time,
        trade_frequency: this.state.trade_frequency,
        algo_id: this.state.url.query.algo_id,
      })
      .then((response) => {
        if (response.data.status === "success") {
          this.setState({
            ledger: response.data.results,
            profit: response.data.stats.profit,
            total_spent_broker_fees:
              response.data.stats.total_spent_broker_fees,
            profit_if_untraded: response.data.stats.profit_if_untraded,
            revenue: response.data.stats.revenue,
            show_graph: true,
            please_wait_show: false,
          });
        }
      });
  };

  closePopup = () => {
    this.setState({
      show_graph: false,
    });
  };
  componentDidMount() {
    if(this.props.state) return this.setState(this.props.state);
    axios.get("/stock_list").then((response) => {
      this.setState(
        {
          stock_list: response.data.arr,
        },
        () => {
          this.handleInitialize();
        }
      );
    });
  }

  render() {
    this.props.getState && this.props.getState(this.state);
    this.props.setResponsive(this.state.ledger.length > 0)
    return (
      <>
        <MomentumTraderFilter
          handleStockChange={this.handleStockChange}
          handlePastTime={this.handlePastTime}
          handleRealTime={this.handleRealTime}
          handleInitialize={this.handleInitialize}
          toggleSettings={() =>
            this.setState({ settings: !this.state.settings })
          }
          handleChange={this.handleChange}
          handleStartTime={this.handleStartTime}
          handleEndTime={this.handleEndTime}
          handleCheck={this.handleCheck}
          {...this.state}
          startTrading={this.startTrading}
          endTrade={this.endTrade}
          handleRun={this.handleRun}
          isResponsive={this.state.ledger.length > 0}        />
        <div className='d-flex flex-column overflow-hidden'>
          <SaleBar
            revenue={this.state.revenue}
            broker_fees={this.state.total_spent_broker_fees}
            profit={this.state.profit}
            buy_hold_profit={this.state.profit_if_untraded}
          />
          <MomentumTraderStockData stock={this.state.ledger} />
          {this.state.show_graph && (
            <ResultsGraph
              x_axis="Data Point"
              y_axis="Profit"
              volume_of_trades={this.state.volume_of_trades}
              close={this.closePopup}
              results={this.state.ledger}
            />
          )}
        </div>
      </>
    );
  }
}

export default withAlgoProductToolsLayout(Options, "Momentum Trader");
