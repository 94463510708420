import React, { useState } from 'react';
import moment from 'moment'
import ChevronBottom from '../../icons-v2/ChevronBottom';
import styles from './StockItem.module.scss';
import { ca } from 'date-fns/locale';

const StockItem = props => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [buy_transactions, setbuy_transactions] = useState([]);
    const [unique_buy, setunique_buy] = useState([]);
    const [buying_amt, setbuying_amt] = useState([]);
    const [sell_transactions, setsell_transactions] = useState([]);
    const [unique_sell, setunique_sell] = useState([]);
    const [selling_amt, setselling_amt] = useState([]);
    const { stock, selectionRange, page, period } = props
    function removeCommas(str) {
        while (str.search(",") >= 0) {
            str = (str + "").replace(',', '');
        }
        return str;
    };

    function setTable() {

         try{
            let buy_transactionss = stock.items?.filter((x) => x.transaction_type === "Purchase")
            setbuy_transactions(buy_transactionss)
            let unique_buys = [...new Set(buy_transactionss?.map(item => item.individual))];
            setunique_buy(unique_buys)
            let buying_amts = buy_transactionss?.reduce((a, b) => a + parseInt(removeCommas(b.amount)), 0);
            setbuying_amt(buying_amts)
            let sell_transactionss = stock.items?.filter((x) => x.transaction_type === "Sale")
            setsell_transactions(sell_transactionss)
            let unique_sells = [...new Set(sell_transactionss?.map(item => item.individual))];
            setunique_sell(unique_sells)
            let selling_amts = sell_transactionss?.reduce((a, b) => a + parseInt(removeCommas(b.amount)), 0);
            setselling_amt(selling_amts)

         }catch(e){

            }
       
    }

    return ( 
        <>
            <tr onClick={() => { setIsExpanded(!isExpanded); setTable() }} className={`${styles.root} ${isExpanded ? styles.active : ''}`}>
                <td><ChevronBottom className={`text-accent-4 ${isExpanded ? 'rotate-180' : ''}`} /></td>
                <td><div>{page}</div></td>
                <td>{stock.ticker}</td>
                <td>{stock.individual}</td>
                <td>{stock.include}</td>
                <td>{moment(selectionRange.startDate).format('MM/DD/YYYY')} | {moment(selectionRange.endDate).format('MM/DD/YYYY')}</td>
                <td>{stock.amounts}</td>
            </tr>
            {isExpanded && (
                <tr className={`border-bottom ${styles.expandedRow}`}>
                    <td colSpan={8} className='p-0'>
                    {period === "All transactions" &&
                                <div className='d-grid col-2 text-center pt-2 pb-1'>
                                    
                                    <p><strong >Buy Side</strong>: Transactions ({buy_transactions.length}), Participants ({unique_buy.length}), Value (${buying_amt})</p>
                                    <p><strong >Sell Side</strong>: Transactions ({sell_transactions.length}), Participants ({unique_sell.length}), Value (${selling_amt})</p>
                                </div>
                            }
                        <table className='primary-table'>
                            <thead className="no-opacity">
                                <tr>
                                    <th></th>
                                    <th><div>Number</div></th>
                                    <th>Owner</th>
                                    <th>Date</th>
                                    <th>Relationship</th>
                                    <th>Transaction <br/> p({stock.items.filter((x)=>x.transaction_type==="Purchase").length}) - s({stock.items.filter((x)=>x.transaction_type==="Sale").length})</th>
                                    <th>Cost($)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    stock.items.map((item, index) => {
                                        return (
                                            <tr>
                                                <td><ChevronBottom className='opacity-0' /></td>
                                                <td><div>{index + 1}</div></td>
                                                <td>{item.representative}</td>
                                                <td>{moment(item.transaction_date).format('MM/DD/YYYY')}</td>
                                                <td>{item.congress_house}</td>
                                                <td>{item.transaction_type}</td>
                                                <td>{parseFloat(item.amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </td>
                </tr>
            )}
        </>
    )
}

export default StockItem;
