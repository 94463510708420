import React, { useEffect, useState } from 'react';
import BoxField from '../../ui-v2/BoxField/BoxField';
import SearchField from "../../ui-v2/SearchField/SearchField";
import AddIcon from '../../icons-v2/AddIcon';
import SelectTags from '../../ui-v2/SelectTags/SelectTags';
import OnBoardProcess from '../OnBoardProcess/OnBoardProcess';
import PlayIcon from '../../icons-v2/PlayIcon';
import useModel from '../../hooks/useModel';

const MainFiltersSection = ({ results, algos, setAlgos }) => {
    const [isFilter, setIsFilter] = useState(false);
    const [data, setData] = useState({});
    const { search = "", algoType = [], sortby = "Top Rated" } = data;
    useModel({ shouldWork: isFilter, changed: [isFilter] });
    useEffect(() => {
        const regex = new RegExp(search, 'i');
        const newAlgos = algos.filter(algo => {
            const pD = algo.priceDetails;
            return (
                algoType.length === 0 || (algoType.includes(pD.type) && !pD.offerFreeTrial)
                || (algoType.includes('Free Trial') && pD.offerFreeTrial)
            )
             && (regex.test(algo.stockAlgoName) || regex.test(algo.short_description))
        })
        if (sortby === "Top Rated") newAlgos.sort((a, b) => b.review - a.review);
        setAlgos(newAlgos);
    }, [data])

    return (
        <form className='filter-panel-wrapper mb-auto'>
            <div className='mb-lg-4 d-flex gap-3'>
                <SearchField
                    name='search'
                    className='flex-1'
                    value={search}
                    onChange={e => setData({ search: e.target.value })}
                />
                <button className='btn d-lg-none btn-text btn-underline' type='button'
                    onClick={() => setIsFilter(true)}
                >Filters</button>
            </div>
            <div className={`filter-container ${isFilter ? 'active' : ''}`}>
                <div className='filter-panel'>
                    <div className='d-lg-none d-flex gap-2 mt-2 align-items-end'>
                        <h6 className='fw-semibold m-0'>Filters</h6>
                        <small className='text-accent-4'>{results} Results</small>
                    </div>
                    

                    <div >
                        <div >
                            <h6 className='overline m-0'>DataSets</h6>
                            {/* {data.algoType.length > 0 && <button className='btn btn-text small text-accent-2' type='button' onClick={() => onClear('algoType')}>Clear</button>} */}
                        </div>
                        {algos.map((algo, index) => <p>{algo.stockAlgoName}</p>
                        )}
                    </div>

                </div>
                <button className='btn btn-icon d-lg-none bg-surface-1 mb-auto mx-3 mt-3' onClick={() => setIsFilter(false)} type='button'><AddIcon className='rotate-45' /></button>
            </div>
        </form>
    )
}

export default MainFiltersSection;
