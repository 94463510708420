import React from 'react';

const OpenImage = ({ other_images, img_text }) => {
    return React.useMemo(() => other_images ? (
        <div className='flex-1 p-3 p-md-4 p-lg-5 d-grid gap-lg-5 gap-md-4 gap-3 col-lg-3 col-md-2'>
            <small className='full-column text-accent-4'>Generated Images: <b className='text-normal'>{img_text}</b></small>
            {other_images.length > 0 ? other_images.map(item => <img src={item} key={item} />) : (
                <h5 className='full-column text-center text-accent-2 opacity-8 mb-5 pb-5'> Results not found!, please try again</h5>
            )}
        </div>
    ): '', [other_images])
}

export default OpenImage;