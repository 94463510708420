import React from 'react';

const Bars = ({ isSubsctract, ...props }) => (
    <svg viewBox="0 0 18 15" fill="none" {...props}>
        <path d={`M1.0498 ${!isSubsctract ? "13.5H16.9494M1.0498" : ""} 7.5H16.9494M1.0498 ${!isSubsctract ? "1.5H16.9494" : ""}`}
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)

export default Bars;