import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import RangePicker from '../../ui/RangePicker';
import SelectField from '../../ui-v2/SelectField/SelectField';
import InputField from '../../ui-v2/InputField/InputField';
import SideFilter from '../../components-v2/SideFilter/SideFilter';
import WatchListImport from './watchlist_import';
import BoxField from '../../ui-v2/BoxField/BoxField';
import Circle from '../../ui-v2/Circle/Circle';
import SignalButtons from './SignalButtons';
import SignalsHandler from '../../live/SignalsHandler/SignalsHandler';
import useQuery from '../../hooks/useQuery';

const Filter = ({
    handleFilterChange,
    sort_type,
    period,
    period_type,
    selected_type,
    handleSortChange,
    selectionRange,
    handleStockChange,
    handleRangeSelect,
    stock_search,
    changeTableView,
    stepNo,
    search_with_people,
    people_search,
    tableView,
    name = "insiders",
    ...props
}) => {
    const signal = <SignalsHandler
        name={name}
        algo_name={props.algo_name || "Insiders"}
        component={options => <SignalButtons name={name}
            {...options}
            stepNo={stepNo}
        />} />
    const isDesktop = useQuery();

    return (
        <SideFilter
            component={isDesktop && <div className='d-flex mx-auto align-items-center gap-md-4 gap-3'>{signal}</div>}
        >
            {!isDesktop && (
                <div className='border-bottom pb-4 d-grid gap-3'>
                    {signal}
                </div>
            )}
            <div>
                {/* {console.log('props', stock_search)} */}
                <div className='mb-3 d-flex justify-content-between align-items-center'>
                    <small id='tip-2' className='position-relative'>Data Filters{stepNo === 2 && <Circle to='right' />}</small>
                    <WatchListImport handleStockChange={handleStockChange} algo={props.algo} />
                </div>
                <InputField
                    fullWidth
                    name='stock_search'
                    label="Search stock symbol"
                    onChange={handleStockChange}
                    value={stock_search}
                />
                {search_with_people &&
                    <InputField
                        fullWidth
                        className='mt-3'
                        name='people_search'
                        label=" Search by politician"
                        onChange={handleStockChange}
                        value={people_search}
                    />
                }


            </div>
            <div className='mb-1'>
                <small className='smaller mb-1 d-block text-accent-4'>Table view</small>
                <BoxField
                    onChange={changeTableView}
                    value='individual'
                    label="Individual Data"
                    checked={tableView === "individual"}
                    name='data-representation'
                    type='radio'
                    className='my-2 d-block'
                />
                <BoxField
                    onChange={changeTableView}
                    label="Group Data"
                    value='grouped_data'
                    type='radio'
                    name='data-representation'
                    checked={tableView !== "individual"}
                />
            </div>
            <div className='d-flex flex-column gap-3'>
                <SelectField
                    title={`Want to only see ${name} buy orders or sell orders?`}
                    fullWidth
                    value={period.replace(' transactions', '')}
                    name="period"
                    onChange={handleFilterChange}
                    label="Filter By Type"
                >

                    {period_type.map((type) => (
                        <MenuItem value={type.replace(' transactions', '')}>{type}</MenuItem>
                    ))}
                </SelectField>
                <SelectField
                    title="You can sort by stocks that have the most participants, most trades, or the cost of the stock purchased."
                    fullWidth
                    value={selected_type}
                    name="selected_type"
                    onChange={handleSortChange}
                    label="Sort By"
                >
                    {sort_type.map((type) => (
                        <MenuItem value={type}>{type}</MenuItem>
                    ))}
                </SelectField>
                <RangePicker
                    ranges={[selectionRange]}
                    onChange={handleRangeSelect}
                    component={({ show, value }) => (
                        <InputField
                            fullWidth
                            name='signal_name'
                            label="Filter By Date"
                            type="text"
                            onFocus={show}
                            value={value}
                            title={`Want to see the ${name} trading that occurred on a specific date?`}
                        />
                    )}
                />
            </div>

        </SideFilter>
    )
}

export default Filter;
