import React from 'react';

const HomeIcon = props => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g>
            <path fillRule="evenodd" stroke='currentColor' clipRule="evenodd" d="M1 10.3704C1 5.8656 1.51241 6.18 4.2706 3.728C5.47736 2.7968 7.3551 1 8.97663 1C10.5973 1 12.5126 2.788 13.7302 3.728C16.4884 6.18 17 5.8656 17 10.3704C17 17 15.3651 17 9 17C2.63488 17 1 17 1 10.3704Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6.57422 12.3082H11.4271" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </g>
    </svg>

)

export default HomeIcon;