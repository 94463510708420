import React from 'react';
import styles from './Card.module.scss';

const Card = ({ title, data = [], children }) => (
    <div className='pt-4' id={title}>
        <b>{title}</b>
        <div className={`d-grid mt-2 col-lg-2 align-items-start ${styles.main}`}>
            {children}
            {data.map((items, i) => (
                <ul className="list-unstyled" key={i}>
                    <li>
                        <span>Parameter</span>
                        Values
                    </li>
                    {items.map((item, k) => (
                        <li key={`${i}${k}`}>
                            <span>{item.title}</span>
                            {item.value}
                        </li>
                    ))}
                </ul>
            ))}
        </div>
    </div>
)

export default Card;