import React from 'react';
import styles from './ProductPageTitleBar.module.scss';
import PlayIcon from '../../icons-v2/PlayIcon';
import AddIcon from '../../icons-v2/AddIcon';
import Bars from '../../icons-v2/Bars';
import InfoIconV2 from '../../icons-v2/InfoIconV2';
import VideoPopUp from '../VideoPopUp/VideoPopUp';
import AlgoInfoPopUp from '../AlgoInfoPopUp/AlgoInfoPopUp';
import WriteAReviewModal from '../WriteAReviewModal/WriteAReviewModal';
import ArrowRightIcon from '../../icons-v2/ArrowRightIcon';

const ProductPageTitleBar = ({ algo, title, onMinimize, goBack, onClose, onDemo, showTips, isResponsive }) => {

    return (
        <div className={`${styles.root} flex-wrap gap-2 text-accent-4 d-flex bg-surface-2 p-3 py-lg-0 px-lg-5 align-items-center`}>
            <button className={`btn btn-icon no-padding ms-2 d-md-flex d-none`} onClick={goBack}><ArrowRightIcon className='rotate-180 text-primary' /></button>
            <h5 className='m-0 text-normal text-capitalize h6-sm ms-auto'>{title}</h5>
            {algo && (
                <div className={`me-0 d-flex gap-md-3 gap-2 text-nowrap ${styles.btnWrapper}`}>
                    <WriteAReviewModal algo={algo} />
                    <AlgoInfoPopUp
                        algo={algo}
                        component={({ showPopUp }) => <button className='btn small-sm btn-rounded' onClick={showPopUp}><InfoIconV2 className='text-primary' /> Info</button>}
                    />
                    <VideoPopUp
                        title={algo.stockAlgoName}
                        url={algo.video_url}
                        component={({ showPopUp }) => <button className='btn small-sm btn-rounded' onClick={showPopUp}><PlayIcon className='text-primary' />Explainer Video</button>}
                    />
                    {showTips && <button className='btn d-none d-lg-flex small-sm btn-rounded' onClick={onDemo}>
                        <PlayIcon className='text-primary' />
                        Play Tutorial
                    </button>}
                </div>
            )}
            <div className='d-md-flex d-none me-md-0 gap-3 ms-md-3 ps-1 pe-3 me-auto'>
                <button className='btn btn-icon no-padding' onClick={onMinimize}><Bars isSubsctract={true} /></button>
                <button className='btn btn-icon no-padding' onClick={onClose}><AddIcon className='rotate-45' /></button>
                {/* <label className='btn btn-icon no-padding' htmlFor='tool-filter-checkbox'><i className='fas fa-filter'></i></label> */}
            </div>
        </div>
    )
}


export default React.memo(ProductPageTitleBar);