import React, { useMemo } from 'react';
import InputField from '../../ui-v2/InputField/InputField';
import Model from '../../ui-v2/Modal/Model';
import Ratings from '../../ui-v2/Ratings/Ratings';
import Form from '../../utility/Form';
import styles from './WriteAReviewModal.module.scss';
import StarIcon from '../../icons-v2/StarIcon';
import usePost from '../../hooks/usePost';

const WriteAReviewModal = ({ algo, review, algoId, userId, setReview, ...props }) => {
    const { isLoading, onPost } = usePost('/algos/review/save', {}, false);
    const onSubmit = data => {
        const update = { ...data.values }
        onPost(
            { ...update, userId, algoId },
            true,
            () => {
                setReview({ res: { review: review ? { ...update, ...review } : update } })
                data.hidePopUp();
            }
        )
    }
    return (
        <Model
            onSubmit={onSubmit}
            Root={Form}
            className={styles.root}
            {...props}
            component={({ showPopUp }) => (
                <button className='btn small-sm btn-rounded' onClick={showPopUp}>
                    <StarIcon className='text-primary' fill='currentColor' />
                    <span>{review ? 'Change' : 'Write a'} Review</span>
                </button>
            )}
            actions={() => (
                <button disabled={isLoading}
                    className={`btn btn-primary fs-normal w-100 ${isLoading ? 'progress-btn' : ''}`}
                >Submit</button>
            )}
        >
            <div
                style={{ backgroundImage: `url(${algo.image_url})` }}
                className={`${styles.title} background text-surface-1`}
            >
                <div>
                    <h6 className='m-0'>{algo.stockAlgoName}</h6>
                    <p className='mb-0 mt-1 lines-1 d-webkit-box' dangerouslySetInnerHTML={{ __html: algo.description }}></p>
                </div>
            </div>
            <div className='d-flex align-items-center justify-content-between text-normal'>
                <p className='m-0'>Rate this algo</p>
                <Ratings
                    name="rating"
                    ratings={review?.rating || 5}
                    className='gap-1 h6 text-accent-1'
                />
            </div>
            <InputField
                label='Write a Review'
                multiline
                rows={3}
                minLength={10}
                helperText='Min. 10 characters'
                name='reviewDescription'
                required={true}
                defaultValue={review?.reviewDescription}
            />
        </Model>
    );
}

const Wrapper = props => {
    const data = { userId: localStorage.getItem("userId"), algoId: props.algo._id }
    const tools = usePost('/algos/review/get', data);
    return tools.res ? <WriteAReviewModal setReview={tools.setStatus} {...props} {...tools.res} {...data} /> : ""
}

const WriteAReviewModalWrapper = props => useMemo(() => props.algo._id ? <Wrapper {...props} /> : null, [props.algo])

export default WriteAReviewModalWrapper;
