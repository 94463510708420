import React from "react";
import ToTop from "../ToTop/ToTop";
import HowItWorksCard from "./HowItWorksCard/HowItWorksCard";
import Tabs from "./Tabs/Tabs";
import './withLeaderboard.scss';
import useLeaderboard from "./useLeaderboard";

const withLeaderBoard = Component => props => {
    const tools = useLeaderboard()
    return (
        <>
            <Tabs {...tools} />
            {tools.isLoggedIn && <HowItWorksCard className="d-lg-none card py-2" title='How it works' />}
            <div className='my-lg-4 mb-1 gap-lg-3 with-leaderboard'>
                <Component {...props} {...tools} />
            </div>
            <ToTop />
        </>
    )
}

export default withLeaderBoard;