import React, { useMemo, useState } from 'react';
import LeftCard from '../../components-v2/LeaderBoardComponents/LeftCard/LeftCard';
import Main from '../../components-v2/LeaderBoardComponents/Main/Main';
import TopLeaderBoardsCard from '../../components-v2/LeaderBoardComponents/TopLeaderBoardsCard/TopLeaderBoardsCard';
import withLeaderBoard from '../../components-v2/LeaderBoardComponents/withLeaderBoard';
import GetStartedModal from '../../components-v2/LeaderBoardComponents/GetStartedModal/GetStartedModal';
import Banner from '../../components-v2/LeaderBoardComponents/Banner/Banner';
import PrizeBanner from '../../components-v2/LeaderBoardComponents/PrizeBanner/PrizeBanner';
import useQuery from '../../hooks/useQuery';
import useStats from '../../hooks/useStats';
import SiteTitle from '../../components/site_title';
import SetUpSubscription from '../../components-v2/LeaderBoardComponents/SetUpSubscription';
import SubscriptionCard from '../../components-v2/LeaderBoardComponents/LeftCard/SetupSuscription';
import FAQs from '../../components-v2/LeaderBoardComponents/LeftCard/FAQs';

const LeaderboardPage = props => {
    const isMobile = useQuery();
    const [refreshTrigger, setRefreshTrigger] = useState(0);
    const stats = useStats(props.user._id, refreshTrigger);
    const prem = stats.data?.premium;

    const refreshStats = () => setRefreshTrigger(refreshTrigger + 1);

    return (
        <>
            <SiteTitle title="StockAlgos - Leaderboard"
                meta_description="A global leaderboard for people to predict the stock market & win prizes"
                keywords="StockAlgos, StockAlgos leaderboard, stock trading competition, stock competition, stock trading leaderboard, stock leaderboard, stock pickers, stock experts, stock guessing"
            />
            <h1 className="d-none">Leaderboard</h1>
            {useMemo(() => props.isLoggedIn && !stats.Loader && (
                <SetUpSubscription {...props} prem={prem} refreshStats={refreshStats} />
            ), [stats.Loader])}
            {useMemo(() => (
                !props.user.activated_on_leaderboard && !localStorage.getItem('activated_on_leaderboard')
            ) && <GetStartedModal {...props} />, [props])}
            {(props.isLoggedIn || !isMobile) && <PrizeBanner prem={prem} />}
            {props.isLoggedIn ? (
                !isMobile && (
                    <div className='d-grid gap-2 leaderboard-sticky-long'>
                        <LeftCard {...props} {...stats} />
                        <SubscriptionCard user={props.user} prem={prem} />
                        <FAQs />
                    </div>
                )
            ) : <Banner />}
            <Main {...props} stats={stats} />
            {!isMobile && <TopLeaderBoardsCard className='leaderboard-sticky' {...props} />}
        </>
    )
}

export default withLeaderBoard(LeaderboardPage);
