import React from "react";
import { NavLink } from 'react-router-dom';
import './styles.scss';

const HeaderLink = ({ Icon, title, className = '', ...props }) => {
    const Root = props.to ? NavLink : 'button';
    return (
        <li className={className}>
            <Root {...props} className={`Header-Link ${props.id || title}`}>
                {Icon && <Icon />} {title}
            </Root>
        </li>
    )
}

export default HeaderLink;