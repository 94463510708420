import React from 'react';
import Medal from '../../../icons-v2/Medal';
import styles from './styles.module.scss';
import ToolTip from '../../../ui-v2/ToolTip/ToolTip';

const UserProgress = ({ className = "", ...props }) => {
    const timeFrames = ['WEEK', 'MONTH', 'ALL']
    const getHTML = key => timeFrames.map(tf => <td key={tf}>{(props[tf] || {})[key]}</td>);
    return (
        <table className={`${className} w-100 small text-start ${styles.root}`}>
            <tbody>
                <tr>
                    <td>Profile Stats</td>
                    {timeFrames.map(tf => <td key={tf}>{tf}</td>)}
                </tr>
                <tr>
                    <td>
                        <span className='d-flex gap-1 align-items-center'>Rank <Medal width="0.81rem" className='text-accent-8' /></span>
                    </td>
                    {getHTML('position')}
                </tr>
                <tr>
                    <td>Predictions (#)</td>
                    {getHTML('number_guesses')}
                </tr>
                <tr>
                <ToolTip title='If you guess the wrong direction, your accuracy is 0. If you guess the right direction its based off of how close your predicted change gets to the actual change.'>
                    <td>Accuracy (%)</td>
                    </ToolTip>
                    {getHTML('guess_accuracy')}
                </tr>
{/*                 
                <tr>
                    <ToolTip title='Points are awarded for correct guesses and deducted for incorrect guesses. It factors in both the accuracy and magnitutde of the guess.'>
                    <td>Points</td>
                    </ToolTip>
                    {getHTML('points')}
                </tr>
                */}
                <tr>
                    <td>Guess Direction (%)</td>
                    {getHTML('avg_guess_direction_accuracy')}
                </tr>
                
            </tbody>
        </table>
    )
}

export default UserProgress;
