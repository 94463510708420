import React from 'react';
import useGet from "../../../hooks/useGet";
// import TopLeaderBoards from '../TopLeaderBoards/TopLeaderBoards';
import UserItem from './UserItem';

let followings = 0;
const UnlockFeed = props => {
    const hash = props.history.location.hash;
    React.useEffect(() => {
        if(followings === 0) return followings++
        const [status, id] = hash.split('=');
        if (id && /follow/i.test(status)) {
            if (status === '#follow') followings += 1;
            else if (status === '#unfollow') followings += -1;
        }
    }, [hash])
    return (
        <div className='d-flex'>
            {(props.stats.followings + followings) > 4 && <button
                className="btn btn-text small"
                onClick={props.unlockFeed}
            >Unlock My Feed</button>}
        </div>
    )
}

const FollowUsers = props => {
    const { data, Loader } = useGet('/fetch_suggested_followers', { limit: 30, random: true })
    return (
        <div className="d-flex p-3">
            <div className="mx-auto small">
                <small>My Feed shows predictions of the people you follow.</small>
                <p className='fw-bold mb-4 bg-primary-lighten-4 py-2 border-radius-4 text-center'>Follow at least 5 people to unlock.</p>
                {Loader ? <Loader fontSize='.5rem' className='mx-auto text-accent-3 mt-3 mb-2' /> : data && (
                    <div className='d-flex flex-column gap-3'>
                        {data.results.map(result => result.userId._id !== props.user._id && (
                            <UserItem isLoggedIn={true} key={result._id} {...result} />
                        ))}
                        <UnlockFeed {...props} />
                    </div>
                )}
            </div>
        </div>
    )
}

export default FollowUsers;
