import React from 'react';
import Rating from '../../ui-v2/Ratings/Ratings';
import Pricing from '../Pricing/Pricing';
import AlgoItemV2 from './AlgoItemV2';

const AlgoItem = ({ priceDetails, review, ...props }) => (
    <AlgoItemV2 className={props.stockAlgoName} to={`/marketplace/${props.url_link}`} {...props}>
        <Pricing {...priceDetails} />
        <div className='d-flex align-items-center mt-auto' style={{ gap: '0.5em' }}>
            <Rating initialRating={review} readonly={true} />
            {review}
        </div>
    </AlgoItemV2>
)

export default AlgoItem;
