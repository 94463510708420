import React from 'react';
import Card from "./Card";
import getNumber from '../../../../utility/getNumber';
import LI from './LI';

const ValuationMeasures = props => (
    <Card title='Valuation Measures'>
        <ul>
            <LI title='PE Ratio' tooltip={`the ratio for valuing a company that measures its current share price relative to its earnings per share (EPS). P/E ratios are used by investors and analysts to determine the relative value of a company's shares in an apples-to-apples comparison. It can also be used to compare a company against its own historical record or to compare aggregate markets against one another or over time. A high P/E ratio could mean that a stock is overvalued or investors are expecting high growth rates in the future.`} value={props.peratio} />
            <LI title='Highest PE over Last 5 Years' tooltip='Highest PE over Last 5 Years' value={props.pehighlast5years} />
            <LI title='Lowest PE over Last 5 Years' tooltip='Lowest PE over Last 5 Years' value={props.pelowlast5years} />
            <LI title='Price to Sales' tooltip={`shows a company's market capitalization divided by the company's sales for the previous 12 months. It is a measure of the value investors are receiving from a company's stock by indicating how much equity is required to deliver $1 of revenue. Analysts prefer to see a lower number for the ratio. A ratio of less than 1 indicates that investors are investing less than $1 for every $1 the company earns in revenue.`} value={getNumber(props.pricetosales, 3)} />
            <LI title='Price to Book' tooltip={`Used to compare a firm's market capitalization to its book value. Book value is also the tangible net asset value of a company calculated as total assets minus intangible assets (.e.g. patents, goodwill) and liabilities. P/B ratios under 1 are typically considered solid investments.`} value={props.pricetobook} />
        </ul>
        <ul>
            <LI title='Price to Tangible Book' tooltip={`A valuation ratio of the price paid for a share relative to tangible book value (Stockholder's Equity less Goodwill and Intangible Assets) per share. Price To Tangible Book number indicates the amount of dollars paid on the stock market for one dollar of tangible book value.`} value={props.pricetotangiblebook} />
            <LI title='Price to Cashflow' tooltip={`a stock valuation indicator or multiple that measures the value of a stock’s price relative to its operating cash flow per share. The ratio uses operating cash flow (OCF), which adds back non-cash expenses such as depreciation and amortization to net income. P/CF is especially useful for valuing stocks that have positive cash flow but are not profitable because of large non-cash charges. A low P/CF multiple may imply that a stock is undervalued in the market.`} value={props.pricetocashflow} />
            <LI title='Price to Free Cashflow' tooltip={`Price to free cash flow is an equity valuation metric that indicates a company's ability to generate additional revenues. It is calculated by dividing its market capitalization by free cash flow values. A lower value for price to free cash flow indicates that the company is undervalued and its stock is relatively cheap. A higher value for price to free cash flow indicates an overvalued company.`} value={props.pricetofreecash} />
            <LI title='BVPS' tooltip={`can be used by investors to gauge whether a stock price is undervalued by comparing it to the firm's market value per share. If a company’s BVPS is higher than its market value per share—its current stock price—then the stock is considered undervalued. In theory, BVPS is the sum that shareholders would receive in the event that the firm was liquidated, all of the tangible assets were sold and all of the liabilities were paid. However, as the assets would be sold at market prices, and book value uses the historical costs of assets, market value is considered a better floor price than book value for a company.`} value={props.bvps} />
            <LI title='CFPS' tooltip={`Cash flow per share is the after-tax earnings plus depreciation on a per-share basis that functions as a measure of a firm's financial strength. Many financial analysts place more emphasis on cash flow per share than on earnings per share (EPS).`} value={props.cfps} />
        </ul>
    </Card>
)

export default ValuationMeasures;
