import React from 'react';
import Card from "./Card";
import getNumber from '../../../../utility/getNumber';
import LI from './LI';

const IncomeStatements = props => (
    <Card title='Income Statements'>
        <ul>
            <LI title='Revenue' tooltip={`the money generated from normal business operations, calculated as the average sales price times the number of units sold. It is the top line (or gross income) figure from which costs are subtracted to determine net income.`} value={getNumber(props.revenue)} start='$' />
            <LI title='Revenue Per Share' tooltip='Revenue Per Share' value={getNumber(props.revenuepershare, 3)} start='$' />
        </ul>
        <ul>
            <LI title='Revenue growth (3 Years)' tooltip={`The average revenue increase as a percent over the last N years. Revenue growth calculates the annualized average rate of change in revenue earned  by a company.`} value={props.revenue3years} end='%' />
            <LI title='Revenue growth (5 Years)' tooltip={`The average revenue increase as a percent over the last N years. Revenue growth calculates the annualized average rate of change in revenue earned  by a company.`} value={props.revenue5years} end='%' />
        </ul>
    </Card>
)

export default IncomeStatements;
