import React, { Fragment, useMemo } from 'react';
import img1 from '../../assets/grediant-1.jpg';
import NotificationItem from '../NotificationItem/NotificationItem';
import NotificationIcon from '../../icons-v2/NotificationIcon';
import ArrowRightIcon from '../../icons-v2/ArrowRightIcon';
import axios from "axios";
import moment from "moment"
import DropCard from '../DropCard/DropCard';
import { useHistory } from 'react-router-dom';
import styles from './notifications.module.scss';
import useNotifications from '../../hooks/useNotifications';

const Notifications = () => {
  const { notifications, setNotifications } = useNotifications();
  const push = useHistory().push

  function dateToFromNowDaily(myDate) {
    var fromNow = moment(myDate).fromNow();

    return moment(myDate).calendar(null, {
      lastWeek: '[Last] dddd',
      lastDay: '[Yesterday]',
      sameDay: '[Today]',
      nextDay: '[Tomorrow]',
      nextWeek: 'dddd',
      sameElse: function () {
        return "[" + fromNow + "]";
      }
    });
  }

  const isUnRead = notifications.some(item => item.notifs.some(not => not.status === 'Unread'));

  const onNotificationRead = async (index, nestedIndex, id, to) => {
    const notification = notifications[index].notifs[nestedIndex];
    if (notification.status === 'Unread') {
      notification.status = 'Read';
      setNotifications(notifications);
      axios.put(`/notification_read/${id}`);
    }
    if (to) push(to);
  }

  const onReadAll = () => {
    const newNotifications = [...notifications];
    for(let item of newNotifications) {
      for(let notify of item.notifs) notify.status = 'Read'
    }
    setNotifications(newNotifications);
    axios.put('/notification_read_all')
  }

  return useMemo(() => (
    <DropCard
      component={(isActive, setActiveHandler) => (
        <button
          onClick={() => setActiveHandler(!isActive)}
          className={`Header-Link Notifications Header-Icon-Link ${isActive ? 'active' : ''}`}
        >
          <span className={isUnRead ? styles.unRead : ''}>
            <NotificationIcon />
          </span>
          <span className='d-lg-none'>Notifications</span>
        </button>
      )}
      header={closeHandler => (
        <div className='mx-3 py-3 border-bottom d-flex align-items-center justify-content-between'>
          <button className='btn btn-icon d-lg-none no-padding ms-0' onClick={closeHandler}><ArrowRightIcon className='rotate-180' /></button>
          <p className='my-0 ms-auto'>Notifications</p>
          {isUnRead && <button className='btn btn-text' onClick={onReadAll}>Read all</button>}
        </div>
      )}
    >
      {notifications.length > 0 ? notifications.map((notif, i) => notif.notifs.length > 0 && (
        <Fragment key={i}>
          <small className='smaller text-accent-4 m-3 d-block'>{dateToFromNowDaily(notif.date)}</small>
          {notif.notifs.map((item, index) => (
            <NotificationItem {...item}
              avatar={img1}
              key={item._id}
              className={index === 0 ? 'border-top' : ''}
              onNotificationRead={to => onNotificationRead(i, index, item._id, to)}
            />
          ))}
        </Fragment>
      ))
        :
        <small className='smaller text-accent-4 m-3 d-block'>You have no notifications. Please check back later.</small>
      }
    </DropCard>
  ), [notifications])
}

export default Notifications;
