import React from 'react';
import Card from "./Card";
import LI from './LI';

const FinancialStrength = props => (
    <Card title='Financial Strength'>
        <ul>
            <LI title='Quick Ratio' tooltip={`The quick ratio measures a company's capacity to pay its current liabilities without needing to sell its inventory or obtain additional financing. More conservative measure than the current ratio. The higher the ratio result, the better a company's liquidity and financial health; the lower the ratio, the more likely the company will struggle with paying debts. `} value={props.quickratio} />
            <LI title='Current Ratio' tooltip={`The current ratio is a liquidity ratio that measures a company’s ability to pay short-term obligations or those due within one year. A ratio under 1.00 indicates that the company’s debts due in a year or less are greater than its assets—cash or other short-term assets expected to be converted to cash within a year or less. A current ratio of less than 1.00 may seem alarming, although different situations can negatively affect the current ratio in a solid company.`} value={props.currentratio} />
            <LI title='Long Term Debt to Capital' tooltip={`a variation of the traditional debt-to-equity (D/E) ratio, shows the financial leverage of a firm. It is calculated by dividing long-term debt by total available capital (long-term debt, preferred stock, and common stock). A higher ratio result means that a company is more highly leveraged, which carries a higher risk of insolvency.`} value={props.longtermdebttocapital} />
        </ul>
        <ul>
            <LI title='Debt to Equity' tooltip={`compares a company’s total liabilities to its shareholder equity and can be used to evaluate how much leverage a company is using. Basically how much of operations are funded by debt vs their owned funds. A high D/E ratio is often associated with high risk; it means that a company has been aggressive in financing its growth with debt.`} value={props.totaldebttoequity} />
            <LI title='Interest Coverage' tooltip={`The interest coverage ratio is a debt and profitability ratio used to determine how easily a company can pay interest on its outstanding debt. The interest coverage ratio is calculated by dividing a company's earnings before interest and taxes (EBIT) by its interest expense during a given period. Generally, a higher coverage ratio is better, although the ideal ratio may vary by industry.`} value={props.intcoverage} />
            <LI title='Leverage Ratio' tooltip={`Tells you how much capital comes from debt financing. Too much debt can be dangerous for a company and its investors. However, if a company's operations can generate a higher rate of return than the interest rate on its loans, then the debt may help to fuel growth. Uncontrolled debt levels can lead to credit downgrades or worse. On the other hand, too few debts can also raise questions.`} value={props.leverageratio} />
        </ul>
    </Card>
)

export default FinancialStrength;
