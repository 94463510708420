import React from 'react';
import Card from "./Card";
import LI from './LI';

const Assets = props => (
    <Card title='Assets'>
        <ul>
            <LI title='Assets Turnover' tooltip={`The asset turnover ratio measures the value of a company's sales or revenues relative to the value of its assets. The asset turnover ratio can be used as an indicator of the efficiency with which a company is using its assets to generate revenue. The higher the asset turnover ratio, the more efficient a company is at generating revenue from its assets. Conversely, if a company has a low asset turnover ratio, it indicates it is not efficiently using its assets to generate sales.`} value={props.assetsturnover} />
            <LI title='Invoice Turnover' tooltip={`The time it takes for an invoice to be paid to the company by its clients. A low value indicates that clients are paying quickly while a high value indicates that it takes a long time for clients to make their payments.`} value={props.invoiceturnover} />
        </ul>
        <ul>
            <LI title='Receivables Turnover' tooltip={`The accounts receivable turnover ratio is an accounting measure used to quantify how efficiently a company is in collecting receivables from its clients.The ratio also measures the times that receivables are converted to cash during a certain time period.A high ratio may indicate that corporate collection practices are efficient with quality customers who pay their debts quickly.`} value={props.receivablesturnover} />
        </ul>
    </Card>
)

export default Assets;
