import React from 'react';
import SideFilter from '../../components-v2/SideFilter/SideFilter';
import SelectField from '../../ui-v2/SelectField/SelectField';
import ToolTip from '../../ui-v2/ToolTip/ToolTip';
import MenuItem from '@material-ui/core/MenuItem';
import BoxField from '../../ui-v2/BoxField/BoxField';
import GearIcon from '../../icons-v2/GearIcon';
import TimePicker from '../../ui-v2/TimePicker/TimePicker';
import InputField from '../../ui-v2/InputField/InputField';
import Spinner from '../../ui-v2/Spinner/Spinner';
import PickersUtilsProvider from '../../ui-v2/PickersUtilsProvider/PickersUtilsProvider';

const MomentumTraderFilter = ({
    stock,
    toggleSettings,
    handleStockChange,
    initialized,
    stock_list,
    handlePastTime,
    handleInitialize,
    settings,
    handleRealTime,
    real_time,
    date_list,
    date_value,
    handleChange,
    handleStartTime,
    handleEndTime,
    start_time,
    end_time,
    starting_amount,
    volume_of_trades,
    trade_frequency,
    buy_differential,
    sell_differential,
    broker_fee,
    authenticated,
    activate_live_trading,
    handleCheck,
    start,
    startTrading,
    endTrade,
    please_wait_show,
    handleRun,
    isResponsive,
    ...props
}) => {
    return (
        <SideFilter isResponsive={isResponsive}>
            <div className='border-bottom pb-4'>
                <div className='d-flex gap-2 align-items-center'>
                    <SelectField
                        title="Enter the stock symbol you want the momentum trader to use."
                        label='Stock Symbol'
                        value={stock}
                        onChange={handleStockChange}
                        name="stock"
                        disabled={!initialized}
                    >
                        {stock_list.map((stock) => (
                            <MenuItem value={stock}>{stock.toUpperCase()}</MenuItem>
                        ))}
                    </SelectField>
                    {initialized && <button
                        className='btn gap-0 mb-2 flex-column text-primary'
                        onClick={toggleSettings}
                    >
                        <span className='btn btn-icon'><GearIcon className='text-primary' /></span>
                        <small>Try me</small>
                    </button>}
                </div>
                <button className='btn mt-3 w-100 btn-primary fs-mediam' onClick={handleInitialize} disabled={initialized}>Look Up</button>
            </div>

            {!initialized && !settings && (
                <div>

                    <BoxField
                        title="See how momentum trading would have worked on passed data."
                        label="Historic Data"
                        className='mb-2 d-block'
                        onChange={handleRealTime}
                        name="real_time"
                        type='radio'
                        defaultChecked={!real_time}
                    />
                    <BoxField
                        title="Get notifications for when you should execute a momentum trade."
                        type='radio'
                        onChange={handlePastTime}
                        // name="real_time"
                        label="Live (Coming Soon!)"
                        name='data-representation'
                        disabled={true}
                        defaultChecked={real_time}
                    />
                </div>
            )}

            {!initialized && !settings && (

                <div className='d-flex flex-column gap-3'>
                    {date_list.length > 0 && (
                        <>
                            <SelectField
                                title="Select the trading day for that you would like the momentum trader to run through."

                                value={date_value}
                                name="date_value"
                                onChange={handleChange}
                                label="Date"
                            >
                                {date_list.map((date) => (
                                    <MenuItem value={date}>{date}</MenuItem>
                                ))}
                            </SelectField>
                            <PickersUtilsProvider>
                                <TimePicker
                                    label="Start Time"
                                    value={start_time}
                                    onChange={handleStartTime}
                                />
                                <TimePicker
                                    label="End Time"
                                    value={end_time}
                                    onChange={handleEndTime}
                                />
                            </PickersUtilsProvider>
                        </>
                    )}
                    <InputField
                        title='Enter an arbitrary starting amount to simulate how your account would act if trading the stock.'
                        name='starting_amount'
                        type="number"
                        onChange={handleChange}
                        value={starting_amount}
                        label='Starting Amount'
                    />
                    <InputField
                        title='Enter the hypothetical number of shares that you would like the momentum trader to make everytime it makes a trade.'
                        label='Volume of Shares'
                        name='volume_of_trades'
                        type="number"
                        onChange={handleChange}
                        value={volume_of_trades}
                    />
                    <InputField
                        title='This represents how long the algorithm will wait before it places the next trade.(ex. If you enter 5 then it will make a trade every 5 minutes.)'
                        name='trade_frequency'
                        label="Trade Frequency (per x min)"
                        type="number"
                        onChange={handleChange}
                        value={trade_frequency}
                    />
                    <InputField
                        title='Enter an amount you would like to trail the stock price by before it makes a purchase. Ex. You enter $1, The stock is at $100 it goes down to $90, then it goes up to $91. Then a buy trade would be executed.'
                        name='buy_differential'
                        label="Buy Differential"
                        type="number"
                        onChange={handleChange}
                        value={buy_differential}
                    />
                    <InputField
                        title="Enter an amount you would like to trail the stock price by before it sells your current stock. Ex. You enter $1, You bought stock at $100. It goes up to $110, then it drops to $109. Then a sell trade would be executed."
                        name='sell_differential'
                        label="Sell Differential"
                        type="number"
                        onChange={handleChange}
                        value={sell_differential}
                    />
                    <InputField
                        title="Enter the hypothetical broker fee that you would have to pay per trade."
                        name='broker_fee'
                        label="Broker Fee"
                        type="number"
                        onChange={handleChange}
                        value={broker_fee}
                    />
                </div>
            )}

            {!initialized && authenticated && (
                <BoxField
                    checked={activate_live_trading}
                    onChange={handleCheck}
                    disabled={start}
                    name="activate_live_trading"
                    label="Activate Live Trading"
                />
            )}

            {!initialized && real_time && (
                <>
                    <button className='btn btn-primary fs-mediam' disabled={start} onClick={startTrading}>Start Trading</button>
                    <button className='btn btn-danger fs-mediam' disabled={start} onClick={endTrade}>End Trading</button>
                </>
            )}

            {!initialized && !real_time && (
                <ToolTip title="Run the momentum trader based off the values you have entered.">
                    <button className='btn w-100 btn-primary fs-mediam' onClick={handleRun}>
                        Run
                        {please_wait_show && <Spinner fontSize="0.25rem" />}
                    </button>
                    {please_wait_show && <small className='text-primary d-block mt-1'>Please wait while the data is processed</small>}
                </ToolTip>
            )}

        </SideFilter>
    )
}

export default MomentumTraderFilter;