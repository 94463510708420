import React from 'react';
import img from '../../../assets/part.svg';
import AddIcon from '../../../icons-v2/AddIcon';
// import Video from '../../Video/Video';
// import styles from './step1.module.scss';

const Step1 = ({ currentStep, onNext, onFinish }) => {
    return currentStep === 2 && (
        <div className={`text-center translate-middle ${currentStep === 2 ? 'active' : ''}`}>
            <div className={`bg-surface-1 p-5 elevation-3 border-radius-14`}>
                <button className='btn btn-icon no-padding me-auto' onClick={onFinish}>
                    <AddIcon className='rotate-45' />
                </button>
                <img alt='' src={img} className='my-5' style={{height: '10.125rem'}} />
                <div className='mx-3'>
                    <h4 className='mt-0 mb-3'>Welcome to StockAlgos</h4>
                    <h6 className='m-0 line-height-2 text-accent-4 fw-normal subtitle'>Get access to the tools and algorithms used exclusively by <br /> professional traders and financial institutions.</h6>
                    {/*<p className='mt-4 mb-3'>To get started, watch the video or take a tour.</p>*/}
                    <p className='mt-5 mb-3 d-flex gap-2 justify-content-center'><button onClick={onNext} className='btn btn-text' >Take a Guided Tour</button> to get started.</p>
                    {/*
                    <Video
                        height='64%' url='https://www.youtube.com/watch?v=Pci5I0JNGq8&ab_channel=StockAlgos'
                        component={(onPlay, className) => (
                            <div className={`${className} background ${styles.videoWrapper}`}>
                                <div className='position-relative z-index-1 d-flex align-items-center justify-content-center flex-column gap-3'>
                                    <button className='btn btn-primary' onClick={onPlay}>Play Video</button>
                                    <small className='fw-bold text-surface-1'>1 min</small>
                                </div>
                            </div>
                        )}
                    />

                    <p className='d-flex align-items-center justify-content-center gap-2 mt-5 mb-0'>Or <button onClick={onNext} className='btn btn-text'>take a guided tour</button></p>
                    */}

                </div>
            </div>
        </div>
    )
}

export default Step1;
