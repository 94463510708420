import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import sliderImg1 from "../assets/images/Slider-image-01.jpg";
import sliderImg2 from "../assets/images/Slider-image-02.jpg";
import sliderImg3 from "../assets/images/Slider-image-03.jpg";
import sliderImg4 from "../assets/images/Slider-image-04.jpg";
import sliderImg5 from "../assets/images/Slider-image-05.jpg";



let sliders = [];

sliders.push(
  <div className="testimonial">
    <h2>
      “Wow, I am in shock. It's crazy to see how hedge funds trade the market and the shear size of the trades they place.“
    </h2>

    <p>- CrimJamer</p>
    <p>Hedge Fund Analysis Tool</p>
  </div>
);

sliders.push(
  <div className="testimonial">
    <h2>
      “Has helped me to make better investments. up 10% in the last two months buy buying undervalued.“
    </h2>

    <p>- Adrian Sanchez</p>
    <p>Fundamentals Tool</p>
  </div>
);

sliders.push(
  <div className="testimonial">
    <h2>
      “Worth the premium compared to finviz because of the sorting“
    </h2>

    <p>- Quinn Taylor</p>
    <p> Insiders Tool</p>
  </div>
);

sliders.push(
  <div className="testimonial">
    <h2>
      “It's like seeing a balance sheet before earnings release“
    </h2>

    <p>- Alex W</p>
    <p>Government Awarded Contracts Tool</p>
  </div>
);

sliders.push(
  <div className="testimonial">
    <h2>
      “I did really well using this tool and trading options on upcoming earnings.“
    </h2>

    <p>- Zachary Reiners</p>
    <p>Earnings Tool</p>
  </div>
);

sliders.push(
  <div className="testimonial">
    <h2>
      “One of the best free tools I have seen for analyzing FDA data. thx“
    </h2>

    <p>- Danny J</p>
    <p>FDA Stock Analysis Tool</p>
  </div>
);

sliders.push(
  <div className="testimonial">
    <h2>
      “Huge time saver. Interested in options trading but not in sifting through all of them. Great way to hone in on best ones.“
    </h2>

    <p>- Callum O'Shaughnessy</p>
    <p>Options Screener Tool</p>
  </div>
);

sliders.push(
  <div className="testimonial">
    <h2>
      “Something different is that it shows you the stock performace leading up to the dividend payout as a % of the dividend“
    </h2>

    <p>- Charles Gullatt</p>
    <p>Ex-Dividend Analysis Tool</p>
  </div>
);



const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 8,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
  },
};




const TestimonialSlider = () => {
  return (
 

    <section className="testimonial-slider">
      <h2 class="subtitle">See what our 3,000+ members are saying…</h2>
      <div className="">
        <Carousel
          responsive={responsive}
          swipeable={true}
          draggable={true}
          showDots={false}
          arrows={false}
          ssr={true} // means to render carousel on server-side.
          infinite={true}
          autoPlaySpeed={1000}
          keyBoardControl={true}
          transitionDuration={500}
          containerClass="carousel-container"
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
        >
          {sliders}
        </Carousel>
      </div>
    </section>


  );
};

export default TestimonialSlider;
