import React from 'react';

export default function Daimond_Large(props) {
    return (
        <svg {...props} width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_8244_69164)">
                <path d="M61.6203 21.0603L51.6292 8.66247C51.3035 8.25845 50.8125 8.02344 50.2936 8.02344H47.7948H37.7199H11.7065C11.1875 8.02344 10.6964 8.25832 10.3709 8.66247L0.37976 21.0603C-0.128183 21.6905 -0.126413 22.5901 0.384058 23.2184L28.7573 58.1419V58.1418L29.2395 52.5761L35.1359 55.8101L35.1373 55.8099L44.4482 44.3496L52.8213 34.0436L61.6161 23.2184C62.1264 22.5901 62.1282 21.6906 61.6203 21.0603Z" fill="#E5CAF3" />
                <path d="M10.3713 8.66211L18.2862 22.1407H0.266777L3.71814e-05 22.1457C-0.00249116 21.7614 0.123926 21.3771 0.379288 21.0598L10.3713 8.66211Z" fill="#9857BD" />
                <path d="M61.6204 21.0601C61.8745 21.3761 62.0009 21.7592 61.9996 22.141H61.9857H43.8398L51.5702 8.97718L51.7586 8.82422H51.7599L61.6204 21.0601Z" fill="#9857BD" />
                <path d="M43.8391 22.1406L33.4375 57.8989L32.3301 59.2629C31.6436 60.1087 30.3542 60.1087 29.6677 59.2629L28.7563 58.1416L23.7666 40.9831L19.3496 25.7966L18.2852 22.1406H43.8391Z" fill="#CB97E7" />
                <path d="M51.7605 8.82366L51.5722 8.97662L43.8418 22.1404L35.1367 8.02344H37.7219H47.7961H50.2954C50.8137 8.02344 51.3054 8.25857 51.6303 8.66184L51.7605 8.82366Z" fill="#AF66DA" />
                <path d="M61.985 22.1406H61.9989C61.9976 22.5237 61.8699 22.9042 61.6146 23.2177L52.8197 34.0428L44.4471 44.3495L33.4375 57.8989L43.8391 22.1406H61.985Z" fill="#BD80E1" />
                <path d="M35.134 8.02344L43.8391 22.1403H18.2852L26.9902 8.02344H35.134Z" fill="#D9B1EE" />
                <path d="M26.9911 8.02344L18.2861 22.1404L10.3711 8.66184C10.696 8.25857 11.1877 8.02344 11.7061 8.02344H26.9911Z" fill="#BD80E1" />
                <path d="M48.5008 44.5717C48.0883 42.533 46.8795 40.743 45.1424 39.5988C44.9629 39.4806 44.9629 39.2174 45.1424 39.0992C46.8795 37.955 48.0883 36.1651 48.5008 34.1264C48.5455 33.9058 48.8609 33.9058 48.9055 34.1264C49.318 36.1651 50.5268 37.955 52.2639 39.0992C52.4434 39.2174 52.4434 39.4806 52.2639 39.5988C50.5268 40.743 49.318 42.533 48.9055 44.5717C48.8607 44.7923 48.5455 44.7923 48.5008 44.5717Z" fill="#FEE97D" />
                <path d="M21.8376 37.4656C21.4915 35.7546 20.477 34.2524 19.0192 33.2922C18.8686 33.1929 18.8686 32.9721 19.0192 32.8728C20.477 31.9126 21.4914 30.4105 21.8376 28.6994C21.8751 28.5142 22.1398 28.5142 22.1772 28.6994C22.5233 30.4104 23.5378 31.9126 24.9957 32.8728C25.1462 32.9721 25.1462 33.1929 24.9957 33.2922C23.5378 34.2524 22.5234 35.7545 22.1772 37.4656C22.1398 37.6508 21.8751 37.6508 21.8376 37.4656Z" fill="#FEE97D" />
                <path d="M42.6338 8.56171C42.3827 7.3208 41.647 6.23147 40.5897 5.53504C40.4805 5.46311 40.4805 5.30294 40.5897 5.23088C41.647 4.53445 42.3827 3.44499 42.6338 2.20421C42.6609 2.06995 42.8528 2.06995 42.88 2.20421C43.1311 3.44511 43.8668 4.53445 44.9241 5.23088C45.0333 5.30281 45.0333 5.46298 44.9241 5.53504C43.8668 6.23147 43.1311 7.32093 42.88 8.56171C42.8528 8.69596 42.6608 8.69596 42.6338 8.56171Z" fill="#FEE97D" />
            </g>
            <defs>
                <clipPath id="clip0_8244_69164">
                    <rect width="62" height="62" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}