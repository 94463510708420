import React from "react";
import useQuery from "../../../hooks/useQuery";
import ExpandSection from "../../../ui-v2/ExpandSection/ExpandSection";
import ScoringSystemModal from "../ScoringSystemModal/ScoringSystemModal";
import styles from './HowItWorksCard.module.scss';


const Section = ({ title, children }) => (
    <div className='d-flex'>
        <div>
            <small className='d-block mb-2 pb-1'>{title}</small>
            {children}
        </div>
    </div>
)

const HowItWorksCard = props => {
    const isMobile = useQuery()
    return (
        <ExpandSection isOpen={!isMobile}
            title={props.title}
            className={`px-3 small ${props.className || ''} ${styles.description}`}
        >
            {!isMobile && <small className='smaller'>{props.title}</small>}
            <Section title="Make predictions">
                <ul className={`list-unstyled d-grid gap-2 opacity-8 ol`}>
                    <li>Pick a stock.</li>
                    <li>Select how much you think it will move and the direction. </li>
                    <li>Select the date for which your prediction will come true.</li>
                    <li>
                        <p className="my-0 ms-1 text-nowrap">See results </p>
                         <ScoringSystemModal
                            component={({ showPopUp }) => <button id="scoring-system" onClick={showPopUp}
                                className={`btn btn-text fs-inherit text-primary${props.isLoggedIn ? '' : '-lighten-3'} text-underline`}
                            >view scoring system</button>}
                        />
                    </li>
                </ul>
            </Section>
            <Section title="Interact with others' predictions">
                <p className="m-0 opacity-8">You can interact with other's predications with likes and comments.</p>
            </Section>
            <Section title="Keep an eye on the Leaderboard">
                <p className='m-0 opacity-8'>The leaderboard shows top accurate predictors with their average accuracy(%).</p>
            </Section>
        </ExpandSection>
    )
}

export default HowItWorksCard;
