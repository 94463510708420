import React from 'react';
import Card from "./Card";
import LI from './LI';

const Profitability = props => (
    <Card title='Profitability'>
        <ul>
            <LI title='Gross Margin' value={props.grossmargin} />
            <LI title='EBIT Margin' value={props.ebitmargin} end='%' />
            <LI title='EBITDA Margin' value={props.ebitdamargin} end='%' />
        </ul>
        <ul>
            <LI title='Pre-Tax Profit Margin' value={props.pretaxprofitmargin} end='%' />
            <LI title='Net Profit Margin' value={props.profitmargintot} end='%' />
        </ul>
    </Card>
)

export default Profitability;
