import React from 'react';
import styles from './Pricing.module.scss';

const Pricing = ({ price, type, regular_price, onSale, offerFreeTrial, number_free_days, isColumn = false, sale_price, ...props }) => {
    return (
        <div {...props}>
            <div className={`${isColumn ? '' : 'd-flex align-items-end gap-2'} text-nowrap text-accent-4`}>
                <p className="my-0 subtitle fs-normal-xl fs-mediam-lg">
                    <span className={`text-primary ${price === 0 ? 'text-uppercase' : ''}  fw-semibold`}>
                        {price === 0 ? 'Free' : `$${onSale ? sale_price : price}`}
                    </span>
                    {price > 0  && <small> / {type === "Monthly Subscription" ? 'Monthly' : 'one-time'}</small>}
                </p>
                {onSale && (
                    <small className={`smaller text-accent-4 ${styles.priceDetails} position-relative`}>
                        ${regular_price} / {type === "Monthly Subscription" ? 'Monthly' : 'one-time'}
                    </small>
                )}
            </div>
            {offerFreeTrial && (
                <div className={`${styles.freeTrialBadge} d-flex mt-1`}>
                    <span className='smaller bg-accent-3 px-1 text-surface-1 fw-semibold'>Free {number_free_days} day trial</span>
                </div>
            )}
        </div>
    )
}

export default Pricing;
