import React, { useState } from 'react';
import PostModal from '../../LeaderBoardComponents/PostModal/PostModal';
import Tabs from '../../../ui-v2/Tabs/Tabs';
import Explore from '../../LeaderBoardComponents/Main/Explore'
import Filters from '../../LeaderBoardComponents/Main/Filters';
import ClockV2 from '../../../icons-v2/ClockV2';


const All = props => <Explore api={`/fetch_global_feeds?competitionId=${props.competitionId}`} {...props} />
const MyPredictions = props => <Explore api={`/fetch_global_feeds?competitionId=${props.competitionId}&&uid=${props.user._id}`} {...props} />

export default function Posts({competition, ...props}) {
    const [{ posts = [], isAll }, setStatus] = useState({
        isAll: localStorage.getItem(competition._id) === "true" || !props.isLoggedIn
    });
    
    const options = ["My Predictions"]
    
    const visibility = competition.post_visibility;
    const currentUser = competition.currentUser;
    const showPosts = visibility === 'All' || (visibility === 'Participants only' && currentUser) || (currentUser && currentUser.status === 'Admin');
    const Root = showPosts && isAll ? All : MyPredictions;
    if(showPosts) options.push("All")

    const onTabChange = title => {
        const isAll = title === 'All';
        localStorage.setItem(competition._id, isAll)
        setStatus({ isAll });
    }

    const start_date = new Date(competition.start_date);
    const isStarted = start_date.getTime() < new Date().getTime();
    return (
        <div className='d-grid gap-lg-2 gap-1'>
            {props.isLoggedIn && (
                <>
                    <PostModal disabled={!isStarted || !currentUser } competitionId={competition._id} competitionDetails={competition} {...props} onCreatePost={post => setStatus({ posts: [post, ...posts], isAll })} />
                    {isStarted && (
                        <div className='d-flex gap-2 flex-wrap'>
                            <div className='bg-accent-6 flex-1 d-flex p-1 border-radius-4'>
                                <Tabs className='flex-1' options={options} value={isAll && showPosts ? "All" : "My Predictions"} onTabChange={onTabChange} />
                            </div>
                            <Filters {...props} />
                        </div>
                    )}
                </>
            )}
            {isStarted ? <Root competitionId={competition._id} posts={posts} {...props} /> : (
                <>
                    <ClockV2 className='text-primary mx-auto  max-w-full mt-5' />
                    <p className='text-center text-accent-4 fw-semibold mt-2'>Competition will start on <br /> {start_date.toLocaleDateString()}</p>
                </>
            )}
        </div>
    )
}