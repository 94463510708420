import React from 'react';
import useModel from '../../hooks/useModel';
import styles from './Model.module.scss';

const NormalModel = ({ isNormal, Root = "div", isLarge ,className, showPopUp, hidePopUp, children, isPopUp, isSimpleModel = false, isSmall = false, title, ...props }) => {
    const closeHandler = Root === 'form' ? null : hidePopUp;
    useModel({ shouldWork: isPopUp === true, changed: [isPopUp], closeHandler });
    if (typeof Root !== 'string') {
        props.hidePopUp = hidePopUp;
        props.showPopUp = showPopUp;
    }
    return (
        <div className={`${styles.popupWrapper} ${isLarge ? styles.large : ''} ${isSmall ? styles.small : ''} ${isNormal ? styles.normal : ''}`}>
            <button type='button' id={title} className={styles.close} onClick={closeHandler} />
            <Root
                {...props}
                className={`
                    ${styles.popup} ${className} gap-3 gap-lg-4 z-index-1 overflow-auto elevation-3
                    ${isSimpleModel ? '' : 'p-resp'} 
                    ${isSmall ? styles.small : ''}
                `}
            >{children}</Root>
        </div>
    )
}

export default NormalModel;