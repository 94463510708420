import React, { useState } from 'react';
import Icon from "../../../icons-v2/Copy"

export default function Copy({ className = '', title='Copy', after_click="Copied" }) {
    const [active, setActive] = useState(false)
    const onCopy = () => {
        setActive(true);
        navigator.clipboard.writeText(window.location.href)
        setTimeout(() => {
            setActive(false)
        }, 1000)
    }
    return active ? <span className='text-accent-3'><i className="fas fa-check"></i> {after_click}</span> : <button onClick={onCopy} className={className}><Icon /> {title}</button>
}