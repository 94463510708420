import React, { useCallback } from 'react';
import Model from "../../../ui-v2/Modal/Model";
import UserAvatar from '../../UserAvatar/UserAvatar';
import Form from '../../../utility/Form';
import Daimond from '../../../icons-v2/daimond';
import axios from 'axios'
import SelectField from '../../../ui-v2/SelectField/SelectField';
import { MenuItem } from '@material-ui/core';
import InputField from '../../../ui-v2/InputField/InputField';


export default function UnSubscribeModal({ location, posts, onSuccess, userId, user, ...props }) {
    const hash = location.hash;
    const [status, id] = hash.split('=');
    const close = useCallback(() => props.history.replace({ hash: '', search: window.location.search }), []);
    if (status !== '#unsubscribe') return "";
    const post = posts.find(post => (post.userId?._id || userId._id) === id);
    if (!post || !post.premium) return "";

    const onSubmit = data => {
   
        axios.post('/cancel_leaderboard_subscription', {
            leaderboardId: data.leaderboardId._id,
            should_cancel: true
          })
        
        // posts.forEach(post => post.userId._id === id && (post.premium.subscribed = false))
    }
    const pUser = post.userId || userId;
    return (
        <>
            <Model
                title="You're about to unsubscribe from:"
                show={true}
                closeHandler={close}
                Root={Form}
                onSubmit={onSubmit}
                footer={isLoading => (
                    <button className={`btn btn-primary bg-accent-2 me-auto ${isLoading ? 'progress-btn' : ''}`}>Unsubscribe</button>
                )}
            >
                <div>
                    <UserAvatar {...pUser} className='mx-auto mb-2 border-radius-20 h3-h4 premium' />
                    <p className='subtitle mb-0 mt-3 gap-2 flex-middle fw-semibold'>{pUser.name} <Daimond /></p>
                </div>
                <div>
                   
                    <p className='mt-3 mb-2'>Leave private feedback (Optional)</p>
                    <InputField name='review' label='Write your review...' multiline rows={4} />
                </div>
            </Model>
        </>
    )
}