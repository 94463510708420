import React from "react";
import SortableList, { SortableItem } from "react-easy-sort";
import { arrayMoveImmutable } from "array-move";
import Item from "./Item";
import axios from 'axios';

function Main(props) {
    const [items, setItems] = React.useState([...props.algos]);
    const onSortEnd = (oldIndex, newIndex) => {
        const newOrder = arrayMoveImmutable(items, oldIndex, newIndex),
        order = newOrder.map(item => item.algoId._id)
        axios.put('/algo-sort', { order })
        setItems(newOrder);
    };

    return (
        <SortableList
            onSortEnd={onSortEnd}
            className="d-grid gap-3 m-3 col-xl-4 overflow- col-md-3 col-sm-2 no-select"
        >
            {items.map((item) => (
                <SortableItem key={item._id}>
                    <div className="d-flex flex-column">
                        <Item {...item} />
                    </div>
                </SortableItem>
            ))}
        </SortableList>
    );
}


export default function Algos(props) {
    if(props.sort != undefined) props.algos.sort((a, b) => props.sort.indexOf(a.algoId._id) - props.sort.indexOf(b.algoId._id))
    return <Main {...props} />
}