import React from 'react';

const Buildings = props => (
    <svg {...props} viewBox="0 0 22 17" fill="none">
        <g stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
            <path d="M2.75 7.6C2.75 7.03995 2.75 6.75992 2.85899 6.54601C2.95487 6.35785 3.10785 6.20487 3.29601 6.10899C3.50992 6 3.78995 6 4.35 6H6.65C7.21005 6 7.49008 6 7.70399 6.10899C7.89215 6.20487 8.04513 6.35785 8.14101 6.54601C8.25 6.75992 8.25 7.03995 8.25 7.6V16H2.75V7.6Z" />
            <path d="M8.25 2.6C8.25 2.03995 8.25 1.75992 8.35899 1.54601C8.45487 1.35785 8.60785 1.20487 8.79601 1.10899C9.00992 1 9.28995 1 9.85 1H12.15C12.7101 1 12.9901 1 13.204 1.10899C13.3922 1.20487 13.5451 1.35785 13.641 1.54601C13.75 1.75992 13.75 2.03995 13.75 2.6V16H8.25V2.6Z" />
            <path d="M13.75 11.6C13.75 11.0399 13.75 10.7599 13.859 10.546C13.9549 10.3578 14.1078 10.2049 14.296 10.109C14.5099 10 14.7899 10 15.35 10H17.65C18.2101 10 18.4901 10 18.704 10.109C18.8922 10.2049 19.0451 10.3578 19.141 10.546C19.25 10.7599 19.25 11.0399 19.25 11.6V16H13.75V11.6Z" />
            <path d="M1 16L21 16" />
        </g>
    </svg>
)

export default Buildings;