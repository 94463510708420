import React from 'react';
import Model from '../../ui-v2/Modal/Model';
import BarChart from '../../ui-v2/BarChart/BarChart';


const SectorAnalysisModal = props => {
    let updated_sector_breakdown = props.sector_breakdown.map((sector, index) => (
        { title: sector.sector, value: sector.percent_of_portfolio }
    ))
    return(
<Model
        className='container'
        show={true}
        closeHandler={props.closeHandler}
        title={
            <>
                Sector Analysis
                <span className='d-block text-accent-4 fw-normal'>{props.name}</span>
            </>
        }
    >
        <BarChart
            data={updated_sector_breakdown}
        />

    </Model>
    )

}
    


export default SectorAnalysisModal;