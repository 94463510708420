const fields = [
  { 
    name: 'marketcap', 
    title: 'Market Cap', 
    description: "Market Cap (Market Capitalization) is the total value of a company's shares of stock. It's like the price tag of the company if you wanted to buy all of its shares. Companies are often categorized as small-cap, mid-cap, or large-cap: Small-cap (< $2 billion), Mid-cap ($2 billion - $10 billion), and Large-cap (> $10 billion)." 
  },
  { 
    name: 'peratio', 
    title: 'P/E', 
    description: "The P/E (Price-to-Earnings) ratio shows how much investors are willing to pay for each dollar of a company's earnings. A P/E ratio of 15-20 is considered average, with lower values potentially indicating undervaluation and higher values suggesting high expected growth or overvaluation. Compare this number with industry averages to gauge if it's high or low." 
  },
  { 
    name: 'pricetosales', 
    title: 'P/S', 
    description: "The P/S (Price-to-Sales) ratio compares a company's stock price to its sales. A lower P/S ratio (around 1-2) is generally better, as it means you're paying less for each dollar of sales. Different industries have varying standards; tech companies might have higher P/S ratios due to growth expectations." 
  },
  { 
    name: 'pbratio', 
    title: 'P/B', 
    description: "The P/B (Price-to-Book) ratio compares a company's market value to its book value (what it owns minus what it owes). A P/B ratio under 1 might mean the stock is undervalued, while 1-3 is common in many industries. High-tech or asset-light companies may have higher P/B ratios." 
  },
  { 
    name: 'alpha', 
    title: 'Alpha', 
    description: "Alpha measures how much an investment outperforms the market. A positive alpha means it's doing better than the market, while a negative alpha means it's doing worse. Ideally, you want an alpha greater than 0 to indicate added value beyond market performance." 
  },
  { 
    name: 'beta', 
    title: 'Beta', 
    description: "Beta measures a stock's risk compared to the market. A beta of 1 means it moves with the market. Less than 1 means it's less volatile, and greater than 1 means it's more volatile. Defensive stocks typically have a beta < 1, while growth stocks may have a beta > 1." 
  },
  { 
    name: 'currentratio', 
    title: 'Current Ratio', 
    description: "The current ratio indicates a company's ability to pay its short-term debts with its short-term assets. A ratio above 1.5 is generally seen as healthy, while below 1 might be a warning sign. However, different industries may have varying norms." 
  },
  { 
    name: 'leverageratio', 
    title: 'Leverage Ratio', 
    description: "The leverage ratio shows how much of a company's capital comes from debt. A common benchmark is a leverage ratio of 0.5, meaning 50% of capital is from debt. However, some industries with stable cash flows, like utilities, might comfortably operate with higher leverage." 
  },
  { 
    name: 'quickratio', 
    title: 'Quick Ratio', 
    description: "The quick ratio measures a company's ability to pay its debts without selling its inventory. A ratio of 1 or higher is generally considered healthy, indicating the company can meet its short-term obligations without relying on inventory sales." 
  },
  { 
    name: 'stddev', 
    title: 'Standard Deviation', 
    description: "Standard deviation measures how much a stock's price moves from its average price. Low standard deviation (e.g., 10% or less) means stable prices, while high standard deviation indicates greater volatility. It's used to assess risk in investment returns." 
  },
  { 
    name: 'totaldebttoequity', 
    title: 'Debt-to-Equity', 
    description: "The debt-to-equity ratio compares a company’s total debt to its equity (owned funds). A common target is around 1.0, indicating equal debt and equity. High-tech and high-growth industries might operate at higher ratios, but a ratio above 2 is often seen as risky." 
  },
  { 
    name: 'dividendyield', 
    title: 'Dividend Yield', 
    description: "Dividend yield shows the percentage of a company's stock price paid out as dividends each year. A yield of 2-4% is considered good, but it varies by industry. High yields (>4%) might indicate high returns or potential risk if unsustainable." 
  },
  { 
    name: 'grossmargin', 
    title: 'Gross Margin (%)', 
    description: "Gross margin is the percentage of revenue that exceeds the cost of goods sold. A higher margin indicates a more profitable company. Manufacturing might have lower margins (20-40%), while software companies may see margins over 70%." 
  },
  { 
    name: 'profitmargintot', 
    title: 'Profit Margin (%)', 
    description: "Profit margin indicates how much profit is made for each dollar of sales. A margin of 10-20% is typical for many industries. Higher margins suggest better cost control and efficiency. Compare against industry standards for better context." 
  },
  { 
    name: 'returnonequity', 
    title: 'ROE', 
    description: "Return on Equity (ROE) measures how well a company uses shareholders' equity to generate profits. An ROE of 15-20% is generally considered good. Higher ROE suggests efficient use of investment funds to grow profits." 
  },
  { 
    name: 'returnoncapital', 
    title: 'ROC', 
    description: "Return on Capital (ROC) measures how effectively a company turns its capital (debt and equity) into profits. A ROC of 10% or higher is often desirable, indicating efficient use of investments to grow and compete in the market." 
  },
  { 
    name: 'returnonassets', 
    title: 'ROA', 
    description: "Return on Assets (ROA) indicates how profitable a company is in relation to its total assets. A ROA of 5% or more is considered good, showing efficient asset use to generate profit. Compare against peers for a more accurate assessment." 
  },
  { 
    name: 'revenue3years', 
    title: 'RG3', 
    description: "Revenue Growth (3 Years) shows the average annual revenue increase over the last three years. A consistent growth rate of 10% or higher is generally positive, indicating healthy business expansion." 
  },
  { 
    name: 'revenue5years', 
    title: 'RG5', 
    description: "Revenue Growth (5 Years) shows the average annual revenue increase over the last five years. A consistent growth rate of 10% or higher is considered strong, especially if it's above industry averages." 
  },
  { 
    name: 'week52performance', 
    title: '52 Week Performance', 
    description: "52 Week Performance measures how much a stock's price has changed over the past year. Positive performance suggests growth, while negative performance might indicate challenges or market downturns." 
  },
  { 
    name: 'diff_from_high', 
    title: '52 Week High Diff (%)', 
    description: "52 Week High Difference (%) shows the percentage difference between a stock's current price and its highest price over the past year. A large difference might signal a buying opportunity if the stock is expected to recover." 
  },
  { 
    name: 'diff_from_low', 
    title: '52 Week Low Diff(%)', 
    description: "52 Week Low Difference (%) shows the percentage difference between a stock's current price and its lowest price over the past year. A large increase from the low might indicate a recovery or upward trend." 
  },
]
exports.fields = fields;

const old_fields = [
  { name: 'marketcap', title: 'Market Cap', description: "refers to the total dollar market value of a company's outstanding shares of stock." },
  { name: 'peratio', title: 'P/E', description: "used for valuing a company that measures its current share price relative to its earnings per share (EPS). P/E ratios are used by investors and analysts to determine the relative value of a company's shares in an apples-to-apples comparison. It can also be used to compare a company against its own historical record or to compare aggregate markets against one another or over time. A high P/E ratio could mean that a stock is overvalued or investors are expecting high growth rates in the future." },
  { name: 'pricetosales', title: 'P/S', description: "shows a company's market capitalization divided by the company's sales for the previous 12 months. It is a measure of the value investors are receiving from a company's stock by indicating how much equity is required to deliver $1 of revenue. Analysts prefer to see a lower number for the ratio. A ratio of less than 1 indicates that investors are investing less than $1 for every $1 the company earns in revenue." },
  { name: 'pbratio', title: 'P/B', description: "used to compare a firm's market capitalization to its book value. Book value is also the tangible net asset value of a company calculated as total assets minus intangible assets (.e.g. patents, goodwill) and liabilities. P/B ratios under 1 are typically considered solid investments." },
  { name: 'alpha', title: 'Alpha', description: "a term used in investing to describe an investment strategy's ability to beat the market, or its 'edge' Alpha is thus also often referred to as “excess return” or “abnormal rate of return,” which refers to the idea that markets are efficient, and so there is no way to systematically earn returns that exceed the broad market as a whole. Ex As of Feb. 28, 2022, DGRW annualized return was 18.1%, which was also higher than the S&P 500 at 16.4%, so it had an alpha of 1.7% " },
  { name: 'beta', title: 'Beta', description: "is a measure of the volatility—or systematic risk—of a security or portfolio compared to the market as a whole. If beta =1, strongly correlated to market. If beta<1, then less volatile than market. If beta > 1, more volatile than market." },
  { name: 'currentratio', title: 'Current Ratio', description: "The current ratio is a liquidity ratio that measures a company’s ability to pay short-term obligations or those due within one year. A ratio under 1.00 indicates that the company’s debts due in a year or less are greater than its assets—cash or other short-term assets expected to be converted to cash within a year or less. A current ratio of less than 1.00 may seem alarming, although different situations can negatively affect the current ratio in a solid company." },
  { name: 'leverageratio', title: 'Leverage Ratio', description: "Tells you how much capital comes from debt financing. Too much debt can be dangerous for a company and its investors. However, if a company's operations can generate a higher rate of return than the interest rate on its loans, then the debt may help to fuel growth. Uncontrolled debt levels can lead to credit downgrades or worse. On the other hand, too few debts can also raise questions." },
  { name: 'quickratio', title: 'Quick Ratio', description: "The quick ratio measures a company's capacity to pay its current liabilities without needing to sell its inventory or obtain additional financing. More conservative measure than the current ratio. The higher the ratio result, the better a company's liquidity and financial health; the lower the ratio, the more likely the company will struggle with paying debts. " },
  { name: 'stddev', title: 'Standard Deviation', description: "is the statistical measure of market volatility, measuring how widely prices are dispersed from the average price. If prices trade in a narrow trading range, the standard deviation will return a low value that indicates low volatility" },
  { name: 'totaldebttoequity', title: 'Debt-to-Equity', description: "compares a company’s total liabilities to its shareholder equity and can be used to evaluate how much leverage a company is using. Basically how much of operations are funded by debt vs their owned funds. A high D/E ratio is often associated with high risk; it means that a company has been aggressive in financing its growth with debt." },
  { name: 'dividendyield', title: 'Dividend Yield', description: "expressed as a percentage, is a financial ratio that shows how much a company pays out in dividends each year relative to its stock price." },
  { name: 'grossmargin', title: 'Gross Margin', description: "equates to net sales minus the cost of goods sold over the total revenue. The gross margin shows the amount of profit made before deducting selling, general, and administrative (SG&A) costs." },
  { name: 'profitmargintot', title: 'Profit Margin', description: "Expressed as a percentage, profit margin indicates how many cents of profit has been generated for each dollar of sale. A company’s bottom line after all other expenses, including taxes and one-off oddities, have been taken out of revenue." },
  { name: 'returnonequity', title: 'ROE', description: "Return on Equity is a measure of financial performance calculated by dividing net income by shareholders' equity. ROE is considered a gauge of a corporation's profitability and how efficient it is in generating profits. Lots of other factors to consider when basing an investment decision around ROE." },
  { name: 'returnoncapital', title: 'ROC', description: "Return on Capital is a ratio that measures how well a company turns capital (e.g. debt, equity) into profits. In other words, ROC is an indication of whether a company is using its investments effectively to maintain and protect their long-term profits and market share against competitors." },
  { name: 'returnonassets', title: 'ROA', description: "Return on assets is a metric that indicates a company's profitability in relation to its total assets. ROA can be used by management, analysts, and investors to determine whether a company uses its assets efficiently to generate a profit." },
  { name: 'revenue3years', title: 'RG3', description: "Revenue Growth (3) - The average revenue increase as a percent over the last N years. Revenue growth calculates the annualized average rate of change in revenue earned  by a company." },
  { name: 'revenue5years', title: 'RG5', description: "Revenue Growth (5) - The average revenue increase as a percent over the last N years. Revenue growth calculates the annualized average rate of change in revenue earned  by a company." },
  { name: 'week52performance', title: '52 Week Performance', description: "The stock price change over the last year." },
  { name: 'diff_from_high', title: '52 Week High Diff', description: "The percentage difference between the 52 week high price and the current price." },
  { name: 'diff_from_low', title: '52 Week Low Diff', description: "The percentage difference between the 52 week low price and the current price." },



]

