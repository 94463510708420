export default function setCSS() {
    const doc = document.documentElement
    doc.style.setProperty('--app-height', window.innerHeight + 'px');
    
    const classList = document.body.classList;

    if(process.env.NODE_ENV !== 'production') classList.add('development')
    
    if ('ontouchstart' in doc || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0) classList.add('hasTouchEffect');
    else classList.remove('hasTouchEffect')
}