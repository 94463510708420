import { useEffect } from 'react';

const options = { changed: [], shouldWork: true, scrollToTop: false, closeHandler: () => {}, isActive: false };

const useModel = (props = options) => {
    const { changed, shouldWork, scrollToTop, closeHandler, isActive } = { ...options, ...props };
    useEffect(() => {
        if (!shouldWork) return;
        const overflow = document.body.style.overflow;
        if (!overflow.includes('hidden')) {
            if (scrollToTop) window.scroll(0, 0);
            document.body.style.overflow = 'hidden'
            return () => document.body.style.overflow = '';
        };
    }, changed);
    useEffect(() => {
        if(closeHandler && isActive) {
            const close = (e) => (e.keyCode === 27) && closeHandler()
            window.addEventListener('keydown', close)
            return () => window.removeEventListener('keydown', close)
        }
    }, [isActive])
    return null;
}

export default useModel;