import React from 'react';

const BagIcon = props => (
    <svg width="18" height="18" {...props} viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
        <g>
            <path fillRule="evenodd" clipRule="evenodd" d="M1 10.5C1 5.6252 3.00022 4 9 4C15.0007 4 17 5.6252 17 10.5C17 15.3748 15.0007 17 9 17C3.00022 17 1 15.3748 1 10.5Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M11.8341 3.4448V2.91715C11.8341 1.85849 11.0325 1 10.045 1H7.95616C6.96864 1 6.16699 1.85849 6.16699 2.91715V3.4448" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8.99902 12.7813V10.5802" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M16.928 8.56089L16.9028 8.57848C14.7992 9.8281 12.0217 10.5827 8.99573 10.5827C5.96978 10.5827 3.20005 9.8281 1.09734 8.57848L1.07129 8.56089" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </g>
    </svg>
)


export default BagIcon;