import React from 'react';

export default function Chat(props) {
    return (
        <svg fill="none" {...props} viewBox="0 0 31 30" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.38737 8.50984C6.36617 8.33268 7.66597 8.33268 9.62979 8.33268H11.7039C14.6078 8.33268 16.0598 8.33268 17.169 8.90554C18.1447 9.40944 18.9379 10.2135 19.435 11.2024C20.0002 12.3267 20.0002 13.7985 20.0002 16.7421V19.579C20.0002 19.873 20.0002 20.02 19.9944 20.1442C19.9006 22.1522 18.7009 23.8624 16.9976 24.6642M5.38737 8.50984C4.91877 8.59466 4.52375 8.72008 4.16466 8.90554C3.189 9.40944 2.39577 10.2135 1.89865 11.2024C1.3335 12.3267 1.3335 13.7985 1.3335 16.7421V21.1868C1.3335 23.3764 3.08467 25.1515 5.24484 25.1515H5.86889C6.67684 25.1515 7.22931 25.9786 6.92924 26.739C6.50564 27.8125 7.72527 28.7829 8.6534 28.1109L11.3608 26.1507C11.3884 26.1307 11.4023 26.1207 11.4158 26.111C12.2807 25.4932 13.3113 25.1585 14.3694 25.1516C14.386 25.1515 14.4081 25.1515 14.4524 25.1515C14.7759 25.1515 14.9376 25.1515 15.0602 25.1456C15.7501 25.1125 16.4044 24.9434 16.9976 24.6642M5.38737 8.50984C5.46173 7.10829 5.63875 6.13307 6.06012 5.30608C6.69928 4.05167 7.71915 3.03179 8.97356 2.39264C10.3996 1.66602 12.2665 1.66602 16.0002 1.66602H18.6668C22.4005 1.66602 24.2674 1.66602 25.6934 2.39264C26.9478 3.03179 27.9677 4.05167 28.6069 5.30608C29.3335 6.73216 29.3335 8.599 29.3335 12.3327V17.9705C29.3335 20.7478 27.082 22.9993 24.3046 22.9993H23.5023C22.4635 22.9993 21.7532 24.0485 22.139 25.013C22.6836 26.3746 21.1155 27.6055 19.9222 26.7532L16.9976 24.6642" stroke="currentColor" strokeWidth="2" />
            <path d="M8.00016 17.6654C8.00016 18.4017 7.40321 18.9987 6.66683 18.9987C5.93045 18.9987 5.3335 18.4017 5.3335 17.6654C5.3335 16.929 5.93045 16.332 6.66683 16.332C7.40321 16.332 8.00016 16.929 8.00016 17.6654Z" fill="currentColor" />
            <path d="M12.0002 17.6654C12.0002 18.4017 11.4032 18.9987 10.6668 18.9987C9.93045 18.9987 9.3335 18.4017 9.3335 17.6654C9.3335 16.929 9.93045 16.332 10.6668 16.332C11.4032 16.332 12.0002 16.929 12.0002 17.6654Z" fill="currentColor" />
            <path d="M16.0002 17.6654C16.0002 18.4017 15.4032 18.9987 14.6668 18.9987C13.9304 18.9987 13.3335 18.4017 13.3335 17.6654C13.3335 16.929 13.9304 16.332 14.6668 16.332C15.4032 16.332 16.0002 16.929 16.0002 17.6654Z" fill="currentColor" />
        </svg>
    )
}