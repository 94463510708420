import React from 'react';
import Model from '../../ui-v2/Modal/Model';

const LogoutModal = ({ logOutUser }) => {
    return (
        <Model
            isSmall={true}
            actions={hidePopUp => (
                <div className='d-flex gap-4'>
                    <button className='btn btn-text' onClick={hidePopUp}>Cancel</button>
                    <button className='btn btn-text text-accent-2' onClick={logOutUser}>Logout</button>
                </div>
            )}
            title='Are you sure?'
            component={({ showPopUp }) => <button className='btn w-100 text-end' onClick={showPopUp}>Logout</button>}
        >
            <p className='m-auto text-accent-4'>You can come back any time, all your tools and algos are running on the cloud, have fun!</p>
        </Model>
    )
}

export default LogoutModal;