import React from "react";
import { NavLink } from "react-router-dom";

const Tabs = props => (
    <div className='tabs-leaderboard'>
        {props.isLoggedIn && <NavLink to={`/leaderboard/user/${props.user._id}`}>Profile</NavLink>}
        <NavLink exact to='/leaderboard'>Feed</NavLink>
        <NavLink to='/leaderboard/top-10'>Top 10</NavLink>
    </div>
)

export default Tabs;