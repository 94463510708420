import React, { useState } from "react";
import styles from './Form.module.scss';
import axiosErrorHandler from "./axiosErrorHandler";

const Form = ({ footer, onSubmit, children, hidePopUp, showPopUp, ...props }) => {
    const [{ err, isLoading, message }, setStatus] = useState({});

    const onFormSubmit = e => {
        e.preventDefault();
        if (isLoading) return;
        const form = e.target;
        const formData = new FormData(form);
        const values = {};
        for (let key of formData.keys()) {
            const elm = form[key];
            if (elm.type !== 'file') {
                if (elm.length > 0) {
                    values[key] = elm.value || formData.getAll(key);
                    const lastElm = elm[elm.length - 1];
                    const mainElm = lastElm.className.includes("Mui") ? lastElm.parentElement.parentElement : lastElm;
                    !lastElm.checkValidity() ? mainElm.classList.add("invalid-field") : mainElm.classList.remove("invalid-field")
                }
                else {
                    values[key] = elm.value;
                    const mainElm = elm.className.includes("Mui") ? elm.parentElement.parentElement : elm;
                    !elm.checkValidity() ? mainElm.classList.add("invalid-field") : mainElm.classList.remove("invalid-field")
                }
            }
            else {
                const mainElm = elm.className.includes("Mui") ? elm.parentElement.parentElement : elm;
                !elm.checkValidity() ? mainElm.classList.add("invalid-field") : mainElm.classList.remove("invalid-field")
            };
        }
        const isValid = form.checkValidity();
        form.classList.add('submitted');
        if (!isValid) return;
        form.classList.add('loading');
        setStatus({ isLoading: true });
        if (onSubmit) {
            const p = {
                values,
                form: e.target,
                onSuccess: message => {
                    form.classList.remove('submitted');
                    setStatus({ message });
                },
                onFailure: err => setStatus({ err: typeof err === 'string' ? err : axiosErrorHandler(err) }),
                formData,
            };
            if (hidePopUp) p.hidePopUp = hidePopUp;
            if (showPopUp) p.showPopUp = showPopUp;
            onSubmit(p)
        }
    }


    return (
        <form onSubmit={onFormSubmit} {...props} noValidate="novalidate">
            {children}
            {err && <small className='text-accent-2 text-start'>{err}</small>}
            {message && <small className={`text-accent-3 d-block p-3 ${styles.success}`}>
                <span>{message}</span>
            </small>}
            {footer && footer(isLoading)}
        </form>
    )
}

export default Form;