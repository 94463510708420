import React, { useMemo, useState } from 'react';
import { Route, useLocation } from 'react-router-dom';
import ProductPage2 from './live/AlgoHoldingPage/ProductPage';
import ChatBot from './components-v2/ChatBot';
import Portal from './hoc-v2/Portal';
import htmlToString from './utility/htmlToString';

export default function AlgoRoute() {
    const search = useLocation().search;
    const id = new URLSearchParams(search).get('algo_id');
    const [state, set] = useState({})
    
    const algoState = state[id] || { algo: null }

    const algoStateForChat = useMemo(() => {
        if (algoState.algo) {
            const { userId, ...restAlgo } = algoState.algo;
            restAlgo.description = htmlToString(restAlgo.description)
            return { ...algoState, algo: restAlgo };
        }
        return algoState;
    }, [algoState]);

    const setState = (data) => {
        const newAlgoState = { ...algoState, ...data };
        const newState = { ...state, [id]: newAlgoState };
        set(newState)
    }
    
    return (
        <>
            {useMemo(() => <Route path='/algo_page' component={props => <ProductPage2 id={id} setState={setState} {...algoState} {...props} />} />, [id, algoState.algo])}
            <Portal id="chat-wrapper">
                <ChatBot newMessage={algoStateForChat} />
            </Portal>
        </>
    )
}