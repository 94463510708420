import React from 'react';
import AddIcon from '../../icons-v2/AddIcon';
import styles from './tag.module.scss';

const Tag = ({ title, label, className = '', showCross, readonly = false, ...props }) => (
    <label className={`${className} ${styles.root} ${showCross ? styles.showCross : ''} ${readonly ? styles.readonly : ''}`}>
        {!readonly && <input type="checkbox" value={title || label} className='d-none' {...props} />}
        <span>{title || label} <AddIcon className={`rotate-45 ${styles.svg}`} /></span>
    </label>
)

export default Tag;