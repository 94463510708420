import React from 'react';
import SideFilter from '../../components-v2/SideFilter/SideFilter';
import ToolTip from '../../ui-v2/ToolTip/ToolTip';
import BoxField from '../../ui-v2/BoxField/BoxField';
import InputField from '../../ui-v2/InputField/InputField';
import Spinner from '../../ui-v2/Spinner/Spinner';
import SelectField from '../../ui-v2/SelectField/SelectField';
import { MenuItem } from '@material-ui/core';
import PriceRange from "../../components-v2/FiltersSection/PriceRange/PriceRange";
import WatchListImport from '../../components-v2/AlgoHoldingCommonTools/watchlist_import';


const OptionsBestFilter = ({
    initialized,
    handleStockChange,
    stock_search,
    fetchInitialData,
    loading,
    fetch_error_msg,
    show_fetch_error,
    unIntializedHanlder,
    date_list,
    handleChange,
    type,
    date_value,
    selected_filter_best_option,
    filter_best_option_list,
    min_ask,
    max_ask,
    max_cost_to_execute,
    min_traded_volume,
    traded_within_number_days,
    loading_trader,
    fetchOptions,
    authenticated,
    activate_live_trading,
    start,
    handleCheck,
    transaction_type,
    expected_change,
    get_upcoming_stocks_with_earnings,
    handle_filter_change,
    algo
}) => {
    return (
        <SideFilter
            footer={(
                <div className='d-flex align-items-center text-accent-3 gap-3'>
                    {initialized ? (
                        <>
                            {loading_trader ? (
                                <>
                                    <p className='m-0'>Running...</p>
                                    <Spinner fontSize='0.35rem' />
                                    <button className='btn btn-primary fs-normal flex-1 bg-accent-2'>Stop</button>
                                </>
                            ) : <button className='btn btn-primary flex-1' onClick={fetchOptions}>Get Option List</button>}
                        </>
                    ) : (
                        loading ? <p className='m-auto d-flex align-items-center gap-3'>Loading... <Spinner fontSize='0.35rem' /></p> : (
                            <button
                                disabled={loading}
                                onClick={fetchInitialData}
                                className='btn btn-primary flex-1'
                            >Fetch Contract Dates</button>

                        )
                    )}

                    {/* <button
                        className='btn btn-primary fs-normal flex-1'
                        disabled={loading_trader}
                        onClick={fetchOptions}
                    >Run</button> */}
                </div>
            )}
        >
            <div className='d-flex flex-column gap-3 border-bottom pb-3'>
                <div className='d-flex justify-content-between'>
                    <small>Data Filters</small>
                    <WatchListImport handleStockChange={handleStockChange} algo={algo} />
                </div>
                <InputField
                    name='stock_search'
                    label="Stock Symbols"
                    disabled={initialized || loading}
                    onChange={handleStockChange}
                    value={stock_search}
                />
                {/*<button className='btn-text btn' onClick={get_upcoming_stocks_with_earnings}>Load stocks with upcoming earnings?</button>*/}
                <div className='d-flex text-accent-3 gap-3 align-items-center'>
                    {initialized && (
                        <button
                            disabled={loading}
                            className='btn btn-text me-auto'
                            onClick={unIntializedHanlder}
                        >Change symbol?</button>
                    )}
                    {show_fetch_error && <small className='text-accent-2'>{fetch_error_msg}</small>}
                </div>
                <>
                    <div className='d-grid col-2 overflow-initial'>
                        <ToolTip title="Select this if you would like to filter for call options.">
                            <BoxField name="type"
                                type='radio'
                                onChange={handleChange}
                                value="calls"
                                label='Call Options'
                                checked={type === 'calls'}
                            />
                        </ToolTip>
                        <ToolTip title="Select this if you would like to filter for put options.">
                            <BoxField
                                name="type"
                                type='radio'
                                value="puts"
                                onChange={handleChange}
                                label='Put Options'
                                checked={type === 'puts'}
                            />
                        </ToolTip>
                    </div>
                    <div className='d-grid col-2 overflow-initial'>
                        <ToolTip title="Select this if you plan on buying the options.">
                            <BoxField
                                name="transaction_type"
                                type='radio'
                                onChange={handleChange}
                                value="Buy"
                                label='Buying Options'
                                checked={transaction_type === 'Buy'}
                            />
                        </ToolTip>
                        <ToolTip title="Select this if you plan on selling the options.">
                            <BoxField name="transaction_type"
                                type='radio'
                                value="Sell"
                                onChange={handleChange}
                                label='Selling Options'
                                checked={transaction_type === 'Sell'}
                            />
                        </ToolTip>
                    </div>
                </>
                <div className='d-flex flex-column gap-3 mt-3'>
                    <SelectField
                        title="Select the sorting mechanism that you would like to use for the results."
                        value={selected_filter_best_option}
                        name="selected_filter_best_option"
                        onChange={handleChange}
                        label="Sort By"
                    >
                        {filter_best_option_list.map((filter, index) => (
                            <MenuItem key={index} value={Object.values(filter)[0]}>{Object.keys(filter)[0]}</MenuItem>
                        ))}
                    </SelectField>
                    <h8 className='overline m-0'>Contract Price</h8>
                    <PriceRange name={"contract_ask"} showDollar={true} show_slider={false} min={0} max={10000} onChange={(event, values) => handle_filter_change(event, values)} />
                    {/*<InputField
                        title='Enter in the minimum amount you would like to pay for a contract. This will make sure that the current ask price of the contract is above the number that you enter.'
                        name='min_ask'
                        label="Minimum Contract Ask"
                        type="number"
                        onChange={handleChange}
                        value={min_ask}
                    />
                    <InputField
                        title='Enter in the maximum amount you would like to pay for a contract. This will make sure that the current ask price of the contract is less than the number that you enter.'
                        name='max_ask'
                        label="Max Contract Ask"
                        type="number"
                        onChange={handleChange}
                        value={max_ask}
                    />
                    */}
                    <InputField
                        title='Enter in the maximum amount you are willing to pay to buy and excercise the contract (this is used to determine margin requirements by brokers). This number entails buying the option (100xStrike+100xStockPrice).'
                        name='max_cost_to_execute'
                        label="Max Cost To Execute"
                        type="number"
                        onChange={handleChange}
                        value={max_cost_to_execute}
                    />
                    {/*
                      <ToolTip title='Enter in the minimum amount of trades that the contract must have gone through.'>
                          <InputField
                              name='min_traded_volume'
                              label="Contract minimum traded volume"
                              type="number"
                              onChange={handleChange}
                              value={min_traded_volume}
                          />
                      </ToolTip>
                      */}

                    <InputField
                        title='Enter in the expected change in the time frame. Calculation assumes change occurs today. (buying assumes increase ITM & selling assumes increasing OTM)'
                        name='expected_change'
                        label="Expected stock change(%)"
                        type="number"
                        onChange={handleChange}
                        value={expected_change}
                    />
                    {/*
                      <ToolTip title='Some options are illiquid, enter in the number of days since the contract has been last traded, that you are okay with scanning for.'>
                          <InputField
                              name='traded_within_number_days'
                              label="Traded with in the last number of days"
                              type="number"
                              onChange={handleChange}
                              value={traded_within_number_days}
                          />
                      </ToolTip>
                      */}
                </div>
            </div>

            {initialized && authenticated &&
                <BoxField
                    checked={activate_live_trading}
                    onChange={handleCheck}
                    disabled={start}
                    name="activate_live_trading"
                    label="Activate Live Trading"
                />
            }
            {date_list.length > 0 && (
                <SelectField
                    name="date_value"
                    label='Contract Expiry'
                    onChange={handleChange}
                    value={date_value}
                >
                    <MenuItem value='all'>All</MenuItem>
                    {date_list.map((date) => (
                        <MenuItem value={date.value}>{date.title}</MenuItem>
                    ))}
                </SelectField>
            )}


        </SideFilter>
    )
}

export default OptionsBestFilter;
