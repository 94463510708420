import React from 'react';
import SideFilter from '../../components-v2/SideFilter/SideFilter';
import InputField from '../../ui-v2/InputField/InputField';
import moment from "moment";
import PickersUtilsProvider from '../../ui-v2/PickersUtilsProvider/PickersUtilsProvider';
import DatePicker from '../../ui-v2/DatePicker/DatePicker';
import StockTable from './StockTable';
import ToolTip from '../../ui-v2/ToolTip/ToolTip';
import BoxField from '../../ui-v2/BoxField/BoxField';

const CoorelationFilter = ({ handleChange, isLoading, stocks, start_date, end_date, error_msg, handleStartChange, handleEndChange, submitStocks, addStock, handleStockChange, layout,find_similar_stocks_to }) => {
    return (
        <SideFilter>
            <div className='d-grid col-2 overflow-initial'>
                        <ToolTip title="">
                            <BoxField name="layout"
                                type='radio'
                                onChange={handleChange}
                                value="table"
                                label='Find Comparable Stocks'
                                checked={layout === "table"}
                            />
                        </ToolTip>
                        <ToolTip title="">
                            <BoxField
                                name="layout"
                                type='radio'
                                value="graph"
                                onChange={handleChange}
                                label='Compare Stocks'
                                checked={layout === "graph"}
                            />
                        </ToolTip>
                    </div>
            {layout !== 'table' ? (
            <div className='d-flex flex-column gap-3'>
                <p>Comparison Dates</p>
            <PickersUtilsProvider>
                    <DatePicker
                        inputVariant='outlined'
                        label="Start Date (historical data)"
                        value={moment(start_date)}
                        name='start_date'
                        onChange={handleStartChange}
                    />
                    <DatePicker
                        className="__custom-input-field"
                        label="End Date (historical data)"
                        value={moment(end_date)}
                        name='end_date'
                        onChange={handleEndChange}
                    />
                </PickersUtilsProvider>
                <p>Stocks to Compare</p>
                {stocks.map((stock, index) => (
                    <InputField
                    key={index}
                    name={`stock_${index}`}
                    label={`Stock ${index+1}`}
                    value={stock}
                    onChange={e => handleStockChange(index, e.target.value)}
                    placeholder='Enter Stock Name'
                />
                ))}
                <button className='btn btn-primary' style={{backgroundColor:"green"}} onClick={addStock}>Add Stock +</button>
                
                
                
                <button
                    className={`btn btn-primary ${isLoading ? 'progress-btn' : ''}`}
                    onClick={submitStocks}
                    disabled={isLoading}
                >
                    Compare
                </button>
                {error_msg.length>0 &&
                  <p style={{color:"red"}}>{error_msg}</p>
                }
            </div>
            ) : (
                <>
               <p>Correlated Stocks</p>
               <InputField
                    name={`find_similar_stocks_to`}
                    label={`Find Correlated Stocks`}
                    value={find_similar_stocks_to}
                    onChange={e => handleChange( e)}
                    placeholder='Enter Stock Symbol'
                />
                </>
            )}
        </SideFilter>
    )
}

export default CoorelationFilter;
