import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useApp } from "../../App";
import UserAvatar from "../UserAvatar/UserAvatar";
import getProfile from "../../utility/getProfile";

const useLeaderboard = () => {
    const auth = useApp();
    const history = useHistory();
    const checkLogin = () => {
        if (!auth.isLoggedIn) history.push({ hash: 'register' });
        return auth.isLoggedIn;
    }
    const getUser = (className = 'smaller') => (
        <UserAvatar
            {...getProfile(auth.user)}
            className={`${className} cursor-pointer`}
            onClick={() => history.push(
                auth.isLoggedIn ? `/leaderboard/user/${auth.user._id}` : { hash: 'register' }
            )}
        />
    )
    useEffect(() => {
        if (history.location.hash.includes('follow')) history.replace({ hash: '' })
    }, [])

    return { ...auth, checkLogin, getUser, history }
}

export default useLeaderboard;