import React from 'react';
import HeaderLink from './HeaderLink';
import BagIcon from '../../icons-v2/BagIcon';
import HomeIcon from '../../icons-v2/HomeIcon';
import Buildings from '../../icons-v2/Building';
import styles from './styles.module.scss';
import LinksWrapper from './LinksWrapper';

const Main = props => {
    const getBadge = (className = '') => <small className={`fw-semibold position-absolute bg-accent-1 smaller px-1 text-normal ${className}`}>New</small>
    return (
        <LinksWrapper {...props}>
            <HeaderLink title='Data' exact id="marketplace-link" to='/' Icon={HomeIcon} />
            <HeaderLink title='Advanced' exact id="marketplace-link" to='/' Icon={HomeIcon} />
            <HeaderLink title='API' exact id="marketplace-link" to='/' Icon={HomeIcon} />

           
        </LinksWrapper>
    )
}

export default Main;