const dependentTagPositionHandler = (tag1, tag2) => {
    const cb = () => {
        const domWidth = window.innerWidth || document.documentElement.clientWidth;
        const domHeight = window.innerHeight || document.documentElement.clientHeight;
        const style = tag2.style
        const rect1 = tag1.getBoundingClientRect();
        style.left = rect1.x + 'px';
        style.top =  rect1.bottom + 'px';
        const rect2 = tag2.getBoundingClientRect();
        if (rect2.left < 0 || rect2.right > domWidth) {
            style.left = 'initial';
            style.right = (domWidth - rect1.right) + 'px'
        }
        if (!(rect2.top >= 0 && rect2.bottom <= domHeight)) {
            style.top = (rect1.bottom - rect2.height - rect1.height) + 'px';
        }
    }
    cb();
    window.addEventListener('resize', cb);
    window.addEventListener('scroll', cb);
    return () => {
        window.removeEventListener('resize', cb);
        window.removeEventListener('scroll', cb);
    }
}

export default dependentTagPositionHandler;