import React, { useEffect } from 'react';
import Model from '../../ui-v2/Modal/Model';
import styles from './AuthPopUp.module.scss';
import SignUpPage from '../SignUpPage';
import LoginPage from '../login';
import { useHistory, useLocation } from 'react-router-dom';


const AuthPopUp = ({ isLoggedIn }) => {

    const { search, hash } = useLocation();
    const history = useHistory();
    const isActive = /register|login/i.test(hash);

    const closeHandler = () => history.replace({ search, hash: '' });

    useEffect(() => {
        if (isActive && isLoggedIn){
           closeHandler()
           window.location.reload();
         };
    }, [isLoggedIn])
    return isActive && (
        <Model show={true} isNormal={true} className={styles.root} closeHandler={closeHandler}>
            {hash === '#login' ? <LoginPage isPopup={true} /> : <SignUpPage isPopup={true} />}
        </Model>
    )
}

export default AuthPopUp;
