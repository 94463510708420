import React from 'react';
import styles from './styles.module.scss';

const ListTile = ({ title, subtitle, imageURL, className }) => (
    <div className={`${styles.root} ${className} align-items-center d-flex`}>
        {imageURL && <img src={imageURL} alt='' className={`border-radius-14 ms-3 ${styles.img}`} />}
        <div className='me-lg-1'>
            <h6 className='mt-0 mb-1 h6-sm'>{title}</h6>
            <p className='m-0 text-accent-4 small-sm'>{subtitle}</p>
        </div>
    </div>
)

export default ListTile;