import React from 'react';

const Message = props => (
    <svg viewBox="0 0 18 19" fill="none" {...props}>
        <path
            d="M15.3087 1.08838H2.69108C1.53117 1.08838 0.588135 2.03136 0.588135 3.1912V12.6539C0.588135 13.8137 1.53117 14.7567 2.69108 14.7567H5.84549V17.5166C5.84549 17.8386 6.2135 18.0259 6.47308 17.8354L10.5771 14.7567H15.3087C16.4686 14.7567 17.4117 13.8137 17.4117 12.6539V3.1912C17.4117 2.03136 16.4686 1.08838 15.3087 1.08838Z"
            stroke="currentColor"
            strokeLinecap="round"
        />
    </svg>
)

export default Message;