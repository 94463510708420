import React from 'react';

export default function Daimond(props) {
    return (
        <svg {...props} width="26" height="22" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.1554 1.56828C19.9678 1.38075 19.7135 1.27539 19.4483 1.27539H6.55172C6.28651 1.27539 6.03215 1.38075 5.84462 1.56828L1.29289 6.12001C0.924064 6.48884 0.900673 7.07921 1.23917 7.47606L12.2392 20.3726C12.4292 20.5953 12.7072 20.7236 13 20.7236C13.2928 20.7236 13.5708 20.5953 13.7608 20.3726L24.7608 7.47606C25.0993 7.07921 25.0759 6.48884 24.7071 6.12001L20.1554 1.56828Z" fill="#C781F0" stroke="white" stroke-width="2" stroke-linejoin="round" />
            <path d="M19.4483 2.27539L13 19.7236L24 6.82711L19.4483 2.27539Z" fill="#DFA8FF" />
            <path d="M6.55172 2.27539L8.23408 6.82711H2L6.55172 2.27539Z" fill="#BC77E3" />
            <path d="M13.0003 2.27539L8.23438 6.82711H17.7662L13.0003 2.27539Z" fill="#DFA8FF" />
            <path d="M17.7656 6.82711H23.9997L19.448 2.27539L17.7656 6.82711Z" fill="#E8C2FF" />
            <path d="M2 6.82812H8.23408L13 19.7246L2 6.82812Z" fill="#9857BD" />
        </svg>
    )
}