import React from 'react';
import Model from '../../ui-v2/Modal/Model';
import StripePaymentPopup from '../../components-v2/StripePaymentPopup/StripePaymentPopup';
import successImg from '../../assets/purchaseComplete.svg';
import Payment from './Payment';

const ConfirmPayment = ({ successFooter, open, onSuccess, onPaymentChange, close, ...props }) => {
    const stripe_payment = props.auth.stripe_payment_method ? false : true,
    [state, setState] = React.useState(open ? stripe_payment : null),
    // [state, setState] = React.useState(true);
    onClose = React.useCallback(() => close ? close() : setState(null), [close]);
    if (state === 'success') return (
        <Model
            title="Purchase Completed"
            closeHandler={onClose}
            show={true}
            actions={close => (
                <div className='d-flex gap-inherit'>
                    {successFooter}
                    <button type='button' onClick={close} className='btn btn-primary'>Close</button>
                </div>
            )}
        >
            <img src={successImg} alt="" className="w-100" />
            {props.successTitle && <h6 className='m-0 text-center fw-mediam text-accent-3'>{props.successTitle}</h6>}
        </Model>
    );
    return (
        <>
            {!open && <button id='confirm-payment-modal' onClick={() => setState(stripe_payment)} className='d-none' type='button'></button>}
            {typeof state === 'boolean' && (state ? (
                <StripePaymentPopup
                    close={onClose}
                    onSuccess={res => {
                        setState(false);
                        onPaymentChange && onPaymentChange(res);
                    }}
                />
            ) : (
                <Payment {...props}
                    close={onClose}
                    onPaymentChange={() => setState(true)}
                    onSuccess={res => {
                        setState("success");
                        onSuccess && onSuccess(res);
                    }}
                />
            ))}
        </>
    )
}

export default ConfirmPayment;