import React from 'react';
import { KeyboardTimePicker } from '@material-ui/pickers';

const TimePicker = props => (
    <KeyboardTimePicker
        {...props}
        inputVariant='outlined'
        KeyboardButtonProps={{
            'aria-label': 'change time',
        }}
        className="__custom-input-field"
    />
)

export default TimePicker;