import React from 'react';
import axios from 'axios'
import URL from 'url-parse';
import { AppContext } from '../../App'
import withAlgoProductToolsLayout from '../../hoc-v2/withAlgoProductToolsLayout/withAlgoProductToolsLayout';
import RSIFilters from './RSIFilters';
import LineChart2 from '../../ui-v2/LineChart/LineChart';
import FilterButton from '../../components-v2/AlgoHoldingCommonTools/FilterButton';

class RSI_Container extends React.Component {
  static contextType = AppContext;
  render() {
    return (
      <RSI
        {...this.props}
        {...this.context} />
    )
  }
}


class RSI extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: new URL(window.location.href, true),
      stock_search: "aapl",
      interval: "daily",
      time_period: 14,
      auto_run: true,

      stock_data: [],
      rsi_data: [],
      api_key_index: 0,
      start: false,
      end: true,
      signal: "",
      volume_shares_per_trade: 0,
      authenticated: false,
      activate_live_trading: false,
      make_trades_trail: false,
      trades_trail_by: 0,



    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
    this.handleEnd()
  }

  confirm_authentication = () => {
    this.setState({
      authenticated: true
    })

  }

  handleStart = (e) => {
    this.setState({
      start: true,
      end: false,
      isLoading: true
    }, () => {
      this.submitStocks()
    })

  }

  handleEnd = (e) => {
    this.setState({
      start: false,
      end: true
    })
  }




  submitStocks = (e) => {
    if (this.state.start === true) {
      axios.post('/fetch_stock_price', {
        stock_search: this.state.stock_search,
        interval: this.state.interval,
        time_period: this.state.time_period,
        api_key_index: this.state.api_key_index
      }).then((response) => {

        this.setState({
          stock_data: response.data.data,
          isLoading: false
        })
      })

      axios.post('/get_rsi_with_slope', {
        stock_search: this.state.stock_search,
        interval: this.state.interval,
        time_period: this.state.time_period,
        api_key_index: this.state.api_key_index
      }).then((response) => {

        this.setState({
          rsi_data: response.data.data,
          api_key_index: response.data.index,
          signal: response.data.data.slice(-1)[0]
        })
        //
        //   if (this.state.start===true){
        //   setTimeout(this.submitStocks, 150000);
        // }


      })
    }

  }

  handleCheck = (e) => {
    this.setState({
      [e.target.name]: e.target.checked
    })
  }
  componentDidMount() {
    if (this.props.state) return this.setState(this.props.state);
  }


  render() {
    this.props.getState && this.props.getState(this.state);
    this.props.setResponsive(this.state.stock_data.length > 0);
    return (

      <>
        <RSIFilters {...this.state}
          handleChange={this.handleChange}
          handleCheck={this.handleCheck}
          handleStart={this.handleStart}
        />
        <div className='flex-1 p-lg-5 p-md-4 p-3 gap-3'>
          <p className='mt-0'>This uses historic data, please check the premium version for realtime data.</p>
          {this.state.stock_data.length > 0 && <div className='d-flex mb-3 justify-content-between align-items-center'>
            <small className='text-accent-4'>Total Resutls {this.state.stock_data.length}</small>
            <FilterButton />
          </div>}
          {/*
      <InteractiveBrokerStocks
        signal ={this.state.signal}
        stock = {this.state.stock_search}
        volume_per_trade={this.state.volume_shares_per_trade}
        confirm_authentication={this.confirm_authentication}
        activate_live_trading={this.state.activate_live_trading}
        make_trades_trail={this.state.make_trades_trail}
        trades_trail_by={this.state.trades_trail_by}
       />
       */}
          {/*
     <Button
        variant="contained"
        color="secondary"
         disabled={this.state.end}

        onClick={this.handleEnd}
      >
      End
    </Button>
    */}

          {this.state.stock_data.length > 0 &&
            <div className='d-grid col-lg-2 gap-5 gal-lg-0'>
              <LineChart2
                data={this.state.stock_data}
                dataKeys={[
                  { dataKey: 'price', stroke: "#8884d8", activeDot: { r: 8 } },
                ]}
              />
              <LineChart2
                data={this.state.rsi_data}
                dataKeys={[
                  { type: "monotone", dataKey: "RSI", stroke: "#8884d8", activeDot: { r: 8 } }
                ]}
              />

            </div>
          }

        </div>
      </>
    )
  }
}

export default withAlgoProductToolsLayout(RSI_Container, 'RSI');