import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import HeroSection from '../../components-v2/CompeComponents/HeroSection';
import useGet from '../../hooks/useGet';
import JoinCompetition from '../../components-v2/CompetitionComponents/JoinCompetition';
import Participants from '../../components-v2/CompeComponents/Participants';
import Posts from '../../components-v2/CompeComponents/Posts';
import useLeaderboard from '../../components-v2/LeaderBoardComponents/useLeaderboard';
import axios from 'axios';
import getProfile from '../../utility/getProfile';
import Model from '../../ui-v2/Modal/Model';
import BroadCast from '../../icons-v2/BroadCast';
import Copy from '../../components-v2/CompeComponents/HeroSection/Copy';
import TopLeaderBoardsCard from '../../components-v2/LeaderBoardComponents/TopLeaderBoardsCard/TopLeaderBoardsCard';
import Tabs from '../../components-v2/CompeComponents/Tabs';

const Main = props => {
    const [competition, setCompetion] = useState({ ...props });
    const tools = useLeaderboard();
    const onUpdate = React.useCallback((status, userId) => axios.post(`/leaderboard-competition/request/${props._id}`, { userId, status }), [])
    const join = () => {
        const participant = { ...getProfile(tools.user), status: 'Participant', totalPosts: 0 }
        const participants = [participant, ...competition.participants];
        setCompetion({
            ...competition,
            currentUser: competition.participant || participant,
            participants
        })
    }

    if ((!competition.currentUser || competition.currentUser.status === 'Pending') && competition.privacy === 'Private') return <JoinCompetition join={join} {...tools} {...competition} />

    const onAccept = participant => {
        const id = participant._id;
        setCompetion({
            ...competition,
            participants: [participant, ...competition.participants],
            pendings: competition.pendings.filter(p => p._id !== id),
            totalParticipants: competition.totalParticipants + 1
        });
        onUpdate('Participant', id)
    }
    const onReject = id => {
        setCompetion({ ...competition, pendings: competition.pendings.filter(p => p._id !== id) })
        onUpdate('Rejected', id)
    }

    const onCompetitionUpdate = data => setCompetion({ ...competition, ...data })
    
    return (
        <>
            <HeroSection {...competition} onCompetitionUpdate={onCompetitionUpdate} {...tools} join={join} />
            <Tabs />
            <div className='my-lg-4 my-1 gap-lg-3 with-leaderboard'>
                <Participants {...competition} {...tools} onAccept={onAccept} onReject={onReject} />
                <Posts {...tools} competition={competition} />
                <TopLeaderBoardsCard />
            </div>
        </>
    )
}

export default function CompetitionPage(props) {
    const id = useParams().id;
    const { data, err, Loader } = useGet(`/leaderboard-competition/${id}`)
    if (Loader) return <Loader toMiddle />
    console.clear();
    if (err) return (
        <div className='translate-middle position-absolute text-center'>
            <h2 className='mt-0 text-accent-2'>{err}</h2>
            <Link to='/' className='btn btn-primary d-inline-flex'>Marketplace</Link>
        </div>
    )
    const modal = window.location.search.includes('new=true');
    return (
        <>
            <Model isNormal={true} closeHandler={() => props.history.replace({ search: "" })} className='small text-center' title='Share Participation Link' show={modal}>
                <BroadCast className='mx-auto' />
                <div>
                    <p className='d-flex gap-2 mt-0 mb-1 justify-content-center'><b>Participation link</b><Copy className='btn-text btn gap-1 small' /></p>
                    <p className='m-0 text-accent-4'>{window.location.href}</p>
                </div>
                <Copy className='btn btn-primary mx-auto' title='Copy Link' />
            </Model>
            <Main {...data} />
        </>
    )
}