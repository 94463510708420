import React from 'react';
import Tooltip from '../../ui-v2/ToolTip/ToolTip';
import ClockLoader from "react-spinners/ClockLoader";
import StockItem from './StockItem';
import moment from 'moment';
import Circle from '../../ui-v2/Circle/Circle';
import useQuery from '../../hooks/useQuery';
import ToolTip from '../../ui-v2/ToolTip/ToolTip';
import FilterButton from '../../components-v2/AlgoHoldingCommonTools/FilterButton';

const TipWrapper = ({ stepNo }) => {
    const isOpen = !useQuery() && stepNo === 1;
    return (
        <th id='tip-1'>
            <ToolTip title="Number of different participants that have traded the stock." open={isOpen}>
                <span className='position-relative'>
                    {isOpen && <Circle />}
                    Participants
                </span>
            </ToolTip>
        </th>
    )
}

const NormalTable = ({ stock_data = [], graph, page, no_result, graphStock, stepNo }) => {
    return (
        <>
            <thead>
                <tr>
                    <Tooltip title="The row number.">
                        <th><div>No</div></th>
                    </Tooltip>
                    <Tooltip title="The stock symbol.">
                        <th>Symbol</th>
                    </Tooltip>
                    <Tooltip title="The name of the House.">
                        <th>Owner</th>
                    </Tooltip>
                    <Tooltip title="Number of transactions that took place on the stock.">
                        <th>Transactions</th>
                    </Tooltip>
                    <TipWrapper stepNo={stepNo} />
                    <Tooltip title="The date that the order occurred.">
                        <th>Date</th>
                    </Tooltip>
                    <Tooltip title="The type of transaction that occurred.">
                        <th>Transaction Type</th>
                    </Tooltip>
                    <Tooltip title="The stock price that the trade occurred at.">
                        <th>Cost</th>
                    </Tooltip>
                </tr>
            </thead>
            <tbody>

                {
                    stock_data.length > 0 ?
                        stock_data.map((stock, index) => (
                            <tr key={index}>
                                <td title='No'><div>{page * 10 + index + 1}</div></td>
                                <td title='Symbol'>
                                    <button disabled={graph.index === index && graph.symb === stock.ticker} className='badge d-flex align-items-center gap-2' key={index}
                                        onClick={(event) => graphStock(event, index, stock.ticker, stock.transaction_date, `House Reaction for ${stock.ticker}`)}
                                    >
                                        {stock.ticker}
                                        {
                                            graph.index === index && graph.symb === stock.ticker &&
                                            <ClockLoader size={18} />
                                        }
                                    </button>
                                </td>
                                <td title='Owner'>{stock.representative}</td>
                                <td title='Transactions'>{stock.individual}</td>
                                <td title='Participants'>{stock.include}</td>
                                <td title='Date'>{moment(stock.transaction_date).format('YYYY-MM-DD')}</td>
                                <td title='Transaction'>{stock.transaction_type}</td>
                                <td title='Cost'>~${parseFloat(stock.amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
                            </tr>
                        ))
                        :
                        <tr>
                            {
                                no_result &&
                                <td colSpan={20}>
                                    <div className='text-center'>No Results...</div>
                                </td>
                            }
                        </tr>
                }
            </tbody>
        </>
    )
}

const ExpandableTableWrapper = ({ stock_data = [], graph, page, no_result, graphStock, selectionRange, stepNo }) => {
    return (
        <>
            <thead>
                <tr>
                    <Tooltip title="The row number.">
                        <th><div>No</div></th>
                    </Tooltip>
                    <Tooltip title="The stock symbol.">
                        <th>Symbol</th>
                    </Tooltip>
                    <Tooltip title="Number of transactions that took place on the stock.">
                        <th>Transactions</th>
                    </Tooltip>
                    <TipWrapper stepNo={stepNo} />
                    <th>Date Range</th>
                    {/* <th>Transaction Type</th> */}
                    <th>Cost Range ($)</th>
                </tr>
            </thead>
            <tbody>
                {
                    stock_data.length > 0 ?
                        stock_data.map((stock, index) => (
                            <StockItem stock={stock} key={index} selectionRange={selectionRange} page={page * 10 + index + 1} />
                        ))
                        :
                        <tr>
                            {
                                no_result &&
                                <td colSpan={20} className="text-center vertical-align-top pt-3">
                                    <span className="fz14">No Results...</span>
                                </td>
                            }
                        </tr>
                }
            </tbody>
        </>
    )
}

const StockTable = props => {
    return (
        <>
            <div className='d-flex justify-content-between mb-3'>
                <small className="m-0 text-accent-4">Total Results {props.totalLength}</small>
                <FilterButton />
            </div>
            <div className='table-wrapper product_tools_table'>
                <div className={props.stepNo === 1 ? 'overflow-hidden' : ''}>
                    <table>
                        {props.tableView === "individual" ? <NormalTable {...props} /> : <ExpandableTableWrapper {...props} />}
                    </table>
                </div>
            </div>
        </>
    )
}

export default StockTable;