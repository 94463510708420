import React, { Component } from 'react';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Model from '../ui-v2/Modal/Model';
import ArrowRightIcon from '../icons-v2/ArrowRightIcon';
import usePost from '../hooks/usePost';
import Spinner from '../ui-v2/Spinner/Spinner';
import ReactChart from '../components-v2/ReactChart/ReactChart';

const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + (i * step));

class Main extends Component {
  state = {
    carousel_number: 0,
    data: [
      {
        label: 'Hold',
        data: [[0, 3], [1, 1], [2, 5], [3, 6], [4, 4]]
      },
      {
        label: 'Buy',
        data: [[0.5, 3], [2, 5], [3, 6], [4, 4]]
      },
      {
        label: 'Sell',
        data: [[0, 3], [1, 1], [2, 5], [3, 6], [4, 4]]
      },
    ],
    all_data: [],
    axes: [
      { primary: true, type: 'linear', position: 'bottom' },
      { type: 'linear', position: 'left' }
    ],
    series: {
      type: 'bubble',
      showPoints: false
    },
    data_arr: [],
    buy_broker_fees: 0,
    sell_broker_fees: 0

  }
  componentDidMount() {

    if (this.props.results.length > 0) {

      this.create_chart_data()



    }

  }

  create_chart_data = async function () {
    let current_data = this.props.results[this.state.carousel_number]
    let time_data = this.props.time_arr[this.state.carousel_number]
    await this.process_buy_data(current_data, time_data)
    await this.process_sell_data(current_data, time_data)
    await this.process_hold_data(current_data, time_data)
  }
  process_buy_data = function (current_data, time_data) {
    return new Promise((resolve, reject) => {
      let buy_arr = []
      current_data.states_buy.map((buy, index) => {
        let temp_buy = [buy, parseFloat(time_data[buy].close)]
        buy_arr.push(temp_buy)
        if (buy_arr.length === current_data.states_buy.length) {

          let obj = {
            label: 'Buy',
            data: buy_arr
          }
          this.setState({
            data: [this.state.data[0], obj, this.state.data[2]],
            buy_broker_fees: current_data.states_buy.length * this.props.broker_fee
          })
          resolve()
        }
        return true
      })
    })
  }
  process_sell_data = function (current_data, time_data) {
    return new Promise((resolve, reject) => {
      let sell_arr = []
      current_data.states_sell.map((sell, index) => {
        let temp_sell = [sell, parseFloat(time_data[sell].close)]
        sell_arr.push(temp_sell)
        if (sell_arr.length === current_data.states_sell.length) {
          let obj = {
            label: 'Sell',
            data: sell_arr
          }
          this.setState({
            data: [this.state.data[0], this.state.data[1], obj],
            sell_broker_fees: current_data.states_sell.length * this.props.broker_fee
          })
          resolve()
        }
        return true
      })
    })
  }
  process_hold_data = function (current_data, time_data) {
    return new Promise((resolve, reject) => {
      let hold_arr = []
      let buy_and_sell = [...current_data.states_sell, ...current_data.states_buy]
      let data_arr = range(0, current_data.data_set.length - 1, 1)
      let filtered_arr = data_arr.filter((x) => !buy_and_sell.includes(x))

      filtered_arr.map((hold, index) => {
        let temp_hold = [hold, parseFloat(time_data[hold].close)]
        hold_arr.push(temp_hold)
        if (hold_arr.length === filtered_arr.length) {
          let obj = {
            label: 'Hold',
            data: hold_arr
          }
          this.setState({
            data: [obj, this.state.data[1], this.state.data[2]]
          }, () => {
            let add_obj = { ...this.state.data, index: this.state.carousel_number }
            this.setState({
              all_data: this.state.all_data.concat([add_obj])
            })
          })
          resolve()
        }
        return true
      })


    })
  }
  forward_carousel = (e) => {
    e.preventDefault()
    if (this.state.carousel_number < this.props.results.length - 1) {
      this.setState({
        carousel_number: this.state.carousel_number + 1
      }, () => {
        this.create_chart_data()
      })
    } else {
      this.setState({
        carousel_number: 0
      }, () => {
        this.create_chart_data()
      })
    }
  }
  back_carousel = (e) => {
    e.preventDefault()
    if (this.state.carousel_number === 0) {
      this.setState({
        carousel_number: this.props.results.length - 1
      }, () => {
        this.create_chart_data()
      })
    } else {
      this.setState({
        carousel_number: this.state.carousel_number - 1
      }, () => {
        this.create_chart_data()
      })
    }
  }


  render() {
    var total_fees = this.state.buy_broker_fees + this.state.sell_broker_fees
    return (

      <Model show={true} title={`${this.props.title}-${this.props.dates[this.state.carousel_number]}`} closeHandler={this.props.close} >
        <ul className='m-0'>
          <li>Started with ${this.props.starting_amt} </li>
          <li>Revenue: ${this.props.results[this.state.carousel_number].total_gains.toFixed(2)} </li>
          <li>Fees: ${total_fees} </li>
          <li>profit: ${this.props.results[this.state.carousel_number].total_gains - total_fees} </li>
          <li>Algo Return: {((this.props.results[this.state.carousel_number].total_gains - total_fees) * 100 / this.props.starting_amt).toFixed(2)}%</li>
        </ul>
        <p className='m-0'>If started with ${this.props.starting_amt} your revenue would be ${this.props.results[this.state.carousel_number].total_gains.toFixed(2)}, fees would be ${total_fees}, profit would be {((this.props.results[this.state.carousel_number].total_gains - total_fees) * 100 / this.props.starting_amt).toFixed(2)}% return</p>
          <ReactChart {...this.state} {...this.props} />
        <div>
          <div className='d-flex align-items-center justify-content-center'>
            <button className='btn btn-icon' onClick={(event) => this.back_(event)}>
              <ArrowRightIcon className='rotate-180' />
            </button>
            {this.props.results.map((result, index) => (
              <FiberManualRecordIcon key={index} style={this.state.carousel_number === index ? { color: "#3680e2" } : { color: "grey" }} />
            ))}
            <button className='btn btn-icon'>
              <ArrowRightIcon onClick={(event) => this.forward_carousel(event)} />
            </button>
          </div>
        </div>
      </Model>
    )
  }
}
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null,
      retryCount: 0
    };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo });
  }

  handleRetry = () => {
    this.setState(prevState => ({ retryCount: prevState.retryCount + 1, error: null, errorInfo: null }));
  }

  render() {
    const { error, errorInfo, retryCount } = this.state;
    const { fallback, children } = this.props;

    if (error) {
      if (retryCount < 2) {

        // Retry the component after a delay
        setTimeout(() => this.handleRetry(), 1000);
        return null;
      }

      // If the component has already failed once, render the fallback component
      return fallback ? fallback({ error, errorInfo }) : null;
    }

    // If there's no error, render the children
    return children;
  }
}

const MyChart = props => {
  const data = {
    model_id: props.model_id,
    dates: props.dates,
    index: props.index,
    type: "intra_day",
  }
  const { res, err } = usePost("get_ml_graph_intra", data)
  if (!res && !err) return <Spinner toMiddle />
  return <>
  <ErrorBoundary>
  <Main results={res.result.result} time_arr={res.result.time_arr} {...props}  />
  </ErrorBoundary>
  </>
}

export default MyChart;
