import React from 'react';
import BoxField from '../../../ui-v2/BoxField/BoxField';
import StockItem from '../StockItem/StockItem';

const Wrapper = props => (
    <div className='flex-1'>
        <small className='smaller text-accent-4 py-2 d-block'>{props.title}</small>
        {props.stocks.map((item, index) => {
            const checked = props.stockKeys.includes(item.ticker);
            return (
                <StockItem {...item}
                    key={item._id}
                    name={props.isSelected ? item.ticker : index}
                    action={(
                        <BoxField name={checked ? item.ticker : index}
                            checked={checked}
                            onChange={props.onChange}
                        />
                    )}
                />
            )
        })}
    </div>
)


export default Wrapper;