import axios from 'axios';


export const loginAPI = credentials => {
    return axios.post("/sign_in", credentials)
        .then(async res => {
            const user = res.data;
            // user.credits = await axios.get('/account_credits');
            return user;
        })
}

export const registerAPI = credentials => {
    return axios.post("/create_user", credentials)
        .then(res => {
            if (res.data.status === "success") return res.data.data;
            if (res.data.status === "User already exists") throw Error(`User with this "${credentials.email}" email already exists!`)
        })
}

export const helpAPI = data => axios.post(`/submit_help_request`, data)