import React from 'react';
import styles from './PortfolioUpdates.module.scss';

const PortfolioItem = props => (
    <div className={props.action}>
        <b>{props.name}</b>
        <div className='d-flex gap-2 mt-1'>
            <b className={styles.action}>{props.action}</b>
            <small>{props.company_name}</small>
        </div>
        <p title='Shares'>{props.shares}</p>
        <p title='Price'>{props.price}</p>
        <p title='Value'>{props.value_str}</p>
        <small className='text-accent-4'>Updated {props.transaction_date}</small>
    </div>
)

const PortfolioUpdates = props => (
    <div className={`d-flex flex-column ${styles.root}`}>
        <small className='text-accent-4 p-3'>Recent Updates</small>
        <div className={`${styles.main} flex-1 overflow-auto`}>
            {props.recent_activity?.map((item, index) => (
                <PortfolioItem
                    key={index}
                    {...item}
                />
            ))}
        </div>
    </div>
)

export default PortfolioUpdates;