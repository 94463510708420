import React from 'react';
import ChevronBottom from '../../icons-v2/ChevronBottom';
import styles from './StockItem.module.scss';

const StockItem = props => {
    const [isExpanded, setExpanded] = React.useState(false);
    const onExpand = e => {
        const elm = e.target;
        const tag = elm.tagName;
        if(tag !== "BUTTON" && tag !== "SVG" && elm.parentNode !== "BUTTON")  setExpanded(!isExpanded);
    }
    return (
        <>
            <tr onClick={onExpand} className={`${styles.root} ${isExpanded ? styles.active : ''}`}>
                <td><ChevronBottom className={`text-accent-4 ${isExpanded ? 'rotate-180' : ''}`} /></td>
                {props.component()}
            </tr>
            {isExpanded && (
                <tr>
                    <td colSpan={50} className='p-0'>
                        {props.children}
                    </td>
                </tr>
            )}
        </>
    )
}

export default StockItem;