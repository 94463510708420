import React, { useEffect, useState } from 'react';

const ToTop = () => {
    const [isActive, setActive] = useState(false);
    useEffect(() => {
        const cb = () => setActive(window.scrollY > 100)
        window.addEventListener('scroll', cb)
        cb()
    }, [])
    return isActive ? (
        <button onClick={() => window.scroll(0, 0)} className="btn btn-icon h5 d-none d-lg-flex bg-primary text-surface-1 position-fixed bottom-0 start-0 ms-3 z-index-5" style={{marginBottom: '8rem'}} type="button">
            <i className="fas fa-arrow-up"></i>
        </button>
    ) : ""
}

export default ToTop;