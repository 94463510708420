import React from 'react';
import SideFilter from '../../components-v2/SideFilter/SideFilter';
import ToolTip from '../../ui-v2/ToolTip/ToolTip';
import BoxField from '../../ui-v2/BoxField/BoxField';
import InputField from '../../ui-v2/InputField/InputField';
import SelectField from '../../ui-v2/SelectField/SelectField';
import { MenuItem } from '@material-ui/core';

const RSIFilters = ({ handleChange, handleCheck, handleStart, isLoading, ...state }) => {
    return (
        <SideFilter>
            <div className='d-flex flex-column gap-3'>
                <InputField
                    title="Enter a stock symbol to analyze RSI"
                    name='stock_search'
                    label="Stock Symbol"

                    onChange={handleChange}
                    value={state.stock_search}

                />
                <SelectField
                    title="Enter a time interval that will be used to calculate the RSI"
                    value={state.interval}
                    name="interval"
                    onChange={handleChange}
                    label="Interval"
                >

                    <MenuItem value={"1min"}>1min</MenuItem>
                    <MenuItem value={"5min"}>5min</MenuItem>
                    <MenuItem value={"15min"}>15min</MenuItem>
                    <MenuItem value={"30min"}>30min</MenuItem>
                    <MenuItem value={"60min"}>60min</MenuItem>
                    <MenuItem value={"daily"}>Daily</MenuItem>
                    <MenuItem value={"weekly"}>Weekly</MenuItem>
                    <MenuItem value={"monthly"}>Monthly</MenuItem>
                </SelectField>
                <InputField
                    title="This will take the last ___ stock data points and use them to calculate the RSI."
                    name='time_period'
                    label="Time Period"
                    type="number"
                    onChange={handleChange}
                    value={state.time_period}

                />
                {state.activate_live_trading &&
                    <InputField
                        name='volume_shares_per_trade'
                        label="Volume of Shares per Trade"
                        type="number"
                        disabled={state.start}
                        onChange={handleChange}
                        value={state.volume_shares_per_trade}
                    />
                }
                {state.authenticated &&
                    <>
                        <div className='d-flex flex-column gap-2'>
                            <BoxField
                                checked={state.activate_live_trading}
                                onChange={handleCheck}
                                name="activate_live_trading"
                                label="Activate Live Trading"
                            />
                            <BoxField
                                checked={state.make_trades_trail}
                                onChange={handleCheck}
                                name="make_trades_trail"
                                label="Make Trades Trail"
                            />
                        </div>
                        {state.make_trades_trail &&
                            <InputField
                                name='trades_trail_by'
                                label="How much do you want your trades to trail by?"
                                type="number"
                                onChange={handleChange}
                                value={state.trades_trail_by}

                            />
                        }
                    </>
                }
                <button
                    className={`btn btn-primary ${isLoading ? 'progress-btn' : ''}`}
                    onClick={handleStart}
                    disabled={isLoading}
                >Fetch</button>
            </div>
        </SideFilter>
    )
}

export default RSIFilters;