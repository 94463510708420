import { useState, useEffect } from 'react';
import axios from 'axios';
import axiosErrorHandler from '../utility/axiosErrorHandler';
import getScrollPosition from '../utility/getScrollPosition';
import { useLocation } from 'react-router-dom';

const useScrollFetch = (api = '/', isLoggedIn = true, posts = []) => {
    const [state, setState] = useState({ exists: isLoggedIn && posts.length % 20 === 0, posts: [...posts] });
    const search = useLocation().search;
    
    const handler = (resetPosts = false) => {
        let otherData = {};
        let p = resetPosts ? [] : [...state.posts];
        let ex = isLoggedIn && p.length % 20 === 0 ? true : false;
        const cb = async isInitial => {
            if ((typeof isInitial === "boolean" && isInitial) || (ex && getScrollPosition(200) >= document.body.offsetHeight)) {
                ex = false;
                try {
                    const [url_2, search_3] = api.split('?');
                    const limit = isLoggedIn ? 20 : 5;
                    let search_2 = `page=${isLoggedIn ? `${+((p.length / limit) + 1).toFixed()}` : `1`}&&limit=${limit}`;
                    if(search_3) search_2 += `&&${search_3}`
                    const url = `${url_2}${search.trim() !== "" ? (search?.includes('?') ? search + '&&' : '?' + search + '&&') : "?"}${search_2}`;
                    const { posts, ...data } = (await axios.get(url)).data;
                    ex = posts.length >= limit;
                    otherData = { ...otherData, ...data };
                    posts.forEach(post => p.push(post));
                    setState({ posts: p, exists: isLoggedIn && ex, ...otherData });
                } catch (err) {
                    setState({ exists: false, posts: p });
                    axiosErrorHandler(err);
                }
            }
        };
        const cleaner = () => {
            if (!isLoggedIn) p = [];
            setState({ exists: true, posts: [] });
            window.removeEventListener('scroll', cb);
        };
        if (p.length === 0) {
            cb(true);
            if(isLoggedIn) window.addEventListener('scroll', cb);
        }
        return cleaner;
    };

    useEffect(() => {
        const cleaner = handler(true);
        setState({ posts: [], exists: true });
        return () => {
            cleaner();
        };
    }, [search]);

    return [state, setState];
};

export default useScrollFetch;
