import React, { useEffect } from 'react';
import ListTile from '../../ui/ListTile/ListTile';
import Coupon from './Coupon';
import Model from '../../ui-v2/Modal/Model';
import Form from '../../utility/Form';
import axios from 'axios';
import Purchase from '../Purchase/Purchase';
import { trackEvent } from '../../tiktokTracking';


const Payment = (props) => {
    useEffect(() => {
        trackEvent('AddToCart', {
            value: props.product.price,
            currency: 'USD',
            contents: [{
                algo_name: props.product.title,
            }]
        });
    }, [])
    return (
        <Model
            show={true}
            title={props.title}
            closeHandler={props.close}
        >
            <Form
                onSubmit={form => {
                    const values = props.values ? { ...props.values, ...form.values } : form.values;
                    axios.post(props.api, values)
                        .then(({ data }) => {
                            if (data.error) form.onFailure(data.status);
                            else props.onSuccess(data);
                        })
                        .catch(form.onFailure)
                }}
                className="d-flex flex-column gap-inherit"
                footer={(loading) => (
                    <div className='model-footer'>
                        <button type='button' className='btn btn-text' onClick={props.close}>Cancel</button>
                        <button disabled={loading} className={`${loading ? 'progress-btn' : ''} btn btn-primary`}>Confirm</button>
                    </div>
                )}
            >
                <ListTile {...props.product} />
                <Purchase showPaymentPopUp={props.onPaymentChange} />
                <Coupon {...props.product} />
            </Form>
        </Model>
    )
}

export default Payment;