import { useEffect, useState } from "react"
import axios from "axios";
import axiosErrorHandler from "../utility/axiosErrorHandler";
import toastr from "toastr";

const usePost = (api, data = {}, boolean = true) => {
    const [{ res, err, isLoading }, setStatus] = useState({});
    const onPost = (data, isToastr = false, cb, errcb) => {
        setStatus({ isLoading: true });
        axios.post(api, data)
            .then(r => {
                const res = r.data;
                isToastr && toastr.success(res.message);
                cb && cb(res)
                setStatus({ res })
            })
            .catch(err => {
                const message = axiosErrorHandler(err)
                errcb && errcb(message)
                setStatus({
                    err: message
                })
            })
    }
    useEffect(() => {
        if (boolean) onPost(data);
    }, []);

    return { res, err, isLoading, onPost, setStatus };
}


export default usePost;