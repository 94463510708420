import React from 'react';
import styles from './styles.module.scss';

const Spinner = ({ fontSize = '1rem', className = 'text-accent-3', toMiddle = false }) => (
    <div className={`${styles.root} ${className} ${toMiddle ? 'position-fixed translate-middle' : ''}`} style={{ fontSize: `${fontSize}` }}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
)

export default Spinner;