import React, {useEffect} from 'react'
import Copy from '../CompeComponents/HeroSection/Copy'
import JoinButton from './JoinButton'
import moment from 'moment'


export default function JoinCompetition(props) {
    useEffect(() => {

    }, [props.start_date]);
    return (
        <div className="min-vh-page small bg-primary-gradient text-center text-surface-1 d-flex">
            <div className='container-v2 m-auto d-flex flex-column gap-7'>
                <div>
                    <h3 className='m-0'>{props.title}</h3>
                    {props.description && <p className='mt-1 opacity-7'>{props.description}</p>}
                </div>
                <div className='d-grid mx-auto gap-inherit'>
                    <div>
                        <p className='d-flex gap-2 m-0 justify-content-center'><b>Participation link</b><Copy className='text-surface-1 opacity-7 btn-text btn gap-1' /></p>
                        <p className='mb-0 mt-1 opacity-7'>{window.location.href}</p>
                    </div>
                    <div className='d-flex mx-auto gap-8'>
                        <div>
                            <b>Start date</b>

                            <p className='mb-0 mt-1 opacity-7'>{moment(new Date(props.start_date).toLocaleDateString()).format('LL')}</p>
                        </div>
                        <div>
                            <b>End date</b>
                            <p className='mb-0 mt-1 opacity-7'>{moment(new Date(props.end_date).toLocaleDateString()).format('LL')}</p>
                        </div>
                    </div>
                    <JoinButton {...props} />
                </div>
            </div>
        </div>
    )
}