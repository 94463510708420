import React, { Fragment } from 'react';
import axios from 'axios'
import URL from 'url-parse';
// import Button from '@material-ui/core/Button';
// import ReactSelect from 'react-select';
import MenuItem from '@material-ui/core/MenuItem';
import toastr from 'toastr'
import './signals.scss'
import Model from '../ui-v2/Modal/Model';
import InputField from '../ui-v2/InputField/InputField';
import SelectField from '../ui-v2/SelectField/SelectField';


class Signals extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      number_of_results: 5,
      signal_name: '',
      stock_search: '',
      article: {
        authors: [],
        symbols: []
      },
      ...this.props
    }
    this.create_signal = this.create_signal.bind(this)
    this.selectOptionsArticle = this.selectOptionsArticle.bind(this)
  }

  selectOptionsArticle(name, e) {
    let { article } = this.state
    article[name] = e
    this.setState({
      article
    })
  }


  componentDidMount() {
    try {
      const newState = {};
      this.props.filter_objects.forEach(obj => {
        newState[obj.state_name] = this.state[obj.state_name] || obj.value || obj.array[0]
      })
      const number = this.props.number || 5;
      const name = this.props.name || '';
      this.setState({
        number_of_results: number,
        signal_name: name,
        ...newState
      })
    } catch (error) {

    }
    // try {
    //   const { type, selected } = this.props
    //   let { article } = this.state
    //   if (type === 'article_analyzer') {
    //     selected.authors.map((item, index) => {
    //       article.authors.push({
    //         value: item, label: item
    //       })
    //       return true
    //     })
    //     selected.symbols.map((item, index) => {
    //       article.symbols.push({
    //         value: item, label: item
    //       })
    //       return true
    //     })
    //     this.setState({ article })
    //   }
    // } catch (error) {

    // }
  }

  handleFilterChange = (e) => {
    let { name, value } = e.target
    this.setState({
      [name]: value,
    })

  }
  handleChange = (e) => {
    e.preventDefault();
    let name = e.target.name;
    this.setState({
      [name]: e.target.value
    })
  }

  handleStockChange = (e, values) => {
    const { value, name } = e.target
    this.setState({
      [name]: values?.join(',') || value,
    })
  }

  create_article_signal = (e, url) => {
    e.preventDefault()
    const { article } = this.state
    let symbols = []
    let authors = []
    article.symbols.map((item, index) => {
      symbols.push(item.value)
      return true
    })
    article.authors.map((item, index) => {
      authors.push(item.value)
      return true
    })
    const json = {
      parameters: {
        symbols,
        authors,
        url: window.location.href
      },
      algo_id: url.query.algo_id,
      algo_name: this.props.algo_name,
    }
    const api_url = '/create_article_signal'
    axios.post([api_url],
      {
        ...json
      }).then((res) => {
        if (res.data.success) {
          this.props.onSuccess()
          this.props.close()
        }
      })
  }

  create_signal = (e, url) => {
    e.preventDefault()
    const { signal_name } = this.state
    if (signal_name && signal_name.length) {
      const json = {
        parameters: {
          ...this.state,
          url: window.location.href
        },
        algo_id: url.query.algo_id,
        algo_name: this.props.algo_name,
        id: this.props.signalID
      }
      const api_url = this.props.type === 'create' ? '/create_signal' : '/update_signal'
      axios.post([api_url],
        {
          ...json
        }).then((res) => {
          toastr.success(`You have successfully ${this.props.type}d the signal`)
          this.props.onSuccess()
        })
    } else {
      toastr.error("You must input signal name.", 'Error')
    }
  }

  render() {
    const url = new URL(window.location.href, true)
    const { done, type } = this.props
    const label1 = type === 'create' ? 'Create a Signal' : 'Update a Signal'
    const label2 = type === 'create' ? "Congratulations, you've successfully created your signal!" : "Congratulations, you've successfully updated your signal!"
    const label3 = type === 'create' ? "You will now receive a daily email with results based off the preferences you selected in your signal. Signals are sent out weekday mornings." : "You will now receive a daily email with results based off the preferences you selected in your signal. Signals are sent out weekday mornings"

    return (
      <Model
        show={true}
        isNormal={true}
        success={done}
        title={done ? label2 : label1}
        closeHandler={this.props.close}
        zIndex={this.props.zindex} className='__signals-popup'
        actions={() => done ? <button className='btn btn-text' onClick={this.props.close}>Close</button> : (
          <button
            className='btn btn-primary'
            onClick={(event) => this.create_signal(event, url)}
          >
            {type.toUpperCase()}
          </button>
        )}
        isSmall={done}
      >
        {done ? <p className='m-auto text-accent-3 small-sm'>{label3}</p>
          : <>
            <p className="m-0 small-sm text-accent-4">Receive a daily email with results based off the preferences you list below.</p>
            <InputField
              name='signal_name'
              label="Signal Name*"
              onChange={this.handleChange}
              value={this.state.signal_name}
            />
            <small className='text-accent-4'>What filters would you like to have?</small>
            <InputField
              name='stock_search'
              label="Stock Symbols (optional)"
              onChange={this.handleStockChange}
              value={this.state.stock_search}
            />
            <div className="d-grid gap-3 col-sm-2 overflow-initial">
              {this.props.filter_objects.length > 0 && this.props.filter_objects.map((obj, index) => {
                return (
                  <Fragment key={index}>
                    {
                      obj.type === 'number' ?
                        <InputField
                          title={obj.tooltip} key={index}
                          value={+this.state[obj.state_name] || 1}
                          name={obj.state_name}
                          label={obj.title}
                          type="number"
                          onChange={(e) => this.handleChange(e)}
                          fullWidth
                        />
                        : obj.type === 'string' ?
                          <InputField
                            title={obj.tooltip} key={index}
                            value={this.state[obj.state_name] || ""}
                            name={obj.state_name}
                            label={obj.title}
                            type="text"
                            onChange={(e) => this.handleChange(e)}
                            fullWidth
                          />
                          :
                          <SelectField
                            title={obj.tooltip} key={index}
                            value={this.state[obj.state_name] || ""}
                            name={obj.state_name}
                            onChange={this.handleFilterChange}
                            label={obj.title}
                          >
                            {obj.array.map((type, index) => <MenuItem key={index} value={type}>{type}</MenuItem>)}
                          </SelectField>
                    }
                  </Fragment>
                )
              })}
              <InputField
                title="Enter the number of stocks you would like to get data for."
                name='number_of_results'
                label="Number Of Results"
                variant="outlined"
                type="number"
                onChange={this.handleChange}
                value={this.state.number_of_results}
              />
            </div>
          </>

        }
      </Model>
    )
  }
}
export default Signals;
