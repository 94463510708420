import axios from "axios";
import React, { useState } from "react";
import Model from "../../ui-v2/Modal/Model";

const DelelteSignalModal = props => {
    const [status, setStatus] = useState(null);
    const onDelete = () => {
        setStatus('loading');
        axios.post('/delete_signal', { id: props.id }).then(_ => {
            setStatus('deleted');
            props.onDelete();
        })
    }
    const done = status === 'deleted'
    return (
        <Model
            isSmall={true}
            show={true}
            title={status === 'deleted' ? 'Signal Deleted' : 'Delete a Signal'}
            closeHandler={props.closeHandler}
            actions={hidePopUp => done ? <button className='btn btn-text' onClick={hidePopUp}>Close</button> : (
                <div className='d-flex gap-4'>
                    <button className='btn btn-text' onClick={hidePopUp}>No</button>
                    <button className='btn btn-text text-accent-2' onClick={onDelete}>Yes</button>
                </div>
            )}
        >
            {done ? <p className='m-auto text-accent-3'>You've successfully deleted this signal!</p> : <p className='m-auto'>Are you sure want to delete this signal No.{props.index}?</p>}
        </Model>
    )
}

export default DelelteSignalModal;