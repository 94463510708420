import React from 'react';
import Model from '../../ui-v2/Modal/Model';
import { useApp } from '../../App';
import usePost from '../../hooks/usePost';

const CancelSubScriptionPopUp = ({ algo_name, trial, algo_id, close }) => {
    const isExpired = /Expir/i.test(trial);
    const app = useApp();
    const tools = usePost("/myPurchases/remove", null, false);

    const onClick = async () => {
        tools.onPost({
            userId: app.user._id,
            algoId: algo_id,
            trial
        }, true, app.onRefresh);
    }
    return (
        <Model show title='Are you sure?' isSmall closeHandler={close}
            actions={() => (
                <>
                    <button className='btn btn-text' onClick={close}>No </button>
                    <button className='btn btn-text text-accent-2' disable={tools.isLoading} onClick={onClick}>
                        Yes  {tools.isLoading && <span className='loader' />}
                    </button>
                </>
            )}
        >
            <p className='m-0 text-accent-4'>
                {!isExpired ? 'Are you sure you would like to cancel your subscription to' : 'Would you like to re-activate your ongoing subscription for'} <b className='text-normal'> {algo_name}</b>?
            </p>
        </Model>
    )
}

export default CancelSubScriptionPopUp;
