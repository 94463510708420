import React from 'react';
import getNumber from '../../../../utility/getNumber';
import Card from "./Card";
import LI from './LI';

const Fundamentals = props => (
    <Card title='Fundamentals'>
        <ul>
            <LI title='Shares Outstanding' tooltip={`a company's stock currently held by all its shareholders, including share blocks held by institutional investors and restricted shares owned by the company’s officers and insiders. Outstanding shares are shown on a company’s balance sheet under the heading “Capital Stock`} value={getNumber(props.sharesoutstanding)} />
            <LI title='Market Cap' tooltip={`refers to the total dollar market value of a company's outstanding shares of stock. `} value={getNumber(props.marketcap)} />
            <LI title='EPS' tooltip={`EPS is described as company's profit per outstanding share of stock and is arrived at by taking a company's quarterly or annual net income and dividing by the number of its shares of stock outstanding`} value={props.eps} />
            <LI title='P/B Ratio' tooltip={`Used to compare a firm's market capitalization to its book value. Book value is also the tangible net asset value of a company calculated as total assets minus intangible assets (.e.g. patents, goodwill) and liabilities. P/B ratios under 1 are typically considered solid investments.`} value={props.pbratio} />
            <LI title='ADR Ratio' tooltip={`refers to a negotiable certificate issued by a U.S. depositary bank representing a specified number of shares—usually one share—of a foreign company's stock. The most common ratio is 1:1 where each ADR represents one common share of the company. If an ADR is listed on an exchange, you can buy and sell it through your broker like any other share.`} value={props.adrratio} />
            <LI title='PTB Ratio' tooltip={`A valuation ratio of the price paid for a share relative to tangible book value (Stockholder's Equity less Goodwill and Intangible Assets) per share. Price To Tangible Book number indicates the amount of dollars paid on the stock market for one dollar of tangible book value.`} value={props.ptbratio} />

        </ul>
        <ul>
            <LI title='Short Interest Ratio' tooltip={`Also called Days To Cover, takes the number of shares held short in a stock and it divides this by the stock's average daily trading volume. Simply put, the ratio can help an investor find out very quickly if a stock is heavily shorted or not shorted versus its average daily trading volume. The short interest ratio indicates how many days it would take for all the shares short to be covered or repurchased in the open market.`} value={props.shortinterest?.sratio} />
            <LI title='Short Interest Percent' tooltip={`Short interest is the number of shares that have been sold short but have not yet been covered or closed out. Short interest, which can be expressed as a number or percentage, is an indicator of market sentiment. Extremely high short interest shows investors are very pessimistic (potentially overly-pessimistic). When investors are overly-pessimistic it can lead to very sharp price rises at times.`} value={props.shortinterest?.spercent} />
            <LI title='Short Interest Date' tooltip='Short Interest Date' value={props.shortinterest?.sdate} />
            <LI title='Short Interest Shares' tooltip={` Similar to short interest percent but represents the number of shares sold short.`} value={props.shortinterest?.sshares} />
            <LI title='PCF Ratio' tooltip={`a stock valuation indicator or multiple that measures the value of a stock’s price relative to its operating cash flow per share. The ratio uses operating cash flow (OCF), which adds back non-cash expenses such as depreciation and amortization to net income. P/CF is especially useful for valuing stocks that have positive cash flow but are not profitable because of large non-cash charges. A low P/CF multiple may imply that a stock is undervalued in the market.`} value={props.pcfratio} />
            <LI title='PFCF Ratio' tooltip={`Price to free cash flow is an equity valuation metric that indicates a company's ability to generate additional revenues. It is calculated by dividing its market capitalization by free cash flow values. A lower value for price to free cash flow indicates that the company is undervalued and its stock is relatively cheap. A higher value for price to free cash flow indicates an overvalued company.`} value={props.pfcfratio} />
        </ul>
    </Card>
)

export default Fundamentals;
