import React from 'react';
import FilterButton from '../../components-v2/AlgoHoldingCommonTools/FilterButton';
import ChartIcon from '../../icons-v2/ChartIcon';

const MomentumTraderMultiStock = props => {
    return (
        <div className='p-3 p-lg-4 p-xl-5 gap-3 d-flex flex-column overflow-hidden'>
            <div className="d-flex gap-inherit align-items-center">
                <h6 className='m-0'>{props.model_title}</h6>
                {props.ledger.length>0 &&
                  <button className='btn btn-rounded pe-2' onClick={props.showPopup}><ChartIcon className='text-primary' />Graph</button>
                }
                <FilterButton className='me-auto' />
            </div>
            <div className="table-wrapper product_tool_table">
                <div>
                    <table>
                        <thead>
                            <tr>
                                <th><div>Signal</div></th>
                                <th>Price</th>
                                <th>Highest Value</th>
                                <th>Lowest Value</th>
                                <th>Trade Profit</th>
                                <th>Total Profit (Trade Profit - Broker Fee)</th>
                                <th>Total Balance (Stock + money)</th>
                                <th>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                          {props.ledger.map((stock) => (
                            <tr key="name">
                              {stock.signal === "buy" ?
                                <>
                                  <td>{stock.signal.toUpperCase()}</td>
                                  <td>{parseFloat(stock.stock_price).toFixed(2)}</td>
                                  <td>{parseFloat(stock.highest_value).toFixed(2)}</td>
                                  <td>{parseFloat(stock.lowest_value).toFixed(2)}</td>
                                  <td>-</td>
                                  <td>{parseFloat(stock.total_profit).toFixed(2)}</td>
                                  <td>{parseFloat(stock.total_balance).toFixed(2)}</td>
                                  <td>{stock.date}</td>
                                </>
                                :
                                <>
                                  <td>{stock.signal.toUpperCase()}</td>
                                  <td>{parseFloat(stock.stock_price).toFixed(2)}</td>
                                  <td>{parseFloat(stock.highest_value).toFixed(2)}</td>
                                  <td>{parseFloat(stock.lowest_value).toFixed(2)}</td>
                                  <td>{parseFloat(stock.trade_profit).toFixed(2)}</td>
                                  <td>{parseFloat(stock.total_profit).toFixed(2)}</td>
                                  <td>{parseFloat(stock.total_balance).toFixed(2)}</td>
                                  <td>{stock.date}</td>
                                </>

                              }

                            </tr>
                          ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default MomentumTraderMultiStock;
