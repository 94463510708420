import React from 'react';
import Model from '../../ui-v2/Modal/Model';
import Video from '../Video/Video';

const VideoPopUp = ({ url, title, ...props }) => {
    const match = url?.match(/^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/);
    return match ? (
        <Model {...props}>
            <p className='model-title m-0'>{title}</p>
            <Video url={url} />
        </Model>
    ) : null;
}

export default VideoPopUp
