import React from 'react';
import LineChart from '../../ui-v2/LineChart/LineChart';
import Model from '../../ui-v2/Modal/Model';
import data from './data';
import styles from './ToolSymbole.module.scss';

const ToolSymbol = ({ title }) => {
    return (
        <Model title='Graph' className={styles.root} component={({ showPopUp }) => (
            <button className='badge cursor-pointer' onClick={e => { e.preventDefault(); showPopUp() }}>
                {title}
            </button>
        )}>
            <LineChart
                labels={["Date", "Price Change (%)"]}
                dataKeys={[
                    { dataKey: 'aapl', stroke: "#8884d8", activeDot: { r: 8 } },
                    { dataKey: 'tsla', stroke: "#82ca9d" }
                ]}
                data={data}
            >
            </LineChart>
        </Model>
    )
}

export default ToolSymbol;