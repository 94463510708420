import React from 'react';
import { GoogleLogin } from 'react-google-login';
import axios from 'axios'
import useAuth from "../../hooks/useAuth"
import './SocialLogins.scss'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import FaceBook from '../../icons-v2/FaceBookV2';


const SocialLogins = () => {

    const auth = useAuth({ isForUnAuthorized: true }).auth;

    const handleSuccess = googleData => {


        axios.post('/google_facebook_sign_in', {
            ...googleData.profileObj
        }).then((res) => {
            if (res.data) {
                auth.persistUser(res.data)
            }
        }).catch((error) => {

        })
    }

    const handleFailure = googleData => {


    }

    const responseFacebook = async response => {

        if (response.status !== "unknown") {
            const json = {
                email: response.email,
                imageUrl: response.picture.data.url,
                name: response.name
            }
            const res = await axios.post('/google_facebook_sign_in', {
                ...json
            })
            if (res.data) {
                auth.persistUser(res.data)
            }
        }
    }

    return (
        <>
            <div className='d-flex align-items-center'>
                <div className='border-bottom flex-1' />
                <p className='mx-2 my-0 line-height-1'>or</p>
                <div className='border-bottom flex-1' />
            </div>
            <div className='text-center gap-3 d-flex flex-wrap justify-content-center text-accent-4'>
                <p className='m-0 w-100'>Sign in with </p>
                <div className='d-grid col-1 gap-3'>
                    {/* <button className='btn bg-normal btn-primary mb-3 w-100' style={{ backgroundColor: '#4285f4' }}><AppleIcon />Sign in with Apple</button>
                <button className='btn btn-primary w-100'><GoogleIcon />Sign in with Google</button> */}
                    <GoogleLogin
                        clientId={'892485314218-ihjgqgvlsgr1bckabhs1bmumcgl49fs3.apps.googleusercontent.com'}
                        buttonText="Sign in with Google"
                        render={renderProps => (
                            <button className='btn btn-border p-3 ps-4 text-accent-5' onClick={renderProps.onClick}>
                                <i className='fab fa-google'></i> <span className="mx-auto text-normal">Google</span>
                            </button>
                        )}
                        onSuccess={handleSuccess}
                        onFailure={handleFailure}
                        cookiePolicy={'single_host_origin'}
                        className='flex-1'
                    />
                    {/* <FacebookLogin
                        appId="985020255379356"
                        autoLoad={false}
                        fields="name,email,picture"
                        callback={responseFacebook}
                        render={renderProps => (
                            <button className='btn btn-border p-3 ps-4 text-accent-5' onClick={renderProps.onClick}>
                                <FaceBook className='fa-facebook' /> <span className="mx-auto text-normal">Facebook</span>
                            </button>
                        )}
                    /> */}
                </div>
            </div>
        </>
    )
}

export default SocialLogins;
