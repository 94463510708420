import React from 'react';
import Model from '../../ui-v2/Modal/Model';
import { InlineWidget } from "react-calendly";
import styles from './BookCall.module.scss';
import AddIcon from '../../icons-v2/AddIcon';

const BookCall = props => {
    return (
        <Model isSimpleModel={true} show={true} className={styles.root} {...props}>
            <button className='btn btn-icon text-surface-1 me-auto' onClick={props.closeHandler}>
                <AddIcon className='rotate-45' />
            </button>
            <InlineWidget url="https://calendly.com/stockalgos/stockalgos-platform-demo" />
        </Model>
    )
}

export default BookCall;
