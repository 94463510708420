import React, { useState, useEffect } from 'react';
import withAlgoProductToolsLayout from "../../hoc-v2/withAlgoProductToolsLayout/withAlgoProductToolsLayout";
import StockInformation from './StockInformation';
import axios from 'axios';
import moment from 'moment';
import styles from './styles.module.scss';

const getFridayOfWeek = (inputDate) => {
    const date = new Date(inputDate);
    let dayOfWeek = date.getDay();
    let differenceToFriday = 5 - dayOfWeek;

    // Adjust for weekend cases
    if (dayOfWeek === 6) {
        differenceToFriday = -1;
    } else if (dayOfWeek === 0) {
        differenceToFriday = 5;
    }

    date.setDate(date.getDate() + differenceToFriday);
    return date;
}

function OptionComparisonFunc({ chain_data = {}, auto_load = false }) { 
    return (
        <>
            <div />
            <div className={`d-grid col-xl-2 gap-4 gal-xl-0 flex-1 border ${styles.root}`}>
                <StockInformation  info={chain_data.stock_1} auto_load={auto_load} className='p-lg-5 p-3' />
                <StockInformation  info={chain_data.stock_2} auto_load={auto_load} className='p-lg-5 p-3' />
            </div>
        </>
    )
} 

export { OptionComparisonFunc };

const ComparisonWrapper = ({ earnings_date, stock="AAPL" }) => {
    const [chainData, setChainData] = useState({});
    const [showChain, setShowChain] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const expirationDate = getFridayOfWeek(moment());

            const stock1 = {
                stockSymbol: stock,
                startDate: moment(expirationDate).subtract(1, 'months').format('YYYY-MM-DD'),
                endDate: moment(expirationDate).format('YYYY-MM-DD'),
                expiration_date: moment(expirationDate).format('YYYY-MM-DD')
            };
          
            const response = await axios.post('/get_prev_earnings_for_hoc', { earnings_date:expirationDate, stock });
            const prevEarningsDate = response.data.earnings_date? response.data.earnings_date: moment(expirationDate).subtract(1, 'months').format('YYYY-MM-DD');


            const expirationDate2 = getFridayOfWeek(prevEarningsDate);
            const stock2 = {
                stockSymbol: stock,
                startDate: moment(prevEarningsDate).subtract(1, 'months').format('YYYY-MM-DD'),
                endDate: moment(prevEarningsDate).format('YYYY-MM-DD'),
                expiration_date: moment(expirationDate2).format('YYYY-MM-DD'),
            };
            console.log('stock1', stock1);
            console.log('stock2', stock2);

            setChainData({
                stock_1: stock1,
                stock_2: stock2,
            });
            setShowChain(true);
        };

        fetchData();
    }, []);

    return (
        <>
             <OptionComparisonFunc chain_data={chainData} auto_load={true} />
        </>
    );
}
// Default export or named export with withAlgoProductToolsLayout
export default withAlgoProductToolsLayout(ComparisonWrapper, 'Option Comparison');