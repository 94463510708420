import React, { useMemo, useState } from 'react';
import Model from "../../ui-v2/Modal/Model";
import SearchWrapper from '../WatchList/SearchWrapper';
import useGet from '../../hooks/useGet';
import { useParams } from 'react-router-dom';

let timer;

const User = props => {
    const [isAdmin, setAdmin] = useState(props.isAdmin)
    const onToggle = () => {
        setAdmin(!isAdmin);
        fetch(`/leaderboard-competition-admin-toggle/${props._id}/${props.competitionId}`)
    }
    return (
        <div className='d-flex gap-3 justify-content-between border-bottom align-items-center'>
            {props.name}
            <button onClick={onToggle} className={`btn btn-icon text-accent-${isAdmin ? '3' : '4'}`}>
                <i className={`fas fa-user-${isAdmin ? 'minus' : 'plus'}`}></i>
            </button>
        </div>
    )
}

const Main = () => {
    const id = useParams().id;
    const [search, setSearch] = useState("")
    const { data, Loader, onParamsChange } = useGet(`/leaderboard-competition-participants/${id}/search`, { search })
    const onChange = e => {
        clearTimeout(timer);
        const search = e.target.value
        setSearch(search);
        timer = setTimeout(() => {
            onParamsChange({ search })
        }, 500)
    }
    const onClear = () => {
        setSearch("");
        onParamsChange({ search: "" })
    }
    return (
        <div>
            {useMemo(() => (
                <SearchWrapper isRounded
                    className='mb-3'
                    value={search}
                    onClear={onClear}
                    onChange={onChange}
                />
            ), [search])}
            <div className='position-relative'>
                {Loader && <Loader toMiddle />}
                {useMemo(() => data && data.map(user => <User competitionId={id} {...user} key={user._id} />))}
            </div>
        </div>
    )
}


export default function AssignAdmin(props) {
    return (
        <Model title="Assign Admin" {...props}>
            <Main />
        </Model>
    )
}