import React from 'react';
import SideFilter from '../../components-v2/SideFilter/SideFilter';
import BoxField from '../../ui-v2/BoxField/BoxField';
import ToolTip from '../../ui-v2/ToolTip/ToolTip';
import InputField from '../../ui-v2/InputField/InputField';
import SelectField from '../../ui-v2/SelectField/SelectField';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import GearIcon from '../../icons-v2/GearIcon';
import RefreshIcon from '../../icons-v2/RefreshIcon';
import AddIcon from '../../icons-v2/AddIcon';
import PickersUtilsProvider from '../../ui-v2/PickersUtilsProvider/PickersUtilsProvider';
import TimePicker from '../../ui-v2/TimePicker/TimePicker';
import DeleteModelPopUp from './DeleteModelPopUp';
import Spinner from '../../ui-v2/Spinner/Spinner';

const MomentumTraderMultiFilter = ({
    real_time,
    handlePastTime,
    handleRealTime,
    new_model,
    new_model_click,
    existing_models,
    existing_model_click,
    handleChange,
    model_title,
    errors,
    selected_model,
    handleModelChange,
    stock,
    handleStockChange,
    initialized,
    stock_list,
    handleCheck,
    run_daily,
    email_notifications,
    handleInitialize,
    authenticated,
    start,
    activate_live_trading,
    date_value,
    handleDateSelect,
    date_list,
    start_time,
    end_time,
    handleStartTime,
    handleEndTime,
    volume_of_trades,
    trade_frequency,
    buy_differential,
    sell_differential,
    save_model,
    handleRun,
    handleRefresh,
    broker_fee,
    handleSettings,
    settings,
    please_wait_show
}) => {
    return (
        <SideFilter footer={
          !initialized?
            (
              <ToolTip title="This will not start the momentum trader, it will just fetch the necessary data and ask you to enter in more data." placement="top">
                  <button
                      className='btn btn-primary w-100'
                      onClick={handleInitialize}
                  >Initialize</button>
              </ToolTip>
            )

            :
            (
              <ToolTip title="Run the momentum trader based off the values you have entered." placement="top">
                <button
                  onClick={handleRun}
                  className='btn btn-primary bg-accent-3 w-100'
                  disabled={please_wait_show}
                >
                  Run
                  {please_wait_show && <Spinner fontSize='0.35rem' />}
                </button>
              </ToolTip>
            )

          }
        >
            <div className='d-flex col-2 pb-4 justify-content-between border-bottom'>
                <ToolTip title="See how momentum trading would have worked on passed data.">
                    <BoxField
                        type='radio'
                        name="real_time"
                        checked={!real_time}
                        onChange={handlePastTime}
                        label="Playground"
                        className='flex-1'
                    />
                </ToolTip>
                {/*
                <ToolTip title="Get notifications for when you should execute a momentum trade.">
                    <BoxField
                        className='flex-1'
                        type='radio'
                        name="real_time"
                        onChange={handleRealTime}
                        checked={real_time}
                        label="Live"
                    />
                </ToolTip>
                */}
            </div>

            {!new_model && <button className='btn btn-text justify-content-start' onClick={new_model_click}><AddIcon />Create new model</button>}
            {/*(existing_models.length === 0) && (
                <div className='d-flex gap-3'>
                    <InputField
                        title="Give your model a title. This is the name it will be saved by so you can refence it later."
                        name='model_title'
                        label="Model Title"
                        disabled={!new_model}
                        onChange={handleChange}
                        value={model_title}
                        error={errors["model_title"]}
                        helperText={errors["model_title"]}
                    />
                    {<button className='btn btn-text me-1' onClick={save_model}>Save</button>}
                </div>
            )*/}
            {(existing_models.length > 0) &&
              <>
              {
                <SelectField
                    value={selected_model.model_title?selected_model.model_title:""}
                    onChange={handleModelChange}
                    label="Select Model"
                    name="model_title"


                >
                    {existing_models.map((model) => (
                        <MenuItem value={model.model_title}>{model.model_title}</MenuItem>
                    ))}
                </SelectField>

            }
            </>
          }



            <div className='d-flex flex-column gap-3'>
                {!new_model && existing_models.length > 0 && (
                    <div className='d-flex gap-3'>
                        <button className='btn btn-text me-auto' onClick={save_model}>Update</button>
                        <DeleteModelPopUp selected_model={selected_model.model_title} />
                    </div>
                )}
                {new_model && (
                    <div className='d-flex gap-3'>
                        <small className='overline'>New Model</small>
                        {initialized &&
                          <button className='btn btn-text me-auto' onClick={save_model}>Save</button>
                        }

                        {(existing_models.length > 0) &&
                          <button className='btn btn-icon no-padding' onClick={existing_model_click}><AddIcon className='rotate-45 text-accent-4' /></button>
                        }
                    </div>
                )}
                {new_model && (
                    <InputField
                        title="Give your model a title. This is the name it will be saved by so you can refence it later."
                        name='model_title'
                        label="Model Title"
                        disabled={!new_model}
                        onChange={handleChange}
                        value={model_title}
                        error={errors["model_title"]}
                        helperText={errors["model_title"]}
                    />
                )}
                <div className='d-flex align-items-center'>
                    <SelectField
                        value={stock}
                        onChange={handleStockChange}
                        disabled={initialized ? true : false}
                        name="stock"
                        label="Stock Symbol"
                        className='flex-1'
                    >
                        {stock_list.map((stock) => (
                            <MenuItem value={stock}>{stock.toUpperCase()}</MenuItem>
                        ))}
                    </SelectField>
                    {!real_time && initialized && (
                        <>
                            <button className='me-3 text-primary btn btn-icon' onClick={handleRefresh}> <RefreshIcon /> </button>
                            <button className='btn btn-icon text-primary' style={{ marginRight: '-0.5rem' }} onClick={handleSettings}> <GearIcon /> </button>
                        </>
                    )}
                </div>
                {(initialized && settings) &&
                  <>
                  <SelectField
                      title="Select the trading day(s) for that you would like the momentum trader to run through."
                      value={date_value}
                      multiple
                      name="date_value"
                      renderValue={(selected) => selected.join(', ')}
                      onChange={handleDateSelect}
                      label='Date(s)'
                  >
                      {date_list.map((date) => (
                          <MenuItem value={date} key={date}>
                              <BoxField checked={date_value.indexOf(date) > -1} label={date} className='d-block w-100' />
                          </MenuItem>
                      ))}
                  </SelectField>
                  <PickersUtilsProvider>
                      <TimePicker
                          label="Start Time"
                          value={start_time}
                          onChange={handleStartTime}
                      />
                      <TimePicker
                          label="End Time"
                          value={end_time}
                          onChange={handleEndTime}
                      />
                  </PickersUtilsProvider>
                  <InputField
                      title="Enter the hypothetical number of shares that you would like the momentum trader to make everytime it makes a trade."
                      name='volume_of_trades'
                      label="Volume of Shares"
                      type="number"
                      onChange={handleChange}
                      value={volume_of_trades}
                  />
                  <InputField
                      name='trade_frequency'
                      label="Trade Frequency (per x min)"
                      type="number"
                      onChange={handleChange}
                      value={trade_frequency}
                      title="This represents how long the algorithm will wait before it places the next trade.(ex. If you enter 5 then it will make a trade every 5 minutes.)"
                  />
                  <InputField
                      title="Enter an amount you would like to trail the stock price by before it makes a purchase. Ex. You enter $1, The stock is at $100 it goes down to $90, then it goes up to $91. Then a buy trade would be executed."
                      name='buy_differential'
                      label="Buy Differential"
                      type="number"
                      onChange={handleChange}
                      value={buy_differential}
                  />
                  <InputField
                      title="Enter an amount you would like to trail the stock price by before it sells your current stock. Ex. You enter $1, You bought stock at $100. It goes up to $110, then it drops to $109. Then a sell trade would be executed."
                      name='sell_differential'
                      label="Sell Differential"
                      type="number"
                      onChange={handleChange}
                      value={sell_differential}
                  />
                  <InputField
                      title="Enter the amount your broker charges you per trade."
                      name='broker_fee'
                      label="Broker Fee"
                      type="number"
                      onChange={handleChange}
                      value={broker_fee}
                  />
                  </>

                }

            </div>

            {real_time && (
                <>

                    {/* {existing_models.length > 0 && (
                        <ToolTip title="Select a model that you have created in the past.">
                            <BoxField
                                checked={!new_model}
                                onChange={existing_model_click}
                                name="new_model"
                                label="Existing Model"
                                type="radio"
                                className='flex-1'
                            />
                        </ToolTip>
                    )} */}

                </>
            )}

            {real_time && (
                <div className='d-flex flex-column gap-3'>
                    <ToolTip title="Have this algo run on a daily basis from Mon-Fri.">
                        <BoxField
                            checked={run_daily}
                            onChange={handleCheck}
                            name="run_daily"
                            label="Run Daily"
                        />
                    </ToolTip>
                    <ToolTip title="Select this if you would like to receive email notifications when the algorithm would buy or sell based off of the price differential.">
                        <BoxField
                            checked={email_notifications}
                            onChange={handleCheck}
                            name="email_notifications"
                            label="Email Notifications"
                        />
                    </ToolTip>
                    {/*!authenticated && (
                        <BoxField
                            disabled={start}
                            onChange={handleCheck}
                            name="activate_live_trading"
                            label="Activate Live Trading"
                            checked={activate_live_trading}
                        />
                    )*/}
                </div>
            )}


        </SideFilter>
    )
}

export default MomentumTraderMultiFilter;
