import React from 'react';
import ToolTip from '../../ui-v2/ToolTip/ToolTip';
import StockItem from './StockItem';
import Circle from '../../ui-v2/Circle/Circle';
import useQuery from '../../hooks/useQuery';
import SymbolTool from '../SymbolTool/SymbolTool';

const TipWrapper = ({ stepNo }) => {
    const isOpen = !useQuery() && stepNo === 1;
    return (
        <th id='tip-1'>
            <ToolTip title="Number of different participants that have traded the stock." open={isOpen}>
                <span className='position-relative'>
                    {isOpen && <Circle />}
                    Participants
                </span>
            </ToolTip>
        </th>
    )
}

const NormalTable = ({ stock_data = [], page, no_result, stepNo }) => {
    return (
        <>
            <thead>
                <tr>
                    <th>
                        <div>
                            <ToolTip title="The row number.">No</ToolTip>
                        </div>
                    </th>
                    <th>
                        <ToolTip title="The stock symbol.">Symbol</ToolTip>
                    </th>
                    <th>
                        <ToolTip title="The name of the insider.">Owner</ToolTip>
                    </th>
                    <th>
                        <ToolTip title="Number of transactions that took place on the stock.">Transactions</ToolTip>
                    </th>
                    <TipWrapper stepNo={stepNo} />
                    <th>
                        <ToolTip title="The insiders role in relation to the stock.">Relationship</ToolTip>
                    </th>
                    <th>
                        <ToolTip title="The date that the order occurred.">Date</ToolTip>
                    </th>
                    <th>
                        <ToolTip title="The type of transaction that occurred.">Transaction</ToolTip>
                    </th>
                    <th>
                        <ToolTip title="The stock price that the trade occurred at.">
                            Share Price ($)
                        </ToolTip>
                    </th>
                    <th>
                        <ToolTip title="The number of shares that the insider transacted.">#Shares</ToolTip>
                    </th>
                    <th>
                        <ToolTip title="The value of the transaction">Value ($)</ToolTip>
                    </th>
                    <th>
                        <ToolTip title="The total number of shares that transacted.">#Shares Total</ToolTip>
                    </th>
                </tr>
            </thead>
            <tbody>
                {
                    stock_data.length > 0 ?
                        stock_data.map((stock, index) => (
                            <tr key={index}>
                                <td title='No'>{page * 10 + index + 1}</td>
                                <td title='Symbol'>
                                    <SymbolTool
                                        date= {stock.date}
                                        ticker={stock.ticker}
                                        title={`Insiders Reaction for ${stock.ticker}`}
                                    />
                                </td>
                                <td title='Owner'><div>{stock.owner}</div></td>
                                <td title='Transactions'>{stock.individual}</td>
                                <td title='Participants'>{stock.include}</td>
                                <td title='Relationship'>{stock.relationship}</td>
                                <td title='Date'>{new Date(stock.date).toLocaleDateString()}</td>
                                <td title='Transaction'>{stock.transaction}</td>
                                <td title='Cost'>{stock.cost}</td>
                                <td title='#Shares'>{stock.shares}</td>
                                <td title='Value ($)'>{stock.value}</td>
                                <td title='#Shares Total'>{stock.totalShares}</td>
                            </tr>
                        ))
                        :
                        <tr>
                            {
                                no_result &&
                                <td colSpan={20} className="text-center vertical-align-top pt-3">
                                    <span className="fz14">No Results...</span>
                                </td>
                            }
                        </tr>
                }
            </tbody>
        </>
    )
}

const ExpandableTableWrapper = ({ stock_data = [], page, no_result, selectionRange, stepNo, filter_transaction }) => {
    return (
        <>
            <thead>
                <tr>
                    <th></th>
                    <th><ToolTip title="The row number.">No</ToolTip></th>
                    <th>
                        <div>
                            <ToolTip title="The stock symbol.">Symbol</ToolTip>
                        </div>
                    </th>
                    <th><ToolTip title="Number of transactions that took place on the stock.">Transactions</ToolTip></th>
                    <TipWrapper stepNo={stepNo} />
                    <th><ToolTip title="The date that the order occurred.">Date Range</ToolTip></th>
                    {/* <th>Transaction Type</th> */}
                    <th><ToolTip title="The stock price that the trade occurred at.">Share Price Range ($)</ToolTip></th>
                    <th><ToolTip title="The total number of shares that transacted.">#Shares Total</ToolTip></th>
                </tr>
            </thead>
            <tbody>
                {
                    stock_data.length > 0 ?
                        stock_data.map((stock, index) => (
                            <StockItem key={index}
                                stock={stock}
                                selectionRange={selectionRange}
                                filter_transaction={filter_transaction}
                                page={page * 10 + index + 1}
                            />
                        ))
                        :
                        <tr>
                            {
                                no_result &&
                                <td colSpan={20} className="text-center vertical-align-top pt-3">
                                    <div>No Results...</div>
                                </td>
                            }
                        </tr>
                }
            </tbody>
        </>
    )
}

const StockTable = props => {
    return (
        <>
            <div className='product_tool_table table-wrapper'>
                <div className={props.stepNo === 1 ? 'overflow-hidden' : ''}>
                    <table>
                        {props.tableView === "individual" ? <NormalTable {...props} /> : <ExpandableTableWrapper {...props} />}
                    </table>
                </div>
            </div>
        </>
    )
}

export default StockTable;
