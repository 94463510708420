import React from "react";

const StarIcon = props => (
    <svg viewBox="0 0 20 19" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
        <path d="M12.1738 6.72381L12.2883 7.06464H12.6478H19.1488V6.56464L19.1504 7.06463C19.2138 7.06442 19.2748 7.08845 19.321 7.13178C19.3578 7.16635 19.383 7.21112 19.3936 7.25992C19.3831 7.3015 19.3617 7.33973 19.3314 7.37051L19.2306 7.45663L13.9492 11.2447L13.6467 11.4617L13.7678 11.8136L15.8182 17.7756L15.8194 17.7791C15.8336 17.8196 15.8346 17.8635 15.822 17.9045C15.8095 17.9455 15.7842 17.9814 15.7498 18.0069L15.7257 18.0249L15.7038 18.0455C15.6878 18.0607 15.6684 18.0717 15.6474 18.0775C15.598 18.0687 15.5511 18.0487 15.5104 18.0188L15.5104 18.0187L15.5043 18.0144L10.2892 14.2975L9.999 14.0906L9.70881 14.2975L4.49371 18.0144L4.49293 18.0149C4.45055 18.0453 4.40235 18.0661 4.35159 18.0763C4.32514 18.0686 4.30059 18.055 4.27987 18.0364L4.26424 18.0224L4.24748 18.0097C4.21233 17.9831 4.18646 17.946 4.17358 17.9039C4.16071 17.8618 4.16143 17.8167 4.17562 17.7751C4.17565 17.775 4.17567 17.7749 4.1757 17.7748L6.22582 11.8136L6.34793 11.4586L6.041 11.2423L0.697198 7.47701L0.69725 7.47693L0.689369 7.4716C0.667384 7.45673 0.648963 7.43717 0.63543 7.41433C0.621899 7.39149 0.613592 7.36594 0.611107 7.33952L0.608006 7.30655L0.600586 7.27428C0.600255 7.27284 0.600091 7.27137 0.600098 7.26989L0.100103 7.26754L0.600082 7.27218C0.600594 7.21707 0.622803 7.16438 0.661897 7.12553C0.700861 7.08681 0.753469 7.06495 0.808385 7.06464H7.3447H7.70423L7.81869 6.72381L9.79554 0.837176C9.8112 0.797931 9.83805 0.764097 9.87279 0.739911C9.90902 0.714691 9.95211 0.701172 9.99625 0.701172C10.0404 0.701172 10.0835 0.714691 10.1197 0.739911C10.1545 0.764098 10.1813 0.797932 10.197 0.837177L12.1738 6.72381Z" stroke="currentColor" />
    </svg>

)

export default StarIcon;
