import React from 'react';
import Model from '../../ui-v2/Modal/Model';
import Video from '../Video/Video';
import styles from './VideoWrapper.module.scss';

const VideoWrapper = ({ url, className = '', ...props }) => (
    <Model {...props} className={`bg-none ${styles.root}`}>
        <Video url={url} />
    </Model>
)

export default VideoWrapper;