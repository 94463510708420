import React from 'react';
import './styles.scss'

export default function CopyBanner() {
    const [active, setActive] = React.useState(false);
    React.useEffect(() => {
        if(active) {
            setTimeout(() => {
                setActive(false);
            }, 3000)
        }
    }, [active])
    return (
        <>
            <input type='radio' className='d-none' id='Copy-Input' checked={active} onChange={() => setActive(true)} />
            <p className='Copy-Banner'>Copied to your Clipboard <i className="fas text-accent-3 fa-check"></i></p>
        </>
    )
}