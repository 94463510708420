import React from 'react';
import RangePicker from '../../../ui/RangePicker';
import TextFieldV3 from '../../../ui-v2/TextFieldV3/TextFieldV3';
import Calendar from '../../../icons-v2/Calendar';
import MagnifierGlassIcon from '../../../icons-v2/MagnifierGlassIcon';

const Filters = props => (
    <div className={`${props.className} d-grid`}>
        <TextFieldV3 name='stock'
            label="Stock"
            placeholder="Search"
            value={props.values.stock}
            onChange={props.onChange}
            Icon={MagnifierGlassIcon}
        />
        <TextFieldV3 type="number"
            name="accuracy"
            placeholder="%"
            label="Accuracy"
            value={props.values.accuracy}
            onChange={props.onChange}
        />
        <RangePicker
            onChange={props.onDateChange}
            component={({ show }) => (
                <>
                    <TextFieldV3
                        label="Date Range"
                        onFocus={show}
                        value={props.values.start_date}
                        readOnly
                        name="start_date"
                        placeholder="From"
                        Icon={Calendar}
                    />
                    <TextFieldV3
                        className='mt-auto'
                        onFocus={show}
                        value={props.values.end_date}
                        readOnly
                        name="end_date"
                        placeholder="To"
                        Icon={Calendar}
                    />
                </>
            )}
        />
        <TextFieldV3 name='hashtags'
            label="HashTags"
            placeholder="#tags"
            value={props.values.hashtags}
            onChange={props.onChange}
            Icon={MagnifierGlassIcon}
        />
    </div>
)

export default Filters;