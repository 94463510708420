
import { fields } from "../live/fundamentals/fields"

// fields.forEach(field => filter[`pure_${field.name}`] = true);
let fundamentals_list = fields.map((x) => x.title)


const getSignalsFields = (toolName, values = {}) => {
  const data = {
    ipos: [

      {
        array: values.sort_type || [],
        state_name: "sort",
        title: "Sort by",
        value: values.sort
      },
      {
        array: values.period_type || [],
        state_name: "period",
        title: "Filter by Date",
        value: values.period
      },
      {
        array: [
          "Previous",
          "Upcoming"
        ],
        state_name: "time_state",
        title: "Analyze Previous or Upcoming",
        value: values.time_state,
        tooltip: 'Determine whether you want to have stocks that have already IPOd or have their IPO coming up',
      },
      {
        array: [0],
        state_name: "start_date",
        title: "Starting Time frame (days)",
        value: values.date,
        tooltip: 'Enter in the number of days to wait before starting to collect data. This will either be forewards or backwards facing based off of if you select upcoming or previous. i.e collect stocks that have/had there IPO date between x-end_date_range. ',
        type: 'number'
      },
      {
        array: [30],
        state_name: "end_date",
        title: "Ending Time frame (days)",
        value: values.date,
        tooltip: 'Enter in the number of days to wait before ending the data collection.This will either be forewards or backwards facing based off of if you select upcoming or previous. i.e collect stocks that have/had there IPO date between start_date_range-x.  ',
        type: 'number'
      },

    ],
    insiders: [
      {
        array: ["Number of transactions", "Number of participants", "Most Value"],
        state_name: "sort",
        title: "Sort By",
        value: values.sort,
        tooltip: 'Get a list with the most participants, most trades, or the cost of the stocks purchased.'
      },
      {
        array: ["All transactions", "Buy transactions", "Sale transactions"],
        state_name: "filter",
        title: "Filter by Type",
        value: values.filter,
        tooltip: 'Get a list of all, buy, or sell transactions.'
      },
      {
        array: [5],
        state_name: "date",
        title: "Time Frame(days)",
        value: values.date,
        tooltip: 'Enter in the number of days prior to the current day that you would like to get results for. Ex. 5 would give you the insider transactions for the previous 5 days.',
        type: 'number'
      },
    ],
    earnings: [
      {
        array: [
          "Date",
          "Highest Market Cap",
          "Highest EPS Surprise",
          "Lowest EPS Surprise",
          "Highest Rev Surprise",
          "Lowest Rev Surprise",
          "Highest Total Surprise",
          "Lowest Total Surprise",
          "Highest Avg Reaction",
          "Lowest Avg Reaction",
        ],
        state_name: "sort",
        title: "Sort by",
        value: values.sort,
        tooltip: 'Sort the results based off of highest or lowest surprise.',
      },
      {
        array: [
          "Previous",
          "Upcoming"
        ],
        state_name: "time_state",
        title: "Analyze Previous or Upcoming",
        value: values.sort,
        tooltip: 'Determine whether you want to have stocks that have already had their earnings released or stocks that have their earnings day coming up.',
      },
      {
        array: [
          "All",
          "AM",
          "PM"
        ],
        state_name: "time",
        title: "Earnings release time (morning or afternoon)",
        value: values.sort,
        tooltip: 'Do you want your earnings stocks to be in the morning, afternoon, or both.',
      },
      {
        array: [0],
        state_name: "start_date",
        title: "Starting Time frame (days)",
        value: values.date,
        tooltip: 'Enter in the number of days to wait before starting to collect data. This will either be forewards or backwards facing based off of if you select upcoming or previous. i.e collect stocks that have/had there earnings date between x-end_date_range. ',
        type: 'number'
      },
      {
        array: [1],
        state_name: "end_date",
        title: "Ending Time frame (days)",
        value: values.date,
        tooltip: 'Enter in the number of days to wait before ending the data collection.This will either be forewards or backwards facing based off of if you select upcoming or previous. i.e collect stocks that have/had there earnings date between start_date_range-x.  ',
        type: 'number'
      },
    ],
    government: [

      {
        array: ["Date", "Number of transactions", "Number of participants", "Most Value"],
        state_name: "sort",
        title: "Sort by",
        value: values.sort,
        tooltip: 'Sort by date, transactions, participants, or value.'
      },
      {
        array: [90],
        state_name: "date",
        title: "Time Frame(days)",
        value: values.date,
        tooltip: 'Enter in the number of days prior to the current day that you would like to get results for. Ex. 5 would give you the insider transactions for the previous 5 days.',
        type: 'number'
      },
    ],
    dividends: [
      {
        array: ["Date", "Biggest Payout", "Biggest Payout Percent"],
        state_name: "sort",
        title: "Sort by",
        tooltip: 'Have your list contain dividends by date, biggest payout, or biggest payout percent.',
        value: values.sort
      },
      {
        array: ["All", "Quarterly", "Monthly", "Semi-Annual", "Annual"],
        state_name: "period",
        title: "Filter by Period",
        tooltip: 'Have you list show stocks that have different dividend payout frequencies.',
        value: values.period
      },
      {
        array: [
          "Previous",
          "Upcoming"
        ],
        state_name: "time_state",
        title: "Analyze Previous or Upcoming",
        value: values.time_state,
        tooltip: 'Determine whether you want to have stocks that have already had their dividends released or stocks that have their ex-dividends day coming up.',
      },
      {
        array: [0],
        state_name: "start_date",
        title: "Starting Time frame (days)",
        value: values.date,
        tooltip: 'Enter in the number of days to wait before starting to collect data. This will either be forewards or backwards facing based off of if you select upcoming or previous. i.e collect stocks that have/had there ex dividends date between x-end_date_range. ',
        type: 'number'
      },
      {
        array: [1],
        state_name: "end_date",
        title: "Ending Time frame (days)",
        value: values.date,
        tooltip: 'Enter in the number of days to wait before ending the data collection.This will either be forewards or backwards facing based off of if you select upcoming or previous. i.e collect stocks that have/had there ex-dividends date between start_date_range-x.  ',
        type: 'number'
      },
    ],
    congress: [
      { state_name: "people_search", title: "Representative Name", value: values.representative, type: "string", required: false },
      {
        array: ["All transactions", "Purchase transactions", "Sale transactions"],
        state_name: "filter",
        title: "Filter by Transaction Type",
        value: values.filter,
        tooltip: 'Get a list of all, buy, or sell transactions.'
      },
      {
        array: ["Date", "Number of transactions", "Number of participants", "Most Value"],
        state_name: "sort",
        title: "Sort by",
        value: values.sort,
        tooltip: 'Sort by date, transactions, participants, or value.'
      },
      {
        array: [90],
        state_name: "date",
        title: "Time Frame(days)",
        value: values.date,
        tooltip: 'Enter in the number of days prior to the current day that you would like to get results for. Ex. 5 would give you the insider transactions for the previous 5 days.',
        type: 'number'
      },
    ],
    house: [
      {
        array: ["All transactions", "Purchase transactions", "Sale transactions"],
        state_name: "filter",
        title: "Filter by Transaction Type",
        value: values.filter,
        tooltip: 'Get a list of all, buy, or sell transactions.'
      },
      {
        array: ["Date", "Number of transactions", "Number of participants", "Most Value"],
        state_name: "sort",
        title: "Sort by",
        value: values.sort,
        tooltip: 'Sort by date, transactions, participants, or value.'
      },
      {
        array: [90],
        state_name: "date",
        title: "Time Frame(days)",
        value: values.date,
        tooltip: 'Enter in the number of days prior to the current day that you would like to get results for. Ex. 5 would give you the insider transactions for the previous 5 days.',
        type: 'number'
      },
    ],
    senator: [
      {
        array: ["All transactions", "Purchase transactions", "Sale transactions"],
        state_name: "filter",
        title: "Filter by Transaction Type",
        value: values.filter,
        tooltip: 'Get a list of all, buy, or sell transactions.'
      },
      {
        array: ["Date", "Number of transactions", "Number of participants", "Most Value"],
        state_name: "sort",
        title: "Sort by",
        value: values.sort,
        tooltip: 'Sort by date, transactions, participants, or value.'
      },
      {
        array: [90],
        state_name: "date",
        title: "Time Frame(days)",
        value: values.date,
        tooltip: 'Enter in the number of days prior to the current day that you would like to get results for. Ex. 5 would give you the insider transactions for the previous 5 days.',
        type: 'number'
      },
    ],
    fda: [
      {
        array: ["All", "Decision", "Reviewal"],
        state_name: "filtered_event_type",
        title: "Event Decision",
        tooltip: 'Have your list show stocks based on the type of FDA event',
        value: values.event_decision
      },
      {
        array: ["All", "Approved", "Declined", "Supported", "Recommended Approval", "Extended"],
        state_name: "filtered_outcome",
        title: "Filter by Outcome",
        tooltip: 'Have you list show stocks that had different types of FDA outcomes',
        value: values.filtered_outcome
      },
      {
        array: [
          "Previous",
          "Upcoming"
        ],
        state_name: "time_state",
        title: "Analyze Previous or Upcoming",
        value: values.time_state,
        tooltip: 'Determine whether you want to have stocks that have already had their fda results released or stocks that have their fda review day coming up.',
      },
      {
        array: [0],
        state_name: "start_date",
        title: "Starting Time frame (days)",
        value: values.date,
        tooltip: 'Enter in the number of days to wait before starting to collect data. This will either be forewards or backwards facing based off of if you select upcoming or previous. i.e collect stocks that have/had there fda date between x-end_date_range. ',
        type: 'number'
      },
      {
        array: [30],
        state_name: "end_date",
        title: "Ending Time frame (days)",
        value: values.date,
        tooltip: 'Enter in the number of days to wait before ending the data collection.This will either be forewards or backwards facing based off of if you select upcoming or previous. i.e collect stocks that have/had there fda date between start_date_range-x.  ',
        type: 'number'
      },
    ],

    fundamentals: [
      {
        array: fundamentals_list,
        state_name: "sortby",
        title: "Select which field you would like to filter by",
        value: values.sortby,
        tooltip: 'Select the ratio/field that you care about the most',
      },
      {
        array: [
          "Highest",
          "Lowest"
        ],
        state_name: "asc",
        title: "Select how you would like the ratio to be sorted",
        value: values.asc,
        tooltip: 'This will return a list of stocks with either the highest or lowest of the ratio you selected',
      },
    ],

  }
  return toolName ? data[toolName] : data;
}

export default getSignalsFields;
