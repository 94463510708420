import axios from 'axios';
import React, { useState } from 'react';
import { useMemo } from 'react';
import useStockSearch from '../../../hooks/useStockSearch';
import CheckIcon from '../../../icons-v2/CheckIcon';
import axiosErrorHandler from '../../../utility/axiosErrorHandler';
import SearchWrapper from '../SearchWrapper/SearchWrapper';
import StockItem from '../StockItem/StockItem';
import Graph_module from '../../../live/graph_module';

const StockList = ({ selectedItem, setState }) => {
    const { results, onAdd, ...sProps } = useStockSearch({ isInitialRequired: false });
    const [graphSymbol, setGraphSymbol] = useState(null)
    const stocks = selectedItem.stocks;

    const symbols = useMemo(() => [], [stocks]);

    const list = useMemo(() => stocks.map(item => {
        symbols.push(item.ticker);
        return <StockItem className='cursor-pointer' onClick={() => setGraphSymbol(item)} {...item} key={item._id} />;
    }), [stocks]);

    const onChange = (item, checked) => {
        const stocks = [...selectedItem.stocks];
        const newSelected = { ...selectedItem };
        newSelected.stocks = checked ? stocks.filter(it => it._id !== item._id) : [...stocks, item];
        setState({ selectedItem: newSelected })
        axios.put(
            checked ? '/remove_from_watchlist' : '/add_to_watchlist',
            { stock_item_id: item._id, watch_list_id: selectedItem._id }
        )
            .catch(err => {
                newSelected.stocs = stocks;
                setState({ selectedItem: newSelected });
                axiosErrorHandler(err);
            })

    }
    return (
        <>
            {graphSymbol && <Graph_module close={() => setGraphSymbol(null)} symbol={graphSymbol.ticker} text={graphSymbol.ticker} />}
            <div className='px-3 flex-1 overflow-auto'>
                {selectedItem.watchlist_type !== 'dynamic' && <SearchWrapper {...sProps} className='gap-0' placeholder="Quick add" />}
                {sProps.value === "" ? list :
                    results.map(item => {
                        const checked = symbols.includes(item.ticker);
                        return (
                            <StockItem {...item}
                                key={item._id}
                                action={
                                    <button
                                        onClick={() => onChange(item, checked)}
                                        className={`btn btn-text fs-inherit ${checked ? '' : 'text-accent-4'}`}
                                    >Add{checked && <>ed<CheckIcon className='smaller' /></>}
                                    </button>
                                }
                            />
                        )
                    })
                }
            </div>
        </>
    )
}

export default StockList;