import React, { Fragment } from 'react';
import styles from './styles.module.scss';

const BarChart = props => {
    const numbers = props.data.map(item => +item.value);
    const max = Math.max(...numbers)
    return (
        <div className={styles.root}>
            {props.data.map((item, index) => (
                <Fragment key={index}>
                    <span>
                        <b>{item.title}</b>
                        {item.value}
                    </span>
                    <div style={{ width: `${item.value * 100 / max}%` }} />
                </Fragment>
            ))}
        </div>
    )
}

export default BarChart;