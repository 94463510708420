import React from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Calendar from '../../icons-v2/Calendar';
import styles from './styles.module.scss';

const DatePicker = ({ className = '', ...props }) => (
    <KeyboardDatePicker
        {...props}
        inputVariant='outlined'
        KeyboardButtonProps={{
            'aria-label': 'change time',
        }}
        className={`__custom-input-field ${styles.root} ${className}`}
        format="MM/dd/yyyy"
        keyboardIcon={<Calendar />}
        inputProps={{className: styles.main}}
        DialogProps={{className: styles.dialog}}
    />
)

export default DatePicker;