import { useApp } from '../App';
import { useEffect } from 'react';
import { useHistory } from "react-router";

const useAuth = (props = {}) => {
    const { isForAuthorized = false, isForUnAuthorized = false } = props;
    const auth = useApp();
    const isLoggedIn = auth.isLoggedIn;
    const history = useHistory();

    useEffect(() => {
        if (isForAuthorized && !isLoggedIn) history.replace('/auth/signup');
        else if (isForUnAuthorized && isLoggedIn) {
            const { pathname, search } = history.location;
            if(/auth/i.test(pathname)) history.replace(search.split('redirect=')[1] || '/');
        }
    }, [isLoggedIn]);

    return { auth, history };
}

export default useAuth;