import React from 'react';
import { useHistory } from "react-router-dom"

const LoginButton = ({ className = '', isLogin = true, children, isPopup = true }) => {
    const history = useHistory();
    const path = isLogin ? 'login' : 'register';
    return (
        <button className={className}
            onClick={() => {
                isPopup ? history.replace({
                    hash: path,
                    search: history.location.search
                }) : history.push(`/${path}`)
            }}
        >
            {children}
        </button>
    )
}

export default LoginButton;