import React from 'react';

const CreditIcon = props => (
    <svg {...props} viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.0749 18.1C4.6152 18.1 0.999903 14.272 0.999903 9.55C0.999903 4.82797 4.6152 1 9.0749 1C13.5346 1 17.1499 4.82797 17.1499 9.55C17.1499 14.272 13.5346 18.1 9.0749 18.1Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M11.45 6.51C10.8454 6.0197 10.0909 5.75146 9.3125 5.75C7.34505 5.75 5.75 7.4505 5.75 9.55C5.75 11.6495 7.34505 13.35 9.3125 13.35C10.1143 13.35 10.8544 13.0669 11.45 12.59" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M9.55005 1C13.0337 1 20 1.855 20 9.55C20 17.245 13.0337 18.1 9.55005 18.1" stroke="currentColor" strokeWidth="1.5" />
    </svg>
)

export default CreditIcon;