import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import SelectField from '../../ui-v2/SelectField/SelectField';
import InputField from '../../ui-v2/InputField/InputField';
import SideFilter from '../../components-v2/SideFilter/SideFilter';
import WatchListImport from '../../components-v2/AlgoHoldingCommonTools/watchlist_import';
import SignalButtons from '../../components-v2/AlgoHoldingCommonTools/SignalButtons';
import useQuery from '../../hooks/useQuery';
import SignalsHandler from '../SignalsHandler/SignalsHandler';


const IPOSFIlters = ({
    handleFilterChange,
    sort_type,
    period,
    period_type,
    selected_type,
    handleSortChange,
    handleStockChange,
    stock_search
}) => {
    const signal = <SignalsHandler
        name="ipos"
        algo_name="Ipos"
        signalValues={{ sort_type, period_type }}
        component={options => <SignalButtons {...options} />}
    />
    const isDesktop = useQuery();
    return (
        <SideFilter component={isDesktop && <div className='mx-auto d-flex gap-3 align-items-center'>{signal}</div>}>
            {!isDesktop && (
                <div className='border-bottom d-grid gap-3 pb-4'>
                    {signal}
                </div>
            )}
            <div>
                <div className='d-flex mb-2 justify-content-between'>
                    <small>Data Filters</small>
                    <WatchListImport handleStockChange={handleStockChange} />
                </div>
                <InputField
                    fullWidth
                    name='stock_search'
                    label="Search stock symbol"
                    onChange={handleStockChange}
                    value={stock_search}
                />
            </div>
            <div className='d-flex flex-column gap-3'>
                <SelectField
                    fullWidth
                    value={period}
                    name="period"
                    onChange={handleFilterChange}
                    label="Filter By Date"
                >

                    {period_type.map((type) => (
                        <MenuItem value={type}>{type}</MenuItem>
                    ))}
                </SelectField>
                <SelectField
                    fullWidth
                    value={selected_type}
                    name="selected_type"
                    onChange={handleSortChange}
                    label="Sort By"
                >
                    {sort_type.map((type) => (
                        <MenuItem value={type}>{type}</MenuItem>
                    ))}
                </SelectField>
            </div>

        </SideFilter>
    )
}

export default IPOSFIlters;
