import React from 'react';
import TopLeaderBoardsCard from "../../components-v2/LeaderBoardComponents/TopLeaderBoardsCard/TopLeaderBoardsCard";
import withLeaderBoard from "../../components-v2/LeaderBoardComponents/withLeaderBoard";
import PrizeBanner from '../../components-v2/LeaderBoardComponents/PrizeBanner/PrizeBanner';


const Top10 = props => {
    return (
        <>
            <PrizeBanner />
            <TopLeaderBoardsCard {...props} />
        </>
    )
}

export default withLeaderBoard(Top10);