import React from "react";
import User from "../LeaderBoardComponents/User/User";
import CheckCircle from "../../icons-v2/CheckCircle";
import CrossCircle from "../../icons-v2/CrossCircle";
import ParticipantsModal from "./ParticipantsModal";

export default function Participants({ participants, pendings, totalParticipants, onAccept, onReject, ...props }) {
    return (
        <div className="d-grid gap-2 leaderboard-sticky-long">
            {React.useMemo(() => pendings.length > 0 && (
                <div className="card">
                    <small className='smaller border-bottom text-accent-4 px-3 py-2 d-block'>Requests ({pendings.length})</small>
                    <div className='p-3 d-grid overflow- small gap-3'>
                        {pendings.map(participant => (
                            <div key={participant._id} className="d-flex gap-1">
                                <User
                                    isLoggedIn={props.isLoggedIn}
                                    {...participant}
                                    className='fw-normal'
                                />
                                <button
                                    onClick={() => onAccept(participant)}
                                    className="btn-icon text-accent-3 no-padding btn h6 me-auto"
                                >
                                    <CheckCircle />
                                </button>
                                <button
                                    onClick={() => onReject(participant._id)}
                                    className="btn-icon no-padding text-accent-2 btn h6"
                                >
                                    <CrossCircle />
                                </button>
                            </div>
                        ))}
                    </div>
                </div>
            ), [pendings])}
            {React.useMemo(() => (
                <div className='card'>
                    <small className='smaller border-bottom text-accent-4 px-3 py-2 d-block'>Participants ({totalParticipants})</small>
                    <div className='p-3 d-grid small gap-3'>
                        {participants.map(participant => (
                            <User
                                isLoggedIn={props.isLoggedIn}
                                {...participant}
                                className='fw-normal d-flex'
                                key={participant._id}
                                subtitle={`(${participant.totalPosts})`}
                            />
                        ))}
                    </div>
                    {participants.length === 5 && <ParticipantsModal {...props} title="View All" className="mb-3 mx-auto" />}
                </div>
            ), [participants])}
        </div>
    )
}