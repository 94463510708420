import React, { Component } from 'react';
import ReactChart from '../components-v2/ReactChart/ReactChart';
import usePost from '../hooks/usePost';
import Model from '../ui-v2/Modal/Model';
import Spinner from '../ui-v2/Spinner/Spinner';

// const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step));

class Main extends Component {
  state = {
    carousel_number: 0,
    data: [
      {
        label: 'Hold',
        data: [[0, 3], [1, 1], [2, 5], [3, 6], [4, 4]]
      },
      {
        label: 'Buy',
        data: [[0.5, 3], [2, 5], [3, 6], [4, 4]]
      },
      {
        label: 'Sell',
        data: [[0, 3], [1, 1], [2, 5], [3, 6], [4, 4]]
      },
    ],
    all_data: [],
    axes: [
      { primary: true, type: 'linear', position: 'bottom' },
      { type: 'linear', position: 'left' }
    ],
    series: {
      type: 'bubble',
      showPoints: false
    },
    data_arr: [],
    buy_broker_fees: 0,
    sell_broker_fees: 0,
    loaded:false

  }
  componentDidMount() {

    if (this.props.results.data_set.length > 0) {

      this.create_chart_data()



    }

  }


  create_chart_data = async function () {
    let current_data = this.props.results
    let time_data = this.props.time_arr
    this.process_all_data(current_data, time_data)

  }
  process_all_data = async function (current_data, time_data) {
    let buy_arr = []
    let sell_arr = []
    let hold_arr = []
    for (let i = 0; i < time_data.length; i++) {

      if (current_data.states_buy.includes(i)) {
        let buy_obj = [i, current_data.data_set[i]]
        buy_arr.push(buy_obj)
        if (i === time_data.length - 1) {
          this.setState({
            data: [
              {
                label: 'Hold',
                data: hold_arr
              },
              {
                label: 'Buy',
                data: buy_arr
              },
              {
                label: 'Sell',
                data: sell_arr
              },
            ],
            buy_broker_fees: buy_arr.length * this.props.broker_fee,
            sell_broker_fees: sell_arr.length * this.props.broker_fee

          })
        }
      }
      else if (current_data.states_sell.includes(i)) {
        let sell_obj = [i, current_data.data_set[i]]
        sell_arr.push(sell_obj)
        if (i === time_data.length - 1) {
          this.setState({
            data: [
              {
                label: 'Hold',
                data: hold_arr
              },
              {
                label: 'Buy',
                data: buy_arr
              },
              {
                label: 'Sell',
                data: sell_arr
              },
            ],
            buy_broker_fees: buy_arr.length * this.props.broker_fee,
            sell_broker_fees: sell_arr.length * this.props.broker_fee

          })
        }
      }
      else {
        let hold_obj = [i, current_data.data_set[i]]
        hold_arr.push(hold_obj)
        if (i === time_data.length - 1) {
          this.setState({
            data: [
              {
                label: 'Hold',
                data: hold_arr
              },
              {
                label: 'Buy',
                data: buy_arr
              },
              {
                label: 'Sell',
                data: sell_arr
              },
            ],
            buy_broker_fees: buy_arr.length * this.props.broker_fee,
            sell_broker_fees: sell_arr.length * this.props.broker_fee

          })
        }
      }
    }
    this.setState({
      loaded:true
    })
  }






  render() {
    var total_fees = this.state.buy_broker_fees + this.state.sell_broker_fees
    return (
      <>
      {this.state.loaded &&
        <Model show={true} closeHandler={this.props.close} title={this.props.title}
          actions={() => <button className='btn btn-primary' onClick={this.props.close}>Close</button>}
        >
          <ul className='mt-0 d-grid col-2 gap-3 p-0'>
            <li>Started with <b>${this.props.starting_amt}</b></li>
            <li>Revenue: <b>${this.props.results.total_gains.toFixed(2)}</b></li>
            <li>Fees: <b>${total_fees}</b></li>
            <li>Profit: <b>${(this.props.results.total_gains - total_fees).toFixed(2)}</b></li>
            <li>Algo Return: <b>{((this.props.results.total_gains - total_fees) * 100 / this.props.starting_amt).toFixed(2)}%</b></li>
          </ul>

          <ReactChart {...this.state} {...this.props} />
        </Model>
      }
      </>

    )
  }

}
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null,
      retryCount: 0
    };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo });
  }

  handleRetry = () => {
    this.setState(prevState => ({ retryCount: prevState.retryCount + 1, error: null, errorInfo: null }));
  }

  render() {
    const { error, errorInfo, retryCount } = this.state;
    const { fallback, children } = this.props;

    if (error) {
      if (retryCount < 2) {

        // Retry the component after a delay
        setTimeout(() => this.handleRetry(), 1000);
        return null;
      }

      // If the component has already failed once, render the fallback component
      return fallback ? fallback({ error, errorInfo }) : null;
    }

    // If there's no error, render the children
    return children;
  }
}

const MyChart = ({ model_id, index, type, dates, ...props }) => {
  const { res, err } = usePost("/get_ml_graph_daily", { model_id, index, type, dates });
  if (!res && !err) return <Spinner toMiddle />
  return <>
  <ErrorBoundary>
  <Main results={res.result} time_arr={res.time_arr} {...props} />
  </ErrorBoundary>
  </>
}

export default MyChart;
