import React, { useState } from 'react';
import './tabs.scss';

const tabs = ['Participants', 'Feed', 'Leaderboard']

export default function Tabs() {
    const [index, setIndex] = useState(1);
    return (
        <div className={`Competition-Tabs tab-${index} mt-1 tabs-leaderboard`}>
            {tabs.map((tab, i) => <button key={i} type='button' className={i === index ? 'active' : ''} onClick={() => setIndex(i)}>{tab}</button>)}
        </div>
    )
}