import React, { useState } from 'react';
import AlgoItem from '../../../components-v2/AlgoItemInstitutional/AlgoItem';
import MainFiltersSection from '../../../components-v2/FiltersSection/MainFiltersSectionInstitutional';

const Main = props => {
    const [algos, setAlgos] = useState([])
    return (
        <div className="d-flex flex-column align-lg-items-start flex-lg-row m-md-4 m-3 gap-md-4 gap-3">
            <MainFiltersSection setAlgos={setAlgos} results={algos.length} sortFields={[]} onChange={e => { }} {...props} />
            <div className='d-grid flex-1 col-xxl-5 col-xl-4 col-md-3 col-sm-2 gap-3' id="algo-list">
                {algos.map(algo => <AlgoItem key={algo._id} {...algo} />)}
            </div>
        </div>
    )
}

export default Main;