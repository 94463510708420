import React from 'react';
import withAlgoProductToolsLayout from '../../hoc-v2/withAlgoProductToolsLayout/withAlgoProductToolsLayout';
import ChatRoom from './ChatRoom';
import Filter from './Filter';
import OpenImage from './OpenImage';

const OpenAI = props => {
    const [state, setState] = React.useState({ isImage: false, chat: [], num_loop:5, should_loop:true, ...props });
    const onStateChange = obj => setState({ ...state, ...obj });
    return (
        <>
            <Filter onChange={onStateChange} {...state} />
            {state.isImage ? <OpenImage {...state} /> : <ChatRoom {...state} />}
        </>
    )
}


export default withAlgoProductToolsLayout(OpenAI, 'AI Playground', { isResponsive: false });
