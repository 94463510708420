import React from "react";
import SiteTitle from '../../components/site_title'
import Banner from "../../components/Banner/Banner";
import useGet from "../../hooks/useGet";
import Main from "./Main/Main";
import ToolsSubscription from "./ToolsSubscription/ToolsSubscription";


const MarketPlacePage = () => {
  const { data, Loader, err } = useGet('/algos');
  return (
    <>
      

      {data ? <Main algos={data.data} />:
        err ? <p className='text-accent-2 mt-4 text-center fw-semibold'>{err}</p> :
          <Loader toMiddle={true} />
      }
    </>
  )
}


export default MarketPlacePage;