import React from 'react';
import TopLeaderBoards from '../TopLeaderBoards/TopLeaderBoards';
import User from '../User/User';
import styles from './TopLeaderBoardsCard.module.scss';
import useGet from '../../../hooks/useGet';
import Spinner from '../../../ui-v2/Spinner/Spinner';
import SelectV3, { Option } from '../../../ui-v2/SelectV3';
import { useParams } from 'react-router-dom';

const ListItem = props => (
  <li value={props.value}>
    <User {...props} />
  </li>
)

const TopLeaderBoardsCard = ({ className = '', ...props }) => {
  const limit = 10;

  const { id } = useParams();
  const isCompetition = Boolean(id);
  const initialParams = { limit, show: isCompetition ? 'COMPETITION' : 'MONTH', leaderboard_scope: isCompetition ? 'COMPETITION' : 'GLOBAL', competition_id: id };

  const { data, err, onParamsChange, params } = useGet('/fetch_leaderboard_standings', initialParams)

  return (
    <>
      <h1 className='d-none'>Leaderboard Top</h1>
      <div className={`card d-grid pb-2 small ${className}`}>
        <p className="m-0 text-accent-4 px-3 d-flex py-2 border-bottom">
          Leaderboard Top {limit}
          {!isCompetition && (
            <SelectV3 value={params.show} className='me-auto text-accent-4' onChange={e => onParamsChange({ show: e.target.value })}>
              <Option key='ALL' value='ALL'>All</Option>
              <Option key='MONTH' value='MONTH'>Month</Option>
              <Option key='WEEK' value='WEEK'>Week</Option>
            </SelectV3>

          )}
        </p>
        {!data && !err ? <Spinner fontSize='.5rem' className='mx-auto text-accent-3 mt-3 mb-2' /> : (
          data && (
            <ul className={`${styles.topFive} d-grid gap-3 m-3 list-unstyled`}>
              {data.results.map(st => (
                <ListItem key={st._id} isLoggedIn={props.isLoggedIn} {...st.userId} value={isCompetition ? `${st.points?.toFixed(2)}` : `${st.guess_accuracy?.toFixed(2)}%`} />
              ))}
              {data.results.length > 0 && <TopLeaderBoards show={params.show} className='mx-auto mb-1 small' {...props} />}
            </ul>
          )
        )}
      </div>
    </>
  )
}

export default TopLeaderBoardsCard;

