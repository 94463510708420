import React from 'react';
import axios from 'axios'
import URL from 'url-parse';
import moment from "moment";
import ResultsGraph from '../results_graph'
import MomentumTraderMultiFilter from './MomentumTraderMultiFilter';
import SaleBar from '../../components/SaleBar/SaleBar';
import withAlgoProductToolsLayout from '../../hoc-v2/withAlgoProductToolsLayout/withAlgoProductToolsLayout';
import MomentumTraderMultiStock from './MomentumTraderMultiStock';
import toastr from "toastr";
import "toastr/build/toastr.min.css"
// import EditModelForm from './EditModelForm';

class Options extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: new URL(window.location.href, true),
      starting_amount: 1000,
      broker_fee: 0,
      date_list: [],
      initialized: false,
      real_time: false,
      start: false,
      end: true,
      ledger: [],
      profit: 0,
      total_spent_broker_fees: 0,

      profit_if_untraded: 0,
      revenue: 0,

      activate_live_trading: false,
      authenticated: false,
      date_value: [],


      show_graph: false,
      please_wait_show: false,
      get_avrg_loading: false,
      stock_list: [],
      admin_key: 0,
      settings: false,
      existing_models: [],
      selected_model: {},
      new_model: true,


      model_title: "",
      stock: ["tsla"],//"spxl"
      volume_of_trades: 1,
      buy_differential: 1,
      sell_differential: 1,
      trade_frequency: 1,
      start_time: new Date(moment().set({ 'hour': 9, 'minute': 30 })),
      end_time: new Date(moment().set({ 'hour': 16, 'minute': 0 })),
      run_daily: false,
      email_notifications: false,
      running: false,
      errors: {},
      type: "intra_day",
      settings:true,
      error:''




    }
  }

  handleChange = (e) => {
    e.preventDefault();
    let name = e.target.name;
    this.setState({
      [name]: e.target.value
    }, () => {
      if (name === "date_value") {
        this.handleStartTime(this.state.start_time)
        this.handleEndTime(this.state.end_time)
      }
    })
  }

  handleDateSelect = (e) => {
    let rough_date = e.target.value
    this.setState({
      date_value: rough_date
    })

  }

  handleStockChange = (e) => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value.split(',')
    }, () => {
      this.handleInitialize()
    })
  }

  handleRealTime = (e) => {
    this.setState({
      real_time: true,
      initialized: true,
      date_list: [1]
    })
  }
  // Changes realtime
  handlePastTime = (e) => {
    this.setState({
      real_time: false,
      initialized: false,
      date_list: []
    })
  }



  confirm_authentication = () => {
    this.setState({
      authenticated: true
    })

  }

  handleStartTime = (date) => {
    // let temp_date = moment(date).format('H:mm');
    this.setState({
      start_time: date
    })
  }

  handleEndTime = (date) => {

    this.setState({
      end_time: date
    })
  }



  handleCheck = (e) => {

    this.setState({
      [e.target.name]: e.target.checked
    })
  }

  handleInitialize = () => {
    axios.post('/fetch_dates_for_data', {
      stock_symbol: this.state.stock
    }).then((response) => {
      if (response.data.status === "success") {
        this.setState({
          date_list: response.data.result,
          date_value: response.data.result.slice(response.data.result.length - 3, response.data.result.length),
          err_msg: "",
          initialized: true
        }, () => {
          this.handleStartTime(this.state.start_time)
          this.handleEndTime(this.state.end_time)
        })
      } else {
        this.setState({
          err_msg: "Data for this stock does not exist."
        })

      }
    })
  }

  handleRun = () => {
    this.setState({
      please_wait_show: true,
      ledger:[]
    })
    if (this.state.admin_key === 1234) {
      axios.post('/run_momentum_trader_multi_simulator', {
        starting_amount: this.state.starting_amount,
        volume_of_trades: this.state.volume_of_trades,
        buy_differential: this.state.buy_differential,
        sell_differential: this.state.sell_differential,
        broker_fee: this.state.broker_fee,
        stock: this.state.stock,
        date_value: this.state.date_value,
        start_time: this.state.start_time,
        end_time: this.state.end_time,
        trade_frequency: this.state.trade_frequency,
        algo_id: this.state.url.query.algo_id,
        stocks: this.state.stock_list
      }).then((response) => {
        if (response.data.status === "success") {
          this.setState({
            ledger: response.data.results,
            profit: response.data.stats.profit,
            total_spent_broker_fees: response.data.stats.total_spent_broker_fees,
            profit_if_untraded: response.data.stats.profit_if_untraded,
            revenue: response.data.stats.revenue,
            show_graph: true,
            please_wait_show: false
          })
        }

      })
    } else {
      axios.post('/run_momentum_trader_multi_past', {
        starting_amount: this.state.starting_amount,
        volume_of_trades: this.state.volume_of_trades,
        buy_differential: this.state.buy_differential,
        sell_differential: this.state.sell_differential,
        broker_fee: this.state.broker_fee,
        stock: this.state.stock,
        date_value: this.state.date_value,
        start_time: this.state.start_time,
        end_time: this.state.end_time,
        trade_frequency: this.state.trade_frequency,
        algo_id: this.state.url.query.algo_id,
      }).then((response) => {
        if (response.data.status === "success") {
          this.setState({
            ledger: response.data.results,
            profit: response.data.stats.profit,
            total_spent_broker_fees: response.data.stats.total_spent_broker_fees,
            profit_if_untraded: response.data.stats.profit_if_untraded,
            revenue: response.data.stats.revenue,
            show_graph: true,
            please_wait_show: false,
            error:''
          })
        }else{
          this.setState({
            please_wait_show: false,
            error:"Please change the parameters and try again"
          })

        }

      })
    }



  }

  run_av_diff = () => {
    this.setState({
      get_avrg_loading: true
    })
    axios.post('/get_avg_dev_multi', {
      stock: this.state.stock,
      date_value: this.state.date_value,
      start_time: this.state.start_time,
      end_time: this.state.end_time,
      trade_frequency: this.state.trade_frequency,
    }).then((response) => {
      this.setState({
        avrg_diff: response.data.result,
        get_avrg_loading: false
      })
    })
  }

  closePopup = () => {
    this.setState({
      show_graph: false
    })
  }
  showPopup = () => {
    this.setState({
      show_graph: true
    })
  }
  componentDidMount() {
    if(this.props.state) return this.setState(this.props.state);
    axios.get('/stock_list').then((response) => {
      this.setState({
        stock_list: response.data.arr
      }, () => {
        this.setState({
          settings: true
        })
      })

    })
    this.fetch_models()
  }


  save_model = () => {

    if (this.state.model_title.length > 0) {

      axios.post('/save_momentum_trader', {
        stock: this.state.stock[0],
        volume_of_trades: this.state.volume_of_trades,
        buy_differential: this.state.buy_differential,
        sell_differential: this.state.sell_differential,
        trade_frequency: this.state.trade_frequency,
        start_time: this.state.start_time,
        end_time: this.state.end_time,
        run_daily: this.state.run_daily,
        selected_model: this.state.selected_model._id,
        email_notifications: this.state.email_notifications,
        running: this.state.running,
        model_title: this.state.model_title,
        type: this.state.type
      }).then(async(response) => {
        this.setState({
          ...response.data.result,
          selected_model: response.data.result,
          errors: {},
          new_model: false
        })
         this.fetch_models()
         toastr.success("Model Saved!", 'Success')

      })
    } else {
      let errors = {}
      errors["model_title"] = "Please enter a model name"
      this.setState({
        errors: errors
      })
    }

  }

  startTrading = () => {
    this.setState({
      running: true
    }, () => {
      this.save_model()
    })
  }
  endTrading = () => {
    this.setState({
      running: false
    }, () => {
      this.save_model()
    })
  }

  fetch_models = () => {
    axios.get(`/momentum_models`).then((response) => {
      this.setState({
        existing_models: response.data.result
      })
    })
  }

  new_model_click = () => {

    this.setState({
      new_model: true,
      selected_model: {},

      starting_amount: 1000,
      broker_fee: 0,
      date_list: [],
      initialized: false,


      model_title: "",
      stock: ["tsla"],//"spxl"
      volume_of_trades: 1,
      buy_differential: 1,
      sell_differential: 1,
      trade_frequency: 1,
      start_time: new Date(moment().set({ 'hour': 9, 'minute': 30 })),
      end_time: new Date(moment().set({ 'hour': 16, 'minute': 0 })),
      run_daily: false,
      email_notifications: false,

      type: "intra_day",
      settings:true,

    })
  }
  existing_model_click = () => {

    this.setState({
      new_model: false,
      selected_model: this.state.existing_models[this.state.existing_models.length - 1],
      settings:true,
      initialized:true,
      ...this.state.existing_models[this.state.existing_models.length - 1]

    },()=>{
      this.handleInitialize()
    })
  }
  handleModelChange = async (e) => {
    e.preventDefault()
    let selected = await this.state.existing_models.filter((x)=>x.model_title===e.target.value)[0]
    this.setState({
      ...e.target.value,
      selected_model: selected,
      new_model:false,
      settings:true,
      initialized:true,
      ...selected
    },()=>{
      this.handleInitialize()
    })
  }

  handleRefresh= () => {
    this.setState({ initialized: false })
  }
  handleSettings= () => {
    this.setState({ settings: !this.state.settings })
  }


  render() {
    this.props.getState && this.props.getState(this.state);
    this.props.setResponsive(this.state.ledger.length > 0);
    return (
      <>
        <MomentumTraderMultiFilter
          {...this.state}
          handlePastTime={this.handlePastTime}
          handleRealTime={this.handleRealTime}
          new_model_click={this.new_model_click}
          existing_model_click={this.existing_model_click}
          handleModelChange={this.handleModelChange}
          handleStockChange={this.handleStockChange}
          handleCheck={this.handleCheck}
          handleInitialize={this.handleInitialize}
          handleDateSelect={this.handleDateSelect}
          handleChange={this.handleChange}
          run_av_diff={this.run_av_diff}
          startTrading={this.startTrading}
          endTrading={this.endTrading}
          save_model={this.save_model}
          handleRun={this.handleRun}
          handleRefresh={this.handleRefresh}
          handleSettings={this.handleSettings}
          selected_model={this.state.selected_model}
          handleStartTime={this.handleStartTime}
          handleEndTime={this.handleEndTime}
        />
        <div className='flex-1 d-flex flex-column overflow-hidden'>
          <SaleBar
            revenue={this.state.revenue}
            broker_fees={this.state.total_spent_broker_fees}
            profit={this.state.profit}
            buy_hold_profit={this.state.profit_if_untraded}
          />
          {this.state.error.length>0 && <p style={{color:"red"}}>{this.state.error}</p>}
          <MomentumTraderMultiStock model_title={this.state.model_title} ledger={this.state.ledger} showPopup={this.showPopup} />
        </div>
        {this.state.show_graph &&
          <ResultsGraph x_axis="Data Point" y_axis="Profit" volume_of_trades={this.state.volume_of_trades} close={this.closePopup} results={this.state.ledger} />
        }
      </>
    )
  }
}

export default withAlgoProductToolsLayout(Options, 'Momentum Trader');
