import React, { Component } from 'react';
import URL from 'url-parse'
import Model from '../ui-v2/Modal/Model';
import MLModelSignalRow from './MLModelSignalRow';
import moment from 'moment';

class MLModelManageSignalPopUp extends Component {
    createSignal = (showPopUp) => {
        showPopUp();
        // axios code
    }
    render() {
      let url = new URL(window.location.href, true)
        return (
            <Model
                title='Manage Signals'
                width='100'
                component={({ showPopUp }) => (
                    <button
                        onClick={() => this.createSignal(showPopUp)}
                        className='btn btn-primary fs-normal w-100 text-uppercase'
                    >
                        Manage Signals
                    </button>
                )}
            >
                <p className="m-0 small-sm text-accent-4">All running signals from all models appear here.</p>
                <div className='table-wrapper'>
                    <div>
                        <table>
                            <thead>
                                <tr>
                                    <th><div>Model Name</div></th>
                                    <th>Stock Symbol</th>
                                    <th>Training Date Start</th>
                                    <th>Training Date End</th>
                                    <th>Predicted Return (train)</th>
                                    {/* <th>Actual Return</th> */}
                                    {/* <th>Test1 Date Start</th>
                                    <th>Test1 Date End</th>
                                    <th>Test1 Date Return</th> */}
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.running_ml_models.length>0 && this.props.running_ml_models.map((running) =>
                                    <MLModelSignalRow
                                        model_name={running.model_id.model_name}
                                        model_id={running.model_id._id}
                                        stock_symbol={running.stock_symbol}
                                        signal_id={running._id}
                                        type={running.type}
                                        status={running.status}
                                        selected_index={running.selected_index}
                                        created_on={moment(running.created_on).format("YYYY-MM-DD")}
                                        traning_date_start={moment(running.model_id.training_start_date).format("YYYY-MM-DD")}
                                        traning_date_end={moment(running.model_id.training_end_date).format("YYYY-MM-DD")}
                                        predicted_return={running.predicted_return.toFixed(2)}
                                        handle_auto_ml_run_stop={this.props.handle_auto_ml_run_stop}
                                        handle_delete_ml_signal={this.props.handle_delete_ml_signal}

                                    />
                                )}
                                {this.props.running_ml_models.length===0 &&
                                  <p style={{textAlign:"center"}}>You don't have any signals. Create a model and click start on a result to see an item appear here.</p>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </Model>
        )
    }
}

export default MLModelManageSignalPopUp;
