import axios from 'axios';
import React, { useState } from 'react';
import InputField from '../../../ui-v2/InputField/InputField';
import Model from '../../../ui-v2/Modal/Model';
import axiosErrorHandler from '../../../utility/axiosErrorHandler';
import DeleteModal from '../../DyanamicWatchlistModal/DeleteModal';
import Main from './Main';


const Wrapper = ({ _id, onDelete, ...props }) => {
    const [{ name, stocks, isLoading }, setState] = useState({ stocks: props.stocks || [], name: props.name || '' })
    const disabled = name.trim() === "" || stocks.length === 0 || isLoading;

    const onSubmit = e => {
        e.preventDefault();
        if (disabled) return;
        const data = { name, stocks }
        setState({ ...data, isLoading: true });
        axios[_id ? 'put' : 'post'](`/watchlist${_id ? `/${_id}` : ''}`, data)
            .then(res => props.onEdit(_id ? data : res.data))
            .catch(err => {
                setState(data);
                axiosErrorHandler(err);
            })
    }

    return (
        <form onSubmit={onSubmit} className='d-flex flex-column gap-inherit'>
            <InputField label='Watchlist name'
                required={true}
                value={name}
                onChange={e => setState({ stocks, name: e.target.value })}
            />
            <Main stocks={stocks} setStocks={stocks => setState({ name, stocks })} />
            <div className='model-footer'>
                {_id && <DeleteModal _id={_id} onDelete={onDelete} />}
                <button className={`btn btn-primary ${isLoading ? 'progress-btn' : ''}`} disabled={disabled}>
                    {_id ? 'Update' : 'Save'}
                </button>
            </div>
        </form>
    )
}

const EditCustomModal = ({ closeHandler, ...props }) => (
    <Model title="Custom watchlist" show={true} closeHandler={closeHandler}>
        <Wrapper {...props} />
    </Model>
)


export default EditCustomModal;