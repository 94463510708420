import React from 'react';
import useGet from "../../../hooks/useGet"
import { Link } from 'react-router-dom';

export default function Competitions() {
    const { data, Loader, err } = useGet('/leaderboard-competition')
    return (
        <>
            {Loader && <Loader className="mx-auto text-accent-3" fontSize={"0.4rem"} />}
            {
                data && (
                    <>
                        {data.length > 0 &&
                            <>
                                <div className="p-3 d-flex flex-column small border-top gap-2">
                                    <small className='smaller'>Competitions</small>
                                    {data.map(cp => (
                                        <Link key={cp._id}
                                            to={`/competition/${cp._id}`}
                                            className='m-0 btn-text gap-1 text-normal d-flex align-items-center'
                                        >
                                            {cp.title} <span className='text-accent-4 small'>({cp.status})</span>
                                            <i className="fas fa-arrow-right me-auto text-accent-4"></i>
                                        </Link>
                                    ))}
                                </div>
                            </>

                        }

                    </>
                )
            }
        </>
    )
}