import React from 'react';
import useGet from '../../hooks/useGet';

const Purchase = props => {
    const { data, Loader } = useGet('/fetch_user_credit_card')
    return (
        <div>
            <h5 className='d-flex gap-3 mt-0 mb-2'>Card Details <button className='btn btn-text' type='button' onClick={props.showPaymentPopUp}>Change</button></h5>
            <div className='border border-radius-8 p-3 text-accent-4'>
                {Loader ? <Loader fontSize='0.25rem' className='mx-auto text-success' /> : `${data.name || ""} **** **** **** ${data.last4}`}
            </div>
        </div>
    )
}

export default Purchase;