import React, { useState } from 'react';
import './styles.scss'

const NavDropDown = ({ children, component, className = '', ...props }) => {
    const [isActive, setIsActive] = useState(false);
    return (
        <>
            {component(isActive, () => setIsActive(false), () => setIsActive(!isActive))}
            <ul className={`${className} Nav-DropDown ${isActive ? 'active' : ''}`} {...props}>
                {children}
            </ul>
        </>
    )
}

export default NavDropDown;