import React from 'react';
import FilterButton from './FilterButton';

const TotalResults = ({ totalLength }) => (
    <div className='d-flex justify-content-between mb-3'>
        <small className="m-0 text-accent-4">Total Results: {totalLength}</small>
        <FilterButton />
    </div>
)

export default TotalResults;