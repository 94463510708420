import React from 'react';
import { useHistory } from 'react-router-dom';
import ArrowRightIcon from '../../icons-v2/ArrowRightIcon';
import ImageWrapper from '../../ui-v2/ImageWrapper/ImageWrapper';
import PlayIcon from '../../icons-v2/PlayIcon';
import VideoWrapper from '../VideoWrapper/VideoWrapper';

const AlgoItemV2 = ({ to, stockAlgoName, image_url, type, video_url, short_description, className = '', children }) => {
    const push = useHistory().push;
    const onClick = () => push(to)
    return (
        <div className={`AlgoItem ${className}`}>
            <ImageWrapper title={stockAlgoName} draggable={false} src={image_url} height='86%' onClick={onClick} />
            {video_url && <VideoWrapper url={video_url}
                component={({ showPopUp }) => (
                    <button
                        onClick={showPopUp}
                        className="AlgoItem-btn"
                    >
                        <PlayIcon />
                    </button>
                )}
            />}
            <div onClick={onClick} className="AlgoItem-Main">
                <h5>{stockAlgoName} <ArrowRightIcon className='text-primary' /></h5>
                {children}
                <div className="AlgoItem-Blog" dangerouslySetInnerHTML={{ __html: short_description }} />
                {type?.length > 0 && <span className="AlgoItem-Badge">{type}</span>}
            </div>
        </div>
    )
}

export default AlgoItemV2;