import axios from "axios";
import moment from "moment";
import React, { Component } from "react";
import withAlgoProductToolsLayout from "../../hoc-v2/withAlgoProductToolsLayout/withAlgoProductToolsLayout";
import Pagination from "../../ui-v2/Pagination/Pagination";
import axiosErrorHandler from "../../utility/axiosErrorHandler";
import Filters from "./Filters";
import StockTable from "./StockTable";


const filters = {
    search: '',
    filtered_outcome: 'all',
    filtered_event_type: 'all',
    page: 0,
    event_date: {
        startDate: new Date(moment().subtract(2, 'month')),
        endDate: new Date(moment().add(1, 'month')),
        key: 'selection',
    },
}

class FDA extends Component {
    state = {
        loading: true,
        ledger: [],
        initialized: false,
        filters
    }
    async getData() {
        if (!this.state.loading) this.setState({ loading: true });
        const filters = this.state.filters;
        const params = {};
        for (let key in filters) {
            const value = filters[key];
            if (key === 'event_date') {
                params.event_date = [filters.event_date.startDate, filters.event_date.endDate]
            }
            else if (typeof value === 'string') {
                const v = value.trim();
                if (v !== '' && v !== 'all') params[key] = v
            }
            else params[key] = filters[key];
        }
        axios.get('/fda', { params })
            .then(res => {
                const newState = {
                    ledger: res.data.result,
                    total: res.data.total,
                    loading: false,
                    initialized: true,
                }
                this.setState(newState)
                this.props.setState(newState)
            })
            .catch(axiosErrorHandler)

    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.filters !== this.state.filters) this.getData();
        if (prevProps.symbol_list !== this.props.symbol_list) {
            this.setState({
                filters: { ...filters, search: this.props.symbol_list }
            },() => this.getData())
        }

    }
    componentDidMount = this.getData; 
    onFiltersClear = () => this.setState({ filters })

    render() {
        const { filters, total, loading, initialized } = this.state
        return (
            <>
                <Filters {...this.state} {...this.props}
                    onStateChange={newState => this.setState(newState)}
                />
                <div className='p-lg-5 p-3 overflow-hidden d-flex flex-column flex-1'>
                    {loading && <div className='bar-loader' />}
                    {initialized && <StockTable onFiltersClear={this.onFiltersClear} {...this.state} />}
                    {total / 20 > 1 && (
                        <Pagination
                            pageCount={Math.ceil(total / 20)}
                            initialPage={filters.page}
                            forcePage={filters.page}
                            onPageChange={data => this.setState({ filters: { ...filters, page: data.selected } })}
                            className='mt-3'
                        />
                    )}
                </div>
            </>
        )
    }
}

export default withAlgoProductToolsLayout(FDA, 'Food & Drug Administration (FDA)');
