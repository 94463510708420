import React, { useState } from 'react';

const ToggleSwitch = ({ initialState = true, onToggle, leftText = '', rightText = '' }) => {
    const [isToggled, setIsToggled] = useState(initialState);

    const handleToggle = () => {
        const newState = !isToggled;
        setIsToggled(newState);
        if (onToggle) {
            onToggle(newState);
        }
    };

    return (
        <div style={styles.container}>
            <span style={styles.leftText}>{leftText}</span>
            <div onClick={handleToggle} style={styles.switchContainer}>
                <div style={{
                    ...styles.switchKnob,
                    left: isToggled ? '26px' : '2px',
                    backgroundColor: isToggled ? '#4CAF50' : '#ccc'
                }} />
            </div>
            <span style={styles.rightText}>{rightText}</span>
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        alignItems: 'center',
    },
    leftText: {
        marginRight: '10px',  // Adjust the space between left text and the toggle switch
    },
    rightText: {
        marginLeft: '10px',  // Adjust the space between the toggle switch and right text
    },
    switchContainer: {
        width: '50px',
        height: '26px',
        borderRadius: '13px',
        backgroundColor: '#ddd',
        position: 'relative',
        cursor: 'pointer',
        transition: 'background-color 0.3s',
    },
    switchKnob: {
        position: 'absolute',
        top: '2px',
        width: '22px',
        height: '22px',
        borderRadius: '50%',
        transition: 'left 0.3s, background-color 0.3s',
    }
};

export default ToggleSwitch;