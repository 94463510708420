import React, { useMemo } from 'react';
import Spinner from '../../../ui-v2/Spinner/Spinner';
import Post from '../../Post'
import LoginButton from '../../LoginButton/LoginButton';
import useScrollFetch from '../../../hooks/useScrollFetch';
import SubscribeModal from '../Subscribe';
import UnSubscribeModal from '../UnSubscribeModal';

const Explore = ({ posts: newPosts = [], displaySubscribedFeed = false, api = "/fetch_global_feeds", premium, ...props }) => {
    const [tools] = useScrollFetch(api, props.isLoggedIn), posts = useMemo(() => [...newPosts, ...tools.posts], [newPosts.length, tools.posts.length]);
    if(premium) posts.forEach(post => post.premium = premium);
    return (
        <>
            {useMemo(() => posts.length > 0 && (
                <>
                    {props.isLoggedIn && (
                        <>
                            <SubscribeModal {...props} posts={tools.posts} />
                            <UnSubscribeModal {...props} posts={tools.posts} />
                        </>
                    )}
                    {posts.map(post => <Post {...post} {...props} key={post._id} />)}
                </>
            ), [posts, props.location.hash])}
            {tools.exists ? <Spinner fontSize='0.5rem' className='mx-auto text-accent-3 mt-2' /> : (
                posts.length === 0 && <img alt='' src='/no-post.png' className='w-100 mt-3' />
            )}
            {!props.isLoggedIn && <LoginButton isLogin={false} className='btn btn-text'>Load more</LoginButton>}
        </>
    )
}

export default Explore;