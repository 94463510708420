import React, { useState } from 'react';
import Model from "../../ui-v2/Modal/Model";
import Pagination from "../../ui-v2/Pagination/Pagination";
import Arrow from "../../icons-v2/ArrowRightIcon";
import useGet from "../../hooks/useGet";
import { useHistory } from 'react-router-dom';
import { useApp } from '../../App';
import Table from '../../ui-v2/Table/Table';
import User from '../LeaderBoardComponents/User/User';

const Main = props => {
    const { data, onParamsChange, params } = useGet(`/leaderboard-competition-participants/${props._id}`, { limit: 10, page: 1 });
    return (
        <Model show={true} title="Participants" closeHandler={props.closeHandler}>
            {data && (
                <>
                    <Table>
                            <thead>
                                <tr>
                                    <th>
                                        <div>User</div>
                                    </th>
                                    <th>Total Posts</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.results.map(user => (
                                    <tr key={user._id}>
                                        <td><User {...props} {...user} /></td>
                                        <td>{user.totalPosts}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    <Pagination
                        pageCount={Math.ceil(data.total / 10)}
                        initialPage={0}
                        onPageChange={page => onParamsChange({ page: page.selected + 1 })}
                        className='small modal-pagination'
                    />
                </>
            )}
        </Model>
    )
}

export default function ParticipantsModal({ className, title = 'View All', ...props }) {
    const [open, setOpen] = useState(false)
    const history = useHistory();
    const auth = useApp()
    return (
        <>
            <button type="button"
                onClick={() => auth.isLoggedIn ? setOpen(true) : history.push({ hash: 'register' })}
                className={`btn small btn-text ${className}`}
            >
                {title} <Arrow />
            </button>
            {open && <Main isLoggedIn={auth.isLoggedIn} _id={props._id} closeHandler={() => setOpen(false)} />}
        </>
    )
}