import React, { useState } from 'react';
import Icon from "../../../icons-v2/Copy"

export default function CopyIcon({ className = '', copy = window.location.href }) {
    const [active, setActive] = useState(false)
    const onCopy = () => {
        setActive(true);
        navigator.clipboard.writeText(copy)
        setTimeout(() => {
            setActive(false)
        }, 1000)
    }
    return (
        <button onClick={onCopy} className={`btn-icon btn ${className}`}>
            {active ? <i className="fas fa-check text-accent-3"></i> : <Icon />}
        </button>
    )
}