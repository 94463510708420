import React, { useState } from 'react';
import styles from './styles.module.scss';

const getClass = (value, error, regex) => `${value ? styles.value : ""} ${(error || (regex && !regex.test(value)) ? styles.invalid : "")} ${error ? styles.error : ""}`;
const toPattern = regex => regex.toString().slice(1, -1).replace(/\/[gimuy]*$/, '');

const Main = ({error, regex, onChange, ...props }) => {
    const [rClass, setClass] = useState(getClass(props.value, error, regex));
    return <input
        className={rClass}
        {...props}
        onBlur={e => setClass(`${getClass(e.target.value, error, regex)} ${styles.blur}`)}
        onChange={e => {
            setClass(`${getClass(e.target.value, error, regex)} ${rClass.includes("blur") ? styles.blur : ""}`);
            onChange && onChange(e);
        }}
    />
}

export default function InputField({ start, end, title, errorText, helperText, className, ...props }) {
    if (props.regex) props.pattern = toPattern(props.regex);
    return (
        <div className={`${className} text-start`}>
            <fieldset className={`${styles.wrapper} ${start ? styles.start : ""}`}>
                {title && <legend>{title} {props.required && <span className='text-accent-2'>*</span>}</legend>}
                <label>
                    <Main {...props} />
                    {start && <span onClick={e => e.preventDefault()} className={styles.left}>{start}</span>}
                    {end && <span onClick={e => e.preventDefault()} className={styles.right}>{end}</span>}
                </label>
            </fieldset>
            {errorText && <small className="d-none">{errorText}</small>}
            {helperText && <small className="text-accent-4">{helperText}</small>}
        </div>
    )
}