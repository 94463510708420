import React from 'react';
import Model from '../../../ui-v2/Modal/Model';

const ContestModal = props => (
    <Model title='Monthly Contest' {...props} isNormal={true}
        actions={hidePopUp => (
            <label className='btn btn-primary px-4' htmlFor='create-post' onClick={hidePopUp}>Make a Prediction</label>
        )}
    >
        <div>
            <b className='small fw-semibold'>Details:</b>
            <ul className='list-unstyled ol d-grid gap-1 mt-2'>
                <li>Each month we will be running a contest to see who the best stock predictors are.</li>
                <li>The top three predictors for this month will win $500, $300 and $200 respectively.</li>
                <li>You need to make at least 3 predictions on 3 different stocks to be eligible.</li>
                <li><span>Each prediction must expire in the current month. I think x stock will change by <strong>month of contest</strong>. This means that you can make guesses for future months starting today.</span></li>
                <li>Stocks that you make your predictions on cannot be acquired or bankrupt.</li>
                <li>Prizes will be distributed in CAD dollars.</li>
            </ul>
        </div>
        <div>
            <b className='small fw-semibold'>Winner announcements:</b>
            <ul className='list-unstyled ol d-grid gap-1 mt-2'>
                <li>The result will be announced at the end of every month.</li>
                <li>Winners will be announced on our social media channels.</li>
                <li>The winner(s) must have a paypal account or bank account that can receive e-transfer/wire to accept payment.</li>
            </ul>
        </div>
    </Model>
)


export default ContestModal;
