import React from 'react';
import SearchField from "../../ui-v2/SearchField/SearchField";

const MobileSearchBox = ({ placeholder, onSubmit, className ='', ...props }) => {
    const onSearch = e => {
        e.preventDefault();
        onSubmit && onSubmit(e);
    }
    return (
        <form onSubmit={onSearch} className={`position-relative d-lg-none ${className}`}>
            <SearchField
                {...props}
                className='flex-1'
            />
            <div className='position-absolute start-0 top-0 h-100 p-1'>
                <button className="btn btn-primary smaller px-3 h-100">Search</button>
            </div>
        </form>
    )
}

export default MobileSearchBox;