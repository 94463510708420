import React from 'react';
import Model from '../../ui-v2/Modal/Model';
import TrashIcon from '../../icons-v2/TrashIcon';

const DeleteModelPopUp = ({ onDelete, model, selected_model, ...props }) => {
    const deleteModelHandler = (hidePopUpHandler) => {
        onDelete && onDelete();
        hidePopUpHandler();
    }
    return (
        <Model
            isNormal={true}
            title='Delete Model'
            {...props}
            component={({ showPopUp }) => <button onClick={showPopUp} className='btn btn-icon no-padding text-accent-2'><TrashIcon /></button>}
            actions={hidePopUp => (
                <>
                    <button className='btn btn-text' onClick={hidePopUp}>Cancel</button>
                    <button className='btn btn-primary bg-accent-2' onClick={() => deleteModelHandler(hidePopUp)}>Delete</button>
                </>
            )}
        >
            <div className='text-center m-auto p-md-5 p-3'>
                <h6 className='mt-0 subtitle-sm mb-2'>Are you sure, you want to delete your "{selected_model}" momentum model?</h6>
                <p className='m-0'>You will not be able to recover the model once it is deleted.</p>
            </div>
        </Model>
    )
}

export default DeleteModelPopUp;
