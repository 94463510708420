import React from 'react';
import HeaderLink from './HeaderLink';
import BagIcon from '../../icons-v2/BagIcon';
import HomeIcon from '../../icons-v2/HomeIcon';
import Buildings from '../../icons-v2/Building';
import styles from './styles.module.scss';
import LinksWrapper from './LinksWrapper';

const Main = props => {
    const getBadge = (className = '') => <small className={`fw-semibold position-absolute bg-accent-1 smaller px-1 text-normal ${className}`}>New</small>
    return (
        <LinksWrapper {...props}>
            {/* <HeaderLink title='Marketplace' exact id="marketplace-link" to='/' Icon={HomeIcon} />
            {props.isLoggedIn && <HeaderLink title='My Algos' to='/dashboard' Icon={BagIcon} />} */}
            <HeaderLink title='My Dashboard' to='/dashboard' Icon={BagIcon} />
            <HeaderLink
                className='no-fill-icon' to='/leaderboard' id='leaderboard-link'
                title={(
                    <span className='position-relative'>
                        Leaderboard
                        {getBadge('d-lg-block d-none end-0 bottom-100')}
                    </span>
                )}
                Icon={() => (
                    <span className='position-relative'>
                        {getBadge(`${styles.badge} d-lg-none`)}
                        <Buildings />
                    </span>
                )}
            />
        </LinksWrapper>
    )
}

export default Main;