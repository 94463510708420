import React from "react"
import useFollow from "../../hooks/useFollow";

const Follow = ({ _id = "", className = "", is_following }) => {
    const { isFollowing, onFollow, isActive } = useFollow(is_following, _id);
    return isActive && (
        <button onClick={onFollow} className={`${className} btn-${isFollowing ? 'border' : 'primary'}-2`}>
            Follow{isFollowing ? 'ing' : ''}
        </button>
    )
}

export default Follow;