import React from 'react';

const CheckIcon = props => (
    <svg viewBox="0 0 13 11" fill="none" {...props}>
        <path d="M1.07227 6.45L4.69131 10.25L11.9294 0.75"
            stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"
        />
    </svg>

)

export default CheckIcon;