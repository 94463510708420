import React, { useState } from 'react';
import StockItem from './StockItem';
import styles from './StockTable.module.scss';
import RightDrawer from './RightDrawer/RightDrawer';
import ToolTip from '../../ui-v2/ToolTip/ToolTip';

const StockTable = ({ ledger, sortHandler, sortby, asc, fields }) => {
    const [stockIndex, setStockIndex] = useState(-1);
    const getActiveClass = name => {
        const props = { name };
        if (name === sortby) props.className = `active ${asc ? 'reverse' : ''}`
        return props
    }

    const showDetailsHandler = (e, index) => {
        if(e.target.nodeName === 'TD' || e.target.parentNode.nodeName == 'TD') setStockIndex(index == stockIndex ? -1 : index)
    }

    return (
        <>
            {stockIndex >= 0 && <RightDrawer {...ledger[stockIndex]} onClose={() => setStockIndex(-1)} />}
            <div className='table-wrapper product_tool_table'>
                <div className={stockIndex >= 0 ? 'overflow-hidden' : ''}>
                    <table>
                        <thead className='no-select' onClick={sortHandler}>
                            <tr className={styles.headRow}>
                                <th><div></div></th>
                                <th {...getActiveClass('ticker')}>Stock Symbol</th>
                                {fields.map((field, index) => <ToolTip title={field.description}><th key={index} {...getActiveClass(field.name)}>{field.title}</th></ToolTip>)}
                                {/*
                                <th name='grossmargin' {...getActiveClass('grossmargin')}>Gross Margin</th>
                                <th name='profitmargintot' {...getActiveClass('profitmargintot')}>Profit Margin</th>
                                */}
                            </tr>
                        </thead>
                        <tbody>
                            {ledger.map((option, index) => <StockItem active={index === stockIndex} onClick={e => showDetailsHandler(e, index)} fields={fields} {...option} key={index} />)}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default StockTable;
