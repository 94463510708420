import React from 'react';
import axios from 'axios'
import URL from 'url-parse';
import BarLoader from "react-spinners/BarLoader";
import Signals from '../../components/signals';
import moment from "moment";
import Graph from '../graph_module';
import { getBusinessDays } from '../../service/logic'
import Pagination from '../../ui-v2/Pagination/Pagination';
import withAlgoProductToolsLayout from '../../hoc-v2/withAlgoProductToolsLayout/withAlgoProductToolsLayout';
import ManageSignalPopUp from '../../components-v2/AlgoHoldingCommonTools/ManageSignalPopUp';
import StockTable from './ConDebtStockTable';
import Filter from '../../components-v2/AlgoHoldingCommonTools/SideFilter';
import SelectField from "../../ui-v2/SelectField/SelectField";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import ConDebtStockFilters from './ConDebtStockFilters';
import Spinner from '../../ui-v2/Spinner/Spinner';
import TipCard from '../../ui-v2/TipCard/TipCard';



class Convertible_Debt extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: new URL(window.location.href, true),
      stock_search: this.props.symbols,
      stock_data: [],
      original_stock_data: [],
      selected_val: 'All',
      selected_exchanges: ['TSX'],
      display_exchange_list: ['All', 'US (all)', 'CAD (all)', 'Nasdaq (all)', 'NYSE (all)', 'DOW', 'DJHF', 'CBO', 'RUS', 'BATS', 'EDGX', 'CONE', 'IEXG', 'MSI', 'MSCI', 'NDD', 'NSD', 'NMF', 'OTC', 'OTO', 'NYE', 'AMX', 'TSX', 'TSV', 'BDM', 'TSXC', 'TSXVC', 'AQNC', 'CNQC', 'ALPHA', 'CNQ', 'PURE', 'CHIX', 'CXB', 'OMEGA', 'TCM', 'LQN', 'ICX', 'AQN', 'AQL', 'CMF'],
      all_exchanges: ['DOW', 'DJHF', 'CBO', 'RUS', 'BATS', 'EDGX', 'CONE', 'IEXG', 'MSI', 'MSCI', 'NDD', 'NSD', 'NMF', 'OTC', 'OTO', 'NYE', 'AMX', 'TSX', 'TSV', 'BDM', 'TSXC', 'TSXVC', 'AQNC', 'CNQC', 'ALPHA', 'CNQ', 'PURE', 'CHIX', 'CXB', 'OMEGA', 'TCM', 'LQN', 'ICX', 'AQN', 'AQL', 'CMF'],
      us_exchange_list: ['DOW', 'DJHF', 'CBO', 'RUS', 'BATS', 'EDGX', 'CONE', 'IEXG', 'MSI', 'MSCI'],
      nasdaq_list: ['NDD', 'NSD', 'NMF', 'OTC', 'OTO'],
      nyse_list: ['NYE', 'AMX'],
      canadian_list: ['TSX', 'TSV', 'BDM', 'TSXC', 'TSXVC', 'AQNC', 'CNQC', 'ALPHA', 'CNQ', 'PURE', 'CHIX', 'CXB', 'OMEGA', 'TCM', 'LQN', 'ICX', 'AQN', 'AQL', 'CMF'],

      selected_security: "all",
      security_type_list: ['BND', 'BSW', 'CD', 'CDA', 'CDI', 'CDR', 'CN', 'CNS', 'CUL', 'CW', 'DEB', 'DR', 'DRT', 'EQS', 'ETF', 'ICL', 'LOA', 'MF', 'NCD', 'NCP', 'NTS', 'PCD', 'PER', 'PPR', 'PRF', 'RCL', 'RCN', 'RCP', 'RDR', 'RDS', 'ROC', 'RUN', 'STP', 'TRT', 'UNT', 'WAR', 'BGO', 'BMU', 'BCO', 'INX', 'UNKNOWN'],
      loading: true,


    }

  }
  componentDidMount() {
    this.fetch_debenture_data()
  }

  handleStockChange = (e, values) => {
    this.setState({
      stock_search: values?.join(',') || e.target.value
    }, () => {
      let arr = this.state.original_stock_data.filter((x) => (x.title.toLowerCase().includes(this.state.stock_search.toLowerCase()) || x.symbol.toLowerCase().includes(this.state.stock_search.toLowerCase())))
      this.setState({
        stock_data: arr
      })
    })
  }

  fetch_debenture_data = () => {
    axios.post("/find_convertible_debt", { ex_list: this.state.selected_exchanges, selected_security: this.state.selected_security }).then((response) => {
      if (response) {
        this.setState({
          stock_data: response.data.results,
          original_stock_data: response.data.results,
          loading: false,
          error: response.data.results.length > 0 ? false : true

        })
      }
    })
  }
  handleSecurityChange = (e) => {
    let val = e.target.value
    this.setState({
      selected_security: val,
      loading: true
    }, () => {
      this.fetch_debenture_data()
    })
  }

  handleModelChange = (e) => {
    let val = e.target.value

    this.setState({
      loading: true
    })
    switch (val) {
      case 'All':

        this.setState({
          selected_val: val,
          selected_exchanges: this.state.all_exchanges
        }, () => {
          this.fetch_debenture_data()
        })
        break;
      case 'US (all)':
        this.setState({
          selected_val: val,
          selected_exchanges: this.state.us_exchange_list
        }, () => {
          this.fetch_debenture_data()
        })
        break;
      case 'CAD (all)':
        this.setState({
          selected_val: val,
          selected_exchanges: this.state.canadian_list
        }, () => {
          this.fetch_debenture_data()
        })
        break;
      case 'Nasdaq (all)':
        this.setState({
          selected_val: val,
          selected_exchanges: this.state.nasdaq_list
        }, () => {
          this.fetch_debenture_data()
        })
        break;
      case 'NYSE (all)':
        this.setState({
          selected_val: val,
          selected_exchanges: this.state.nyse_list
        }, () => {
          this.fetch_debenture_data()
        })
        break;
      default:

        this.setState({
          selected_val: val,
          selected_exchanges: [val]
        }, () => {
          this.fetch_debenture_data()
        })

    }

  }
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      loading: true
    }, () => {
      this.fetch_debenture_data()
    })
  }
  render() {
    const tipProps = this.props.tipProps;
    const stepNo = tipProps.stepNo;
    let filter_best_option_list =
      [
        { "All": 'all' },
        { "Bond": 'BND' },
        { "Basket Warrant": 'BSW' },
        { "Convertible Debenture": 'CD' },
        { "Share Depository Certificate": 'CDA' },
        { "Chess Depository Certificate": 'CDI' },
        { "CEDEAR": 'CDR' },
        { "Convertible Notes": 'CN' },
        { "Conversion": 'CNS' },
        { "Convertible Unsecured Loan Stock": 'CUL' },
        { "Covered Warrant": 'CW' },
        { "Debenture": 'DEB' },
        { "Depository Receipts": 'DR' },
        { "Distribution Rights": 'DRT' },
        { "Equity Shares": 'EQS' },
        { "Exchange Traded Fund": 'ETF' },
        { "Irredeamable Covertible Loan Stock": 'ICL' },
        { "Letter of Allotment": 'LOA' },
        { "Unit Trust": 'MF' },
        { "Non Convertible Debenture": 'NCD' },
        { "Non-Redeemable Convertible Cumulative Preference Stock": 'NCP' },
        { "Notes": 'NTS' },
        { "Partly Convertible Debenture": 'PCD' },
        { "Perpetual Exchangeable Repurchaseable Listed Shares": 'PER' },
        { "Poison Pill Rights": 'PPR' },
        { "Preference Share": 'PRF' },
        { "Redeemable Convertible Secured Loan Stocks": 'RCL' },
        { "Redeemable Convertible Secured Notes": 'RCN' },
        { "Receipt": 'RCP' },
        { "Redemption Rights": 'RDR' },
        { "Redeemable Shares": 'RDS' },
        { "Redeemable Optional Convertible Preference Shares": 'ROC' },
        { "Redeemable Unconvertible Notes": 'RUN' },
        { "Stapled Security": 'STP' },
        { "Tradeable Rights": 'TRT' },
        { "Units": 'UNT' },
        { "Warrants": 'WAR' },
        { "Government Bonds": 'BGO' },
        { "Municipal Bonds": 'BMU' },
        { "Corporate Bonds": 'BCO' },
        { "Index": 'INX' },
        {"Unknown":'UNKNOWN'}
      ]
    return (
      <>
        <TipCard id='tip-1'
          totalSteps={2}
          activeStep={1}
          {...tipProps}
          className='mt-3 me-0'
        >Hover over an item for an explanation of its function.</TipCard>
        <TipCard id='tip-2'
          {...tipProps}
          totalSteps={2}
          activeStep={2}
        >Filter the data based on your desired criteria (ie. by date, by stock, by transaction type, etc.). View the data as grouped by company, or by individual transactions.</TipCard>
        <ConDebtStockFilters
          {...this.props}
          {...this.state}
          handleStockChange={this.handleStockChange}
          handleModelChange={this.handleModelChange}
          handleSecurityChange={this.handleSecurityChange}
          filter_best_option_list={filter_best_option_list}
          handleChange={this.handleChange}
          stepNo={stepNo}
        />
        <div className='p-lg-5 p-3 overflow-hidden d-flex flex-column flex-1'>

          {this.state.loading ?
            <Spinner className='m-auto text-accent-3' />
            :
            <>
              {this.state.error ?
                <p>No results</p>
                :
                <StockTable stepNo={stepNo} stock_data={this.state.stock_data} />
              }


            </>
          }
        </div>


      </>
    )
  }
}
export default withAlgoProductToolsLayout(Convertible_Debt, 'Convertible Debt', { showTips: true });
