import { useEffect, useState } from "react"
import axios from "axios";
import axiosErrorHandler from "../utility/axiosErrorHandler";
import toastr from "toastr";

const defaultOp = {
    data: {},
    cb: () => { },
    errcb: () => { },
    isToastr: false,
    active: false,
    method: 'post'
}

const usePost = (api, opts = defaultOp) => {
    const [state, setStatus] = useState({});
    const onPost = (options = opts) => {
        if (state.isLoading) return;
        const { data, errcb, cb, isToastr, method } = { ...defaultOp, ...opts, ...options, ...state };
        setStatus({ isLoading: true, data });
        axios[method]('/' + api, data)
            .then(r => {
                const res = r.data;
                isToastr && toastr.success(res.message);
                cb(res)
                setStatus({ res, data })
            })
            .catch(err => {
                const message = axiosErrorHandler(err)
                errcb(message)
                setStatus({ err: message, data })
            })
    }
    useEffect(() => {
        if (opts.active) onPost(opts);
    }, []);

    return { ...state, onPost, setStatus };
}


export default usePost;