import React from 'react';
import './styles.scss';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import ToolTip from '../ToolTip/ToolTip';

const withFormField = (WrappedComponent) => {
    const App = ({ className = "", styles, error, errorText, helperText, fullWidth = true, title, ...props }) => {
        if(props.required && props.label) props.label = <>{props.label}<span className='text-accent-2'> *</span></>;
        return (
            <ToolTip title={title}>
                <FormControl {...styles}
                    error={error}
                    variant="outlined"
                    fullWidth={fullWidth}
                    className={`__custom-input-field ${errorText ? "__errorBlogContainer" : ""} ${className}`}
                >
                    <InputLabel>{props.label}</InputLabel>
                    <WrappedComponent
                        {...props}
                    />
                    <FormHelperText className='mx-0 mt-1'>{helperText || errorText}</FormHelperText>
                </FormControl>
            </ToolTip>
        )
    }
    return App
}

export default withFormField;