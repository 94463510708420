import React, { useState } from 'react';
import Model from "../../ui-v2/Modal/Model";
import Form from "../../utility/Form";
import Step1 from './Step1';
import Step2 from './Step2';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

export default function CreateModal(props) {
    const [form, setForm] = useState(props._id ? {
        title: props.title,
        description: props.description,
        start_date: props.start_date,
        end_date: props.end_date,
        email_domain: props.email_domain,
        privacy: props.privacy,
        participation: props.participation,
        post_visibility: props.post_visibility
    } : {})
    const [step, setStep] = useState(1)
    const history = useHistory()
    const onSubmit = data => {
        if (step === 1) {
            setForm({ ...form, ...data.values });
            data.onSuccess("")
            setStep(2);
            return
        }

        const d = { ...form, ...data.values }

        // Convert date fields to UTC
        if (d.start_date) {
            d.start_date = new Date(d.start_date);
        }
        if (d.end_date) {
            d.end_date = new Date(d.end_date);
        }

        if (d.email_domain.trim() === "") d.email_domain = "";
        const id = props._id;
        if (id) {
            axios.put(`/leaderboard-competition/${id}`, d)
                .then(() => {
                    props.onCompetitionUpdate && props.onCompetitionUpdate(d);
                    data.onSuccess('Competition has been updated')
                    data.hidePopUp()
                })
                .catch(err => data.onFailure(err))
        }
        else {
            axios.post('/leaderboard-competition', d)
                .then(res => history.push(`/competition/${res.data._id}?new=true`))
                .catch(err => data.onFailure(err))
        }
    }
    return (
        <Model component={props.component}
            isNormal={true}
            Root={Form}
            onSubmit={onSubmit}
            title={props._id ? "Edit Competition" : "Create Competition"}
            footer={loading => (
                <div className='model-footer'>
                    {step === 2 && <button onClick={() => setStep(1)} className='btn btn-text' type='button'>Back</button>}
                    <button disabled={loading}
                        className={`btn-primary btn ${loading ? "progress-btn" : ""}`}
                    >{step === 1 ? 'Next' : props._id ? 'Update' : 'Create Competition'}</button>
                </div>
            )}
        >
            <div className='d-flex gap-3 justify-content-center align-items-center'>
                <p className='rounded-badge'>1</p>
                <b>Details</b>
                <span className='text-accent-5'>------------------</span>
                <p className={`rounded-badge ${step === 1 ? 'bg-accent-6 text-accent-4' : ''}`}>2</p>
                <b className={step === 2 ? '' : 'text-accent-4'}>Settings</b>
            </div>
            {step === 1 && <Step1 {...form} />}
            {step === 2 && <Step2 {...form} />}
        </Model>
    )
}