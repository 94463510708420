import React, { useState } from "react";
import Portal from "../../hoc-v2/Portal";
import useDepandentPosition from "../../hooks/useDepandentPosition";
import CheckIcon from "../../icons-v2/CheckIcon";
import ChevronBottom from "../../icons-v2/ChevronBottom";
import setPropsToChildrens from "../../utility/setPropsToChildrens";
import styles from './Select.module.scss';

const SelectV3 = ({ children, onChange, name, className = '', value, component, title, isNormal }) => {
    const [isActive, setActive] = useState(false)
    const [child, setChild] = useState(
        (value && children?.find(child => child.props.value === value)) ||
        children[0] ||
        children
    );

    const onInputChange = (e, child) => {
        setChild(child);
        onChange && onChange(e);
    }


    const refs = useDepandentPosition(isActive)

    const btnProps = {
        type: 'button',
        onBlur: isActive ? (() => setTimeout(() => {
            setActive(false);
        }, 300)) : undefined,
        ref: refs.parentRef,
        onClick: () => setActive(!isActive)
    }

    return (
        <>
            {component ? component(btnProps) : (
                <button {...btnProps}
                    className={`btn btn-text small gap-1 ${className}`}
                > {title || child?.props.children} <ChevronBottom /></button>
            )}
            {isActive && (
                <Portal id='popup-wrapper'>
                    <div ref={refs.childRef} className={`${styles.main} animation-opacity`}>
                        {setPropsToChildrens(children, ch => ({
                            checked: ch.props.children === child.props.children,
                            onChange: e => onInputChange(e, ch),
                            name: name || 'select',
                            type: isNormal ? 'checkbox' : 'radio'
                        }))}
                    </div>
                </Portal>
            )}
        </>
    )
}

export const Option = ({ children, ...props }) => (
    <label className={props.checked && props.type === 'radio' ? styles.checked : ''}>
        <input className='d-none' {...props} />
        {children}
        {props.checked && <CheckIcon />}
    </label>
)

export default SelectV3;