import React, { useState, useCallback } from "react";
import { Link } from "react-router-dom";
import WatchlistV2 from "../../components-v2/WatchlistV2";
import useModel from "../../hooks/useModel";
import SearchField from "../../ui-v2/SearchField/SearchField";
import styles from './DashboardMain.module.scss';
import Algos from "./Algos";
import TotalView from "./total_view";
import ToggleElement from "./toggleElement";
import Switch from "../../ui-v2/Switch";
import useQuery from "../../hooks/useQuery";
import { useApp } from "../../App";


const DashboardMain = props => {
    const [state, setState] = useState({
        algos: [...props.algos],
        search: "",
        isMenu: false,
        dashboard_view: 'all',


    }), { algos, search, isMenu, dashboard_view } = state;
    const onChange = useCallback(e => {
        const search = e.target.value;
        const algos = props.algos.filter(algo => algo.algoId.stockAlgoName.toLowerCase().includes(search.toLowerCase()));
        setState({ ...state, search, algos });
    }, [props])
    const isMobile = useQuery()
    useModel({ shouldWork: isMenu && isMobile, changed: [isMenu], scrollToTop: true });
    const handleToggle = () => {
        console.log('toggle')
        setState({ ...state, dashboard_view: dashboard_view === 'list' ? 'all' : 'list' })
    }
    const isLoggedIn = useApp().isLoggedIn;
    return (
        <div className='d-flex position-relative'>
            <div className="flex-1">

                {dashboard_view === 'list' ?
                    <>
                        <div className='border-bottom overflow-hidden d-flex gap-2 align-items-center p-3 justify-content-between'>
                            <div className="d-md-flex gap-2 align-items-center d-none">
                                <h5 className='m-0'>My Algos</h5>
                                <p className='mt-2 mb-0 text-accent-4'>({algos.length}) </p>
                                <Link to={isLoggedIn ? '/my-account' : '#register'} className='btn-text'>Manage</Link>
                            </div>
                            <SearchField onChange={onChange} name='search' className={`flex-1  ${styles.searchField}`} />
                            <div className="d-flex text-accent-4 small align-items-center gap-2">
                                Dashboard <Switch onChange={handleToggle} /> Separate Tools
                            </div>
                            <button
                                className="btn small btn-text d-lg-none"
                                onClick={() => setState({ ...state, isMenu: true })}
                            >Watchlists <i className="fas fa-chevron-right" />
                            </button>
                        </div>
                        {algos.length > 0 ? <Algos sort={props.sort} algos={algos} /> : <h3 className='my-5 h4-sm container py-5 text-accent-2 fw-mediam text-center'>404, algos with keyword "{search}" doesn't exist!</h3>}
                    </>
                    :
                    <>
                        {algos.length > 0 ?
                            <TotalView isLoggedIn={isLoggedIn} setState={setState} sort={props.sort} algos={algos} handleToggle={handleToggle} />
                            : <h3 className='my-5 h4-sm container py-5 text-accent-2 fw-mediam text-center'>404, algos with keyword "{search}" doesn't exist!</h3>}
                    </>
                }


            </div>
            <WatchlistV2 dashboard_view={dashboard_view === 'all'} isMenu={isMenu} {...props} onClose={() => setState({ ...state, isMenu: false })} />
        </div>
    )

}

export default DashboardMain;
